import React, { useEffect, useState } from "react";
import MapGL, { Source, Layer, FlyToInterpolator } from "react-map-gl";

import { getCenter, getBounds } from "geolib";

import { WebMercatorViewport } from "react-map-gl";
import "./HeatMap.css";

interface IProps {
  lat?: number;
  lon?: number;
  properties: IPropertyEPC[];
  accumulatedCO2: number;
  height: string;
}

const MAX_ZOOM_LEVEL = 20;
const INITIAL_VIEW_STATE = {
  latitude: 50.908187, //
  longitude: -1.4176743,
  zoom: 9,
  height: window.innerHeight,
  width: window.innerWidth,
};
export const heatmapLayer = {
  maxzoom: MAX_ZOOM_LEVEL,
  type: "heatmap" as const,
  paint: {
    // Increase the heatmap weight based on frequency and property magnitude
    "heatmap-weight": [
      "interpolate",
      ["linear"],
      ["get", "mag"],
      0,
      0,
      6,
      1,
    ] as any,
    // Increase the heatmap color weight weight by zoom level
    // heatmap-intensity is a multiplier on top of heatmap-weight
    "heatmap-intensity": [
      "interpolate",
      ["linear"],
      ["zoom"],
      0,
      0,
      5,
      0.5,
    ] as any,
    // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
    // Begin color ramp at 0-stop with a 0-transparancy color
    // to create a blur-like effect.
    "heatmap-color": [
      "interpolate",
      ["linear"],
      ["heatmap-density"],
      0,
      "rgba(33,102,172,0)",
      0.25,
      "rgb(103,169,207)",
      0.5,
      "rgb(209,229,240)",
      0.8,
      "rgb(199, 252, 253)",
      1,
      "rgb(76, 51, 138)",
      2,
      "rgb(101, 175, 255)",
    ] as any,
    // Adjust the heatmap radius by zoom level
    "heatmap-radius": ["interpolate", ["linear"], ["zoom"], 0, 1, 5, 15] as any,
    // Transition from heatmap to circle layer by zoom level
    //'heatmap-opacity': ['interpolate', ['linear'], ['zoom'], 7, 1, 9, 0]
  },
};

export const HeatMap: React.FC<IProps> = (props: IProps) => {
  const mapRef = React.useRef(null);
  const [viewportHeat, setViewportHeat] =
    React.useState<any>(INITIAL_VIEW_STATE);
  const [propCarbon, setPropCarbon] = useState<number>(0);

  useEffect(() => {
    //lat and long can be passed as props to both maps
    if (props.lat != null && props.lon != null) {
      INITIAL_VIEW_STATE.latitude = props.lat;
      INITIAL_VIEW_STATE.longitude = props.lon;
    }
    setViewportHeat(INITIAL_VIEW_STATE);
  }, [props.lat || props.lon]);
  useEffect(() => {
    setTimeout(() => {
      setPropCarbon(props.accumulatedCO2);
    }, 1);
  });

  const makeGeoJSON = (properties: any[]) => {
    //creating the heat map based on the points, and based on how the json is supposed to look like
    return {
      type: "FeatureCollection" as const,
      crs: {
        type: "name",
        properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" },
      },
      features: properties.map((feature: IPropertyEPC) => {
        return {
          type: "Feature" as const,
          properties: {
            id: feature.property_id,
            mag: feature.emissions_current,
            time: 1507425650893,
            felt: null,
            tsunami: 0,
          },
          geometry: {
            type: "Point" as const,
            coordinates: [feature.lon, feature.lat, 0.0],
          },
        };
      }),
    };
  };
  const [propertyIds, setPropertyIds] = useState<number[]>([]);

  useEffect(() => {
    setPropertyIds(props.properties.map((x) => x.property_id));
  }, [props.properties]);

  const data = React.useMemo(() => {
    try {
      //this mainly renders the viewport - when the properties change, the viewport changes automatically
      const markersToCalculateCentre: any = [];
      props.properties.map((property: IPropertyEPC) => {
        markersToCalculateCentre.push({
          latitude: property.lat,
          longitude: property.lon,
        });
      });

      if (markersToCalculateCentre.length != 0) {
        //only update viewport if there is more than 1 property
        const centreOfMarkers = getCenter(markersToCalculateCentre);
        const boundsOfMarkers = getBounds(markersToCalculateCentre);

        if (centreOfMarkers && boundsOfMarkers) {
          let { latitude, longitude, zoom } = new WebMercatorViewport(
            viewportHeat
          ).fitBounds(
            [
              [boundsOfMarkers.minLng, boundsOfMarkers.minLat],
              [boundsOfMarkers.maxLng, boundsOfMarkers.maxLat],
            ],
            {
              padding: 40,
            }
          );
          //if there's only one property
          if (markersToCalculateCentre.length == 1) zoom = 13;
          setViewportHeat({
            latitude: latitude,
            longitude: longitude,
            zoom: zoom,
            transitionInterpolator: new FlyToInterpolator({ speed: 1.2 }),
            transitionDuration: "auto",
          });
        }
      }

      return makeGeoJSON(props.properties);
    } catch {
      console.log("error");
    }
  }, [propCarbon, propertyIds]);

  //process text of acuumulated CO2
  const CO2text = React.useMemo(() => {
    let roundedNumber = Math.floor(props.accumulatedCO2 * 100) / 100; //capped at 2 decimals
    let text = "";
    if (roundedNumber >= 1000) {
      text = (Math.floor(roundedNumber / 10) / 100).toString() + "K";
    } else {
      text = roundedNumber.toString();
    }
    return text;
  }, [propCarbon]);

  return (
    <div id="container">
      <MapGL
        {...viewportHeat}
        mapboxApiAccessToken="pk.eyJ1IjoianNhbmNoOTgiLCJhIjoiY2tzNHVpajEwMjg1cjJwbXNoeGY0NmhkcyJ9.OGFBN2CHTg4vUIIVOk0CIw"
        mapStyle="mapbox://styles/mapbox/dark-v9"
        onViewportChange={(
          e: React.SetStateAction<{
            latitude: number;
            longitude: number;
            zoom: number;
            height: string;
            width: string;
          }>
        ) => {
          setViewportHeat(e);
        }}
        ref={mapRef}
        height={props.height}
        width="100%"
      >
        <Source type="geojson" data={data}>
          <Layer {...heatmapLayer} />
        </Source>
        <p id="CO2text">{CO2text}</p>
        <p id="CO2exptext">CO2 Current SAP (t/yr)</p>
      </MapGL>
    </div>
  );
};

export default HeatMap;
