import React, { useEffect, useState } from "react";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import {
  Card,
  CardContent,
  TextField,
  Button,
  makeStyles,
  Typography,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  IconButton,
} from "@material-ui/core";
import { FilterOptionsState } from "@material-ui/lab";
import { filterProps } from "@deck.gl/aggregation-layers/utils/prop-utils";
import ClearIcon from "@material-ui/icons/Clear";

interface IFilter {
  name: string;
  options: "more" | "less" | "equal" | "category";
  value: string;
}

interface IProps {
  key: number;
  filter: IFilter;
  index: number;
  filterOptions: (
    options: string[],
    state: FilterOptionsState<string>
  ) => string[];
  trySetSelected: (filterName: string) => IFilterObject | undefined;
  assignedFilters: IFilter[];
  setAssignedFilters: React.Dispatch<React.SetStateAction<IFilter[]>>;
  filters: Array<IFilterObject>;
}

export default function FileInput(props: IProps) {
  const [value, setValue] = useState<IFilter["options"]>("equal");
  const [number, setNumber] = useState<number>(0);
  const [textValue, setTextValue] = useState("");

  function getFilterOptions() {
    let filterObj = props.filters.find(
      (filter) => filter.name === props.filter.name
    );
    if (filterObj) {
      return filterObj.options;
    } else {
      return [];
    }
  }
  return (
    <div
      style={{
        display: "flex",
        alignItems: "flex-start",
        flex: "1 0 auto",
        width: "100%",
      }}
    >
      <Autocomplete
        freeSolo
        disableClearable
        key={props.index}
        filterOptions={props.filterOptions}
        onChange={(e: any, newValue: string) => {
          let matched = props.trySetSelected(newValue);
          console.log(newValue);
          console.log(matched);
          if (matched && matched.type === "category") {
            props.setAssignedFilters(
              props.assignedFilters.map((f, i) =>
                i === props.index && matched
                  ? { ...f, options: "category", name: matched.name }
                  : f
              )
            );
          } else if (matched) {
            props.setAssignedFilters(
              props.assignedFilters.map((f, i) =>
                i === props.index && matched
                  ? { ...f, options: "equal", name: matched.name }
                  : f
              )
            );
          }
        }}
        options={props.filters.map((filter) => filter.name)}
        style={{ flex: "2 0 auto", marginRight: "20px" }}
        renderInput={(params: any) => (
          <TextField
            className="address_search"
            {...params}
            label="New Filter"
            margin="dense"
            // color="secondary"
            variant="filled"
            fullWidth
            style={{
              background: "white",
              width: "100%",
              marginBottom: "20px",
              marginRight: "20px",
            }}
            onChange={(event) => {
              let matched = props.trySetSelected(event.target.value);
            }}
            InputProps={{ ...params.InputProps, type: "search" }}
          />
        )}
      />
      {!props.filter.options ? (
        <></>
      ) : props.filter.options === "category" ? (
        <></>
      ) : (
        <FormControl style={{ flex: "1 0 auto", paddingRight: "20px" }}>
          <InputLabel id="option"></InputLabel>
          <Select
            labelId="option"
            id="option"
            value={value}
            label=""
            style={{ flex: "1 0 auto" }}
            onChange={(e: any) => {
              setValue(e.target.value);
              props.setAssignedFilters(
                props.assignedFilters.map((f, i) =>
                  i === props.index ? { ...f, options: e.target.value } : f
                )
              );
            }}
          >
            <MenuItem value={"More"}>More</MenuItem>
            <MenuItem value={"Less"}>Less</MenuItem>
            <MenuItem value={"Equal"}>Equal</MenuItem>
          </Select>
        </FormControl>
      )}
      {props.filter.options === "category" ? (
        <Autocomplete
          freeSolo
          disableClearable
          key={props.index}
          filterOptions={props.filterOptions}
          onChange={(e: any, newValue: string) => {
            setTextValue(newValue);
            props.setAssignedFilters(
              props.assignedFilters.map((f, i) =>
                i === props.index ? { ...f, value: newValue } : f
              )
            );
          }}
          options={getFilterOptions()}
          style={{ flex: "2 0 auto" }}
          renderInput={(params: any) => (
            <TextField
              className="address_search"
              {...params}
              label="New Filter"
              margin="dense"
              // color="secondary"
              variant="filled"
              style={{
                background: "white",
                marginBottom: "20px",
                flex: "2 0 auto",
              }}
              InputProps={{ ...params.InputProps, type: "search" }}
            />
          )}
        />
      ) : (
        <TextField
          label="Value"
          variant="filled"
          margin="dense"
          style={{
            background: "white",
          }}
          onChange={(event: any) => {
            setNumber(event.target.value);
            setTextValue(event.target.value);
            props.setAssignedFilters(
              props.assignedFilters.map((f, i) =>
                i === props.index ? { ...f, value: event.target.value } : f
              )
            );
          }}
        />
      )}
      {props.assignedFilters.length - 1 == props.index ? (
        <IconButton
          onClick={() => {
            props.setAssignedFilters(
              props.assignedFilters.filter((f, i) => i !== props.index)
            );
          }}
        >
          <ClearIcon />
        </IconButton>
      ) : (
        <></>
      )}
    </div>
  );
}
