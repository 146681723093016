import React, { useState, useEffect } from "react";
import Waterfall from "./Waterfall";

interface IProps {
  co2Reductions: Map<string, number>;
  co2Emissions: number;
}

interface column {
  x: string;
  y: [number, number];
  fillColor: string | number;
}

interface seriesArray {
  data: column[];
}

export default function WaterfallLoader(props: IProps) {
  const [co2AfterReductions, setCo2AfterReductions] = useState<
    Map<string, number>
  >(new Map());

  const [options, setOptions] = useState({
    chart: {
      id: "apexchart-example",
      background: "#fff",
    },
    dataLabels: {
      style: {
        fontSize: "120px",
        fontFamily: "Helvetica, Arial, sans-serif",
        fontWeight: "bold",
      },
    },
    xaxis: {
      labels: {
        style: {
          fontSize: "9px",
        },
        rotate: 0
      },
      categories: ['']
    },
    plotOptions: {
      bar: {
        columnWidth: "80%",
      },
    },
  });

  const [series, setSeries] = useState<seriesArray[]>([
    {
      data: [
        {
          x: "Co2 Emissions",
          y: [0, props.co2Emissions],
          fillColor: "#949494",
        },
      ],
    },
  ]);

  const [loading, setLoading] = useState(true);

  /** 
   Sets the values of the map containing co2 values after reductions
   based on new co2 reduction from props
  */
  function calculateCo2AfterReductions(
    interventions: Map<string, number>
  ): void {
    const newCo2AfterReductions = new Map<string, number>();
    const arrayNewCo2Reductions = Array.from(interventions);

    const reducer = (acc: number, intervention: [string, number]): number => {
      try {
        let newValue: number = acc + Math.round(intervention[1]);
        if (newValue < 0) {
          newValue = 0;
        }
        newCo2AfterReductions.set(intervention[0], newValue);
        return newValue;
      } catch (error) {
        return acc;
      }
    };
    arrayNewCo2Reductions.reduce(reducer, props.co2Emissions);
    setCo2AfterReductions(newCo2AfterReductions);
  }

  const breakString = (input: string) : string[] | string => {
    if(input){
      if(input.length > 21){
        let fragments = input.split(' ')
        let x = Math.floor(input.length / 21)
        let result : string[] = []
  
        console.log('x :' + x, input.length);
  
        for(let i = 0; i < (x+1); i++){
          let combined = fragments.slice(0, 4).reduce((p, c) => p + ' ' + c, '')
          result.push(combined)
          fragments = fragments.slice(4)
        }
        return result;
      }
    }
    
    return input;
  }

  useEffect(() => {
    console.log(
      "co2reductions from waterfallloader " + Array.from(props.co2Reductions)
    );
    calculateCo2AfterReductions(props.co2Reductions);
  }, [props.co2Reductions]);

  /**
   * creates formatted list of objects and appends them to series data
   */
  useEffect(() => {

    const listToAppend: column[] = [];
    const currentEmissions: column[] = [
      {
        x: "",
        y: [0, props.co2Emissions],
        fillColor: "#949494",
      },
    ];
    let lastValue = props.co2Emissions;

    const intCategories : any[] = []

    intCategories.push('Co2 Emissions');

    co2AfterReductions.forEach((value, intervention) => {
      console.log(intervention);
      intCategories.push(breakString(intervention));
      listToAppend.push({
        x: '',
        y: [lastValue, value],
        fillColor: "#fca030",
      });
      lastValue = value;
    });

    const potentialEmissions: column = {
      x: "",
      y: [lastValue, 0],
      fillColor: "#949494",
    };

    intCategories.push("Potential CO2 Emissions");
    console.log(intCategories);
    setSeries([
      {
        data: currentEmissions.concat(listToAppend).concat(potentialEmissions),
      },
    ]);
    setOptions({...options, xaxis: {...options.xaxis, categories: intCategories}})
    console.log(options);
    setLoading(false);
  }, [co2AfterReductions]);

  // useEffect(() => {
  //   setOptions({ ...options });
  // }, [series]);


  return (
    <>{loading ? <></> : <Waterfall options={options} series={series} />}</>
  );
}
