import React from "react";
import { ApexOptions } from "apexcharts";
import Chart from "react-apexcharts";

interface IProps {
  options: ApexOptions;
  series: any;
}

export default function ImdChart(props: IProps) {
  return (
    <Chart
      options={props.options}
      series={props.series}
      type="bar"
      width="95%"
      height="300px"
    />
  );
}
