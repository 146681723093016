import React, { useState, useEffect, useContext } from "react";
import "./login-page.css";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import {
  // Button,
  Form,
  Grid,
  Header,
  Message,
  Segment,
} from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import { GetLogins, ChangePassword, Refresh, GetGroup } from "./login-fetch";
import Logo from "../../img/logo_absolar.png";
import { UserContext } from "../../contexts/UserContext";

interface IProps {
  redirected: boolean;
  setUserGroup: React.Dispatch<React.SetStateAction<string>>;
}
export const LogInPage: React.FC<IProps> = (props: IProps) => {
  const userContext = useContext(UserContext);

  const [emailValue, setEmailValue] = React.useState<string>("");
  const [passwordValue, setPasswordValue] = React.useState<string>("");
  const [newPasswordValue, setNewPasswordValue] = React.useState<string>("");
  const [newPassRequired, setNewPassRequired] = React.useState<boolean>(false);
  const handleSignUp = () => {
    alert("gonna open sign up");
    //history.push(something);
  };

  const checkCredentials = () => {
    // alert(emailValue + ' ' + passwordValue);
    GetLogins({
      username: emailValue,
      password: passwordValue,
    })
      .then((response: LoginResponse) => {
        //todo check response for change password
        if (
          response.challengeName &&
          Object.values(response.challengeName).includes(
            "NEW_PASSWORD_REQUIRED"
          )
        ) {
          alert("new password required");
          setNewPassRequired(true);
        } else if (
          response.authenticationResult &&
          Object.keys(response.authenticationResult).includes("idToken")
        ) {
          GetGroup(response.authenticationResult["idToken"]).then((groupResponse) => groupResponse.text())
          .then((group: string)=>{
            props.setUserGroup(group);
            if (
              response.authenticationResult){
                userContext.setUserState({
                  username: emailValue,
                  idToken: response.authenticationResult["idToken"],
                  accessToken: response.authenticationResult["accesssToken"],
                  refreshToken: response.authenticationResult["refreshToken"],
                  loggedIn: true,
                });
              }
          
          }).catch((e)=>{
            alert("login failed, user group not set, please email info@absolar.co.uk to fix your account")
          });
          
          // if (!props.redirected) {
          //     history.push('/');
          // }
        }
      })
      .catch((e) => {
        alert("incorrect username or password");
        alert(e.message);
      });
  };

  const changePassword = () => {
    // alert(emailValue + ' ' + passwordValue);
    ChangePassword({
      username: emailValue,
      password: passwordValue,
      newPassword: newPasswordValue,
    })
      .then((response: LoginResponse) => {
        //todo check response for change password
        if (
          response.challengeName &&
          Object.values(response.challengeName).includes(
            "NEW_PASSWORD_REQUIRED"
          )
        ) {
          alert("new Pass required");
          setNewPassRequired(true);
        } else if (
          response.authenticationResult &&
          Object.keys(response.authenticationResult).includes("idToken")
        ) {
          userContext.setUserState({
            username: emailValue,
            idToken: response.authenticationResult["idToken"],
            accessToken: response.authenticationResult["accesssToken"],
            refreshToken: response.authenticationResult["refreshToken"],
            loggedIn: true,
          });
          // if (!props.redirected) {
          //     history.push('/');
          // }
        } else {
          alert("something went wrong");
        }
      })
      .catch((e: Error) => {
        alert(e.message);
      });
  };

  return (
    <div className="login-page">
      <Grid textAlign="center" id="log-in-container" verticalAlign="middle">
        <Grid.Column>
          <div id="login_title_container">
            <img src={Logo} style={{ width: "100px" }} />
            <br />
            <span className="loginTitle">Carbon Planning</span>
          </div>
          <Form id="log_in_form">
            <TextField
              // id="log_in_form"
              label="User email"
              variant="outlined"
              style={{ marginBottom: 20 }}
              fullWidth
              value={emailValue}
              onChange={(e) => {
                setEmailValue(e.target.value);
              }}
            />
            <TextField
              // id="log_in_form"
              label="Password"
              type="password"
              variant="outlined"
              style={{ marginBottom: 20 }}
              fullWidth
              value={passwordValue}
              onChange={(p) => {
                setPasswordValue(p.target.value);
              }}
            />

            {/* <Segment className="loginPanel" stacked> */}
            {/* <Form.Input
              id="log_in_form"
              fluid
              icon="user"
              iconPosition="left"
              placeholder="E-mail address"
              value={emailValue}
              onChange={(e) => {
                setEmailValue(e.target.value);
              }}
            />
            <Form.Input
              id="log_in_form"
              fluid
              icon="lock"
              iconPosition="left"
              placeholder="Password"
              type="password"
              value={passwordValue}
              onChange={(p) => {
                setPasswordValue(p.target.value);
              }}
            /> */}

            <Button
              id="btn_SSC"
              disabled={newPassRequired}
              variant="contained"
              color="primary"
              fullWidth
              onClick={checkCredentials}
            >
              Login
            </Button>
            {/* </Segment> */}
            {newPassRequired ? (
              <Segment>
                Please update your password:
                <Form.Input
                  fluid
                  icon="lock"
                  iconPosition="left"
                  placeholder="New Password"
                  type="password"
                  value={newPasswordValue}
                  onChange={(e) => {
                    setNewPasswordValue(e.target.value);
                  }}
                />
                {/* <Form.Input
                                    fluid
                                    icon="lock"
                                    iconPosition="left"
                                    placeholder="Password"
                                    type="password"
                                    value={passwordValue}
                                    onChange={(e) => {
                                        setNewPasswordValue(e.target.value);
                                    }}
                                /> */}
                <Button
                  id="btn_SSC"
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={changePassword}
                >
                  Update
                </Button>
              </Segment>
            ) : null}
          </Form>
          {/* <Message id="signUpPanel">
                        New to us?{' '}
                        <a href="#" onClick={handleSignUp}>
                            Sign Up
                        </a>
                    </Message> */}
        </Grid.Column>
      </Grid>
    </div>
  );
};
