import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import ActionPlanChart from "./ActionPlanChart";
import "../ActionPlanPage/ActionPlanPage.css";

interface IProps {
  co2Data: number[];
  fuelBillData: number[];
  sapScores: number[];
  yearRange: number[];
  isChartLoading: boolean;
  portfolioYearInterventions?: Array<YearPortfolioIntervention>;
}

interface columnRange {
  from: number;
  to: number;
  color: string;
}

export default function ActionPlanChartLoader(props: IProps): JSX.Element {
  const getLetter = (score: number) => {
    if (score < 21) {
      return " | G";
    } else if (score < 39) {
      return " | F";
    } else if (score < 55) {
      return " | E";
    } else if (score < 69) {
      return " | D";
    } else if (score < 81) {
      return " | C";
    } else if (score < 92) {
      return " | B";
    } else {
      return " | A";
    }
  };
  const [year, setYear] = useState<number>(0);

  const [series, setSeries] = useState([
    {
      name: "CO2",
      type: "line",
      data: props.co2Data,
    },
    {
      name: "Real fuel bill",
      type: "line",
      data: props.fuelBillData,
    },
    {
      name: "EPC Rating",
      type: "column",
      data: props.sapScores,
    },
  ]);

  const [options, setOptions] = useState<ApexOptions>({
    chart: {
      type: "line",
      zoom: {
        enabled: false,
      },
      background: "#fff",
      events: {
        dataPointMouseEnter: function (event, chartContext, config) {
          setYear(2012 + parseInt(config.dataPointIndex.toString()));
        },
      },
    },
    plotOptions: {
      bar: {
        columnWidth: "100%",
        colors: {
          ranges: [
            {
              from: 0,
              to: 20,
              color: "#db4130",
            },
            {
              from: 21,
              to: 38,
              color: "#db7902",
            },
            {
              from: 39,
              to: 54,
              color: "#dba502",
            },
            {
              from: 55,
              to: 68,
              color: "#d4e612",
            },
            {
              from: 69,
              to: 80,
              color: "#73c400",
            },
            {
              from: 81,
              to: 91,
              color: "#00c92c",
            },
            {
              from: 92,
              to: 120,
              color: "#009621",
            },
          ],
        },
      },
    },
    colors: ["#77B6EA", "#545454"],
    stroke: {
      width: [4, 4],
      colors: ["#77B6EA", "#545454", "transparent"],
      dashArray: [0, 5, 0],
    },
    title: {
      text: "Forecast KPI's",
    },
    xaxis: {
      type: "category",
      categories: props.yearRange,
      labels: {
        rotate: 0,
        hideOverlappingLabels: true,
      },

      tooltip: {
        enabled: true,
      },
    },
    yaxis: [
      {
        title: {
          text: "CO2 (market)",
        },
      },
      { show: false },
      {
        opposite: true,
        title: {
          text: "EPC Rating",
        },
        seriesName: "EPC Rating",
        labels: {
          formatter: (value) => {
            return value.toString() + getLetter(value);
          },
        },
      },
    ],
  });

  useEffect(() => {
    // console.log("array", props.portfolioYearInterventions);
    setSeries([
      {
        name: "CO2",
        type: "line",
        data: props.co2Data,
      },
      {
        name: "Real fuel bill",
        type: "line",
        data: props.fuelBillData,
      },
      {
        name: "EPC Rating",
        type: "column",
        data: props.sapScores,
      },
    ]);
    setOptions({ ...options, xaxis: { categories: props.yearRange } });
  }, [props]);

  return (
    <>
      <ActionPlanChart
        portfolioYearInterventions={props.portfolioYearInterventions}
        year={year}
        options={options}
        series={series}
      />
    </>
  );
}
