import { Button, TableCell, TableRow, Input } from "@material-ui/core";
import "./ActionPlanPage.css";
import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../contexts/UserContext";
import { getData } from "../get-data";
import CheckIcon from "@mui/icons-material/Check";
import SaveIcon from "@mui/icons-material/Save";
import { IconButton } from "@mui/material";
import { Delete } from "@mui/icons-material";

interface IProps {
  key: number;
  tariffChange: TariffChange;
  tariffs: Array<tariff>;
  editing: boolean;
  energyCategories: EnergyCategory[];
  updateTimeline: () => void;
  removeTariffChange: (tariffChangeId: number) => void;
}

export default function TariffChangeRow(props: IProps) {
  const [value, setValue] = useState<string>(
    props.tariffChange.plannedYear.toString()
  );
  const userContext = useContext(UserContext);

  const [checked, setChecked] = useState<boolean>(false);

  function updateTariffYear(tariffChangeId: number, year: string) {
    const url =
      "/api/sap/tariff_change_year?tariffChangeId=" +
      tariffChangeId +
      "&year=" +
      year;

    return getData("https://captapi.absolarlab.com" + url, {
      userState: userContext.userState,
      setUserState: userContext.setUserState,
    }).then((res) => {
      props.updateTimeline();
    });
  }

  return (
    <TableRow key={props.key}>
      <TableCell>
        {
          props.tariffs.find((t) => t.id === props.tariffChange.fuelLookupId)
            ?.fuel
        }
      </TableCell>
      <TableCell>
        {
          props.energyCategories.find(
            (e) => e.id === props.tariffChange.energyCategoryId
          )?.name
        }
      </TableCell>
      <TableCell>
        <Input
          type="number"
          defaultValue={props.tariffChange.plannedYear}
          onChange={(event) => {
            setValue(event.target.value);
            setChecked(false);
          }}
          disabled={!props.editing}
        />
      </TableCell>
      <TableCell>
        {props.editing ? (
          <div>
            {checked ? (
              <IconButton>
                <CheckIcon />
              </IconButton>
            ) : (
              <IconButton
                onClick={() => {
                  updateTariffYear(props.tariffChange.id, value);
                  setChecked(true);
                }}
              >
                <SaveIcon />
              </IconButton>
            )}
            <IconButton
              style={{ marginLeft: 10 }}
              onClick={() => {
                props.removeTariffChange(props.tariffChange.id);
              }}
            >
              <Delete />
            </IconButton>
          </div>
        ) : null}
      </TableCell>
    </TableRow>
  );
}
