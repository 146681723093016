import React, { useState, useEffect } from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  Button,
  TablePagination,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { Checkbox } from "@material-ui/core";
import { numberWithCommas } from "../utilities";

interface IProps {
  interventionList: PortfolioIntervention[];
  addAllInterventions: (ticked: boolean) => void;
  addTickedIntervention: (interventionId: number) => void;
  tickedInterventions: number[];
  defaultRows: number;
}

export default function InterventionSelect(props: IProps) {
  const history = useHistory();
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [currentProp, setCurrentProp] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(props.defaultRows);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
    setCurrentProp(newPage * rowsPerPage);
  };
  return (
    <Table size="small">
      <TableHead>
        <TablePagination
          count={props.interventionList.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
        <TableRow>
          <TableCell style={{ width: "10%" }}>
            <Checkbox
              checked={
                props.tickedInterventions.length ===
                props.interventionList.length
              }
              onChange={() => {
                if (!selectAll) {
                  props.addAllInterventions(true);
                } else {
                  if (
                    props.tickedInterventions.length !==
                    props.interventionList.length
                  ) {
                    props.addAllInterventions(true);
                  } else {
                    props.addAllInterventions(false);
                  }
                }
                setSelectAll(!selectAll);
              }}
              color="primary"
            />{" "}
            Add
          </TableCell>
          <TableCell>Intervention</TableCell>
          <TableCell>Install Cost</TableCell>
          <TableCell>CO2 Impact (Year 1)</TableCell>
          <TableCell>Fuel Cost Impact (Year 1)</TableCell>
          <TableCell>CO2 Saving over Lifetime</TableCell>
          <TableCell>Bill saving over lifetime</TableCell>
          <TableCell>Number of properties</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {props.interventionList.length > 0
          ? props.interventionList
              .slice(currentProp, currentProp + rowsPerPage)
              .map((element) => (
                <InterventionRow
                  element={element}
                  selectAll={selectAll}
                  key={element.interventionId}
                  addTickedIntervention={props.addTickedIntervention}
                  tickedInterventions={props.tickedInterventions}
                />
              ))
          : null}
      </TableBody>
    </Table>
  );
}
function InterventionRow(props: {
  element: PortfolioIntervention;
  selectAll: boolean;
  key: number;
  addTickedIntervention: (interventionId: number) => void;
  tickedInterventions: number[];
}): JSX.Element {
  const [checked, setChecked] = useState<boolean>(
    props.tickedInterventions.includes(props.element.interventionId)
  );
  useEffect(() => {
    setChecked(
      props.tickedInterventions.includes(props.element.interventionId)
    );
  }, [props.selectAll]);
  return (
    <TableRow key={props.key}>
      <TableCell>
        <Checkbox
          checked={checked}
          onChange={() => {
            props.addTickedIntervention(props.element.interventionId);

            setChecked(!checked);
          }}
          color="primary"
        />
      </TableCell>
      <TableCell>{props.element.name}</TableCell>
      <TableCell align="right">
        £{numberWithCommas(props.element.totalCost.toFixed(0))}
      </TableCell>
      <TableCell align="right">
        {numberWithCommas(props.element.currentCo2Impact.toFixed(0)) + " "}
        kg
      </TableCell>
      <TableCell align="right">
        £
        {numberWithCommas(props.element.currentFuelCostImpact.toFixed(0)) + " "}
      </TableCell>
      <TableCell align="right">
        {props.element.lifetime
          ? numberWithCommas(
              (props.element.lifetime * props.element.currentCo2Impact).toFixed(
                0
              )
            ) + " kg"
          : "n/a"}
      </TableCell>
      <TableCell align="right">
        £
        {props.element.lifetime
          ? numberWithCommas(
              (
                props.element.currentFuelCostImpact * props.element.lifetime
              ).toFixed(0)
            )
          : "n/a"}
      </TableCell>

      <TableCell align="center">{props.element.numberProperties}</TableCell>
    </TableRow>
  );
}
