import React from "react";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

interface IProps {
  options: ApexOptions;
  series: any[];
}

export default function InfillChart(props: IProps): JSX.Element {
  return (
    <Chart
      options={props.options}
      series={props.series}
      type="bar"
      width="100%"
      height="45vh"
    />
  );
}
