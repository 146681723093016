import {
  Button,
  Card,
  CardContent,
  Grid,
  makeStyles,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  TextField,
} from "@material-ui/core";

import "./PageNotFoundPage.css";
import React, { useEffect, useState } from "react";
import Missing_png from "../../img/Mistake.jpg";

export default function PageNotFound() {
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <div
        style={{
          backgroundImage: `url(${Missing_png})`,
          width: "100%",
          height: "100vh",
        }}
      >
        <div
          style={{
            backgroundColor: "black",
            opacity: 0.75,
            width: "100%",
            height: "100%",
            display: "flex",
            alignSelf: "center",
          }}
        >
          <div style={{ width: "70%", margin: "auto", padding: 20 }}>
            <div
              style={{
                fontSize: "6vw",
                fontWeight: "bolder",
                color: "white",
                zIndex: 100,
              }}
            >
              Oops... 
            </div>
            <div style={{ fontSize: "2vw", fontWeight: 500, color: "white" }}>
              No EPC information available for this property
            </div>
            <div style={{ color: "white", textShadow: "2px 2px 3px black" }}>
              This is likely to be caused by lack of EPC assessment for this
              property and that we are not able to identify a similar property
              within nearby distance. To address this gap of information, it is
              highly recommended to conduct an EPC assessment. Otherwise, if you think there is
              something wrong, please let us know by:{" "}
              <a href="mailto:info@absolar.co.uk">info@absolar.co.uk</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
