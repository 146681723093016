import "./interventionspage.css";
import {
  Card,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Switch,
  Button,
  Modal,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  Input,
  TextField,
} from "@material-ui/core";
import "../../App.css";
import WaterfallLoader from "../Waterfall/WaterfallLoader";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useState, useEffect, useContext } from "react";

import { useParams } from "react-router-dom";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { UserContext } from "../../contexts/UserContext";
import { deleteData, getData, postData } from "../get-data";
import PropertySelect from "./PropertySelect";
import LoadingOverlay from "react-loading-overlay-ts";
import DeleteIcon from "@material-ui/icons/Delete";
import BulkSetYear from "./BulkSetYear";

interface IProps {
  portfolioDataLoading: boolean;
  portfolioInterventionsDto: PortfolioInterventionsDto;
  setPortfolioInterventionsDto: React.Dispatch<
    React.SetStateAction<PortfolioInterventionsDto>
  >;
  tariffs: Array<tariff>;
  selectedPortfolio: PortfolioDetail;
  getPortfolioInterventions: (customTariff?: number | undefined) => void;
  getPortfolio: (id?: number) => void;
}

interface IParams {
  id: string | undefined;
}

export default function PortfolioInterventionsPage(props: IProps) {
  const userContext = useContext(UserContext);
  const [toggleSap, setToggleSap] = useState<boolean>(false);
  const [toggleCustomTariff, setToggleCustomTariff] = useState<boolean>(false);
  const [addInterventionModal, setAddInterventionModal] =
    useState<boolean>(false);
  const [fuelChange, setFuelChange] = useState<boolean>(false);
  const [newKwhImpact, setNewKwhImpact] = useState<number>(0);
  const [newInstallCost, setNewInstallCost] = useState<number>(0);
  const [addingIntervention, setAddingIntervention] = useState<boolean>(false);
  const [loadingOn, setLoadingOn] = useState<boolean>(false);
  const [energyCategory, setEnergyCategory] = useState<EnergyCategory>({
    id: -1,
    name: "Please Select One",
  });
  const [energyTariff, setEnergyTariff] = useState<tariff>({
    ...({} as tariff),
    fuel: "Select a Tariff",
    id: -1,
  });
  const [energyCategories, setEnergyCategories] = useState<
    Array<EnergyCategory>
  >([]);
  const [newIntervention, setNewIntervention] = useState<string>("");
  const [interventionReduces, setInterventionReduces] = useState<boolean>(true);
  const [planned, setPlanned] = useState<Map<string, number>>(
    new Map<string, number>()
  );
  const [interventionTypes, setInterventionTypes] = useState<
    Array<InterventionType>
  >([]);
  const [interventionToDelete, setInterventionToDelete] = useState<
    PortfolioIntervention | undefined
  >();

  const [portfolioProperties, setPortfolioProperties] = useState<
    Array<PortfolioProperty>
  >([]);
  const [tickedProperties, setTickedProperties] = useState<number[]>([]);

  const { id } = useParams<IParams>();
  const [updated, setUpdated] = useState<boolean>(false);

  function numberWithCommas(x: number | string) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  const totalCo2 = () => {
    if (toggleSap) {
      return props.portfolioInterventionsDto.portfolioInterventionsDtos.reduce(
        (sum, x) => sum + x.carbon,
        0
      );
    } else {
      return props.portfolioInterventionsDto.portfolioInterventionsDtos.reduce(
        (sum, x) => sum + x.currentCo2Impact,
        0
      );
    }
  };
  const addTickedProperty = (propertyId: number) => {
    let newTickedProperties = tickedProperties;
    if (newTickedProperties.find((x) => x === propertyId)) {
      newTickedProperties = newTickedProperties.filter((x) => x !== propertyId);
    } else {
      newTickedProperties = newTickedProperties.concat(propertyId);
    }
    setTickedProperties(newTickedProperties);
  };
  const addAllProperties = (ticked: boolean) => {
    if (ticked) {
      const newTickedProperties: number[] = [];
      portfolioProperties.forEach((property) =>
        newTickedProperties.push(property.propertyId)
      );
      setTickedProperties(newTickedProperties);
    } else {
      setTickedProperties([]);
    }
  };

  const postBulkIntervention = (data: {
    interventionName: string;
    tariffChange: boolean;
    tariffId: number;
    portfolioId: number;
    plannedYear: number;
    installCost: number;
    kwhImpact: number;
    energyCategoryId: number;
    propertyIds: number[];
  }) => {
    setAddInterventionModal(false);
    setAddingIntervention(false);
    setLoadingOn(true);

    postData(
      "https://captapi.absolarlab.com/api/portfolio/bulk_add_interventions",
      data,
      {
        userState: userContext.userState,
        setUserState: userContext.setUserState,
      }
    )
      .then(() => {
        setNewInstallCost(0);
        setNewKwhImpact(0);
      })
      .then(() => {
        if (toggleCustomTariff) {
          props.getPortfolioInterventions(35);
        } else {
          props.getPortfolioInterventions();
        }
      })
      .then(() => {
        setLoadingOn(false);
        setTickedProperties([]);
      });
  };

  function bulkDeleteIntervention(interventionId: number) {
    let url =
      "/api/portfolio/" +
      props.selectedPortfolio.id.toString() +
      "/bulk_delete_interventions?interventionId=" +
      interventionId.toString();
    if (toggleCustomTariff) {
      url += "&customTarrif=35";
    }
    deleteData("https://captapi.absolarlab.com" + url, {
      userState: userContext.userState,
      setUserState: userContext.setUserState,
    })
      .then((response) => {
        return response.json();
      })
      .then((portfolioInterventionsDto: PortfolioInterventionsDto) => {
        props.setPortfolioInterventionsDto(portfolioInterventionsDto);
        setInterventionToDelete(undefined);
      })
      .catch((e) => {
        alert(e.message);
      });
  }

  const fetchInterventionTypes = () => {
    const url = "/api/sap/interventionlist";
    getData("https://captapi.absolarlab.com" + url, {
      userState: userContext.userState,
      setUserState: userContext.setUserState,
    }).then((interventionTypes: Array<InterventionType>) => {
      setInterventionTypes(interventionTypes);
    });
  };
  const fetchEnergyCategories = () => {
    const url = "/api/sap/energycategories";

    getData("https://captapi.absolarlab.com" + url, {
      userState: userContext.userState,
      setUserState: userContext.setUserState,
    }).then((energyCategories: Array<EnergyCategory>) => {
      setEnergyCategories(energyCategories);
    });
  };

  const fetchProperties = () => {
    const url =
      "/api/portfolio/portfolioproperties?portfolioId=" +
      props.selectedPortfolio.id.toString();

    getData("https://captapi.absolarlab.com" + url, {
      userState: userContext.userState,
      setUserState: userContext.setUserState,
    }).then((portfolioProperties: Array<PortfolioProperty>) => {
      setPortfolioProperties(portfolioProperties);
    });
  };

  useEffect(() => {
    if (props.selectedPortfolio.id == -1 && id) {
      props.getPortfolio(parseInt(id));
    }
    fetchInterventionTypes();
    fetchEnergyCategories();
    fetchProperties();
  }, []);
  useEffect(() => {
    if (props.selectedPortfolio.id == -1 && id) {
      props.getPortfolio(parseInt(id));
    }
    fetchInterventionTypes();
    fetchEnergyCategories();
    fetchProperties();
  }, [props.selectedPortfolio]);

  useEffect(() => {
    if (
      props.portfolioInterventionsDto.portfolioInterventionsDtos.length !== 0
    ) {
      if (toggleSap) {
        setPlanned(
          new Map<string, number>(
            props.portfolioInterventionsDto.portfolioInterventionsDtos.map(
              (i) => [i.name, -parseInt(i.carbon.toFixed(0))]
            )
          )
        );
      } else {
        setPlanned(
          new Map<string, number>(
            props.portfolioInterventionsDto.portfolioInterventionsDtos.map(
              (i) => [i.name, -parseInt(i.currentCo2Impact.toFixed(0))]
            )
          )
        );
      }
    }
  }, [props.portfolioInterventionsDto.portfolioInterventionsDtos]);

  useEffect(() => {
    if (
      props.portfolioInterventionsDto.portfolioInterventionsDtos.length !== 0
    ) {
      if (toggleSap) {
        setPlanned(
          new Map<string, number>(
            props.portfolioInterventionsDto.portfolioInterventionsDtos.map(
              (i) => [i.name, -parseInt(i.carbon.toFixed(0))]
            )
          )
        );
      } else {
        setPlanned(
          new Map<string, number>(
            props.portfolioInterventionsDto.portfolioInterventionsDtos.map(
              (i) => [i.name, -parseInt(i.currentCo2Impact.toFixed(0))]
            )
          )
        );
      }
    }
  }, [toggleSap]);
  useEffect(() => {
    if (toggleCustomTariff) {
      props.getPortfolioInterventions(35);
    } else {
      props.getPortfolioInterventions();
    }
  }, [toggleCustomTariff]);

  const fetchCSV = (intervention: PortfolioIntervention) => {
    const url = "/api/export/intervention_csv";
    const parameters =
      "?portfolioId=" +
      props.selectedPortfolio.id +
      "&interventionId=" +
      intervention.interventionId;
    let name =
      props.selectedPortfolio.name +
      ":" +
      intervention.interventionId +
      "CAPT.csv";
    name = name.replace(/ /g, "_");
    postData(
      "https://captapi.absolarlab.com" + url + parameters,
      {},
      userContext,
      true
    )
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = name;
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove(); //afterwards we remove the element again
      });
  };

  return (
    <>
      <Card className="card" style={{ backgroundColor: "#113d64" }}>
        <LoadingOverlay
          className="myLoading"
          active={props.portfolioDataLoading}
          spinner
          text="Loading..."
        >
          <CardContent className="header">
            <h3 className="headerText">{props.selectedPortfolio.name}</h3>
          </CardContent>
          <hr className="solid" />
          <CardContent
            className="interventionsContent"
            style={{ paddingTop: "0px", paddingBottom: "5px" }}
          >
            <h3 className="headerText">Interventions</h3>
            <Grid
              container
              spacing={2}
              style={{ height: "80px", marginBottom: "16px" }}
            >
              <Grid md={4} item>
                <div
                  style={{
                    backgroundColor: "#fff",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "3px",
                    height: "100%",
                  }}
                >
                  Total Cost <br />
                  <b>
                    £
                    {props.portfolioInterventionsDto
                      ? numberWithCommas(
                          props.portfolioInterventionsDto.portfolioInterventionsDtos
                            .reduce((sum, x) => sum + x.totalCost, 0)
                            .toFixed(0)
                        )
                      : 0}
                  </b>
                </div>
              </Grid>
              <Grid md={4} item>
                <div
                  style={{
                    backgroundColor: "#fff",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "3px",
                    height: "100%",
                  }}
                >
                  {toggleSap
                    ? "Total CO2 Impact (SAP2012)"
                    : "Total CO2 Impact (Current Rates)"}{" "}
                  <br />
                  <b>{numberWithCommas(totalCo2().toFixed(0))} kg/year</b>
                </div>
              </Grid>
              <Grid md={4} item>
                <div
                  style={{
                    backgroundColor: "#fff",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "3px",
                    height: "100%",
                  }}
                >
                  Number of Unique Properties
                  <br />
                  <b>
                    {props.portfolioInterventionsDto
                      ? numberWithCommas(
                          props.portfolioInterventionsDto.numberProperties
                        )
                      : "loading..."}
                  </b>
                </div>
              </Grid>
            </Grid>
            <div style={{ display: "flex", alignItems: "center" }}>
              {toggleSap ? (
                <h2 className="headerText">CO2 impact (SAP 2012)</h2>
              ) : (
                <h2 className="headerText">CO2 impact (Current Rates)</h2>
              )}

              {/* <h2 className="headerText" style={{ paddingLeft: "10%" }}>
            Market Rate Fuel cost impact
          </h2> */}
            </div>
            <div style={{ color: "white" }}>
              Current Rates
              <Switch
                checked={toggleSap}
                onChange={() => {
                  setToggleSap(!toggleSap);
                }}
              />
              SAP 2012
            </div>
            <div style={{ color: "white", alignItems: "right" }}>
              Existing Tariff
              <Switch
                checked={toggleCustomTariff}
                onChange={() => {
                  setToggleCustomTariff(!toggleCustomTariff);
                }}
              />
              If electricity tariff applied
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flex: "1 0 1",
                paddingBottom: "0px",
              }}
            >
              <div style={{ width: "85vw", paddingBottom: "0px" }}>
                <WaterfallLoader
                  co2Reductions={planned}
                  co2Emissions={
                    toggleSap
                      ? props.portfolioInterventionsDto.emissions
                      : props.portfolioInterventionsDto.currentEmissions
                  }
                />
              </div>
            </div>
            <Button
              disabled={tickedProperties.length === 0 && addingIntervention}
              size="small"
              variant="contained"
              onClick={() => {
                if (addingIntervention) {
                  if (newIntervention === "") {
                    alert("Please enter the intervention name");
                  } else {
                    setAddInterventionModal(true);
                  }
                } else {
                  setAddingIntervention(true);
                }
              }}
            >
              {addingIntervention ? "Confirm" : "Add"} new intervention
            </Button>
            {addingIntervention ? (
              <Button
                size="small"
                variant="contained"
                onClick={() => {
                  setAddingIntervention(false);
                  setNewInstallCost(0);
                  setNewKwhImpact(0);
                  setTickedProperties([]);
                  setLoadingOn(false);
                }}
              >
                Cancel
              </Button>
            ) : (
              <BulkSetYear
                interventions={
                  props.portfolioInterventionsDto.portfolioInterventionsDtos
                }
                selectedPortfolio={props.selectedPortfolio}
                setUpdated={setUpdated}
              />
            )}
            {addingIntervention ? (
              <div
              className="action-plan_inner"
                style={{
                  backgroundColor: "white",
                  marginTop: "25px",
                  marginBottom: "20px",
                }}
              >
                <Table size="small">
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <Autocomplete
                          style={{ width: "12vw" }}
                          freeSolo
                          id="free-solo-2-demo"
                          disableClearable
                          onChange={(e: any, newValue: string) => {
                            setNewIntervention(newValue);
                          }}
                          defaultValue={""}
                          options={interventionTypes.map(
                            (option) => option.name
                          )}
                          renderInput={(params: any) => (
                            <TextField
                              {...params}
                              label="Search input"
                              // margin="normal"
                              fullWidth
                              variant="outlined"
                              onChange={(e) => {
                                setNewIntervention(e.target.value);
                              }}
                              defaultValue={
                                ""
                                // selectedIntervention !== undefined
                                //     ? selectedIntervention.interventionType.intervention
                                //     : null
                              }
                              InputProps={{
                                ...params.InputProps,
                                type: "search",
                              }}
                            />
                          )}
                        />
                      </TableCell>
                      <TableCell>
                        Install cost per property:{" "}
                        <Input
                          // margin="normal"
                          // fullWidth
                          type="number"
                          defaultValue={0}
                          onChange={(e) => {
                            setNewInstallCost(parseFloat(e.target.value));
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        kWh Impact per property:{" "}
                        <Input
                          // margin="normal"
                          // fullWidth
                          type="number"
                          defaultValue={0}
                          onChange={(e) => {
                            setNewKwhImpact(parseFloat(e.target.value));
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <hr className="solid" />
                <PropertySelect
                  propertyList={portfolioProperties}
                  addAllProperties={addAllProperties}
                  addTickedProperty={addTickedProperty}
                  tickedProperties={tickedProperties}
                  defaultRows={20}
                />
              </div>
            ) : null}
            <div
                          className="action-plan_inner"

              style={{
                backgroundColor: "white",
                marginTop: "25px",
                marginBottom: "20px",
              }}
            >
              <LoadingOverlay active={loadingOn} spinner text="Loading...">
                <Table    className="action-plan_inner" size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Intervention Area</TableCell>
                      <TableCell style={{ width: "7%" }}>
                        Install Cost
                      </TableCell>
                      <TableCell style={{ width: "7%" }}>
                        CO2 Impact (Year 1)
                      </TableCell>
                      <TableCell style={{ width: "7%" }}>
                        Fuel Cost Impact (Year 1)
                      </TableCell>
                      <TableCell style={{ width: "7%" }}>
                        Lifetime (Per Salix)
                      </TableCell>
                      <TableCell style={{ width: "7%" }}>
                        CO2 Saving over Lifetime
                      </TableCell>
                      <TableCell style={{ width: "7%" }}>
                        Cost per Tonne of lifetime CO2 saving
                      </TableCell>
                      <TableCell style={{ width: "7%" }}>
                        Bill saving over lifetime
                      </TableCell>
                      <TableCell style={{ width: "7%" }}>
                        Lifetime bill saving per £1 of Install Cost
                      </TableCell>
                      <TableCell style={{ width: "7%" }}>
                        kWh Impact p.a.
                      </TableCell>
                      <TableCell style={{ width: "7%" }}>
                        Number of Properties
                      </TableCell>
                      <TableCell style={{ width: "7%" }}>
                        Download CSV
                      </TableCell>
                      <TableCell style={{ width: "7%" }}>
                        Delete on all properties?
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props.portfolioInterventionsDto.portfolioInterventionsDtos.map(
                      (portfolioIntervention) => {
                        return portfolioInterventionRow(
                          portfolioIntervention,
                          numberWithCommas,
                          toggleSap,
                          fetchCSV,
                          setInterventionToDelete,
                          false
                        );
                      }
                    )}
                  </TableBody>
                </Table>
              </LoadingOverlay>
            </div>
            {props.portfolioInterventionsDto.doneInterventionsDtos.length >
            0 ? (
              <div  className="action-plan_inner"
                style={{
                  backgroundColor: "white",
                  marginTop: "25px",
                  marginBottom: "20px",
                }}
              >
                <h3>Done Interventions</h3>
                <LoadingOverlay active={loadingOn} spinner text="Loading...">
                  <Table  size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Intervention Area</TableCell>
                        <TableCell style={{ width: "7%" }}>
                          Install Cost
                        </TableCell>
                        <TableCell style={{ width: "7%" }}>
                          CO2 Impact (Year 1)
                        </TableCell>
                        <TableCell style={{ width: "7%" }}>
                          Fuel Cost Impact (Year 1)
                        </TableCell>
                        <TableCell style={{ width: "7%" }}>
                          Lifetime (Per Salix)
                        </TableCell>
                        <TableCell style={{ width: "7%" }}>
                          CO2 Saving over Lifetime
                        </TableCell>
                        <TableCell style={{ width: "7%" }}>
                          Cost per Tonne of lifetime CO2 saving
                        </TableCell>
                        <TableCell style={{ width: "7%" }}>
                          Bill saving over lifetime
                        </TableCell>
                        <TableCell style={{ width: "7%" }}>
                          Lifetime bill saving per £1 of Install Cost
                        </TableCell>
                        <TableCell style={{ width: "7%" }}>
                          kWh Impact p.a.
                        </TableCell>
                        <TableCell style={{ width: "7%" }}>
                          Number of Properties
                        </TableCell>
                        <TableCell style={{ width: "7%" }}>
                          Download CSV
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {props.portfolioInterventionsDto.doneInterventionsDtos.map(
                        (portfolioIntervention) => {
                          return portfolioInterventionRow(
                            portfolioIntervention,
                            numberWithCommas,
                            toggleSap,
                            fetchCSV,
                            setInterventionToDelete,
                            true
                          );
                        }
                      )}
                    </TableBody>
                  </Table>
                </LoadingOverlay>
              </div>
            ) : null}

            <Modal
              open={interventionToDelete ? true : false}
              onClose={() => {
                setInterventionToDelete(undefined);
              }}
            >
              <div
                style={{
                  top: "50%",
                  left: "50%",
                  marginRight: "-50%",
                  transform: "translate(-50%, -50%)",
                  position: "absolute",
                  width: "40%",
                  backgroundColor: "white",
                  padding: 10,
                }}
              >
                <h3>Delete Intervention in Portfolio</h3>
                <p>
                  Are you sure you want to delete{" "}
                  <b>{interventionToDelete?.name}</b> from all properties?
                </p>
                <Button
                  variant="contained"
                  onClick={() => {
                    if (interventionToDelete) {
                      bulkDeleteIntervention(
                        interventionToDelete.interventionId
                      );
                    }
                  }}
                >
                  Delete
                </Button>
              </div>
            </Modal>
            <Modal
              open={addInterventionModal}
              onClose={() => {
                setAddInterventionModal(false);
                setFuelChange(false);
                setEnergyCategory({
                  id: -1,
                  name: "Please Select One",
                });
                setEnergyTariff({
                  ...({} as tariff),
                  fuel: "Select a Tariff",
                  id: -1,
                });
                setInterventionReduces(true);
              }}
            >
              <div
                style={{
                  top: "50%",
                  left: "50%",
                  marginRight: "-50%",
                  transform: "translate(-50%, -50%)",
                  position: "absolute",
                  width: "40%",
                  backgroundColor: "white",
                  padding: 10,
                }}
              >
                <h1>
                  Add <b style={{ color: "red" }}>{newIntervention}</b>{" "}
                  intervention
                </h1>
                <p>Please select if this reduces or consumes energy:</p>
                <ToggleButtonGroup
                  value={interventionReduces}
                  exclusive
                  onChange={(_, newValue: boolean) => {
                    setInterventionReduces(newValue);
                  }}
                  aria-label="text alignment"
                >
                  <ToggleButton value={true} aria-label="left aligned">
                    Reduces KWh
                  </ToggleButton>
                  <ToggleButton value={false} aria-label="left aligned">
                    Consumes KWh
                  </ToggleButton>
                </ToggleButtonGroup>
                <p>Please select the category:</p>
                <div
                // style={{
                //   display: "flex",
                //   justifyContent: "space-around",
                //   alignItems: "flex-end",
                // }}
                >
                  <FormControl style={{ width: "200px" }}>
                    <InputLabel id="energy-category-select-label">
                      Energy Category
                    </InputLabel>
                    <Select
                      labelId="energy-category-select-label"
                      id="energy-category-select"
                      value={energyCategory.id}
                      onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
                        let newCategory = energyCategories.find(
                          (x) => x.id == (e.target.value as number)
                        );
                        if (newCategory) {
                          setEnergyCategory(newCategory);
                        }
                      }}
                    >
                      {energyCategories.map((energyCategoryChoice) => {
                        return (
                          <MenuItem
                            key={energyCategoryChoice.id}
                            value={energyCategoryChoice.id}
                          >
                            {energyCategoryChoice.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
                <br />
                <p>
                  The <b>deviation</b> option applies to electrical
                  interventions that don&#39;t fit into the <b>lighting</b>{" "}
                  category.
                </p>
                Does this intervention include a Fuel Tarriff change?
                <Checkbox
                  checked={fuelChange}
                  onClick={() => {
                    setFuelChange(!fuelChange);
                    setEnergyTariff({
                      ...({} as tariff),
                      fuel: "Select a Tariff",
                      id: -1,
                    });
                  }}
                />
                <br />
                {fuelChange ? (
                  <div>
                    <FormControl style={{ width: "200px" }}>
                      <InputLabel id="energy-category-select-label">
                        Select New Fuel Tariff
                      </InputLabel>
                      <Select
                        labelId="energy-category-select-label"
                        id="energy-category-select"
                        value={energyTariff.id}
                        onChange={(
                          e: React.ChangeEvent<{ value: unknown }>
                        ) => {
                          let newTariff = props.tariffs.find(
                            (x) => x.id == (e.target.value as number)
                          );
                          if (newTariff) {
                            setEnergyTariff(newTariff);
                          }
                        }}
                      >
                        {props.tariffs.length > 0 ? (
                          props.tariffs.map((tariff) => {
                            return (
                              <MenuItem key={tariff.id} value={tariff.id}>
                                {tariff.fuel}
                              </MenuItem>
                            );
                          })
                        ) : (
                          <></>
                        )}
                      </Select>
                    </FormControl>
                    {energyTariff.id !== -1 && energyCategory.id !== -1 ? (
                      <p>
                        This will create a fuel tariff update for{" "}
                        <b>{energyCategory.name}</b> to{" "}
                        <b>{energyTariff.fuel}</b>
                        .
                        <br />
                        If this is not what you intended:{" "}
                        <button
                          onClick={() => {
                            setEnergyTariff({
                              ...({} as tariff),
                              fuel: "Select a Tariff",
                              id: -1,
                            });
                          }}
                        >
                          clear fuel tariff change selection
                        </button>
                      </p>
                    ) : null}
                  </div>
                ) : null}
                <Button
                  size="small"
                  variant="contained"
                  disabled={energyCategory.id === -1}
                  onClick={() => {
                    let kwhImpact = newKwhImpact;
                    if (!interventionReduces) {
                      kwhImpact = 0 - newKwhImpact;
                    }
                    let data = {
                      portfolioId: props.selectedPortfolio.id,
                      interventionName: newIntervention,
                      plannedYear: -1,
                      installCost: newInstallCost,
                      kwhImpact: kwhImpact,
                      tariffChange: energyTariff.id !== -1,
                      tariffId: energyTariff.id,
                      energyCategoryId: energyCategory.id,
                      propertyIds: tickedProperties,
                    };
                    postBulkIntervention(data);
                  }}
                >
                  Add intervention
                </Button>
              </div>
            </Modal>
          </CardContent>
        </LoadingOverlay>
      </Card>
    </>
  );
}
function portfolioInterventionRow(
  portfolioIntervention: PortfolioIntervention,
  numberWithCommas: (x: number | string) => string,
  toggleSap: boolean,
  fetchCSV: (intervention: PortfolioIntervention) => void,
  setInterventionToDelete: React.Dispatch<
    React.SetStateAction<PortfolioIntervention | undefined>
  >,
  done: boolean
): JSX.Element {
  return (
    <TableRow key={portfolioIntervention.interventionId}>
      <TableCell>{portfolioIntervention.name}</TableCell>
      <TableCell align="right">
        £{numberWithCommas(portfolioIntervention.totalCost.toFixed(0))}
      </TableCell>
      <TableCell align="right">
        {toggleSap
          ? numberWithCommas(portfolioIntervention.carbon.toFixed(0)) + " "
          : numberWithCommas(
              portfolioIntervention.currentCo2Impact.toFixed(0)
            ) + " "}
        kg
      </TableCell>
      <TableCell align="right">
        £
        {toggleSap
          ? numberWithCommas(portfolioIntervention.fuelCostImpact.toFixed(0)) +
            " "
          : numberWithCommas(
              portfolioIntervention.currentFuelCostImpact.toFixed(0)
            ) + " "}
      </TableCell>
      <TableCell align="right">
        {portfolioIntervention.lifetime
          ? Math.round(portfolioIntervention.lifetime) + " years"
          : "n/a"}
      </TableCell>
      <TableCell align="right">
        {toggleSap
          ? portfolioIntervention.lifetime
            ? numberWithCommas(
                (
                  portfolioIntervention.lifetime *
                  portfolioIntervention.currentCo2Impact
                ).toFixed(0)
              )
            : "n/a"
          : portfolioIntervention.lifetime
          ? numberWithCommas(
              (
                portfolioIntervention.lifetime * portfolioIntervention.carbon
              ).toFixed(0)
            ) + " kg"
          : "n/a"}
      </TableCell>
      <TableCell align="right">
        £
        {toggleSap
          ? portfolioIntervention.lifetime
            ? numberWithCommas(
                (
                  portfolioIntervention.totalCost /
                  ((portfolioIntervention.lifetime *
                    portfolioIntervention.currentCo2Impact) /
                    1000)
                ).toFixed(0)
              )
            : "n/a"
          : portfolioIntervention.lifetime
          ? numberWithCommas(
              (
                portfolioIntervention.totalCost /
                ((portfolioIntervention.lifetime *
                  portfolioIntervention.carbon) /
                  1000)
              ).toFixed(0)
            )
          : "n/a"}
      </TableCell>
      <TableCell align="right">
        £
        {toggleSap
          ? portfolioIntervention.lifetime
            ? numberWithCommas(
                (
                  portfolioIntervention.fuelCostImpact *
                  portfolioIntervention.lifetime
                ).toFixed(0)
              )
            : "n/a"
          : portfolioIntervention.lifetime
          ? numberWithCommas(
              (
                portfolioIntervention.currentFuelCostImpact *
                portfolioIntervention.lifetime
              ).toFixed(0)
            )
          : "n/a"}
      </TableCell>
      <TableCell align="right">
        £
        {toggleSap
          ? portfolioIntervention.lifetime && portfolioIntervention.totalCost
            ? numberWithCommas(
                (
                  (portfolioIntervention.lifetime *
                    portfolioIntervention.fuelCostImpact) /
                  portfolioIntervention.totalCost
                ).toFixed(2)
              )
            : "n/a"
          : portfolioIntervention.lifetime && portfolioIntervention.totalCost
          ? numberWithCommas(
              (
                (portfolioIntervention.lifetime *
                  portfolioIntervention.currentFuelCostImpact) /
                portfolioIntervention.totalCost
              ).toFixed(2)
            )
          : "n/a"}
      </TableCell>
      <TableCell align="right">
        {numberWithCommas(portfolioIntervention.totalKwhImpact.toFixed(0))}
      </TableCell>
      <TableCell align="right">
        {numberWithCommas(portfolioIntervention.numberProperties.toFixed(0))}
      </TableCell>
      <TableCell>
        <Button
          variant="contained"
          size="small"
          onClick={() => fetchCSV(portfolioIntervention)}
        >
          Download
        </Button>
      </TableCell>
      {!done ? (
        <TableCell>
          <Button
            variant="contained"
            onClick={() => setInterventionToDelete(portfolioIntervention)}
          >
            <DeleteIcon />
          </Button>
        </TableCell>
      ) : null}
    </TableRow>
  );
}
