import React from "react";

export const UserContext = React.createContext<{
  userState: UserState;
  setUserState: (value: React.SetStateAction<UserState>) => void;
  userGroup: string;
}>({
  userState: {
    idToken: "",
    refreshToken: "",
    accessToken: "",
    username: "",
    loggedIn: false,
  },
  setUserState: (value: React.SetStateAction<UserState>) => {
    console.error("error set user state function not properly set");
  },
  userGroup: '',
});
