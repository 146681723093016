import React, { useEffect, useState } from "react";
import { ApexOptions } from "apexcharts";
import BuildingVolumeChart from "./BuildingVolumeChart";
import { NumericLiteral } from "typescript";
interface IProps {
  value: number | null;
  realValue: number | null;
}
export default function BuildingVolumeLoader(props: IProps) {
  const [series, setSeries] = useState([
    {
      name: "No of buildings",
      data: [
        254, 1713, 24846, 25702, 7614, 3411, 2032, 1158, 741, 476, 349, 254,
        210, 180,
      ],
    },
  ]);
  useEffect(() => {
    setOptions({
      chart: {
        type: "area",
        toolbar: {
          show: false,
        },
      },
      colors: [
        "#CC99C9",
        "#cc99a1",
        "#9EC1CF",
        "#9ea5cf",
        "#9ee09e",
        "#9ee0ca",
        "#fdfd97",
        "#fdee97",
        "#feb144",
        "#fe7c44",
        "#FF6653",
        "#ff5353",
      ],
      title: { text: "Volume" },
      plotOptions: {
        bar: {
          horizontal: true,
          distributed: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },

      yaxis: {
        // min: 0,
        // max: 70,
        reversed: true,
      },
      xaxis: {
        categories: [
          100, 200, 300, 400, 500, 600, 700, 800, 1000, 1100, 1200, 1300, 1400,
        ],
        labels: {
          style: {
            colors: [
              "#CC99C9",
              "#cc99a1",
              "#9EC1CF",
              "#9ea5cf",
              "#9ee09e",
              "#9ee0ca",
              "#fdfd97",
              "#fdee97",
              "#feb144",
              "#fe7c44",
              "#FF6653",
              "#ff5353",
            ],
            fontSize: "12px",
          },
          show: false,
        },
      },
      annotations: {
        yaxis: [
          {
            y: props.value ? Math.round(props.value / 100) * 100 : 1400,
            borderColor: "#f29305",
            label: {
              borderColor: "#f29305",
              style: {
                color: "#fff",
                background: "#f29305",
              },
              text: props.realValue
                ? "This building: " + props.realValue.toFixed(0).toString() + "m3"
                : "unknown",
              position: "left",
              offsetX: props.realValue
                ? props.realValue >= 1400
                  ? 150
                  : 60
                : 60,
            },
          },
        ],
      },
    });
  }, [props.value]);
  const [options, setOptions] = useState<ApexOptions>({
    chart: {
      type: "area",
      toolbar: {
        show: false,
      },
    },
    colors: [
      "#CC99C9",
      "#cc99a1",
      "#9EC1CF",
      "#9ea5cf",
      "#9ee09e",
      "#9ee0ca",
      "#fdfd97",
      "#fdee97",
      "#feb144",
      "#fe7c44",
      "#FF6653",
      "#ff5353",
    ],
    title: { text: "Volume" },
    plotOptions: {
      bar: {
        horizontal: true,
        distributed: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },

    yaxis: {
      // min: 0,
      // max: 70,
      reversed: true,
    },
    xaxis: {
      categories: [
        100, 200, 300, 400, 500, 600, 700, 800, 1000, 1100, 1200, 1300, 1400,
      ],
      labels: {
        style: {
          colors: [
            "#CC99C9",
            "#cc99a1",
            "#9EC1CF",
            "#9ea5cf",
            "#9ee09e",
            "#9ee0ca",
            "#fdfd97",
            "#fdee97",
            "#feb144",
            "#fe7c44",
            "#FF6653",
            "#ff5353",
          ],
          fontSize: "12px",
        },
        show: false,
      },
    },
    annotations: {
      yaxis: [
        {
          y: props.value ? Math.round(props.value / 100) * 100 : 1400,
          borderColor: "#f29305",
          label: {
            borderColor: "#f29305",
            style: {
              color: "#fff",
              background: "#f29305",
            },
            text: props.realValue
              ? "This building: " + props.realValue.toFixed(0).toString() + "m3"
              : "unknown",
            position: "left",
            offsetX: props.realValue
              ? props.realValue >= 1400
                ? 150
                : 60
              : 60,
          },
        },
      ],
    },
  });

  return <BuildingVolumeChart options={options} series={series} />;
}
