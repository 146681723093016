import React, { useEffect, useState, Dispatch, useContext } from "react";
import {
  Button,
  Card,
  CardContent,
  Grid,
  makeStyles,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Modal,
  Input,
} from "@material-ui/core";
import { Checkbox } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { putData } from "../get-data";
import { UserContext } from "../../contexts/UserContext";
import { numberWithCommas } from "../utilities";

interface IProps {
  intervention: Intervention;
  handleEdit?: (id: number, installCost: number, kwhImpact: number) => void;
  handleYearEdit?: (id: number, year: number) => void;
  type: string;
  planned?: Map<string, number>;
  setPlanned?: Dispatch<React.SetStateAction<Map<string, number>>>;
  key?: string;
  editing: boolean;
  deleteIntervention?: (interventionId: number) => void;
  toggleSap?: boolean;
  setAsDone?: (
    propertyId: number,
    doneYear: string,
    doneKwhImpact: string,
    doneInstallCost: string
  ) => void;
}

export default function Intervention(props: IProps) {
  const userContext = useContext(UserContext);

  const [value, setValue] = useState<string>(
    props.intervention.plannedYear === -1
      ? "-1"
      : props.intervention.plannedYear.toString()
  );
  const [doneYear, setDoneYear] = useState<string>(
    props.intervention.plannedYear === -1
      ? "-1"
      : props.intervention.plannedYear.toString()
  );
  const [doneKwhImpact, setDoneKwhImpact] = useState<string>(
    props.intervention.kwhImpact != null
      ? props.intervention.kwhImpact.toString()
      : ""
  );
  const [doneInstallCost, setDoneInstallCost] = useState<string>(
    props.intervention.installCost != null
      ? props.intervention.installCost.toString()
      : ""
  );
  const [toDelete, setToDelete] = useState<boolean>(false);
  const [isDone, setIsDone] = useState<boolean>(false);
  const [installCost, setInstallCost] = useState<string>(
    props.intervention.installCost != null
      ? props.intervention.installCost.toString()
      : ""
  );
  const [kwhImpact, setKwhImpact] = useState<string>(
    props.intervention.kwhImpact != null
      ? props.intervention.kwhImpact.toString()
      : ""
  );
  const [checked, setChecked] = useState<boolean>(true);

  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const handleReset = (event: React.MouseEvent) => {
    event.preventDefault();
    if (props.handleYearEdit) {
      props.handleYearEdit(props.intervention.id, -1);
    }
    setValue("-1");
  };

  const handle2030 = (event: React.MouseEvent) => {
    event.preventDefault();
    if (props.handleYearEdit) {
      props.handleYearEdit(props.intervention.id, 2030);
    }
    setValue("2030");
  };

  const handle2040 = (event: React.MouseEvent) => {
    event.preventDefault();
    if (props.handleYearEdit) {
      props.handleYearEdit(props.intervention.id, 2040);
    }
    setValue("2040");
  };
  function  getEnergyCategory(energyCategory: number){
    if(energyCategory === 1){
      return "Heating";
    }else if (energyCategory === 2){
      return "Lighting";

    }else if (energyCategory === 3){
      return "Hot water";

    }else {
      return "Deviation";

    }
  }
  function renderModal() {
    return (
      <Modal
        open={toDelete}
        onClose={() => {
          setToDelete(false);
        }}
      >
        <div
          style={{
            top: "50%",
            left: "50%",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            position: "absolute",
            width: "35%",
            backgroundColor: "white",
            padding: 10,
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          Please confirm that you would like to delete this intervention:{" "}
          <b>{props.intervention.interventionName}</b>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              if (props.deleteIntervention) {
                props.deleteIntervention(props.intervention.id);
              }
            }}
          >
            Delete
          </Button>
        </div>
      </Modal>
    );
  }

  function renderDoneModal() {
    return (
      <Modal
        open={isDone}
        onClose={() => {
          setIsDone(false);
        }}
      >
        <div
          style={{
            top: "50%",
            left: "50%",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            position: "absolute",
            width: "40%",
            backgroundColor: "white",
            padding: 10,
          }}
        >
          Done Year:{" "}
          <Input
            type="number"
            value={doneYear}
            onChange={(event) => setDoneYear(event.target.value)}
          />
          <br />
          Final Install Cost:
          <Input
            type="number"
            value={doneInstallCost}
            onChange={(event) => setDoneInstallCost(event.target.value)}
          />
          <br />
          Final kWh Impact:
          <Input
            type="number"
            value={doneKwhImpact}
            onChange={(event) => setDoneKwhImpact(event.target.value)}
          />
          <br />
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              if (props.setAsDone) {
                props.setAsDone(
                  props.intervention.id,
                  doneYear,
                  doneKwhImpact,
                  doneInstallCost
                );
              }
            }}
          >
            Mark as Done
          </Button>
        </div>
      </Modal>
    );
  }

  useEffect(() => {
    if (props.planned && props.setPlanned) {
      switch (checked) {
        case false:
          const plannedTemp = props.planned;
          plannedTemp.delete(props.intervention.interventionName);
          const newPlanned = new Map(plannedTemp);
          console.log("newPlanned " + Array.from(newPlanned));
          props.setPlanned(newPlanned);
          return;
        case true:
          if (!props.planned.has(props.intervention.interventionName)) {
            const newPlanned = new Map(props.planned);
            newPlanned.set(
              props.intervention.interventionName,
              -props.intervention.co2Impact
            );
            props.setPlanned(newPlanned);
          }
      }
    }
  }, [checked]);

  const switchControl = (type: string) => {
    switch (type) {
      case "year":
        return (
          <>
            <Input
              type="number"
              value={value}
              onChange={(event) => setValue(event.target.value)}
              disabled={!props.editing}
            />
            <Button style={{ marginLeft: "5px" }} onClick={handleReset}>
              Reset
            </Button>
            <Button style={{ marginLeft: "5px" }} onClick={handle2030}>
              <u>2030</u>
            </Button>
            <Button style={{ marginLeft: "5px" }} onClick={handle2040}>
              <u>2040</u>
            </Button>
          </>
        );
      case "check":
        return (
          <Checkbox color="primary" checked={checked} onChange={handleCheck} />
        );
    }
  };

  return (
    <TableRow>
      <TableCell style={{position:"sticky", left:0, background:"white"}}><b>{props.intervention.interventionName}</b></TableCell>
      {props.type === "check" ? (
        <>
        <TableCell  align="right">{props.intervention.score}</TableCell>
          {props.editing ? (
            <TableCell>
              <Input
                style={{ width: 100 }}
                type="number"
                value={installCost}
                onChange={(event) => setInstallCost(event.target.value)}
                disabled={!props.editing}
              />
            </TableCell>
          ) : (
            <TableCell  align="right">
              {"£" + numberWithCommas(Math.round(props.intervention.installCost).toString())}
            </TableCell>
          )}
          {props.toggleSap ? (
            <>
              <TableCell align="right">
                {numberWithCommas(
                  Math.round(props.intervention.co2Impact).toFixed(0).toString()
                ) + " kg"}
              </TableCell>
              <TableCell align="right">
                {"£" +
                  numberWithCommas(
                    Math.round(props.intervention.costSavings).toFixed(0)
                  ).toString()}
              </TableCell>
              <TableCell align="right">
                {props.intervention.lifetime != null
                  ? Math.round(props.intervention.lifetime)
                  : 0}{" "}
                years
              </TableCell>
              <TableCell align="right">
                {props.intervention.lifetime
                  ? numberWithCommas(
                      (
                        props.intervention.co2Impact *
                        props.intervention.lifetime
                      ).toFixed(0)
                    ) + " kg"
                  : "n/a"}
              </TableCell>
              <TableCell align="right">
                {props.intervention.lifetime
                  ? "£" +
                    numberWithCommas(
                      (
                        props.intervention.installCost /
                        ((props.intervention.co2Impact / 1000) *
                          props.intervention.lifetime)
                      ).toFixed(0)
                    )
                  : "n/a"}
              </TableCell>
              <TableCell align="right">
                {props.intervention.lifetime
                  ? "£" +
                    numberWithCommas(
                      (
                        props.intervention.costSavings *
                        props.intervention.lifetime
                      ).toFixed(0)
                    )
                  : "n/a"}
              </TableCell>
              <TableCell align="right">
                {props.intervention.lifetime && props.intervention.installCost
                  ? "£" +
                    numberWithCommas(
                      (
                        (props.intervention.costSavings *
                          props.intervention.lifetime) /
                        props.intervention.installCost
                      ).toFixed(2)
                    )
                  : "n/a"}
              </TableCell>
            </>
          ) : (
            <>
              <TableCell align="right">
                {numberWithCommas(
                  Math.round(props.intervention.currentCo2Impact).toFixed(0)
                ).toString() + " kg"}
              </TableCell>
              <TableCell align="right">
                {"£" +
                  numberWithCommas(
                    Math.round(props.intervention.currentCostSavings).toFixed()
                  ).toString()}
              </TableCell>
              <TableCell align="right">
                {props.intervention.lifetime != null
                  ? Math.round(props.intervention.lifetime)
                  : 0}{" "}
                years
              </TableCell>
              <TableCell align="right">
                {props.intervention.lifetime
                  ? numberWithCommas(
                      (
                        props.intervention.currentCo2Impact *
                        props.intervention.lifetime
                      ).toFixed(0)
                    ) + " kg"
                  : "n/a"}
              </TableCell>
              <TableCell align="right">
                {props.intervention.lifetime
                  ? "£" +
                    numberWithCommas(
                      (
                        props.intervention.installCost /
                        ((props.intervention.currentCo2Impact / 1000) *
                          props.intervention.lifetime)
                      ).toFixed(0)
                    )
                  : "n/a"}
              </TableCell>
              <TableCell align="right">
                {props.intervention.lifetime
                  ? "£" +
                    numberWithCommas(
                      (
                        props.intervention.currentCostSavings *
                        props.intervention.lifetime
                      ).toFixed(0)
                    )
                  : "n/a"}
              </TableCell>
              <TableCell align="right">
                {props.intervention.lifetime && props.intervention.installCost
                  ? "£" +
                    numberWithCommas(
                      (
                        (props.intervention.currentCostSavings *
                          props.intervention.lifetime) /
                        props.intervention.installCost
                      ).toFixed(2)
                    )
                  : "n/a"}
              </TableCell>
            </>
          )}
          {props.editing ? (
            <TableCell>
              <Input
                style={{ width: 100 }}
                margin="none"
                type="number"
                value={kwhImpact}
                onChange={(event) => setKwhImpact(event.target.value)}
                disabled={!props.editing}
              />
            </TableCell>
          ) : (
            <TableCell  align="right">
              {Math.round(props.intervention.kwhImpact).toString()}
            </TableCell>
          )}
          <TableCell align="center">{getEnergyCategory(props.intervention.energyCategoryId)}</TableCell>
        </>
      ) : (
        <>
          <TableCell align="right">
            £
            {numberWithCommas(
              Math.round(props.intervention.installCost).toFixed(0)
            ).toString()}
          </TableCell>
          <TableCell align="right">
            {numberWithCommas(
              Math.round(props.intervention.currentCo2Impact).toFixed(0)
            ).toString() + "kg"}
          </TableCell>
          <TableCell align="right">
            {"£" +
              numberWithCommas(
                Math.round(props.intervention.currentCostSavings).toFixed(0)
              ).toString()}
          </TableCell>
          <TableCell align="right">
            {props.intervention.lifetime != null
              ? Math.round(props.intervention.lifetime)
              : 0}{" "}
            years
          </TableCell>
          <TableCell align="right">
            {props.intervention.lifetime
              ? numberWithCommas(
                  (
                    props.intervention.currentCo2Impact *
                    props.intervention.lifetime
                  ).toFixed(0)
                ) + " kg"
              : "n/a"}
          </TableCell>
          <TableCell align="right">
            {props.intervention.lifetime
              ? "£" +
                numberWithCommas(
                  (
                    props.intervention.installCost /
                    ((props.intervention.currentCo2Impact / 1000) *
                      props.intervention.lifetime)
                  ).toFixed(0)
                )
              : "n/a"}
          </TableCell>
          <TableCell align="right">
            {props.intervention.lifetime
              ? "£" +
                numberWithCommas(
                  (
                    props.intervention.currentCostSavings *
                    props.intervention.lifetime
                  ).toFixed(0)
                )
              : "n/a"}
          </TableCell>
          <TableCell align="right">
            {props.intervention.lifetime && props.intervention.installCost
              ? "£" +
                numberWithCommas(
                  (
                    (props.intervention.currentCostSavings *
                      props.intervention.lifetime) /
                    props.intervention.installCost
                  ).toFixed(2)
                )
              : "n/a"}
          </TableCell>
          <TableCell align="right">
            {props.intervention.kwhImpact.toFixed(0)}
          </TableCell>
        </>
      )}
      <TableCell align="center">
        <div >{switchControl(props.type)}</div>
      </TableCell>
      {props.editing ? (
        props.type === "check" ? (
          <>
            <TableCell >
              <Button
                variant="contained"
                onClick={() => {
                  if (props.handleEdit) {
                    props.handleEdit(
                      props.intervention.id,
                      parseInt(installCost),
                      parseInt(kwhImpact)
                    );
                  }
                }}
              >
                Save
              </Button>
              <Button
                style={{ paddingLeft: 5, paddingRight: 5 }}
                variant="contained"
                onClick={() => setIsDone(true)}
              >
                Mark as Done
              </Button>
              <Button variant="contained" onClick={() => setToDelete(true)}>
                <DeleteIcon />
              </Button>
              {renderModal()}
              {renderDoneModal()}
            </TableCell>
          </>
        ) : (
          <TableCell>
            <Button
              variant="contained"
              onClick={() => {
                if (props.handleYearEdit) {
                  props.handleYearEdit(props.intervention.id, parseInt(value));
                }
              }}
            >
              Save
            </Button>
          </TableCell>
        )
      ) : (
        <TableCell></TableCell>
      )}
    </TableRow>
  );
}
