import React, { useState, useEffect, Dispatch, useContext } from "react";
import ReactMapGL, {
  Marker,
  MapRef,
  WebMercatorViewport,
  FlyToInterpolator,
  InteractiveMapProps,
} from "react-map-gl";
import { useParams, useHistory } from "react-router-dom";
import useSupercluster from "use-supercluster";
import { BBox } from "geojson";
import {
  Badge,
  Card,
  CardContent,
  Drawer,
  Switch,
  TextField,
  Button,
  Divider,
} from "@material-ui/core";
import RoomIcon from "@material-ui/icons/Room";
import DeleteIcon from "@material-ui/icons/Delete";
import { getData, postData } from "../get-data";
import { UserContext } from "../../contexts/UserContext";
import LoadingOverlay from "react-loading-overlay-ts";
import { Autocomplete } from "@material-ui/lab";
import "./map-page.css";
import { getBounds, getCenter } from "geolib";

interface IProps {
  filters: Array<IFilterObject>;
  setSelectedProperty: Dispatch<React.SetStateAction<IProperty>>;
  selectedProperty: IProperty;
  fetchPropertyDetails: (property: number) => Promise<void>;
  fetchInterventions: (property: IProperty) => void;
  foundProperties: IPropertyEPC[];
  loadingOn: boolean;
  setLoadingOn: Dispatch<React.SetStateAction<boolean>>;
  organisation: string;
}

export default function MapComponent(props: IProps) {
  const userContext = useContext(UserContext);
  const mapRef = React.useRef<MapRef>(null);
  const [noPropertiesText, setNoPropertiesText] = useState<string>(
    props.foundProperties.length.toString()
  );
  const [viewport, setviewport] = React.useState<InteractiveMapProps>({
    latitude: 50.908187,
    longitude: -1.4176743,
    zoom: 11,
    height: window.innerHeight,
    width: window.innerWidth,
  });
  const [epcColour, setepcColour] = React.useState<boolean>(true);
  const [selectedProperties, setSelectedProperties] = React.useState<
    IPropertyEPC[]
  >([]);
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [selectedPropInfos, setSelectedPropInfos] = useState<IPropertyEPC[]>(
    []
  );
  const history = useHistory();

  let boundsToUSe: BBox | undefined = undefined;

  const points: point[] = props.foundProperties.map(
    (property: IPropertyEPC) => ({
      type: "Feature",
      properties: {
        isCluster: false,
        propertyID: property.property_id,
        allInformation: property,
        propertyType: "house",
        current: property.current_energy_efficiency,
      },
      geometry: {
        type: "Point",
        coordinates: [property.lon, property.lat],
      },
    })
  );

  const zoomToUSe: number = viewport.zoom == undefined ? 0 : viewport.zoom;

  const bounds = mapRef.current
    ? mapRef.current.getMap().getBounds().toArray().flat()
    : undefined;

  if (bounds != undefined) {
    boundsToUSe = [bounds[0], bounds[1], bounds[2], bounds[3]];
  } else {
    boundsToUSe = undefined;
  }

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds: boundsToUSe,
    zoom: zoomToUSe,
    options: {
      radius: 75,
      maxZoom: 20,
      map: (item: { propertyType: string; current: number }) => ({
        [item.propertyType]: 1,
        current: item.current,
      }),
      reduce: (
        acc: { [x: string]: number; current: number },
        cur: { [x: string]: number; current: number }
      ) => {
        for (const eachField in cur) {
          acc[eachField] = (acc[eachField] || 0) + cur[eachField];
        }
      },
    },
  });
  useEffect(() => {
    try {
      setNoPropertiesText(props.foundProperties.length.toString());
      console.log("calculating blah...");
      //this mainly renders the viewport - when the properties change, the viewport changes automatically
      const markersToCalculateCentre: any = [];
      props.foundProperties.map((property: IPropertyEPC) => {
        markersToCalculateCentre.push({
          latitude: property.lat,
          longitude: property.lon,
        });
      });

      if (markersToCalculateCentre.length != 0) {
        //only update viewport if there is more than 1 property
        const centreOfMarkers = getCenter(markersToCalculateCentre);
        const boundsOfMarkers = getBounds(markersToCalculateCentre);

        if (centreOfMarkers && boundsOfMarkers) {
          let { latitude, longitude, zoom } = new WebMercatorViewport({
            latitude: viewport.latitude,
            longitude: viewport.longitude,
            zoom: viewport.zoom,
            height: window.innerHeight,
            width: window.innerWidth,
          }).fitBounds(
            [
              [boundsOfMarkers.minLng, boundsOfMarkers.minLat],
              [boundsOfMarkers.maxLng, boundsOfMarkers.maxLat],
            ],
            {
              padding: 40,
            }
          );
          //if there's only one property
          if (markersToCalculateCentre.length == 1) zoom = 13;
          setviewport({
            latitude: latitude,
            longitude: longitude,
            zoom: zoom,
            transitionInterpolator: new FlyToInterpolator({ speed: 1.2 }),
            transitionDuration: "auto",
          });
        }
      }
    } catch {
      console.log("error");
    }
  }, [props.foundProperties]);
  const fetchPropertyDetails = (property: IProperty) => {
    props.setSelectedProperty(property);
    props.fetchPropertyDetails(property.property_id)
    .catch((e) => history.push("/404"));
    props.fetchInterventions(property);
  };

  function instanceOfClusterPoint(object: any): object is clusterPoint {
    return "id" in object;
  }

  function instanceOfPoint(object: any): object is point {
    return "propertyID" in object.properties;
  }

  const getColor = (epcRatingToEval: number, clusterCount: number) => {
    let epcRating = 0;
    if (clusterCount > -1) {
      epcRating = epcRatingToEval / clusterCount;
    } else {
      epcRating = epcRatingToEval;
    }
    if (epcRating >= 92) {
      return "#008054";
    }
    if (epcRating >= 81) {
      return "#19b459";
    }
    if (epcRating >= 69) {
      return "#8dce46";
    }
    if (epcRating >= 55) {
      return "#ffd500";
    }
    if (epcRating >= 39) {
      return "#fcaa65";
    }
    if (epcRating >= 21) {
      return "#ef8023";
    }
    return "#e9153b";
  };

  const handleClickCluster = (points: point[]) => {
    setDrawerOpen(true);
    setSelectedProperties(
      points.flatMap((point) => point.properties.allInformation)
    );
  };

  function trySetSelectedProperty(address: string) {
    const matchedProperty = props.foundProperties.find(
      (x) => x.address === address
    );
    if (matchedProperty) {
      props.setSelectedProperty(matchedProperty);
    }
  }

  return (
    <Card style={{ background: "#D8DEE9", marginBottom: "12px" }}>
      <CardContent
        className="content"
        style={{
          width: "80vw",
          height: "60vh",
        }}
      >
        <Drawer
          anchor={"right"}
          open={drawerOpen}
          onClose={() => {
            setDrawerOpen(false);
            props.setSelectedProperty({} as IProperty);
          }}
        >
          <Autocomplete
            value={props.selectedProperty.address}
            disableClearable
            onChange={(e: any, newValue: string) => {
              trySetSelectedProperty(newValue);
            }}
            id="epc_search_container"
            options={selectedProperties.map((property) => property.address)}
            style={{ width: 650 }}
            renderInput={(params) => (
              <TextField
                className="epc_search"
                {...params}
                label="Select an address"
                margin="dense"
                fullWidth
                variant="filled"
                InputProps={{ ...params.InputProps, type: "search" }}
              />
            )}
          />
          <br></br>
          <div style={{ margin: "0px 14px 0px 14px" }}>
            <Button
              variant="contained"
              style={{ alignItems: "right" }}
              onClick={() => {
                if (props.selectedProperty.property_id) {
                  fetchPropertyDetails(props.selectedProperty);
                  history.push(
                    "/propertyoverview/" + props.selectedProperty.property_id
                  );
                }
              }}
            >
              View more property details
            </Button>
            {Object.keys(props.selectedProperty).map((property) => {
              return (
                <div key={property}>
                  <Divider />

                  <h5>{property}</h5>
                  <p>{props.selectedProperty[property as keyof IProperty]}</p>
                </div>
              );
            })}
          </div>
        </Drawer>
        <ReactMapGL
          {...viewport}
          mapboxApiAccessToken={
            "pk.eyJ1IjoianNhbmNoOTgiLCJhIjoiY2tzNHVpajEwMjg1cjJwbXNoeGY0NmhkcyJ9.OGFBN2CHTg4vUIIVOk0CIw"
          }
          mapStyle="mapbox://styles/mapbox/light-v9"
          onViewportChange={(e: InteractiveMapProps) => {
            setviewport(e);
          }}
          ref={mapRef}
        >
          {clusters.map((cluster: clusterPoint | point) => {
            const [longitude, latitude] = cluster.geometry.coordinates;

            if (instanceOfClusterPoint(cluster)) {
              const { point_count: pointCount } = cluster.properties;
              return (
                <Marker
                  key={`cluster-${cluster.id}`}
                  latitude={latitude}
                  longitude={longitude}
                >
                  <Badge badgeContent={pointCount} color="secondary">
                    <RoomIcon
                      className="marker"
                      style={{
                        color: epcColour
                          ? getColor(
                              cluster.properties.current,
                              cluster.properties.point_count
                            )
                          : "grey",
                        stroke: "grey",
                      }}
                      fontSize="large"
                      onClick={(e) => {
                        //console.log(cluster, supercluster.getLeaves(cluster.id));
                        handleClickCluster(
                          supercluster.getLeaves(cluster.id, Infinity)
                        );
                      }}
                    />
                  </Badge>
                  {/* // </Box> */}
                  {/* )} */}
                </Marker>
              );
            } else if (instanceOfPoint(cluster)) {
              const { allInformation: propInfo } = cluster.properties;
              return (
                <Marker
                  key={`property-${cluster.properties.propertyID}`}
                  latitude={latitude}
                  longitude={longitude}
                >
                  <RoomIcon
                    style={{
                      color: epcColour
                        ? getColor(propInfo.current_energy_efficiency, -1)
                        : "grey",
                      stroke: "grey",
                    }}
                    className="marker"
                    fontSize="large"
                    onClick={(e) => {
                      setSelectedProperties([propInfo]);
                      setDrawerOpen(true);
                    }}
                  />
                </Marker>
              );
            }
          })}
          <p
            style={{
              fontSize: 15,
              color: "white",
              textShadow: "0 0 3px rgb(0, 0, 0), 0 0 5px rgb(0, 0, 0)",
            }}
          >
            No. of properties:             {noPropertiesText}
          </p>

    
        </ReactMapGL>
        <div id="toggle_epc_div">
          <Switch
            checked={epcColour}
            onChange={() => {
              setepcColour(!epcColour);
            }}
          />
          Toggle EPC colouring
        </div>
      </CardContent>
    </Card>
  );
}
