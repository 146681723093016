import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

interface IProps {
  options: ApexOptions;
  series: any[];
}

export default function Waterfall(props: IProps) {

  return (
    <Chart
      options={props.options}
      series={props.series}
      type="rangeBar"
      width="100%"
      height="300px"
    />
  );
}
