import {
  Button,
  Card,
  CardContent,
  Grid,
  makeStyles,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  TextField,
} from "@material-ui/core";
//   import { SmallNavbar } from "../NavBar/navbar-small";
//   import ActionPlanChartLoader from "../ActionPlanChart/ActionPlanChartLoader";
//   import Intervention from "./Intervention";
import "./PowerBIPage.css";
import React, { useEffect, useState } from "react";

interface IProps {
  // propertyDetails: any;
  // tariffs: any[];
  organisation: string;
}

export default function PowerBIPage(props: IProps) {
  return (
    <div id="map_container">
      {props.organisation === "Portsmouth City Council" ? (
        <iframe
          id="map_iframe"
          src="https://app.powerbi.com/view?r=eyJrIjoiYjc3Y2QxYzktYTYzNS00ZmYzLTkxY2EtMWU1YTVjN2MzMmZhIiwidCI6ImUyYmUwY2QyLTc0ODMtNDcwYi05M2QzLThmZjNiZDEwOTQ3NiJ9"
        ></iframe>
      ) : (
        <iframe
          id="map_iframe"
          src="https://app.powerbi.com/view?r=eyJrIjoiYmZhMjhjZTctOGMxYS00YjFlLTg3Y2QtMDVkZDVmY2JhZjFjIiwidCI6ImUyYmUwY2QyLTc0ODMtNDcwYi05M2QzLThmZjNiZDEwOTQ3NiJ9&pageName=ReportSection"
        ></iframe>
      )}
    </div>
  );
}
