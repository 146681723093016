import {
  Button,
  Card,
  CardContent,
  Grid,
  makeStyles,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  TextField,
} from "@material-ui/core";
import TodayIcon from "@mui/icons-material/Today";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import ViewInArIcon from "@mui/icons-material/ViewInAr";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";

//   import "./PageNotFoundPage.css";
import React, { useRef, useEffect, useState, useContext } from "react";
//   import Missing_png from "../../img/Mistake.jpg";
import ASHP_image from "../../img/ASHP_modelling.png";
import EWI_image from "../../img/ewi_image.png";
import FilterPage_image from "../../img/filter_page.png";
import { UserContext } from "../../contexts/UserContext";

export default function ReleaseNotePage() {
  const ref1 = useRef<null | HTMLDivElement>(null);
  const ref2 = useRef<null | HTMLDivElement>(null);
  const ref3 = useRef<null | HTMLDivElement>(null);
  const ref4 = useRef<null | HTMLDivElement>(null);
  const userContext = useContext(UserContext);

  const handleClick1 = () => {
    ref1.current?.scrollIntoView({ behavior: "smooth" });
  };
  const handleClick2 = () => {
    ref2.current?.scrollIntoView({ behavior: "smooth" });
  };
  const handleClick3 = () => {
    ref3.current?.scrollIntoView({ behavior: "smooth" });
  };
  const handleClick4 = () => {
    ref4.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div style={{ width: "100%", height: "100%", display: "table" }}>
      <div
        style={{
          width: "200px",
          height: "100%",
          borderRight: "1px solid black",
          padding: 20,
          display: "table-cell",
        }}
      >
        <div
          style={{
            top: 0,
            position: "sticky",
          }}
        >
          <h4>Content</h4>
          <ul style={{ paddingLeft: 18, listStyle: "initial" }}>
            <li
              onClick={handleClick1}
              style={{ paddingBottom: 20, cursor: "pointer" }}
            >
              EPC updates
            </li>
            <li
              onClick={handleClick2}
              style={{ paddingBottom: 20, cursor: "pointer" }}
            >
              Advanced filter functions
            </li>
            <li
              onClick={handleClick3}
              style={{ paddingBottom: 20, cursor: "pointer" }}
            >
              Air Source Heat Pump modelling
            </li>
            <li
              onClick={handleClick4}
              style={{ paddingBottom: 20, cursor: "pointer" }}
            >
              External wall insulation (EWI) modelling
            </li>
          </ul>
        </div>
      </div>
      <div style={{ padding: "10px 20px", display: "table-cell" }}>
        <h2>Release Notes</h2>
        <List dense style={{ paddingLeft: 0 }}>
          <ListItem disablePadding style={{ padding: 0 }}>
            <ListItemButton style={{ paddingLeft: 0 }}>
              <ListItemIcon>
                <EventAvailableIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  userContext.userGroup == "soton"
                    ? "25 September 2023"
                    : userContext.userGroup == "reading"
                    ? "09 March 2023"
                    : "31 October 2022"
                }
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding style={{ padding: 0 }}>
            <ListItemButton style={{ paddingLeft: 0 }}>
              <ListItemIcon>
                <ViewInArIcon />
              </ListItemIcon>
              <ListItemText primary="Version No. 1.4" />
            </ListItemButton>
          </ListItem>
        </List>
        <Divider />
        {/* <TodayIcon /> Released date: 12 July 2022 */}
        <div id="epc-updates" ref={ref1}>
          <h4>EPC updates</h4>
          The EPC database used in the assessment has been updated to the latest
          by the Department for Levelling Up, Housing and Communities (DLUHC)
          dated{" "}
          {userContext.userGroup == "soton"
            ? "July 2023"
            : userContext.userGroup == "reading"
            ? "December 2022"
            : "June 2022"}
          . The next update is estimated to be February 2022. More information
          about EPC can be found{" "}
          <a
            href="https://www.gov.uk/find-energy-certificate"
            style={{ color: "black" }}
          >
            here.
          </a>
        </div>
        <div id="advanced-filter" ref={ref2}>
          <h4 style={{ marginTop: 30 }}>Advanced filter functions</h4>
          <img src={FilterPage_image} style={{ width: "80%" }} />
          <div style={{ marginBottom: 15 }}>
            A new landing page has been added to Version 1.4 to allow users to
            bulk search properties that are suitable to apply specific
            interventions. The Search page is also equipped with multi filter
            functions, so users could specify the charcteristics of properties
            that they want to find. This includes current / potential EPC bands,
            property type, EPC type (lodged or infilled), and energy system
            efficiency.
          </div>
        </div>
        <div id="ashp" ref={ref3}>
          <h4 style={{ marginTop: 30 }}>Air Source Heat Pump modelling</h4>
          <div>
            <img src={ASHP_image} style={{ width: 300, marginBottom: 10 }} />
          </div>
          <div style={{ marginBottom: 15 }}>
            The feasiblity for each property to installed Air Source Heat Pump
            (ASHP) has been analysed based on the construction geometrics,
            distance to neighbouring buildings, and the feasibility of accessing
            external wall areas. The assessment takes into account the minimum
            distance required to meet MCS noise requirement. More details about
            the MCS guidance can be found{" "}
            <a
              href="https://mcscertified.com/technology/air-source-heat-pump/"
              style={{ color: "black" }}
            >
              here
            </a>
            .
          </div>
          <div>
            For a property to be considered as suitable for ASHP, it needs to
            meet the following conditions:
          </div>
          <ul>
            <li>
              It contains external wall areas for the placement of ASHP external
              unit. For deploying ASHP unit, the location needs to be physically
              accessible, being at least 2 meter away from neighbouring
              buildings and at least 1 meter clear from the land border of this
              property
            </li>
            <li>
              If the location can be seen from a viewpoint of any neighbouring
              buildings, the distance between the location and the viewpoint
              needs to be greater than a minimum threshold set out in MCS
              guidance
            </li>
            <li>
              The viewpoint of neighbouring buildings is permitted to be 1 meter
              outside a habitable room.{" "}
            </li>
            <li>
              The minimum distance threshold is defined by the choice of ASHP,
              and whether noise barrier has been installed.{" "}
            </li>
            <li>
              For locations not within direct line of sight, there is no minimum
              distance requirement.{" "}
            </li>
            <li>
              For buildings without external wall areas that satisfy the above
              requirement, they are determined as not suitable for ASHP, and
              vice versa.{" "}
            </li>
          </ul>
        </div>
        <div id="external-wall" ref={ref4}>
          <h4 style={{ marginTop: 30 }}>
            External wall insulation (EWI) modelling{" "}
          </h4>
          <img src={EWI_image} style={{ width: "80%" }} />
          <div style={{ marginBottom: 15 }}>
            The feasibility of each building to apply external wall insulation
            is assessed by the accessibility. By using LiDAR simulation, the
            model takes into the following conditions. First, walls that are not
            attached to other properties are defined as external walls. The
            distance between each part of external walls and neighbouring
            buildings is then measured. Only wall areas that have clearence
            greater than 2 meters are identified as suitable to apply EWI. Wall
            areas that are too close to adjacent buildings are likely to
            obstruct engineers to access and apply insulation. Additionally, the
            lack of insulation on part of the external wall is likely to cause
            thermal bridge, which should be avoided.
          </div>
        </div>
      </div>
    </div>
  );
}
