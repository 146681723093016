import { exception } from 'react-ga';
import { getData, postData, putData } from '../get-data';

interface IProps {
    username: string;
    password: string;
}

interface ChangePasswordProps {
    username: string;
    password: string;
    newPassword: string;
}

interface RefreshToken {
    username: string;
    refreshToken: string;
}

export async function GetGroup(idToken: string){

  const response = await  fetch('https://captapi.absolarlab.com/api/auth/group', {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        headers: new Headers({
              Authorization: "Bearer " + idToken,
            }),
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      });
      if (response.ok) {
          return response;
    }else{
        throw new Error("Not logged in");
    }
}

export function GetLogins(props: IProps) {
    return postData('https://captapi.absolarlab.com/api/auth/login', props);
}

export function ChangePassword(props: ChangePasswordProps) {
    return putData('https://captapi.absolarlab.com/api/auth/password', props);
}

export function Refresh(props: RefreshToken) {
    return putData('https://captapi.absolarlab.com/api/auth/refresh', props);
}
