import { SmallNavbar } from "../NavBar/navbar-small";
import "./KeyAssumptionsPage.css";
import {
  Button,
  Card,
  CardContent,
  Grid,
  makeStyles,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
} from "@material-ui/core";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Tab } from "material-ui";
import React, { useContext, useEffect, useState } from "react";
import Input from "@material-ui/core/Input";
import Modal from "@material-ui/core/Modal";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import { UserContext } from "../../contexts/UserContext";
import { getData, postData, putData } from "../get-data";
const useStyles = makeStyles({
  topTab: {
    width: "310px",
    borderRadius: "5px 5px 0px 0px",
    backgroundColor: "#FFF",
    border: "2px solid black",
    "&:hover": {
      backgroundColor: "#ebebeb",
    },
  },
  innerTab: {
    width: "200px",
    borderRadius: "5px 5px 0px 0px",
    backgroundColor: "#FFF",
    border: "1px solid black",
    fontSize: "8pt",
  },
});

interface IProps {}

export default function CostAssumptionsPage(props: IProps) {
  const userContext = useContext(UserContext);

  const classes = useStyles();

  const [changesToPost, setChangesToPost] = useState<
    Array<InterventionCostDto>
  >([]);

  const [interventionCosts, setInterventionCosts] = useState<
    Array<InterventionCostDto>
  >([]);
  const [multiplierColumns, setMultiplierColumns] = useState<Array<string>>([]);
  const fetchInterventionCosts = () => {
    getData(
      "https://captapi.absolarlab.com/api/costs/intervention_costs",
      userContext
    ).then((interventionCosts: Array<InterventionCostDto>) => {
      setInterventionCosts(interventionCosts);
    });
  };
  const fetchMultiplierColumns = () => {
    getData(
      "https://captapi.absolarlab.com/api/costs/multiplier_columns",
      userContext
    ).then((multiplierColumns: Array<string>) => {
      setMultiplierColumns(multiplierColumns);
    });
  };
  useEffect(() => {
    fetchInterventionCosts();
    fetchMultiplierColumns();
  }, []);
  const update = (id: number, cost: number, multiplierColumn: string) => {
    let url =
      "https://captapi.absolarlab.com/api/costs?interventionId=" +
      id.toString() +
      "&cost=" +
      cost.toString();
    if (multiplierColumn != null) {
      if (multiplierColumn != "") {
        url += "&columnMultiplier=" + multiplierColumn;
      }
    }
    return putData(url, {}, userContext, false);
  };

  return (
        <Accordion style={{width:"80%"}}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            // style={{background:"lightblue"}}
          >
            Intervention Costs Parameters
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <div
                style={{
                  backgroundColor: "#fff",
                  // flex: "1 0 1",
                  minHeight: "585px",
                  padding: 50,
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Intervention Name</TableCell>
                      <TableCell>Cost</TableCell>
                      <TableCell>Multiplier property</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {interventionCosts.map((interventionCost) => {
                      return (
                        <TableRow
                          key={interventionCost.interventionId.toString()}
                        >
                          <InterventionCostRow
                            interventionCost={interventionCost}
                            update={update}
                            multiplierColumns={multiplierColumns}
                          />
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
  );
}

interface RowProps {
  interventionCost: InterventionCostDto;
  update: (id: number, cost: number, multiplierColumn: string) => Promise<any>;
  multiplierColumns: Array<string>;
}

function InterventionCostRow(props: RowProps) {
  const [cost, setCost] = useState<number | null>(props.interventionCost.cost);
  const [multiplierColumn, setMultiplierColumn] = useState<string>(
    props.interventionCost.multiplier_column
  );
  const [originalCost, setOriginalCost] = useState<number | null>(
    props.interventionCost.cost
  );
  const [originalMultiplierColumn, setOriginalMultiplierColumn] =
    useState<string>(props.interventionCost.multiplier_column);
  const [costError, setCostError] = useState<boolean>(false);
  const [updating, setUpdating] = useState<boolean>(false);
  return (
    <>
      <TableCell>{props.interventionCost.name}</TableCell>
      <TableCell>
        <Input
          value={cost}
          type="number"
          error={costError}
          onChange={(e) => {
            setCost(parseFloat(e.target.value));

            setCostError(false);
          }}
          placeholder={costError ? "required" : "no cost set"}
        />
      </TableCell>
      <TableCell>
        <Select
          variant="outlined"
          style={{ width: "100%" }}
          id="column-select"
          placeholder="no multiplier set"
          displayEmpty
          value={multiplierColumn}
          onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
            setMultiplierColumn(e.target.value as string);
          }}
        >
          {props.multiplierColumns.map((column) => {
            return (
              <MenuItem
                key={column}
                value={column}
                
              >
                {column == "" ? "Flat rate" : column}
              </MenuItem>
            );
          })}
        </Select>
      </TableCell>
      <TableCell>
        <Button
          onClick={() => {
            if (cost) {
              setUpdating(true);
              props
                .update(
                  props.interventionCost.interventionId,
                  cost,
                  multiplierColumn
                )
                .then((recordsUpdated: number) => {
                  alert(
                    "success, for " +
                      props.interventionCost.name +
                      " records updated:" +
                      recordsUpdated
                  );
                  setUpdating(false);
                  setOriginalCost(cost);
                  setOriginalMultiplierColumn(multiplierColumn);
                })
                .catch((e) => {
                  alert(
                    "Unable to update costs for intervention: " +
                      props.interventionCost.name +
                      " . Try reloading and trying again"
                  );
                  setUpdating(false);
                });
            } else {
              setCostError(true);
            }
          }}
          disabled={
            (cost === originalCost &&
              multiplierColumn == originalMultiplierColumn) ||
            updating
          }
        >
          {updating ? "Updating..." : "Update"}
        </Button>
      </TableCell>
    </>
  );
}
