import React, { useState, useEffect } from "react";
import { ApexOptions } from "apexcharts";
import BuildingHeightChart from "./BuildingHeightChart";

interface IProps {
  heightToBase: number;
  maxHeight: number;
}

export default function BuildingHeightChartLoader(props: IProps) {
  const [series, setSeries] = useState([
    {
      data: [props.heightToBase, props.maxHeight],
    },
  ]);

  useEffect(() => {
    setOptions({
      chart: {
        type: "area",
        toolbar: {
          show: false,
        },
      },
      colors: ["#c878f0", "#9a78f0"],
      title: { text: "Building height" },
      plotOptions: {
        bar: {
          horizontal: false,
          distributed: true,
          // columnWidth: "50%",
          barHeight: "100%",
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      stroke: {
        width: 2,
        colors: ["#fff"],
      },
      yaxis: {
        // min: 0,
        // max: 30,
        // show: false,
        
      },
      xaxis: {
        categories: [
          "Building height to base of roof",
          "Max height (including roof, chimney etc.)",
        ],
        labels: {
          style: {
            colors: ["#c878f0", "#9a78f0"],
            fontSize: "12px",
          },
          show: true,
        },
      },
    });
  }, []);

  const [options, setOptions] = useState<ApexOptions>({
    chart: {
      type: "bar",
      // height: 500,
    },
    colors: ["#54bf58", "#9ee09e", "#fdfd97", "#feb144", "#FF6653", "#CC99C9"],
    plotOptions: {
      bar: {
        horizontal: true,
        distributed: true,

        // barHeight: "100%",
        dataLabels: {
          position: "bottom",
        },
      },
    },
  });
  useEffect(() => {
    setSeries([
      {
        data: [props.heightToBase, props.maxHeight],
      },
    ]);
    setOptions({
      chart: {
        type: "area",
        height:"auto",
        width:"auto",
        toolbar: {
          show: false,
        },
      },
      colors: ["#c878f0", "#9a78f0"],
      title: { text: "Building height" },
      plotOptions: {
        bar: {
          horizontal: false,
          distributed: true,
          // columnWidth: "50%",
          barHeight: "100%",
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      stroke: {
        width: 2,
        colors: ["#fff"],
      },
      yaxis: {
        // min: 0,
        // max: 30,
        // show: false,
      },
      xaxis: {
        categories: [
          "Eaves height",
          "Roof top height",
        ],
        labels: {
          style: {
            colors: ["#c878f0", "#9a78f0"],
            fontSize: "12px",
          },
          show: true,
        },
      },
    });
  }, [props.heightToBase]);

  return <BuildingHeightChart options={options} series={series} />;
}
