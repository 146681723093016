import React, { useEffect, useState } from 'react';
import { Chart } from "react-google-charts";
import {
    Button,
    Card,
    CardContent,
    Grid,
    makeStyles,
    TextField,
    ButtonProps
} from "@material-ui/core";

interface IProps {
    filterEPC: any;
    properties: IPropertyEPC[];
    allProperties: IPropertyEPC[];
}


export const BarChartsEPC: React.FC<IProps> = (props: IProps) => {

    const [dataCurrentEPC, setDataCurrentEpc] = React.useState<any>([['Rating', 'Current Rating', { role: 'style' }]]);
    const [dataPotentialEPC, setDataPotentialEpc] = React.useState<any>([['Rating', 'Potential Rating', { role: 'style' }]]);

    const [isCurrentStacked, setIsCurrentstacked] = React.useState(false);
    const [isPotentialStacked, setIsPotentialstacked] = React.useState(false);
    const [typeOfBarChosen, setTypeOfBarChosen] = React.useState("all");

    React.useMemo(() => {

        if (typeOfBarChosen == "current") {//if the bar from teh current chart has been pressed
            //first time updateing the potential chart and making it stacked
            let data: any = [
                ['Rating', 'Potential Rating', { role: 'style' }, 'Rest Potential Rating', { role: 'style' }],
            ];
            let potentialA = props.properties.filter(value => value.potential_energy_rating == "A").length;
            let totalA = props.allProperties.filter(value => value.potential_energy_rating == "A").length - potentialA;
            data.push(['A', potentialA, '008054', totalA, '#CCE5DC']);
            let potentialB = props.properties.filter(value => value.potential_energy_rating == "B").length;
            let totalB = props.allProperties.filter(value => value.potential_energy_rating == "B").length - potentialB;
            data.push(['B', potentialB, '19b459', totalB, '#D1F0DD']);
            let potentialC = props.properties.filter(value => value.potential_energy_rating == "C").length;
            let totalC = props.allProperties.filter(value => value.potential_energy_rating == "C").length - potentialC;
            data.push(['C', potentialC, '8dce46', totalC, '#E8F5DA']);
            let potentialD = props.properties.filter(value => value.potential_energy_rating == "D").length;
            let totalD = props.allProperties.filter(value => value.potential_energy_rating == "D").length - potentialD;
            data.push(['D', potentialD, 'ffd500', totalD, '#FFF6CC']);
            let potentialE = props.properties.filter(value => value.potential_energy_rating == "E").length;
            let totalE = props.allProperties.filter(value => value.potential_energy_rating == "E").length - potentialE;
            data.push(['E', potentialE, 'fcaa65', totalE, '#FEEEE0']);
            let potentialF = props.properties.filter(value => value.potential_energy_rating == "F").length;
            let totalF = props.allProperties.filter(value => value.potential_energy_rating == "F").length - potentialF;
            data.push(['F', potentialF, 'ef8023', totalF, '#FBE5D3']);
            let potentialG = props.properties.filter(value => value.potential_energy_rating == "G").length;
            let totalG = props.allProperties.filter(value => value.potential_energy_rating == "G").length - potentialG;
            data.push(['G', potentialG, 'e9153b', totalG, '#FAD0D7']);

            setDataPotentialEpc(data);
            setIsPotentialstacked(true);

            //now updating the current chart

            let oldDataCurrentEPC = dataCurrentEPC;
            let ratingChosen = props.properties[0].current_energy_rating;
            oldDataCurrentEPC[0] = ['Rating', 'Current Rating', { role: 'style' }];
            let currentA = props.allProperties.filter(value => value.current_energy_rating == "A").length;
            oldDataCurrentEPC[1] = ['A', currentA, ratingChosen == "A" ? '#008054' : '#CCE5DC'];
            let currentB = props.allProperties.filter(value => value.current_energy_rating == "B").length;
            oldDataCurrentEPC[2] = ['B', currentB, ratingChosen == "B" ? '#19b459' : '#D1F0DD'];
            let currentC = props.allProperties.filter(value => value.current_energy_rating == "C").length;
            oldDataCurrentEPC[3] = ['C', currentC, ratingChosen == "C" ? '#8dce46' : '#E8F5DA'];
            let currentD = props.allProperties.filter(value => value.current_energy_rating == "D").length;
            oldDataCurrentEPC[4] = ['D', currentD, ratingChosen == "D" ? '#ffd500' : '#FFF6CC'];
            let currentE = props.allProperties.filter(value => value.current_energy_rating == "E").length;
            oldDataCurrentEPC[5] = ['E', currentE, ratingChosen == "E" ? '#fcaa65' : '#FEEEE0'];
            let currentF = props.allProperties.filter(value => value.current_energy_rating == "F").length;
            oldDataCurrentEPC[6] = ['F', currentF, ratingChosen == "F" ? '#ef8023' : '#FBE5D3'];
            let currentG = props.allProperties.filter(value => value.current_energy_rating == "G").length;
            oldDataCurrentEPC[7] = ['G', currentG, ratingChosen == "G" ? '#e9153b' : '#FAD0D7'];
            setDataCurrentEpc(oldDataCurrentEPC);
            
        } else if (typeOfBarChosen == "potential") { //if a bar from the potential chart was clicked

            //first updating teh current chart - making it stacked with 2 values
            let data: any = [
                ['Rating', 'Current Rating', { role: 'style' }, 'Rest Current Rating', { role: 'style' }],
            ];
            
            let currentA = props.properties.filter(value => value.current_energy_rating == "A").length;
            let totalA = props.allProperties.filter(value => value.current_energy_rating == "A").length - currentA;
            data.push(['A', currentA, '008054', totalA, '#CCE5DC']);
            let currentB = props.properties.filter(value => value.current_energy_rating == "B").length;
            let totalB = props.allProperties.filter(value => value.current_energy_rating == "B").length - currentB;
            data.push(['B', currentB, '19b459', totalB, '#D1F0DD']);
            let currentC = props.properties.filter(value => value.current_energy_rating == "C").length;
            let totalC = props.allProperties.filter(value => value.current_energy_rating == "C").length - currentC;
            data.push(['C', currentC, '8dce46', totalC, '#E8F5DA']);
            let currentD = props.properties.filter(value => value.current_energy_rating == "D").length;
            let totalD = props.allProperties.filter(value => value.current_energy_rating == "D").length - currentD;
            data.push(['D', currentD, 'ffd500', totalD, '#FFF6CC']);
            let currentE = props.properties.filter(value => value.current_energy_rating == "E").length;
            let totalE = props.allProperties.filter(value => value.current_energy_rating == "E").length - currentE;
            data.push(['E', currentE, 'fcaa65', totalE, '#FEEEE0']);
            let currentF = props.properties.filter(value => value.current_energy_rating == "F").length;
            let totalF = props.allProperties.filter(value => value.current_energy_rating == "F").length - currentF;
            data.push(['F', currentF, 'ef8023', totalF, '#FBE5D3']);
            let currentG = props.properties.filter(value => value.current_energy_rating == "G").length;
            let totalG = props.allProperties.filter(value => value.current_energy_rating == "G").length - currentG;
            data.push(['G', currentG, 'e9153b', totalG, '#FAD0D7']);

            setDataCurrentEpc(data);
            setIsCurrentstacked(true);

             //now updating the potential chart

             let oldDataPotentialEPC = dataPotentialEPC;
             let ratingChosen = props.properties[0].potential_energy_rating;
             oldDataPotentialEPC[0] = ['Rating', 'Potential Rating', { role: 'style' }];
             let potentialA = props.allProperties.filter(value => value.potential_energy_rating == "A").length;
             oldDataPotentialEPC[1] = ['A', potentialA, ratingChosen == "A" ? '#008054' : '#CCE5DC'];
             let potentialB = props.allProperties.filter(value => value.potential_energy_rating == "B").length;
             oldDataPotentialEPC[2] = ['B', potentialB, ratingChosen == "B" ? '#19b459' : '#D1F0DD'];
             let potentialC = props.allProperties.filter(value => value.potential_energy_rating == "C").length;
             oldDataPotentialEPC[3] = ['C', potentialC, ratingChosen == "C" ? '#8dce46' : '#E8F5DA'];
             let potentialD = props.allProperties.filter(value => value.potential_energy_rating == "D").length;
             oldDataPotentialEPC[4] = ['D', potentialD, ratingChosen == "D" ? '#ffd500' : '#FFF6CC'];
             let potentialE = props.allProperties.filter(value => value.potential_energy_rating == "E").length;
             oldDataPotentialEPC[5] = ['E', potentialE, ratingChosen == "E" ? '#fcaa65' : '#FEEEE0'];
             let potentialF = props.allProperties.filter(value => value.potential_energy_rating == "F").length;
             oldDataPotentialEPC[6] = ['F', potentialF, ratingChosen == "F" ? '#ef8023' : '#FBE5D3'];
             let potentialG = props.allProperties.filter(value => value.potential_energy_rating == "G").length;
             oldDataPotentialEPC[7] = ['G', potentialG, ratingChosen == "G" ? '#e9153b' : '#FAD0D7'];
             setDataPotentialEpc(oldDataPotentialEPC);

        } else { //some settings on the page occured /i.e. clicking "ALL" or another rating on the left menu
            //this means bars should return to normal - they aren't stacked anymore

            setIsPotentialstacked(false);
            setIsCurrentstacked(false);

            let noAcurrent = 0;
            let noBcurrent = 0;
            let noCcurrent = 0;
            let noDcurrent = 0;
            let noEcurrent = 0;
            let noFcurrent = 0;
            let noGcurrent = 0;
            let noApotential = 0;
            let noBpotential = 0;
            let noCpotential = 0;
            let noDpotential = 0;
            let noEpotential = 0;
            let noFpotential = 0;
            let noGpotential = 0;
            for (const eachProperty of props.properties) { //it's easier to evaluate all the computaions like this rather than copy pasting code
                //evaluates the next strings into expressions (dynamically)
                eval("no" + eachProperty.current_energy_rating + "current++");
                eval("no" + eachProperty.potential_energy_rating + "potential++");
            }
            let oldDataCurrentEPC = dataCurrentEPC;
            oldDataCurrentEPC[0] = ['Rating', 'Current Rating', { role: 'style' }];
            oldDataCurrentEPC[1] = ['A', noAcurrent, '#008054'];
            oldDataCurrentEPC[2] = ['B', noBcurrent, '#19b459'];
            oldDataCurrentEPC[3] = ['C', noCcurrent, '#8dce46'];
            oldDataCurrentEPC[4] = ['D', noDcurrent, '#ffd500'];
            oldDataCurrentEPC[5] = ['E', noEcurrent, '#fcaa65'];
            oldDataCurrentEPC[6] = ['F', noFcurrent, '#ef8023'];
            oldDataCurrentEPC[7] = ['G', noGcurrent, '#e9153b'];
            setDataCurrentEpc(oldDataCurrentEPC);

            let oldDataPotentialEPC = dataPotentialEPC;
            oldDataPotentialEPC[0] = ['Rating', 'Potential Rating', { role: 'style' }];
            oldDataPotentialEPC[1] = ['A', noApotential, '#008054'];
            oldDataPotentialEPC[2] = ['B', noBpotential, '#19b459'];
            oldDataPotentialEPC[3] = ['C', noCpotential, '#8dce46'];
            oldDataPotentialEPC[4] = ['D', noDpotential, '#ffd500'];
            oldDataPotentialEPC[5] = ['E', noEpotential, '#fcaa65'];
            oldDataPotentialEPC[6] = ['F', noFpotential, '#ef8023'];
            oldDataPotentialEPC[7] = ['G', noGpotential, '#e9153b'];
            setDataPotentialEpc(oldDataPotentialEPC);

        }

        setTypeOfBarChosen("all");


    }, [props.properties]);

    return (
        <>
            <Grid item xs={6}>
                <Chart
                    width={'100%'}
                    height={'200px'}
                    // Note here we use Bar instead of BarChart to load the material design version
                    chartType="BarChart"
                    loader={<div>Loading Chart</div>}
                    data={dataCurrentEPC}
                    chartEvents={[
                        {
                            //what happens when someone clicks on a bar chart
                            eventName: 'select',
                            callback: ({ chartWrapper }) => {
                                const chart = chartWrapper.getChart()
                                const selection = chart.getSelection()
                                if (selection.length === 1) {
                                    const [selectedItem] = selection
                                    const dataTable = chartWrapper.getDataTable()
                                    const { row, column } = selectedItem
                                    const value = dataTable?.getValue(row, column);
                                    const chartName = dataTable?.getColumnLabel(column);

                                    const ratingSelected = dataCurrentEPC[row + 1][0];

                                    //set current ratings across charts
                                    setTypeOfBarChosen("current");

                                    props.filterEPC(ratingSelected, "current");



                                }
                            },
                        },
                    ]}
                    options={{
                        title: 'Current EPC Rating',
                        isStacked: isCurrentStacked,
                        axes: {
                            y: {
                                0: { side: 'left' },
                            },
                        },
                        legend: { position: 'none' },
                    }}
                    // For tests
                    rootProps={{ 'data-testid': '5' }}
                />
            </Grid>
            <Grid item xs={6}>
                <Chart
                    width={'100%'}
                    height={'200px'}
                    // "Bar" is for material design version but has limited functionality
                    chartType="BarChart"
                    loader={<div>Loading Chart</div>}
                    data={dataPotentialEPC}
                    chartEvents={[
                        {
                            eventName: 'select',
                            callback: ({ chartWrapper }) => {
                                const chart = chartWrapper.getChart()
                                const selection = chart.getSelection()
                                if (selection.length === 1) {
                                    const [selectedItem] = selection
                                    const dataTable = chartWrapper.getDataTable()
                                    const { row, column } = selectedItem
                                    const value = dataTable?.getValue(row, column);
                                    const chartName = dataTable?.getColumnLabel(column);
                                    const ratingSelected = dataPotentialEPC[row + 1][0];

                                    //set potential ratings across charts
                                    setTypeOfBarChosen("potential");


                                    props.filterEPC(ratingSelected, "potential");
               
                                }
                            },
                        },
                    ]}
                    options={{
                        title: 'Potential EPC Rating',
                        isStacked: isPotentialStacked,
                        axes: {
                            y: {
                                0: { side: 'left' },
                            },
                        },
                        legend: { position: 'none' },
                    }}
                    // For tests
                    rootProps={{ 'data-testid': '5' }}
                />
            </Grid>
        </>
    );
};

export default BarChartsEPC;
