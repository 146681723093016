import React from "react";
import { Search, LocationCity, Settings, Map, Info, Equalizer } from "@material-ui/icons";

let MenuItems: IMenuItem[] = [
  {
    title: "Search",
    button: <Search />,
    url: "/home",
    cName: "nav-links",
  },

  {
    title: "Portfolio HQ",
    button: <LocationCity />,
    url: "/portfolio",
    cName: "nav-links",
  },
  {
    title: "Assumptions",
    button: <Settings />,
    url: "/assumptions",
    cName: "nav-links",
  },
  {
    title: "Statistics",
    button: <Equalizer />,
    url: "/statistics",
    cName: "nav-links",
  }
];

export default MenuItems;
