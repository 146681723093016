import {
  Button,
  Card,
  CardContent,
  Grid,
  makeStyles,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Link,
  TextField,
  Switch,
  Input,
} from "@material-ui/core";
import { SmallNavbar } from "../NavBar/navbar-small";
import { useParams } from "react-router-dom";
import ActionPlanChartLoader from "../ActionPlanChart/ActionPlanChartLoader";
import "./ActionPlanPage.css";
import React, { useContext, useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay-ts";
import { UserContext } from "../../contexts/UserContext";
import { getData, postData } from "../get-data";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import IconButton from "@material-ui/core/IconButton";
import SaveOutlinedIcon from "@material-ui/icons/SaveOutlined";
import PublishOutlinedIcon from "@material-ui/icons/PublishOutlined";
import { numberWithCommas } from "../utilities";
import InterventionRow from "./InterventionRow";
import AddImpact from "./AddImpact";

const useStyles = makeStyles({
  button: {
    width: "20%",
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
    backgroundColor: "#205a81",
    border: "2px solid #091e49",
  },
  gridItem: {
    backgroundColor: "#091e49",
  },
  card: {
    padding: "0px",
    backgroundColor: "#113d64",
  },
});

interface ITimeData {
  sap: {
    [year: string]: number;
  };
  carbon: {
    [year: string]: number;
  };
  bill: {
    [year: string]: number;
  };
  impacts: {
    [id: number]: number;
  };
}

interface IParams {
  id: string | undefined;
}

interface IProps {
  selectedPortfolio: PortfolioDetail;
  portfolioInterventionsDto: PortfolioInterventionsDto;
  getPortfolio: (id?: number) => void;
}

interface SandboxIntervention {
  interventionId: number;
  year: number;
  numberOfProperties: number;
}
interface SandboxImpact {
  id: number;
  kwhImpact: number;
  year: number;
  energyCategoryId: number;
}

interface SandboxData {
  sandboxInterventions: SandboxIntervention[];
  sandboxImpacts: SandboxImpact[];
}
export default function PortfolioSandboxing(props: IProps) {
  const userContext = useContext(UserContext);

  const classes = useStyles();
  const [yearRange, setYearRange] = useState<number[]>([]);
  const [co2Data, setCo2Data] = useState<number[]>([]);
  const [fuelBillData, setFuelBillData] = useState<number[]>([]);
  const [sapScores, setSapScores] = useState<number[]>([]);
  const { id } = useParams<IParams>();
  const [currentCo2, setCurrentCo2] = useState<number>(0);
  const [totalCurrentCo2, setTotalCurrentCo2] = useState<number>(0);
  const [co2By2035, setCo2By2035] = useState<number>(0);
  const [totalCo2By2035, setTotalCo2By2035] = useState<number>(0);
  const [co2By2050, setCo2By2050] = useState<number>(0);
  const [totalCo2By2050, setTotalCo2By2050] = useState<number>(0);
  const [percentChange, setPercentChange] = useState<number>(0);

  const [editing, setEditing] = useState<boolean>(false);
  const [isAverage, setIsAverage] = useState<boolean>(false);
  const [totalCo2, setTotalCo2] = useState<number[]>([]);
  const [totalFuel, setTotalFuel] = useState<number[]>([]);
  const [loadingOn, setLoadingOn] = useState<boolean>(true);
  const [isChartLoading, setChartLoading] = useState<boolean>(false);
  const [sandboxInterventions, setSandboxInterventions] = useState<
    SandboxIntervention[]
  >([]);
  const [sandboxImpacts, setSandboxImpacts] = useState<Array<SandboxImpact>>(
    []
  );
  const [sandboxData, setSandboxData] = useState<SandboxData>({
    sandboxImpacts: sandboxImpacts,
    sandboxInterventions: sandboxInterventions,
  });
  const [co2Impact, setCo2Impact] = useState<
    Array<{ id: number; impact: number }>
  >([]);

  const [needReload, setNeedReload] = useState<boolean>(false);

  const range = (start: number, stop: number, step: number) =>
    Array.from(
      { length: (stop - start) / step + 1 },
      (_, i) => start + i * step
    );

  const [portfolioYearInterventionsDto, setPortfolioYearInterventionsDto] =
    useState<Array<YearPortfolioIntervention>>([]);

  function fetchTimeData(): Promise<ITimeData> {
    setSandboxData({
      sandboxImpacts: sandboxImpacts,
      sandboxInterventions: sandboxInterventions,
    });
    let data: SandboxData = {
      sandboxImpacts: sandboxImpacts,
      sandboxInterventions: sandboxInterventions,
    };

    const url =
      "/api/portfolio/sandboxtimeline?portfolioId=" +
      id?.toString() +
      "&start=2012&end=2050";

    return postData("https://captapi.absolarlab.com" + url, data, {
      userState: userContext.userState,
      setUserState: userContext.setUserState,
    });
  }

  const initTopMetrics = (data: ITimeData): void => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const cur = data.carbon[currentYear];
    setCurrentCo2(Math.round(cur));
    setTotalCurrentCo2(
      Math.round(cur * props.selectedPortfolio.numberProperties)
    );
    setCo2By2035(Math.round(data.carbon[2035]));
    setTotalCo2By2035(
      Math.round(data.carbon[2035] * props.selectedPortfolio.numberProperties)
    );
    setCo2By2050(Math.round(data.carbon[2050]));
    setTotalCo2By2050(
      Math.round(data.carbon[2050] * props.selectedPortfolio.numberProperties)
    );
    setPercentChange(Math.round(((cur - data.carbon[2050]) / cur) * 100));
  };

  /**
   *  fetches time data from server.
   *  Then formats it ready for it to
   *  be passed into ActionPlanChartLoader
   */
  const getTimeData = (): void => {
    setNeedReload(false);
    setChartLoading(true);

    fetchTimeData()
      .then((data) => {
        initTopMetrics(data);

        const years = Object.keys(data.sap);
        const startYear = parseInt(years[0]);
        const endYear = parseInt(years[years.length - 1]);
        setYearRange(range(startYear, endYear, 1));

        const sapArray = Object.values(data.sap);
        const roundedSapArray = sapArray.map((val) => Math.round(val));
        setSapScores(roundedSapArray);

        const co2Array = Object.values(data.carbon);
        const roundedCo2Array = co2Array.map(
          (val) => Math.round((val + Number.EPSILON) * 100) / 100
        );
        setCo2Data(roundedCo2Array);

        const impacts = data.impacts;
        let impactsArr: { id: number; impact: number }[] = [];
        for (let key in impacts) {
          if (impacts.hasOwnProperty(key)) {
            impactsArr = [
              ...impactsArr,
              { id: parseInt(key), impact: impacts[key] },
            ];
          }
        }
        setCo2Impact(impactsArr);

        const fuelBillArray = Object.values(data.bill);
        const roundedFuelBillArray = fuelBillArray.map(
          (val) => Math.round((val + Number.EPSILON) * 100) / 100
        );
        setFuelBillData(roundedFuelBillArray);

        const totalFuel = roundedFuelBillArray.map(
          (val) =>
            Math.round(
              (val * props.selectedPortfolio.numberProperties +
                Number.EPSILON) *
                100
            ) / 100
        );

        setTotalFuel(totalFuel);

        const totalCo2 = roundedCo2Array.map(
          (val) =>
            Math.round(
              (val * props.selectedPortfolio.numberProperties +
                Number.EPSILON) *
                100
            ) / 100
        );
        setTotalCo2(totalCo2);

        setChartLoading(false);
        setLoadingOn(false);
      })
      .catch((e) => console.log(e));
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsAverage(!isAverage);
  };

  useEffect(() => {
    if (props.selectedPortfolio.id == -1 && id) {
      props.getPortfolio(parseInt(id));
    }
    getTimeData();
  }, []);
  useEffect(() => {
    if (props.selectedPortfolio.id == -1 && id) {
      props.getPortfolio(parseInt(id));
    }
    getTimeData();
  }, [props.selectedPortfolio]);

  const amendSandboxIntervention = (
    sandboxIntervention: SandboxIntervention
  ) => {
    if (sandboxIntervention.year !== -1) {
      const newInterventions = sandboxInterventions.filter(
        (x) => x.interventionId !== sandboxIntervention.interventionId
      );
      newInterventions.push(sandboxIntervention);
      setSandboxInterventions(newInterventions);
    } else {
      const newInterventions = sandboxInterventions.filter(
        (x) => x.interventionId !== sandboxIntervention.interventionId
      );
      setSandboxInterventions(newInterventions);
    }
  };
  const amendSandboxYearIntervention = (
    sandboxYearIntervention: YearPortfolioIntervention
  ) => {
    if (sandboxYearIntervention.plannedYear !== -1) {
      let newInterventions = portfolioYearInterventionsDto.filter(
        (x) => x.interventionName !== sandboxYearIntervention.interventionName
      );
      newInterventions.push(sandboxYearIntervention);
      newInterventions.map((intervention) => {
        let cost = intervention.installCost * intervention.propertyNum;
        let kwh = intervention.kwhImpact * intervention.propertyNum;

        intervention.kwhImpact = kwh;
        intervention.installCost = cost;
      });
      setPortfolioYearInterventionsDto(newInterventions);
    } else {
      let newInterventions = portfolioYearInterventionsDto.filter(
        (x) => x.interventionName !== sandboxYearIntervention.interventionName
      );
      setPortfolioYearInterventionsDto(newInterventions);
    }
  };

  useEffect(() => {
    setChartLoading(true);
    setNeedReload(true);
  }, [sandboxInterventions]);

  useEffect(() => {
    setChartLoading(true);
    setNeedReload(true);
  }, [sandboxImpacts]);

  return (
    <Card className="card" classes={{ root: classes.card }} >
      <LoadingOverlay active={loadingOn} spinner text="Loading...">
        <CardContent className="header">
          <h3 className="headerText">{props.selectedPortfolio.name}</h3>
        </CardContent>
        <hr className="solid" />
        <CardContent style={{ paddingTop: "0px", paddingBottom: "0px" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <h3 className="headerText">Action Plan Sandbox</h3>
            <h5 className="redText">Nothing done in sandboxing is saved</h5>
          </div>
          <div className="action-plan">
            <div
              className="action-plan_inner"
              style={{
                background: "rgb(0, 26, 61)",
                color: "white",
                padding: "1px",
              }}
            >
              <Grid
                container
                spacing={3}
                style={{
                  textAlign: "center",
                  paddingTop: "20px",
                  paddingBottom: "20px",
                }}
              >
                <Grid item xs={3}>
                  Current emissions <br />
                  <h1>
                    {isAverage
                      ? numberWithCommas(currentCo2)
                      : numberWithCommas(totalCurrentCo2)}{" "}
                    kg
                  </h1>
                </Grid>
                <Grid item xs={3}>
                  By 2035 <br />
                  <h1>
                    {isAverage
                      ? numberWithCommas(co2By2035)
                      : numberWithCommas(totalCo2By2035)}{" "}
                    kg
                  </h1>
                </Grid>
                <Grid item xs={3}>
                  By 2050 <br />
                  <h1>
                    {isAverage
                      ? numberWithCommas(co2By2050)
                      : numberWithCommas(totalCo2By2050)}{" "}
                    kg
                  </h1>
                </Grid>
                <Grid item xs={3}>
                  Change in emissions (by 2050)
                  <br />
                  <h1>{percentChange}%</h1>
                </Grid>
              </Grid>
            </div>
            <div className="action-plan_inner" style={{
                  backgroundColor: "white",
                }}>
              <div
                
              >
                <Table size="small" >
                  <TableHead style={{ background: "lightgrey" }}>
                    <TableRow>
                      <TableCell style={{ width: "15%" }}>
                        Intervention Area
                      </TableCell>
                      <TableCell style={{ width: "7%" }}>
                        Total Install Cost
                      </TableCell>
                      <TableCell style={{ width: "7%" }}>
                        CO2 Impact (Year 1)
                      </TableCell>
                      <TableCell style={{ width: "7%" }}>
                        Fuel Cost Impact (Year 1)
                      </TableCell>
                      <TableCell style={{ width: "7%" }}>
                        Lifetime (Per Salix)
                      </TableCell>
                      <TableCell style={{ width: "7%" }}>
                        CO2 Saving over Lifetime
                      </TableCell>
                      <TableCell style={{ width: "7%" }}>
                        Cost per Tonne of lifetime CO2 saving
                      </TableCell>
                      <TableCell style={{ width: "7%" }}>
                        Bill saving over lifetime
                      </TableCell>
                      <TableCell style={{ width: "7%" }}>
                        Lifetime bill saving per £1 of Install Cost
                      </TableCell>
                      <TableCell style={{ width: "7%" }}>kWh Impact</TableCell>
                      <TableCell style={{ width: "7%" }}>
                        Number of Properties
                      </TableCell>
                      <TableCell style={{ width: "10%" }}>
                        Number to carry out
                      </TableCell>
                      <TableCell style={{ width: "28%" }}>Year</TableCell>
                      <TableCell>
                        {" "}
                        <Button
                          variant="contained"
                          size="small"
                          color="secondary"
                          // style={{ marginLeft: 10 }}
                          onClick={() => {
                            setEditing(!editing);
                          }}
                        >
                          Edit
                        </Button>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props.portfolioInterventionsDto.portfolioInterventionsDtos.map(
                      (portfolioIntervention) => {
                        return (
                          <InterventionRow
                            portfolioIntervention={portfolioIntervention}
                            key={portfolioIntervention.interventionId}
                            editing={editing}
                            amendSandboxIntervention={amendSandboxIntervention}
                            amendSandboxYearIntervention={
                              amendSandboxYearIntervention
                            }
                          />
                        );
                      }
                    )}
                  </TableBody>
                </Table>
              </div>
            </div>
            <div>
              <AddImpact
                amendSandboxImpact={setSandboxImpacts}
                Co2Impacts={co2Impact}
                setCo2Impact={setCo2Impact}
              />
            </div>
            <div
              className="action-plan_inner"
              style={{ background: "white", padding: "1px" }}
            >
              <LoadingOverlay
                active={isChartLoading && !loadingOn}
                spinner={
                  needReload ? (
                    <Button
                      style={{ background: "white" }}
                      disabled={!needReload}
                      onClick={getTimeData}
                    >
                      Reload
                    </Button>
                  ) : undefined
                }
                text={
                  needReload ? (
                    <p>Needs reloading, press click the button above</p>
                  ) : (
                    "Loading..."
                  )
                }
              >
                {isAverage ? (
                  <ActionPlanChartLoader
                    portfolioYearInterventions={portfolioYearInterventionsDto}
                    co2Data={co2Data}
                    fuelBillData={fuelBillData}
                    sapScores={sapScores}
                    yearRange={yearRange}
                    isChartLoading={isChartLoading}
                  />
                ) : (
                  <ActionPlanChartLoader
                    portfolioYearInterventions={portfolioYearInterventionsDto}
                    co2Data={totalCo2}
                    fuelBillData={totalFuel}
                    sapScores={sapScores}
                    yearRange={yearRange}
                    isChartLoading={isChartLoading}
                  />
                )}
              </LoadingOverlay>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                paddingLeft: "30px",
              }}
            >
              <h3 style={{ color: "white" }}>Total</h3>
              <Switch
                checked={isAverage}
                onChange={handleChange}
                name="switch"
              />
              <h3 style={{ color: "white" }}>Average</h3>
            </div>
          </div>
        </CardContent>
      </LoadingOverlay>
    </Card>
  );
}
