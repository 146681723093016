import { SmallNavbar } from "../NavBar/navbar-small";
import "./KeyAssumptionsPage.css";
import {
  Button,
  Card,
  CardContent,
  Grid,
  makeStyles,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
} from "@material-ui/core";
import { Tab } from "material-ui";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useContext, useEffect, useState } from "react";
import Input from "@material-ui/core/Input";
import Modal from "@material-ui/core/Modal";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import { UserContext } from "../../contexts/UserContext";
import { getData, postData } from "../get-data";
const useStyles = makeStyles({
  topTab: {
    width: "310px",
    borderRadius: "5px 5px 0px 0px",
    backgroundColor: "#FFF",
    border: "2px solid black",
    "&:hover": {
      backgroundColor: "#ebebeb",
    },
  },
  innerTab: {
    width: "200px",
    borderRadius: "5px 5px 0px 0px",
    backgroundColor: "#FFF",
    border: "1px solid black",
    fontSize: "8pt",
  },
});

interface IProps {
  fetchTariffs: () => void;
  tariffs: Array<tariff>;
  group: string;
}

export default function KeyAssumptionsPage(props: IProps) {
  const userContext = useContext(UserContext);

  const classes = useStyles();
  const [selectedTariff, setSelectedTariff] = useState<tariff | undefined>(
    undefined
  );
  const [selectedTariffYearPrices, setSelectedTariffYearPrices] = useState<
    TariffYearPrice | undefined
  >();
  const [editingTariff, setEditingTariff] = useState<boolean>(false);
  const [changesToPost, setChangesToPost] = useState<
    Array<{ tariffId: number; year: string; type: string; value: number }>
  >([]);
  const fetchTariffYearValues = (tariffId: number) => {
    getData(
      "https://captapi.absolarlab.com/api/sap/tariffyearvalues?tariffId=" +
        tariffId.toString(),
      userContext
    ).then((tariffYearPrices: TariffYearPrice) => {
      setSelectedTariffYearPrices(tariffYearPrices);
    });
  };
  useEffect(() => {
    if (selectedTariff) {
      fetchTariffYearValues(selectedTariff.id);
    }
  }, [selectedTariff]);
  const changeTariffValue = (year: string, value: number, type: string) => {
    let currentChanges = changesToPost;
    if (selectedTariff) {
      let existing = currentChanges.find(
        (x) =>
          x.year === year && x.tariffId === selectedTariff.id && x.type === type
      );
      if (currentChanges) {
        currentChanges = currentChanges.filter((x) => x !== existing);
      }
      currentChanges.push({
        tariffId: selectedTariff.id,
        year: year,
        type: type,
        value: value,
      });
      setChangesToPost(currentChanges);
      // if (type === "price") {
      //   currentTariffsYearPrices[selectedTariff.fuel][year].price = value;
      // } else if (type === "carbon") {
      //   currentTariffsYearPrices[selectedTariff.fuel][year].carbon = value;
      // } else if (type === "standing") {
      //   currentTariffsYearPrices[selectedTariff.fuel][year].standingCharge =
      //     value;
      // }
    }
  };
  const saveTariffChanges = () => {
    let url = "";
    changesToPost.forEach((change) => {
      if (change.type === "price") {
        url =
          "api/sap/pricetariffyear?organisationId=1&tariffId=" +
          change.tariffId +
          "&year=" +
          change.year +
          "&price=" +
          change.value.toString();
      } else if (change.type === "carbon") {
        url =
          "api/sap/pricetariffyear?organisationId=1&tariffId=" +
          change.tariffId +
          "&year=" +
          change.year +
          "&carbon=" +
          change.value.toString();
      } else if (change.type === "standing") {
        url =
          "api/sap/pricetariffyear?organisationId=1&tariffId=" +
          change.tariffId +
          "&year=" +
          change.year +
          "&standingCharge=" +
          change.value.toString();
      }

      postData(
        "https://captapi.absolarlab.com/" + url,
        undefined,
        userContext
      ).then(() => {
        setChangesToPost([]);
      });
    });
  };
  return (
    <Accordion defaultExpanded={props.group != "soton"} style={{width:"80%"}}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>Tariffs Costs Parameters</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div>
          <div
            style={{
              backgroundColor: "#fff",
              flex: "1 0 1",
              minHeight: "585px",
              
              padding: 50,
            }}
          >
            <FormControl style={{ width: "500px" }}>
              <InputLabel style={{ marginLeft: 10 }} id="tariff-select-label">
                Tariffs
              </InputLabel>
              <Select
                variant="outlined"
                style={{ marginBottom: 10 }}
                labelId="tariff-select-label"
                id="tariff-select"
                value={selectedTariff ? selectedTariff.id : undefined}
                onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
                  let matchedTariff = props.tariffs.find(
                    (x) => x.id == e.target.value
                  );
                  if (matchedTariff) {
                    setSelectedTariff(matchedTariff);
                  }
                }}
              >
                {props.tariffs.map((tariff) => {
                  return (
                    <MenuItem key={tariff.id} value={tariff.id}>
                      {tariff.fuel}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <Button
              onClick={() => {
                if (editingTariff) {
                  saveTariffChanges();
                }
                setEditingTariff(!editingTariff);
              }}
              // size="small"
              style={{ marginLeft: 20 }}
              variant="contained"
              disabled={!selectedTariffYearPrices}
            >
              {editingTariff ? "Save Changes" : "Edit Tariffs"}
            </Button>
            <div>
              <div>
                <div
                  style={{
                    flex: "1 0 1",
                    minHeight: "500px",
                    border: "1px solid black",
                    padding: 10,
                  }}
                >
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">Year</TableCell>
                        <TableCell align="center">
                          Market £ Rate (p/kWh)
                        </TableCell>
                        <TableCell align="center">Standing Charge</TableCell>
                        <TableCell align="center">
                          Carbon output (kg CO<sub>2</sub>/kWh)
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {selectedTariffYearPrices && selectedTariff
                        ? Object.keys(selectedTariffYearPrices).find(
                            (x) => x === selectedTariff.fuel
                          )
                          ? Object.keys(
                              selectedTariffYearPrices[selectedTariff.fuel]
                            ).map((year) => (
                              <TableRow key={year}>
                                <TableCell align="center">{year}</TableCell>
                                <TableCell align="center">
                                  <Input
                                    defaultValue={
                                      selectedTariffYearPrices[
                                        selectedTariff.fuel
                                      ][year].price
                                    }
                                    type="number"
                                    disabled={!editingTariff}
                                    onChange={(e) => {
                                      changeTariffValue(
                                        year,
                                        parseFloat(e.target.value),
                                        "price"
                                      );
                                    }}
                                  />
                                </TableCell>
                                <TableCell align="center">
                                  <Input
                                    defaultValue={
                                      selectedTariffYearPrices[
                                        selectedTariff.fuel
                                      ][year].standingCharge
                                    }
                                    type="number"
                                    disabled={!editingTariff}
                                    onChange={(e) => {
                                      changeTariffValue(
                                        year,
                                        parseFloat(e.target.value),
                                        "standing"
                                      );
                                    }}
                                  />
                                </TableCell>
                                <TableCell align="center">
                                  <Input
                                    defaultValue={
                                      selectedTariffYearPrices[
                                        selectedTariff.fuel
                                      ][year].carbon
                                    }
                                    type="number"
                                    disabled={!editingTariff}
                                    onChange={(e) => {
                                      changeTariffValue(
                                        year,
                                        parseFloat(e.target.value),
                                        "carbon"
                                      );
                                    }}
                                  />
                                </TableCell>
                              </TableRow>
                            ))
                          : "Loading tariffs"
                        : "Please select a Tariff above"}
                    </TableBody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
}
