import React from "react";
import ChartSVG from "./svg-chart";

interface EpcComponentGraphProps {
  current: number;
  potential: number;
  capt_potential: number;
}

function EpcComponentGraph(props: EpcComponentGraphProps) {
  let LetterPCAPT = "";
  let ColorPCAPT = "";
  let LetterP = "";
  let ColorP = "";
  let LetterC = "";
  let ColorC = "";

  let HeightCAPT = 50;
  let Height = 50;
  let HeightC = 50;

  if (props.potential <= 20) {
    LetterP = "G";
    ColorP = "band-g";
    Height = Height + 13;
  } else if (props.potential >= 21 && props.potential <= 38) {
    LetterP = "F";
    ColorP = "band-f";
    Height = Height + 13;
  } else if (props.potential >= 39 && props.potential <= 54) {
    LetterP = "E";
    ColorP = "band-e";
    Height = Height + 13;
  } else if (props.potential >= 55 && props.potential <= 62) {
    LetterP = "D";
    ColorP = "band-d";
    Height = Height + 13;
  } else if (props.potential >= 63 && props.potential <= 68) {
    LetterP = "D";
    ColorP = "band-d";
    Height = Height + 20;
  } else if (props.potential >= 69 && props.potential <= 80) {
    LetterP = "C";
    ColorP = "band-c";
    Height = Height + 13;
  } else if (props.potential >= 81 && props.potential <= 91) {
    LetterP = "B";
    ColorP = "band-b";
    Height = Height + 13;
  } else if (props.potential >= 92) {
    LetterP = "A";
    ColorP = "band-a";
    Height = Height + 13;
  }
  if (props.capt_potential <= 20) {
    LetterPCAPT = "G";
    ColorPCAPT = "band-g";
    HeightCAPT = HeightCAPT + 13;
  } else if (props.capt_potential >= 21 && props.capt_potential <= 38) {
    LetterPCAPT = "F";
    ColorPCAPT = "band-f";
    HeightCAPT = HeightCAPT + 13;
  } else if (props.capt_potential >= 39 && props.capt_potential <= 54) {
    LetterPCAPT = "E";
    ColorPCAPT = "band-e";
    HeightCAPT = HeightCAPT + 13;
  } else if (props.capt_potential >= 55 && props.capt_potential <= 62) {
    LetterPCAPT = "D";
    ColorPCAPT = "band-d";
    HeightCAPT = HeightCAPT + 13;
  } else if (props.capt_potential >= 63 && props.capt_potential <= 68) {
    LetterPCAPT = "D";
    ColorPCAPT = "band-d";
    HeightCAPT = HeightCAPT + 20;
  } else if (props.capt_potential >= 69 && props.capt_potential <= 80) {
    LetterPCAPT = "C";
    ColorPCAPT = "band-c";
    HeightCAPT = HeightCAPT + 13;
  } else if (props.capt_potential >= 81 && props.capt_potential <= 91) {
    LetterPCAPT = "B";
    ColorPCAPT = "band-b";
    HeightCAPT = HeightCAPT + 13;
  } else if (props.capt_potential >= 92) {
    LetterPCAPT = "A";
    ColorPCAPT = "band-a";
    HeightCAPT = HeightCAPT + 13;
  }
  if (props.current <= 20) {
    LetterC = "G";
    ColorC = "band-g";
    HeightC = HeightC + 13;
  } else if (props.current >= 21 && props.current <= 38) {
    LetterC = "F";
    ColorC = "band-f";
    HeightC = HeightC + 13;
  } else if (props.current >= 39 && props.current <= 54) {
    LetterC = "E";
    ColorC = "band-e";
    HeightC = HeightC + 13;
  } else if (props.current >= 55 && props.current <= 62) {
    LetterC = "D";
    ColorC = "band-d";
    HeightC = HeightC + 13;
  } else if (props.current >= 63 && props.current <= 68) {
    LetterC = "D";
    ColorC = "band-d";
    HeightC = HeightC + 20;
  } else if (props.current >= 69 && props.current <= 80) {
    LetterC = "C";
    ColorC = "band-c";
    HeightC = HeightC + 13;
  } else if (props.current >= 81 && props.current <= 91) {
    LetterC = "B";
    ColorC = "band-b";
    HeightC = HeightC + 13;
  } else if (props.current >= 92) {
    LetterC = "A";
    ColorC = "band-a";
    HeightC = HeightC + 13;
  }

  return (
    <div>
      <ChartSVG
        currentValue={props.current}
        currentHeight={(100 - props.current) * 4 + HeightC}
        potentialValue={props.potential}
        potentialHeight={(100 - props.potential) * 4 + Height}
        currentLetter={LetterC}
        currrentColor={ColorC}
        potentialLetter={LetterP}
        potentialColor={ColorP}
        captPotentialLetter={LetterPCAPT}
        captPotentialColor={ColorPCAPT}
        captPotentialValue={props.capt_potential}
        captPotentialHeight={
          (100 - (props.capt_potential > 100 ? 100 : props.capt_potential)) *
            4 +
          HeightCAPT
        }
      />
    </div>
  );
}
export default EpcComponentGraph;
