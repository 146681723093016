import React, { useState, useEffect, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import "../../App.css";
import {
  Button,
  Card,
  CardContent,
  Grid,
  makeStyles,
  Tooltip,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  TextField,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Input,
} from "@material-ui/core";
import TopRadialBarLoader from "./TopRadialBarLoader";
import BottomRadialBarLoader from "./BottomRadialBarLoader";
import { UserContext } from "../../contexts/UserContext";
import { getData } from "../get-data";
import InboxIcon from "@material-ui/icons/Inbox";
import DraftsIcon from "@material-ui/icons/Drafts";
import EmojiObjectsIcon from "@material-ui/icons/EmojiObjects";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import PermContactCalendarIcon from "@material-ui/icons/PermContactCalendar";
import ImdChartLoader from "./ImdChart/ImdChartLoader";
import ImdIncomeChartLoader from "./ImdIncomeChart/ImdIncomeChartLoader";
import LoadingOverlay from "react-loading-overlay-ts";

interface IParams {
  id: string | undefined;
}

interface IProps {
  // propertyDetails: IPropertyDetails;
  propertyDetails: IPropertyDetails;
  setSingleProperty: React.Dispatch<React.SetStateAction<boolean>>;
  reload: (id?: number) => Promise<void> | null;
}

export default function FuelDetailsPage(props: IProps) {
  const userContext = useContext(UserContext);
  const [fuelkWhs, setFuelKWhs] = useState<Map<string, number>>(new Map());
  const [editing, setEditing] = useState<boolean>(false);
  const [loadingFrame, setLoadingFrame] = useState<boolean>(true);
  const [propertyDetails, setPropertyDetails] = useState<any | undefined>(
    undefined
  );
  const history = useHistory();

  const { id } = useParams<IParams>();
  const [expired, setExpired] = useState<boolean>(false);
  const [fuelBillData, setFuelBillData] = useState<number[]>([]);

  function fetchTimeData(){
    const url = "/api/sap/timeline?propertyId=" + id + "&start=2012&end=2050";

    getData("https://captapi.absolarlab.com" + url, {
      userState: userContext.userState,
      setUserState: userContext.setUserState,
    }) .then((data: ITimeData) => {
    

      const fuelBillArray = Object.values(data.bill);
      const roundedFuelBillArray = fuelBillArray.map(
        (val) => Math.round((val + Number.EPSILON) * 100) / 100
      );
      setFuelBillData(roundedFuelBillArray);

    })
    .catch((e) => console.log(e));;
  }
  const [buildingAttributes, setBuildingAttributes] = useState<
    BuildingAttributes | undefined
  >(undefined);
  useEffect(() => {
    fetchTimeData()
    const currentDate = new Date();
    const lodgementDate = new Date(props.propertyDetails.lodgement_date);
    if (!isNaN(lodgementDate.getTime())) {
      const difference = Math.floor(
        (currentDate.getTime() - lodgementDate.getTime()) /
          (1000 * 60 * 60 * 24 * 365)
      );
      console.log(difference);
      if (difference >= 10) {
        setExpired(true);
      }
    }
  }, [props.propertyDetails]);
  const getBuildingAttributes = () => {
    const url = "/api/sap/buildingattribute?uprn=" + props.propertyDetails.uprn;

    getData("https://captapi.absolarlab.com" + url, {
      userState: userContext.userState,
      setUserState: userContext.setUserState,
    }).then((buildingAttributesDto: BuildingAttributes) => {
      setBuildingAttributes(buildingAttributesDto);
    });
  };

  useEffect(() => {
    if (Object.keys(props.propertyDetails).length === 0) {
      props.reload(parseInt(id!))?.catch((e) => {
        history.push("/404");
      });
    }
    setPropertyDetails(props.propertyDetails);
    props.setSingleProperty(true);
    getBuildingAttributes();
  }, []);

  useEffect(() => {
    getBuildingAttributes();
  }, [props.propertyDetails]);

  return (
    <>
      {Object.keys(props.propertyDetails).length === 0 ? (
        <></>
      ) : (
        <Card className="card" style={{ backgroundColor: "#113d64" }}>
          <CardContent className="header">
            <h3 className="headerText">
              {props.propertyDetails.single_line_address}
            </h3>
          </CardContent>
          <hr className="solid" />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <div className="top_headline">
                  <div>UPRN</div>
                  <div className="headline_result">
                    {props.propertyDetails.uprn}
                  </div>
                </div>
              </Grid>
              <Grid item xs={3}>
                <div className="top_headline">
                  <div>EPC status</div>
                  <div className="headline_result">
                    {props.propertyDetails.epc_type}
                  </div>
                </div>
              </Grid>
              <Grid item xs={3}>
                {expired ? (
                  <Tooltip
                    title="EPC expired"
                    placement="top"
                    style={{ fontSize: "20px" }}
                  >
                    <div
                      className="top_headline"
                      style={{ background: "#ffb3b3" }}
                    >
                      <div>Assessment date</div>
                      <div className="headline_result">
                        {props.propertyDetails.lodgement_date}
                      </div>
                    </div>
                  </Tooltip>
                ) : (
                  <div className="top_headline" style={{ background: "white" }}>
                    <div>Assessment date</div>
                    <div className="headline_result">
                      {props.propertyDetails.lodgement_date}
                    </div>
                  </div>
                )}
              </Grid>
              <Grid item xs={3}>
                <div className="top_headline">
                  <div>EPC band</div>
                  <div className="headline_result">
                    {props.propertyDetails.current_energy_rating}
                  </div>
                </div>
              </Grid>

              <Grid container item spacing={2}>
                <Grid item xs={6}>
                  <CardContent
                    style={{
                      backgroundColor: "white",
                    }}
                  >
                    <div className="panel_title">
                      Fuel Consumption (prior to any planned interventions)
                    </div>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <strong>Area</strong>
                          </TableCell>
                          <TableCell>
                            <strong>Fuel Type</strong>
                          </TableCell>
                          <TableCell>
                            <strong>Per EPC (kWh)</strong>
                          </TableCell>
                          <TableCell>
                            <strong>Used in CAPT (kWh)</strong>
                            <Button
                              variant="contained"
                              size="small"
                              color={editing ? "primary" : "secondary"}
                              style={{ marginLeft: 10 }}
                              onClick={() => {
                                setEditing(!editing);
                              }}
                            >
                              {editing ? "Save" : "Edit"}
                            </Button>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {Object.keys(props.propertyDetails).find(
                          (x) => x === "kwhTariffs"
                        ) !== null
                          ? Object.keys(props.propertyDetails.kwhTariffs).map(
                              (energyType: string) => {
                                const fuelType = Object.keys(
                                  props.propertyDetails.kwhTariffs[energyType]
                                )[0];

                                const kWhChangeEventHandler = (event: any) => {
                                  setFuelKWhs(
                                    new Map(
                                      fuelkWhs.set(
                                        energyType,
                                        parseInt(event.target.value)
                                      )
                                    )
                                  );
                                };

                                return (
                                  <TableRow key={energyType}>
                                    <TableCell>{energyType}</TableCell>
                                    <TableCell>{fuelType}</TableCell>
                                    <TableCell>
                                      {props.propertyDetails.kwhTariffs[
                                        energyType
                                      ][fuelType].toFixed(0)}
                                    </TableCell>
                                    <TableCell>
                                      <Input
                                        defaultValue={props.propertyDetails.kwhTariffs[
                                          energyType
                                        ][fuelType].toFixed(0)}
                                        onChange={(event) =>
                                          kWhChangeEventHandler(event)
                                        }
                                        style={{
                                          width: "75px",
                                        }}
                                        disabled={!editing}
                                      />
                                    </TableCell>
                                  </TableRow>
                                );
                              }
                            )
                          : "Loading"}
                      </TableBody>
                    </Table>
                  </CardContent>
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    display: "flex",
                    // height: "100%" ,
                    // width: "100%"
                  }}
                >
                  <CardContent
                    style={{
                      backgroundColor: "white",
                      width: "100%",
                      padding: "5px",
                    }}
                  >
                    <div style={{ height: "100%" }}>
                      {/* <div className="panel_title">
                    Income IMD of the property LSOA
                  </div> */}

                      {/* <BottomRadialBarLoader
                    ImdIncome={
                      buildingAttributes ? buildingAttributes.imd_income : 0
                    }
                  /> */}
                      <LoadingOverlay
                        className="myLoading"
                        active={loadingFrame}
                        classNamePrefix="MyLoader_overlay"
                      >
                        <iframe
                          onLoad={() => setLoadingFrame(false)}
                          src="https://fryford.github.io/imdmap/"
                          style={{ width: "100%", height: "100%" }}
                        ></iframe>
                      </LoadingOverlay>
                    </div>
                  </CardContent>
                </Grid>
              </Grid>

              <Grid container item spacing={2}>
                <Grid
                  item
                  xs={6}
                  style={{
                    display: "flex",
                    height: "100%",
                    width: "50%",
                  }}
                >
                  <CardContent
                    style={{
                      backgroundColor: "white",
                      margin: "0px 0px 0px 0px",
                      paddingTop: "3px",
                      flex: "1 0 auto",
                      // height: "100%",
                    }}
                  >
                    <div style={{ height: "100%" }}>
                      <div className="panel_title">Property statistics</div>
                      <List component="nav" aria-label="main mailbox folders">
                        <ListItem>
                          <ListItemIcon>
                            <EmojiObjectsIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <span>
                               {fuelBillData.length > 0 ? 
                                 "£" +  fuelBillData[10].toFixed(0): "loading"} 
                               
                              </span>
                            }
                            secondary="Estimated fuel costs of this property at 2022"
                          />
                        </ListItem>
                        <Divider />
                        <ListItem>
                          <ListItemIcon>
                            <EqualizerIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary="£1181"
                            secondary="Fuel cost - national median"
                          />
                        </ListItem>
                        <Divider />
                        <ListItem>
                          <ListItemIcon>
                            <PermContactCalendarIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              buildingAttributes
                                ? buildingAttributes.imd_income + " / 10"
                                : "Unknown"
                            }
                            secondary="Decile of income deprivation (1 most deprived, 10 least deprived)"
                          />
                        </ListItem>
                        <Divider />
                        <ListItem>
                          <ListItemIcon>
                            <DraftsIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              buildingAttributes
                                ? buildingAttributes.lsoa11cd
                                : "Unknown"
                            }
                            secondary="LSOA ID of building location"
                          />
                        </ListItem>
                      </List>
                    </div>
                  </CardContent>
                </Grid>

                <Grid
                  item
                  xs={6}
                  style={{
                    display: "flex",
                    height: "90.5%",
                    width: "50%",
                  }}
                >
                  <div className="panel_card">
                    <div className="panel_title">
                      Regional deprivation statistics
                    </div>
                    <div
                      className="panel_card_graphs"
                      style={{ minHeight: 200 }}
                    >
                      <div style={{ width: "50%" }}>
                        <ImdIncomeChartLoader
                          value={
                            buildingAttributes
                              ? buildingAttributes.imd_income
                              : null
                          }
                        />
                      </div>
                      <div style={{ width: "50%" }}>
                        <ImdChartLoader
                          value={
                            buildingAttributes
                              ? buildingAttributes.imd_decile
                              : null
                          }
                        />
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}
    </>
  );
}
