import React, { useState } from "react";
import "./svg-chart.css";

interface IProps {
  currentValue: number;
  currentLetter: string;
  currentHeight: number;
  currrentColor: string;

  potentialValue: number;
  potentialLetter: string;
  potentialHeight: number;
  potentialColor: string;
  captPotentialLetter: string;
  captPotentialColor: string;
  captPotentialValue: number;
  captPotentialHeight: number;
}

function ChartSVG(props: IProps) {
  return (
    <div style={{ marginTop: 0, paddingBottom: 0 }}>
      <svg
        id="epc_diagram"
        width="100%"
        viewBox="-5 -5 730 432"
        aria-labelledby="svg-title"
        aria-describedby="svg-desc"
        role="img"
        // height="320px"
        // max-height="320px"
      >
        <line
          x1="72"
          y1="0"
          x2="72"
          y2="426"
          stroke="#b1b4b6"
          strokeWidth="1"
        ></line>
        <line
          x1="410"
          y1="0"
          x2="410"
          y2="426"
          stroke="#b1b4b6"
          strokeWidth="1"
        ></line>
        <line
          x1="510"
          y1="0"
          x2="510"
          y2="426"
          stroke="#b1b4b6"
          strokeWidth="1"
        ></line>
        <line
          x1="0"
          y1="55"
          x2="615"
          y2="55"
          stroke="#b1b4b6"
          strokeWidth="1"
        ></line>
        <line
          x1="0"
          y1="0"
          x2="615"
          y2="0"
          stroke="#b1b4b6"
          strokeWidth="1"
        ></line>
        <line
          x1="0"
          y1="0"
          x2="615"
          y2="0"
          stroke="#b1b4b6"
          strokeWidth="1"
        ></line>
        <line
          x1="0"
          y1="0"
          x2="615"
          y2="0"
          stroke="#b1b4b6"
          strokeWidth="1"
        ></line>
        <line
          x1="0"
          y1="0"
          x2="615"
          y2="0"
          stroke="#b1b4b6"
          strokeWidth="1"
        ></line>
        <line
          x1="0"
          y1="0"
          x2="0"
          y2="376"
          stroke="#b1b4b6"
          strokeWidth="1"
        ></line>
        <line
          x1="615"
          y1="426"
          x2="615"
          y2="0"
          stroke="#b1b4b6"
          strokeWidth="1"
        ></line>
        <line
          x1="615"
          y1="426"
          x2="0"
          y2="426"
          stroke="#b1b4b6"
          strokeWidth="1"
        ></line>
        {/* <line
          x1="720"
          y1="426"
          x2="720"
          y2="0"
          stroke="#b1b4b6"
          strokeWidth="1"
        ></line> */}
        {/* <line
          x1="720"
          y1="426"
          x2="0"
          y2="426"
          stroke="#b1b4b6"
          strokeWidth="1"
        ></line> */}
        <rect width="78" height="50" x="72" y="75" className="band-a"></rect>
        <rect width="118" height="50" x="72" y="125" className="band-b"></rect>
        <rect width="158" height="50" x="72" y="175" className="band-c"></rect>
        <rect width="198" height="50" x="72" y="225" className="band-d"></rect>
        <rect width="238" height="50" x="72" y="275" className="band-e"></rect>
        <rect width="278" height="50" x="72" y="325" className="band-f"></rect>
        <rect width="318" height="50" x="72" y="375" className="band-g"></rect>
        <rect
          width="72"
          height="50"
          x="0"
          y="75"
          className="band-a-score"
        ></rect>
        <rect
          width="72"
          height="50"
          x="0"
          y="125"
          className="band-b-score"
        ></rect>
        <rect
          width="72"
          height="50"
          x="0"
          y="175"
          className="band-c-score"
        ></rect>
        <rect
          width="72"
          height="50"
          x="0"
          y="225"
          className="band-d-score"
        ></rect>
        <rect
          width="72"
          height="50"
          x="0"
          y="275"
          className="band-e-score"
        ></rect>
        <rect
          width="72"
          height="50"
          x="0"
          y="325"
          className="band-f-score"
        ></rect>
        <rect
          width="72"
          height="50"
          x="0"
          y="375"
          className="band-g-score"
        ></rect>

        <text
          role="presentation"
          aria-hidden="true"
          x="0"
          y="0"
          className="letter"
        >
          <tspan x="107" y="114">
            A
          </tspan>
          <tspan x="147" y="164">
            B
          </tspan>
          <tspan x="187" y="214">
            C
          </tspan>
          <tspan x="227" y="264">
            D
          </tspan>
          <tspan x="267" y="314">
            E
          </tspan>
          <tspan x="307" y="364">
            F
          </tspan>
          <tspan x="347" y="414">
            G
          </tspan>
        </text>
        <text
          role="presentation"
          aria-hidden="true"
          x="0"
          y="0"
          className="small"
        >
          <tspan x="8" y="105">
            92+
          </tspan>
          <tspan x="8" y="155">
            81-91
          </tspan>
          <tspan x="8" y="205">
            69-80
          </tspan>
          <tspan x="8" y="255">
            55-68
          </tspan>
          <tspan x="8" y="305">
            39-54
          </tspan>
          <tspan x="8" y="355">
            21-38
          </tspan>
          <tspan x="8" y="405">
            1-20
          </tspan>
        </text>

        <text
          role="presentation"
          aria-hidden="true"
          x="8"
          y="15"
          className="small"
          dominantBaseline="middle"
        >
          Score
        </text>

        <text
          role="presentation"
          aria-hidden="true"
          x="85"
          y="15"
          className="small"
          dominantBaseline="middle"
        >
          Energy rating
        </text>

        <text
          role="presentation"
          aria-hidden="true"
          x="460"
          y="15"
          className="small"
          textAnchor="middle"
          dominantBaseline="middle"
        >
          Current
        </text>

        <text
          role="presentation"
          aria-hidden="true"
          x="565"
          y="15"
          className="small"
          textAnchor="middle"
          dominantBaseline="middle"
        >
          Potential
        </text>
        {/* <text
          role="presentation"
          aria-hidden="true"
          x="565"
          y="40"
          className="small"
          textAnchor="middle"
          dominantBaseline="middle"
        >
          (EPC)
        </text>
        <text
          role="presentation"
          aria-hidden="true"
          x="665"
          y="15"
          className="small"
          textAnchor="middle"
          dominantBaseline="middle"
        >
          Potential
        </text>
        <text
          role="presentation"
          aria-hidden="true"
          x="665"
          y="40"
          className="small"
          textAnchor="middle"
          dominantBaseline="middle"
        >
          (CAPT)
        </text> */}
        <svg
          role="presentation"
          aria-hidden="true"
          x="415"
          y={props.currentHeight}
          width="90"
          height="50"
        >
          <rect
            width="75"
            height="50"
            className={props.currrentColor}
            x="24.5"
          ></rect>
          <polygon
            points="0,25 25,50 25,0 0,25"
            className={props.currrentColor}
          ></polygon>
          <text x="18" y="32" className="current-potential-number">
            {props.currentValue} |
          </text>
          <text x="67" y="32" className="small-letter">
            {props.currentLetter}
          </text>
        </svg>

        <svg
          role="presentation"
          aria-hidden="true"
          x="515"
          y={props.potentialHeight}
          width="90"
          height="50"
        >
          <rect
            width="75"
            height="50"
            className={props.potentialColor}
            x="24.5"
          ></rect>
          <polygon
            points="0,25 25,50 25,0 0,25"
            className={props.potentialColor}
          ></polygon>
          <text x="18" y="32" className="current-potential-number">
            {props.potentialValue} |
          </text>
          <text x="67" y="32" className="small-letter">
            {props.potentialLetter}
          </text>
        </svg>
        {/* <svg
          role="presentation"
          aria-hidden="true"
          x="620"
          y={props.captPotentialHeight}
          width="90"
          height="50"
        >
          <rect
            width="75"
            height="50"
            className={props.captPotentialColor}
            x="24.5"
          ></rect>
          <polygon
            points="0,25 25,50 25,0 0,25"
            className={props.captPotentialColor}
          ></polygon>
          <text x="18" y="32" className="current-potential-number">
            {props.captPotentialValue} |
          </text>
          <text x="67" y="32" className="small-letter">
            {props.captPotentialLetter}
          </text>
        </svg> */}
      </svg>
    </div>
  );
}

export default ChartSVG;
