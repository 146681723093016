import {
  Button,
  TableCell,
  TableRow,
  Input,
  Table,
  TableHead,
  TableBody,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import "./ActionPlanPage.css";
import React, { useContext, useEffect, useState } from "react";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import IconButton from "@material-ui/core/IconButton";
import SaveOutlinedIcon from "@material-ui/icons/SaveOutlined";
import PublishOutlinedIcon from "@material-ui/icons/PublishOutlined";
import { numberWithCommas } from "../utilities";
import { getData } from "../get-data";
import { UserContext } from "../../contexts/UserContext";
import { TextField } from "@mui/material";
import NewImpactRow from "./NewImpactRow";

interface SandboxImpact {
  id: number;
  kwhImpact: number;
  year: number;
  energyCategoryId: number;
}
interface IProps {
  amendSandboxImpact: (sandboxImpact: SandboxImpact[]) => void;
  Co2Impacts: Array<{ id: number; impact: number }>;
  setCo2Impact: React.Dispatch<
    React.SetStateAction<
      {
        id: number;
        impact: number;
      }[]
    >
  >;
}

export default function AddImpact(props: IProps) {
  const userContext = useContext(UserContext);

  const [sandboxImpactDto, setSandboxImpactDto] = useState<
    Array<SandboxImpact>
  >([]);

  const [edit, setEdit] = useState<boolean>(true);

  const [energyCategories, setEnergyCategories] = useState<
    Array<EnergyCategory>
  >([]);

  const [rowMaps, setRowMaps] = useState<Array<{ id: number; edit: boolean }>>(
    []
  );

  const [numberRows, setNumberRows] = useState<number[]>([]);

  const [savedRows, setSavedRows] = useState<boolean>(true);

  const setMap = (id: number, edit: boolean) => {
    let maps = rowMaps;

    let obj = maps.findIndex((obj) => obj.id == id);

    if (obj != -1) {
      maps[obj].edit = edit;
    } else {
      maps = [...maps, { id: id, edit: edit }];
    }

    setRowMaps(maps);
  };
  const addRow = () => {
    let arr = numberRows;
    if (arr.length == 0) {
      let newArr = [1];
      setNumberRows(newArr);
    }
    if (arr.length > 0) {
      let newNum = arr[arr.length - 1] + 1;

      arr = [...arr, newNum];
      setNumberRows(arr);
    }
  };
  const deleteRow = (id: number, edit: boolean) => {
    let sortedArr = numberRows;
    sortedArr.push(sortedArr.splice(sortedArr.indexOf(id), 1)[0]);
    sortedArr = sortedArr.filter((item) => item !== id);
    setNumberRows(sortedArr);

    let sortedMaps = rowMaps;
    sortedMaps.push(
      sortedMaps.splice(sortedMaps.indexOf({ id: id, edit: edit }), 1)[0]
    );
    sortedMaps = sortedMaps.filter((item) => item.id !== id);
    setRowMaps(sortedMaps);

    const lessImpacts = sandboxImpactDto.filter((x) => x.id !== id);
    const newCo2 = props.Co2Impacts.filter(x=> x.id !== id);
    props.setCo2Impact(newCo2)
    setSandboxImpactDto(lessImpacts);
    setSavedRows(true);
    props.amendSandboxImpact(lessImpacts);
  };
  useEffect(() => {
    addRow();
  }, []);
  const fetchEnergyCategories = () => {
    const url = "/api/sap/energycategories";

    getData("https://captapi.absolarlab.com" + url, {
      userState: userContext.userState,
      setUserState: userContext.setUserState,
    }).then((energyCategories: Array<EnergyCategory>) => {
      setEnergyCategories(energyCategories);
    });
  };
  const cancelImpact = () => {
    props.amendSandboxImpact([]);

    setNumberRows([]);
    setRowMaps([]);
    setSandboxImpactDto([]);
  };

  const applyImpact = (sandboxImpact: SandboxImpact) => {
    let newImpacts = sandboxImpactDto.filter((x) => x.id !== sandboxImpact.id);
    newImpacts = [...newImpacts, sandboxImpact];
    setSandboxImpactDto(newImpacts);

    props.amendSandboxImpact(newImpacts);

    setSavedRows(true);
    setEdit(!edit);
  };

  useEffect(() => {
    fetchEnergyCategories();
  }, []);

  useEffect(() => {
    fetchEnergyCategories();
  }, []);

  return (
    <div className="action-plan_inner">
      <Table
        size="small"
        style={{
          backgroundColor: "white",
        }}
      >
        <TableHead style={{ background: "lightgrey" }}>
          <TableRow>
            <TableCell>
              <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={cancelImpact}
              >
                Clear All Impacts
              </Button>
            </TableCell>

            <TableCell>
              <></>
            </TableCell>
            <TableCell>
              <></>
            </TableCell>
            <TableCell>
              <></>
            </TableCell>
            <TableCell>
              <></>
            </TableCell>
            <TableCell>
              <></>
            </TableCell>
            <TableCell>
              {savedRows ? (
                <></>
              ) : (
                <span style={{ color: "red" }}>All rows must be saved</span>
              )}
            </TableCell>
            <TableCell>
              <></>
            </TableCell>
            <TableCell>
              <></>
            </TableCell>
            <TableCell>
              <></>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody  >
          {numberRows.map((id) => (
            <>
              <NewImpactRow
                key={id}
                amendSandboxImpact={applyImpact}
                deleteRow={deleteRow}
                setMap={setMap}
                energyCategories={energyCategories}
                id={id}
                delete={Math.max(...numberRows) == id}
                co2Impact={props.Co2Impacts.find((x) => x.id == id)}
              />
            </>
          ))}
          <TableRow>
            <TableCell>
              <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={addRow}
              >
                + Row
              </Button>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
}
