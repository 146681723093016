import React, { useEffect, useState } from "react";
import { ApexOptions } from "apexcharts";
import Chart from "react-apexcharts";

interface IProps {
  co2DataPlanned: number[];
  co2DataDone: number[];
  co2DataDefault: number[];
  yearRange: number[];
}

export default function CompletedLineGraph(props: IProps) {
  const [series, setSeries] = useState([
    // {
    //   name: "CO2 with completed interventions",
    //   data: props.co2DataDone,
    // },
    {
      name: "CO2 with planned and completed interventions",
      data: props.co2DataPlanned,
    },
    {
      name: "CO2 without interventions",
      data: props.co2DataDefault,
    },
  ]);
  const [options, setOptions] = useState<ApexOptions>({
    chart: {
      type: "line",
      zoom: {
        enabled: false,
      },
      background: "#fff",
      width: "auto",
      height: "auto",
    },

    // colors: ["#77B6EA", "#545454", "#545454"],
    // stroke: {
    //   width: [4, 4],
    //   colors: ["#77B6EA", "#545454", "transparent"],
    //   dashArray: [0, 5, 0],
    // },
    title: {
      // text: "Forecast CO2",
    },
    xaxis: {
      type: "category",
      categories: props.yearRange,
      labels: {
        rotate: 0,
        hideOverlappingLabels: true,
      },

      tooltip: {
        enabled: true,
      },
    },
    yaxis: [
      {
        title: {
          text: "CO2 (market)",
        },
      },
      { show: false },
    ],
  });
  useEffect(() => {
    setSeries([
      // {
      //   name: "CO2 with completed interventions",
      //   data: props.co2DataDone,
      // },
      {
        name: "CO2 with planned and completed interventions",
        data: props.co2DataPlanned,
      },
      {
        name: "CO2 without interventions",
        data: props.co2DataDefault,
      },
    ]);
    setOptions({ ...options, xaxis: { categories: props.yearRange } });
  }, [props]);

  return <Chart options={options} series={series} type="line" width="95%" />;
}
