import React, { useEffect } from "react";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { numberWithCommas } from "../utilities";

interface IProps {
  options: ApexOptions;
  series: any[];
  year: number;
  portfolioYearInterventions?: Array<YearPortfolioIntervention>;
}

export default function ActionPlanChart(props: IProps): JSX.Element {
  const Interventions = props.portfolioYearInterventions?.filter(
    (x) => x.doneYear == props.year || x.plannedYear == props.year
  );

  return (
    <div>
      <Chart
        options={props.options}
        series={props.series}
        type="line"
        width="100%"
        height="260vh"
      />
      {props.portfolioYearInterventions ? (
        <div>
          <Table
            size="small"
            style={{
              backgroundColor: "white",
            }}
          >
            <TableBody>
              <TableRow>
                <TableCell>Interventions for {props.year}:</TableCell>
              </TableRow>
              {Interventions?.length == 0 ? (
                <TableRow>
                  <TableCell>None</TableCell>
                </TableRow>
              ) : (
                <></>
              )}
              {Interventions?.map((intervention, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>{intervention.interventionName}</TableCell>
                    <TableCell>Properties:</TableCell>
                    <TableCell>{intervention.propertyNum}</TableCell>
                    <TableCell>kWh Impact:</TableCell>
                    <TableCell>
                      {numberWithCommas(intervention.kwhImpact.toFixed(0))}
                    </TableCell>
                    <TableCell>Cost:</TableCell>
                    <TableCell>
                      {" "}
                      £{numberWithCommas(intervention.installCost.toFixed(0))}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
