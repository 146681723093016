import React, { useEffect, useState, useContext } from "react";
import { Card, CardContent, makeStyles } from "@material-ui/core";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  styled,
  tableCellClasses,
} from "@mui/material";
import "./AdditionalInfoPage.css";
import { UserContext } from "../../contexts/UserContext";
import { getData } from "../get-data";
import LoadingOverlay from "react-loading-overlay-ts";
import { useHistory, useParams } from "react-router-dom";

interface IParams {
  id: string | undefined;
}
interface IProps {
  selectedProperty: IProperty;
  setSingleProperty: React.Dispatch<React.SetStateAction<boolean>>;
  reload: (id?: number) => Promise<void>|null;
}

const useStyles = makeStyles({
  card: {
    padding: "0px",
    backgroundColor: "#113d64",
  },
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#c7dceb",
  },
}));

export default function AdditionalInfoPage(props: IProps) {
  let { id } = useParams<IParams>();
  const userContext = useContext(UserContext);
  const classes = useStyles();
  const [additionalInfos, setAdditionalInfos] = useState<Array<any>>();
  const [loaded, setLoaded] = useState<boolean>(false);
  const history = useHistory();

  const fetchAdditionalInfos = () => {
    const url = "/api/sap/propertyadditionalinfo?";
    getData("https://captapi.absolarlab.com" + url + "propertyId=" + id, {
      userState: userContext.userState,
      setUserState: userContext.setUserState,
    }).then((response) => {
      setAdditionalInfos(response);
      setLoaded(true);
    });
  };

  useEffect(() => {
    if (Object.keys(props.selectedProperty).length === 0) {
      props.setSingleProperty(true);
      props.reload(parseInt(id!))?.catch((e)=>{history.push("/404")});
    }
    fetchAdditionalInfos();
    props.setSingleProperty(true);
  }, []);

  return (
    <Card className="card" style={{ backgroundColor: "#113d64" }}>
      <CardContent className="header">
        <h3 className="headerText">{props.selectedProperty.address}</h3>
      </CardContent>
      <hr className="solid" />
      <CardContent style={{ paddingTop: "0px", paddingBottom: "0px" }}>
        <h3 className="headerText">Additional Info</h3>
        <LoadingOverlay active={!loaded}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Attribute Name</StyledTableCell>
                  <StyledTableCell>Attribute Value</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {additionalInfos?.map((additionalInfo) => (
                  <TableRow key={additionalInfo.id}>
                    <TableCell>
                      {additionalInfo.attribute_name.replace(/_/g, " ")}
                    </TableCell>
                    <TableCell>{additionalInfo.attribute_value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </LoadingOverlay>
      </CardContent>
    </Card>
  );
}
