import React, { Dispatch, useEffect, useState, useContext } from "react";
import {
  Button,
  Card,
  CardContent,
  Grid,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  TextField,
  Link,
  TableContainer,
  Modal,
} from "@material-ui/core";
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core/styles";
import { numberWithCommas } from "../utilities";
import { useHistory } from "react-router";
import { getData, putData } from "../get-data";
import { UserContext } from "../../contexts/UserContext";
import PropertyList from "./PropertyList";
import SearchComponent from "./SearchComponent";

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      // backgroundColor: theme.palette.common.black,
      color: "black",
      fontWeight: "bold",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

interface IProps {
  portfolio: PortfolioDetail;
  setSelectedPortfolio: Dispatch<React.SetStateAction<PortfolioDetail>>;
  setDeleteCheck: Dispatch<React.SetStateAction<boolean>>;
  setPortfolioToDelete: Dispatch<React.SetStateAction<PortfolioDetail>>;
  properties: IPropertyEPC[];
  filters: Array<IFilterObject>;
}

export default function PortfolioListItem(props: IProps) {
  const userContext = useContext(UserContext);
  const history = useHistory();
  const [showEditUI, setShowEditUI] = useState<boolean>(false);
  const [properties, setProperties] = useState<IPropertyEPC[]>([]);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [portfolio, setPortfolio] = useState<PortfolioDetail>(props.portfolio);
  const [adding, setAdding] = useState<boolean>(false);

  const fetchPortfolioProperties = (newPortfolio?: PortfolioDetail) => {
    if (newPortfolio) {
      setPortfolio(newPortfolio);
    }
    let url =
      "https://captapi.absolarlab.com/api/portfolio/" +
      portfolio.id.toString() +
      "/properties";
    getData(url, userContext).then((list) => {
      setProperties(list);
      setAdding(false);
    });
  };

  const addProperty = (property: IProperty) => {
    let url =
      "https://captapi.absolarlab.com/api/portfolio/" +
      portfolio.id +
      "/add_properties";
    putData(url, [property.property_id], userContext).then(
      (res: PortfolioDetail) => {
        fetchPortfolioProperties(res);
      }
    );
  };
  const handleOpenPortfolio = (portfolio: PortfolioDetail) => () => {
    event?.preventDefault();
    history.push("/portfolio_report/" + portfolio.id.toString());
    props.setSelectedPortfolio(portfolio);
  };

  useEffect(() => {
    if (showEditUI) {
      fetchPortfolioProperties();
    }
  }, [showEditUI]);

  useEffect(() => {
    if (properties.length > 0) {
      console.log("yes");
      setLoaded(true);
    }
  }, [properties]);

  return (
    <>
      <StyledTableRow key={portfolio.id}>
        <StyledTableCell component="th" scope="row">
          {portfolio.name}
        </StyledTableCell>
        <StyledTableCell align="left">
          {numberWithCommas(portfolio.numberProperties)}
        </StyledTableCell>
        <StyledTableCell align="left">{portfolio.user}</StyledTableCell>
        <StyledTableCell align="left">
          <Button size="small" onClick={handleOpenPortfolio(portfolio)}>
            OPEN
          </Button>
          <Button
            style={{ backgroundColor: "#f58484", color: "#fff" }}
            size="small"
            onClick={() => {
              props.setPortfolioToDelete(portfolio);
              props.setDeleteCheck(true);
            }}
          >
            Delete
          </Button>
          <Button size="small" onClick={() => setShowEditUI(true)}>
            Edit
          </Button>
          <Modal
            open={showEditUI}
            onClose={() => {
              setShowEditUI(false);
              setAdding(false);
            }}
          >
            <div
              style={{
                top: "50%",
                left: "50%",
                marginRight: "-50%",
                transform: "translate(-50%, -50%)",
                position: "absolute",
                width: "35%",
                backgroundColor: "white",
                padding: 10,
                maxHeight: "80vh",
                overflow: "auto",
              }}
            >
              {adding ? (
                <></>
              ) : (
                <>
                  <PropertyList
                    properties={properties}
                    loaded={loaded}
                    portfolio={portfolio}
                    fetchPortfolioProperties={fetchPortfolioProperties}
                  />
                  <br />
                  <Button onClick={() => setAdding(true)}>
                    Add New Property
                  </Button>
                </>
              )}
              {adding ? (
                <SearchComponent
                  properties={props.properties}
                  filters={props.filters}
                  addProperty={addProperty}
                />
              ) : (
                <></>
              )}
            </div>
          </Modal>
        </StyledTableCell>
      </StyledTableRow>
    </>
  );
}
