export async function postData(
  url: string,
  data = {},
  refreshProps?: RefreshProps,
  noJson?: boolean
): Promise<any> {
  // Default options are marked with *
  const response = await fetch(url, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + refreshProps?.userState.idToken,
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  });
  if (response.ok) {
    if (noJson) {
      return response;
    }
    return response.json(); // parses JSON response into native JavaScript objects
  } else if (response.status === 401) {
    if (refreshProps) {
      const refreshResponse = await refresh(
        refreshProps.userState.username,
        refreshProps.userState.refreshToken
      );

      if (refreshResponse.ok) {
        const refreshTokenData: LoginResponse = await refreshResponse.json();

        if (refreshTokenData.authenticationResult) {
          const userDataUpdate: UserState = {
            username: refreshProps.userState.username,
            idToken: refreshTokenData.authenticationResult["idToken"],
            accessToken: refreshTokenData.authenticationResult["accesssToken"],
            refreshToken: refreshProps.userState.refreshToken,
            loggedIn: true,
          };
          refreshProps.setUserState(userDataUpdate);
          const newRefreshProps: RefreshProps = {
            setUserState: refreshProps.setUserState,
            userState: userDataUpdate,
          };
          return postData(url, data, newRefreshProps);
        }
      } else {
        refreshProps.setUserState({
          username: refreshProps.userState.username,
          idToken: "",
          accessToken: "",
          refreshToken: "",
          loggedIn: false,
        });
        const text = (await refreshResponse.text()).valueOf();
        throw new Error(text);
      }
    } else {
      throw new Error("Not logged in");
    }
  } else {
    const text = (await response.text()).valueOf();
    throw new Error(text);
  }
}

export async function putData(
  url: string,
  data = {},
  refreshProps?: RefreshProps,
  noJson?: boolean
): Promise<any> {
  // Default options are marked with *
  const response = await fetch(url, {
    method: "PUT", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + refreshProps?.userState.idToken,
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  });
  if (response.ok) {
    if (noJson) {
      return response;
    }
    return response.json(); // parses JSON response into native JavaScript objects
  } else if (response.status === 401) {
    if (refreshProps) {
      const refreshResponse = await refresh(
        refreshProps.userState.username,
        refreshProps.userState.refreshToken
      );

      if (refreshResponse.ok) {
        const refreshTokenData: LoginResponse = await refreshResponse.json();
        if (refreshTokenData.authenticationResult) {
          const userDataUpdate: UserState = {
            username: refreshProps.userState.username,
            idToken: refreshTokenData.authenticationResult["idToken"],
            accessToken: refreshTokenData.authenticationResult["accesssToken"],
            refreshToken: refreshProps.userState.refreshToken,
            loggedIn: true,
          };
          refreshProps.setUserState(userDataUpdate);
          const newRefreshProps: RefreshProps = {
            setUserState: refreshProps.setUserState,
            userState: userDataUpdate,
          };

          return putData(url, data, newRefreshProps);
        }
        getData;
      } else {
        refreshProps.setUserState({
          username: refreshProps.userState.username,
          idToken: "",
          accessToken: "",
          refreshToken: "",
          loggedIn: false,
        });
        const text = (await refreshResponse.text()).valueOf();
        throw new Error(text);
      }
    } else {
      throw new Error("Not logged in. " + (await response.text()).valueOf());
    }
  } else {
    const text = (await response.text()).valueOf();
    throw new Error(text);
  }
}

export async function getData(
  url: string,
  refreshProps?: RefreshProps,
  noJson?: boolean
): Promise<any> {
  // Default options are marked with *
  const response = await fetch(url, {
    method: "GET", // *GET, POST, PUT, DELETE, etc.
    headers: refreshProps
      ? new Headers({
          Authorization: "Bearer " + refreshProps.userState.idToken,
        })
      : undefined,
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
  });
  if (response.ok) {
    if (noJson) {
      return response;
    }
    return response.json(); // parses JSON response into native JavaScript objects
  } else if (response.status === 401) {
    alert("refreshing");
    if (refreshProps) {
      const refreshResponse = await refresh(
        refreshProps.userState.username,
        refreshProps.userState.refreshToken
      );

      if (refreshResponse.ok) {
        const refreshTokenData: LoginResponse = await refreshResponse.json();
        if (refreshTokenData.authenticationResult) {
          const userDataUpdate: UserState = {
            username: refreshProps.userState.username,
            idToken: refreshTokenData.authenticationResult["idToken"],
            accessToken: refreshTokenData.authenticationResult["accesssToken"],
            refreshToken: refreshProps.userState.refreshToken,
            loggedIn: true,
          };
          refreshProps.setUserState(userDataUpdate);
          const newRefreshProps: RefreshProps = {
            setUserState: refreshProps.setUserState,
            userState: userDataUpdate,
          };

          return getData(url, newRefreshProps);
        }
      } else {
        refreshProps.setUserState({
          username: refreshProps.userState.username,
          idToken: "",
          accessToken: "",
          refreshToken: "",
          loggedIn: false,
        });
        const text = (await refreshResponse.text()).valueOf();
        throw new Error(text);
      }
    } else {
      throw new Error("Not logged in" + url);
    }
  } else {
    const text = (await response.text()).valueOf();
    throw new Error(text);
  }
}
export async function deleteData(
  url: string,
  refreshProps?: RefreshProps
): Promise<any> {
  // Default options are marked with *
  const response = await fetch(url, {
    method: "DELETE", // *GET, POST, PUT, DELETE, etc.
    headers: refreshProps
      ? new Headers({
          Authorization: "Bearer " + refreshProps.userState.idToken,
        })
      : undefined,
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
  });
  if (response.ok) {
    return response; // parses JSON response into native JavaScript objects
  } else if (response.status === 401) {
    if (refreshProps) {
      const refreshResponse = await refresh(
        refreshProps.userState.username,
        refreshProps.userState.refreshToken
      );

      if (refreshResponse.ok) {
        const refreshTokenData: LoginResponse = await refreshResponse.json();
        if (refreshTokenData.authenticationResult) {
          const userDataUpdate: UserState = {
            username: refreshProps.userState.username,
            idToken: refreshTokenData.authenticationResult["idToken"],
            accessToken: refreshTokenData.authenticationResult["accesssToken"],
            refreshToken: refreshProps.userState.refreshToken,
            loggedIn: true,
          };
          refreshProps.setUserState(userDataUpdate);
          const newRefreshProps: RefreshProps = {
            setUserState: refreshProps.setUserState,
            userState: userDataUpdate,
          };

          return deleteData(url, newRefreshProps);
        }
        getData;
      } else {
        refreshProps.setUserState({
          username: refreshProps.userState.username,
          idToken: "",
          accessToken: "",
          refreshToken: "",
          loggedIn: false,
        });
        const text = (await refreshResponse.text()).valueOf();
        throw new Error(text);
      }
    } else {
      throw new Error("Not logged in" + url);
    }
  } else {
    const text = (await response.text()).valueOf();
    throw new Error(text);
  }
}
async function refresh(username: string, refreshtoken: string) {
  return await fetch(
    "https://captapi.absolarlab.com/api/auth/refresh?username=" + username,
    {
      method: "PUT", // *GET, POST, PUT, DELETE, etc.
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ refreshToken: refreshtoken }),
    }
  );
}
