import React, { useContext, useEffect, useState } from "react";
import { ApexOptions } from "apexcharts";
import BuildingAgeChart from "./BuildingAgeChart";
import { getData } from "../../get-data";
import { UserContext } from "../../../contexts/UserContext";
interface IProps {
  value: LSOA;
  age: string;
}
export default function BuildingAgeLoader(props: IProps) {
  const [series, setSeries] = useState([
    {
      name: "Percentage of buildings",
      data: [
        props.value.bppre1900p ? props.value.bppre1900p : 0,
        props.value.bp190018p ? props.value.bp190018p : 0,
        props.value.bp191929p ? props.value.bp191929p : 0,
        props.value.bp193039p ? props.value.bp193039p : 0,
        props.value.bp194554p ? props.value.bp194554p : 0,
        props.value.bp195564p ? props.value.bp195564p : 0,
        props.value.bp196572p ? props.value.bp196572p : 0,
        props.value.bp197382p ? props.value.bp197382p : 0,
        props.value.bp198392p ? props.value.bp198392p : 0,
        props.value.bp199399p ? props.value.bp199399p : 0,
        props.value.bp200008p ? props.value.bp200008p : 0,
        props.value.bp200911p ? props.value.bp200911p : 0,
        props.value.bp201214p ? props.value.bp201214p : 0,
        props.value.bp201517p ? props.value.bp201517p : 0,
        props.value.bp201820p ? props.value.bp201820p : 0,
        props.value.bp2021p ? props.value.bp2021p : 0,
      ],
    },
  ]);
  useEffect(() => {
    setOptions({
      chart: {
        type: "bar",
        toolbar: {
          show: false,
        },
      },
      colors: [
        "#54bf58",
        // "#9ee09e",
        // "#fdfd97",
        // "#feb144",
        // "#FF6653",
        // "#CC99C9",
      ],
      title: { text: "Building age" },
      plotOptions: {
        bar: {
          horizontal: true,
          distributed: true,
          barHeight: "100%",
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      // stroke: {
      //   width: 1,
      //   colors: ["#fff"],
      // },
      yaxis: {
        // min: 0,
        // max: 30,
        // show: false,
      },
      xaxis: {
        categories: [
          "Pre 1900",
          "1900 - 1918",
          "1919 - 1929",
          "1930 - 1939",
          "1945 - 1954",
          "1955 - 1964",
          "1965 - 1972",
          "1973 - 1982",
          "1983 - 1992",
          "1993 - 1999",
          "2000 - 2008",
          "2009 - 2011",
          "2012 - 2014",
          "2015 - 2017",
          "2018 - 2020",
          "Post 2021",
        ],

        labels: {
          style: {
            // colors: [
            //   "#54bf58",
            //   "#9ee09e",
            //   "#fdfd97",
            //   "#feb144",
            //   "#FF6653",
            //   "#CC99C9",
            // ],
            fontSize: "12px",
          },
          show: false,
        },
      },
      annotations: {
        yaxis: [
          {
            y: props.age,
            borderColor: "#f29305",
            label: {
              borderColor: "#f29305",
              style: {
                color: "#fff",
                background: "#f29305",
              },
              text: props.age !== "" ? props.age : "unknown",
              position: "right",
              // offsetX: 150,
            },
          },
        ],
      },
    });
  }, [props.value]);
  const [options, setOptions] = useState<ApexOptions>({
    chart: {
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    colors: [
      "#54bf58",
      // "#9ee09e",
      // "#fdfd97",
      // "#feb144",
      // "#FF6653",
      // "#CC99C9",
    ],
    title: { text: "Building height" },
    plotOptions: {
      bar: {
        horizontal: true,
        distributed: true,
        barHeight: "100%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    stroke: {
      width: 0.2,
      colors: ["#fff"],
    },
    yaxis: {
      // min: 0,
      // max: 30,
      // show: false,
    },
    xaxis: {
      categories: [
        "Pre 1900",
        "1900 - 1918",
        "1919 - 1929",
        "1930 - 1939",
        "1945 - 1954",
        "1955 - 1964",
        "1965 - 1972",
        "1973 - 1982",
        "1983 - 1992",
        "1993 - 1999",
        "2000 - 2008",
        "2009 - 2011",
        "2012 - 2014",
        "2015 - 2017",
        "2018 - 2020",
        "Post 2021",
      ],

      labels: {
        style: {
          // colors: [
          //   "#54bf58",
          //   "#9ee09e",
          //   "#fdfd97",
          //   "#feb144",
          //   "#FF6653",
          //   "#CC99C9",
          // ],
          fontSize: "12px",
        },
        show: false,
      },
    },
  });

  return <BuildingAgeChart options={options} series={series} />;
}
