import React, { useState } from "react";
import "./EnergyEfficiencyPage.css";
import { SmallNavbar } from "../NavBar/navbar-small";
import epc from "../../img/epc.jpg";
import epc_cost from "../../img/epc_cost.jpg";
import co2 from "../../img/co2.jpg";
import energy_bill from "../../img/energy_bill.jpg";
import RadarLoader from "./RadarChart/RadarLoader";
import WarningIcon from '@mui/icons-material/Warning';
import Tooltip from "@mui/material/Tooltip";
import EpcGraphCountry from "./epc-chart-country";
import EPCFreq from "../PropertyOverviewPage/EPCFreqChart/EPCFreqLoader";
import { useHistory, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardContent,
  CardActionArea,
  CardActions,
  CardMedia,
  Grid,
  makeStyles,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  List,
  ListItem,
  ListItemText,
  Divider,
  Modal,
  Fade,
  Box,
  Typography,
  Backdrop,
} from "@material-ui/core";
import EpcComponentGraph from "./epc-chart";
import { useEffect } from "react";

const useStyles = makeStyles({
  tableCell: {
    fontSize: "16 px",
  },
});

interface IParams {
  id: string | undefined;
}

interface IProps {
  propertyDetails: IPropertyDetails;
  tariffs: any[];
  setSingleProperty: React.Dispatch<React.SetStateAction<boolean>>;
  reload: (id?: number) => Promise<void> | null;
  reloaded: boolean;
  epcStats: EpcStats | undefined;

}
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  maxHeight: "80%",
  overflowY: "auto",
};

export default function EnergyEfficiencyPage(props: IProps) {
  const history = useHistory();
  const { id } = useParams<IParams>();
  const [heatTariff, setHeatTariff] = useState("");
  const [hotwaterTariff, setHotwaterTariff] = useState("");
  const [lightingTariff, setLightingTariff] = useState("");
  const [expired, setExpired] = useState<boolean>(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  function humanize(str: string) {
    let i,
      frags = str.split("_");
    for (i = 0; i < frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(" ");
  }
  useEffect(() => {
    const currentDate = new Date();
    const lodgementDate = new Date(props.propertyDetails.lodgement_date);
    if (!isNaN(lodgementDate.getTime())) {
      const difference = Math.floor(
        (currentDate.getTime() - lodgementDate.getTime()) /
          (1000 * 60 * 60 * 24 * 365)
      );
      console.log(difference);
      if (difference >= 10) {
        setExpired(true);
      }
    }
  }, [props.propertyDetails]);
  useEffect(() => {
    if (Object.keys(props.propertyDetails).length === 0 && id) {
      props.reload(parseInt(id!))?.catch((e) => {
        history.push("/404");
      });
    }
  }, []);

  useEffect(() => {
    props.setSingleProperty(true);
  }, []);

  useEffect(() => {
    let foundHeat = props.tariffs.find(
      (element) => element.id === props.propertyDetails.mainheat_tariff
    );

    let foundHotwater = props.tariffs.find(
      (element) => element.id === props.propertyDetails.hotwater_tariff
    );

    let foundLighting = props.tariffs.find(
      (element) => element.id === props.propertyDetails.electricity_tariff
    );

    if (foundHeat) {
      setHeatTariff(foundHeat.fuel);
    }

    if (foundHotwater) {
      setHotwaterTariff(foundHotwater.fuel);
    }

    if (foundLighting) {
      setLightingTariff(foundLighting.fuel);
    }
  }, [props.tariffs]);

  return (
    <>
      {Object.keys(props.propertyDetails).length === 0 ? (
        <></>
      ) : (
        <Card className="card" style={{ backgroundColor: "#113d64" }}>
          <CardContent className="header">
            <h3 className="headerText">
              {props.propertyDetails.single_line_address}
            </h3>
          </CardContent>

          <hr className="solid" />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <div className="top_headline">
                  <div>UPRN</div>
                  <div className="headline_result">
                    {props.propertyDetails.uprn}
                  </div>
                </div>
              </Grid>
              <Grid item xs={3}>
                <div className="top_headline">
                  <div>EPC status</div>
                  <div className="headline_result">
                    {props.propertyDetails.epc_type}
                  </div>
                </div>
              </Grid>
              <Grid item xs={3}>
                {expired ? (
                  <Tooltip title="EPC expired"  placement="top" style={{fontSize: "20px"}}>
                    <div
                      className="top_headline"
                      style={{ background: "#ffb3b3" }}
                    >
                      <div>Assessment date</div>
                      <div className="headline_result">
                        {props.propertyDetails.lodgement_date}
                      </div>
                    </div>
                  </Tooltip>
                ) : (
                  <div className="top_headline" style={{ background: "white" }}>
                    <div>Assessment date</div>
                    <div className="headline_result">
                      {props.propertyDetails.lodgement_date}
                    </div>
                  </div>
                )}
              </Grid>
              <Grid item xs={3}>
                <div className="top_headline">
                  <div>EPC band</div>
                  <div className="headline_result">
                    {props.propertyDetails.current_energy_rating}
                  </div>
                </div>
              </Grid>
            </Grid>
            <h3 className="headerText">Energy Efficiency Information</h3>
            <Grid container spacing={2} justify="space-between">
              <Grid item xs={6}>
                <div className="panel_card">
                  <div className="panel_title">EPC rating</div>
                  <EpcComponentGraph
                    current={props.propertyDetails.current_energy_efficiency}
                    potential={
                      props.propertyDetails.potential_energy_efficiency
                    }
                    capt_potential={props.propertyDetails.potential_sap_capt}
                  />
                </div>
              </Grid>
              <Grid item xs={3}>
                <div className="panel_card">
                  <div className="panel_title">Authority-wide EPC analysis</div>
                  <EPCFreq
                    rating={props.propertyDetails.current_energy_efficiency}
                    band={props.propertyDetails.current_energy_rating}
                    data={
                      props.epcStats
                        ? Object.keys(props.epcStats.energyRatingsDistribution)
                            .sort()
                            .map((rating) =>
                              props.epcStats
                                ? props.epcStats.energyRatingsDistribution[
                                    rating
                                  ]
                                : 0
                            )
                        : undefined
                    }
                  />
                  {/* <EpcGraphCountry
                    current={props.propertyDetails.current_energy_efficiency}
                  /> */}
                </div>
              </Grid>
              <Grid item xs={3}>
                <div className="panel_card">
                  <div className="panel_title">Property overview</div>
                  <div style={{ padding: 10 }}>
                    <Table size="small" style={{ height: "100%" }}>
                      <TableRow>
                        <TableCell>
                          <strong>Property type:</strong>
                        </TableCell>
                        <TableCell>
                          {props.propertyDetails.property_type}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <strong>Built form:</strong>
                        </TableCell>
                        <TableCell>
                          {props.propertyDetails.built_form}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <strong>Floor area:</strong>
                        </TableCell>
                        <TableCell>
                          {props.propertyDetails.total_floor_area}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <strong>EPC status:</strong>
                        </TableCell>
                        <TableCell>{props.propertyDetails.epc_type}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <strong>EPC date:</strong>
                        </TableCell>
                        <TableCell
                          style={{ background: expired ? "#ffb3b3" : "white" }}
                        >
                          {expired ? (<Tooltip title="EPC expired"><div>{props.propertyDetails.lodgement_date}</div></Tooltip>):(<span>{props.propertyDetails.lodgement_date}</span>)}
                          {/* {props.propertyDetails.lodgement_date} */}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <strong>Main Heat fuel/tariff:</strong>
                        </TableCell>
                        <TableCell>{heatTariff}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <strong>Hot water fuel/tariff:</strong>
                        </TableCell>
                        <TableCell>{hotwaterTariff}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <strong>Lighting fuel/tariff:</strong>
                        </TableCell>
                        <TableCell>{lightingTariff}</TableCell>
                      </TableRow>
                    </Table>
                    <Button
                      style={{ marginTop: "10px" }}
                      variant="contained"
                      onClick={handleOpen}
                    >
                      See More
                    </Button>
                    <Modal
                      aria-labelledby="transition-modal-title"
                      aria-describedby="transition-modal-description"
                      open={open}
                      onClose={handleClose}
                      closeAfterTransition
                      BackdropComponent={Backdrop}
                      BackdropProps={{
                        timeout: 500,
                      }}
                    >
                      <Fade in={open}>
                        <Box sx={style}>
                          <Typography
                            style={{ marginBottom: "20px" }}
                            id="transition-modal-title"
                            variant="h6"
                            component="h2"
                          >
                            <strong>All Property EPC data</strong>
                          </Typography>
                          <Typography
                            id="transition-modal-description"
                            // sx={{ mt: 2 }}
                          >
                            <Table size="small" style={{ height: "100%" }}>
                              {Object.entries(props.propertyDetails).map(
                                (x, index) => {
                                  if (x[0].toLowerCase() !== "kwhtariffs") {
                                    return (
                                      <TableRow key={index}>
                                        <TableCell>
                                          <strong>{humanize(x[0])}:</strong>
                                        </TableCell>
                                        <TableCell>{x[1]}</TableCell>
                                      </TableRow>
                                    );
                                  }
                                }
                              )}
                            </Table>
                          </Typography>
                        </Box>
                      </Fade>
                    </Modal>
                  </div>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="panel_card">
                  <div style={{ padding: 10 }}>
                    <Table size="small" style={{ minHeight: "320px" }}>
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <strong>Feature</strong>
                          </TableCell>
                          <TableCell>
                            <strong>Description</strong>
                          </TableCell>
                          <TableCell>
                            <strong>Rating</strong>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>Walls</TableCell>
                          <TableCell>
                            {props.propertyDetails.walls_description}
                          </TableCell>
                          <TableCell>
                            {props.propertyDetails.walls_energy_eff}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Roof</TableCell>
                          <TableCell>
                            {props.propertyDetails.roof_description}
                          </TableCell>
                          <TableCell>
                            {props.propertyDetails.roof_energy_eff}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Floor</TableCell>
                          <TableCell>
                            {props.propertyDetails.floor_description}
                          </TableCell>
                          <TableCell>
                            {props.propertyDetails.floor_energy_eff}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Windows</TableCell>
                          <TableCell>
                            {props.propertyDetails.windows_description}
                          </TableCell>
                          <TableCell>
                            {props.propertyDetails.windows_energy_eff}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Main heating</TableCell>
                          <TableCell>
                            {props.propertyDetails.mainheat_description}
                          </TableCell>
                          <TableCell>
                            {props.propertyDetails.mainheat_energy_eff}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Main heating control</TableCell>
                          <TableCell>
                            {props.propertyDetails.mainheatcont_description}
                          </TableCell>
                          <TableCell>
                            {props.propertyDetails.mainheatc_energy_eff}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Hot water</TableCell>
                          <TableCell>
                            {props.propertyDetails.hotwater_description}
                          </TableCell>
                          <TableCell>
                            {props.propertyDetails.hot_water_energy_eff}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Lighting</TableCell>
                          <TableCell>
                            {props.propertyDetails.lighting_description}
                          </TableCell>
                          <TableCell>
                            {props.propertyDetails.lighting_energy_eff}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ borderBottom: "none" }}>
                            Secondary heating
                          </TableCell>
                          <TableCell style={{ borderBottom: "none" }}>
                            {props.propertyDetails.secondheat_description}
                          </TableCell>
                          <TableCell style={{ borderBottom: "none" }}>
                            N/A
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </div>
                </div>
              </Grid>
              <Grid item xs={3}>
                <div className="panel_card">
                  <RadarLoader
                    wall={props.propertyDetails.walls_energy_eff}
                    roof={props.propertyDetails.roof_energy_eff}
                    window={props.propertyDetails.windows_energy_eff}
                    heating={props.propertyDetails.mainheat_energy_eff}
                    control={props.propertyDetails.mainheatc_energy_eff}
                    water={props.propertyDetails.hot_water_energy_eff}
                    lighting={props.propertyDetails.lighting_energy_eff}
                    floor={props.propertyDetails.floor_energy_eff}
                    secondary={props.propertyDetails.sheating_energy_eff}
                  />
                </div>
              </Grid>
              <Grid
                item
                xs={3}
                container
                direction="column"
                justify="space-between"
              >
                <Grid item container justify="space-between" spacing={2}>
                  <Grid item xs={12}>
                    <div className="top_headline">
                      <div className="figure_title">
                        Heating costs (SAP2012)
                      </div>
                      <div className="headline_result">
                        £{props.propertyDetails.heating_cost_current}
                      </div>
                    </div>
                  </Grid>
                  {/* <Grid item xs={6}>
                    <div className="top_headline">
                      <div className="figure_title">
                        Heating costs (Market rates)
                      </div>
                      <div className="headline_result">£{props.propertyDetails.marketRateHeat.toFixed(0)}</div>
                    </div>
                  </Grid> */}
                </Grid>
                <Grid item container justify="space-between" spacing={2}>
                  <Grid item xs={12}>
                    <div className="top_headline">
                      <div className="figure_title">
                        Hot water costs (SAP2012)
                      </div>
                      <div className="headline_result">
                        £{props.propertyDetails.hot_water_cost_current}
                      </div>
                    </div>
                  </Grid>
                  {/* <Grid item xs={6}>
                    <div className="top_headline">
                      <div className="figure_title">
                        Hot water costs (Market)
                      </div>
                      <div className="headline_result">£{props.propertyDetails.marketRateWater.toFixed(0)}</div>
                    </div>
                  </Grid> */}
                </Grid>
                <Grid item container justify="space-between" spacing={2}>
                  <Grid item xs={12}>
                    <div className="top_headline">
                      <div className="figure_title">
                        Lighting costs (SAP2012)
                      </div>
                      <div className="headline_result">
                        £{props.propertyDetails.lighting_cost_current}
                      </div>
                    </div>
                  </Grid>
                  {/* <Grid item xs={6}>
                    <div className="top_headline">
                      <div className="figure_title">
                        Lighting costs (Market rates)
                      </div>
                      <div className="headline_result">£{props.propertyDetails.marketRateLighting.toFixed(0)}</div>
                    </div>
                  </Grid> */}
                </Grid>
                <Grid item container justify="space-between" spacing={2}>
                  <Grid item xs={12}>
                    <div className="top_headline">
                      <div className="figure_title">CO2 (SAP2012)</div>
                      <div className="headline_result">
                        {props.propertyDetails.co2_emissions_current}{" "}
                        Tonnes/year
                      </div>
                    </div>
                  </Grid>
                  {/* <Grid item xs={6}>
                    <div className="top_headline">
                      <div className="figure_title">
                        CO2 (Market carbon factor)
                      </div>
                      <div className="headline_result">{(props.propertyDetails.marketRateCarbon / 1000).toFixed(2)} Tonnes/year</div>
                    </div>
                  </Grid> */}
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}
    </>
  );
}
