import { Button, TableCell, TableRow, Input } from "@material-ui/core";
import "./ActionPlanPage.css";
import React, { useContext, useEffect, useState } from "react";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import IconButton from "@material-ui/core/IconButton";
import SaveOutlinedIcon from "@material-ui/icons/SaveOutlined";
import PublishOutlinedIcon from "@material-ui/icons/PublishOutlined";
import { numberWithCommas } from "../utilities";

interface SandboxIntervention {
  interventionId: number;
  year: number;
  numberOfProperties: number;
}

interface IProps {
  portfolioIntervention: PortfolioIntervention;
  key: number;
  editing: boolean;
  amendSandboxIntervention: (sandboxIntervention: SandboxIntervention) => void;
  amendSandboxYearIntervention: (
    sandboxIntervention: YearPortfolioIntervention
  ) => void;
}

export default function InterventionRow(props: IProps) {
  const [carryOutProperties, setCarryOutProperties] = useState<number>(0);

  const [year, setYear] = useState<number>(-1);

  return (
    <TableRow key={props.key}>
      <TableCell>{props.portfolioIntervention.name}</TableCell>
      <TableCell align="right">
        <React.Fragment>
          £{numberWithCommas(props.portfolioIntervention.totalCost.toFixed(0))}
        </React.Fragment>
      </TableCell>
      <TableCell align="right">
        <React.Fragment>
          <span>
            {" "}
            {numberWithCommas(props.portfolioIntervention.carbon.toFixed(0))} kg
          </span>
        </React.Fragment>
      </TableCell>
      <TableCell align="right">
        £
        {numberWithCommas(
          props.portfolioIntervention.currentFuelCostImpact.toFixed(0)
        )}
      </TableCell>
      <TableCell align="right">
        {props.portfolioIntervention.lifetime
          ? Math.round(props.portfolioIntervention.lifetime) + " years"
          : "n/a"}
      </TableCell>
      <TableCell align="right">
        {props.portfolioIntervention.lifetime
          ? numberWithCommas(
              (
                props.portfolioIntervention.lifetime *
                props.portfolioIntervention.carbon
              ).toFixed(0)
            ) + " kg"
          : "n/a"}
      </TableCell>
      <TableCell align="right">
        £
        {props.portfolioIntervention.lifetime
          ? numberWithCommas(
              (
                props.portfolioIntervention.totalCost /
                ((props.portfolioIntervention.lifetime *
                  props.portfolioIntervention.carbon) /
                  1000)
              ).toFixed(0)
            )
          : "n/a"}
      </TableCell>
      <TableCell align="right">
        £
        {props.portfolioIntervention.lifetime
          ? numberWithCommas(
              (
                props.portfolioIntervention.currentFuelCostImpact *
                props.portfolioIntervention.lifetime
              ).toFixed(0)
            )
          : "n/a"}
      </TableCell>
      <TableCell align="right">
        £
        {props.portfolioIntervention.lifetime &&
        props.portfolioIntervention.totalCost
          ? numberWithCommas(
              (
                (props.portfolioIntervention.lifetime *
                  props.portfolioIntervention.currentFuelCostImpact) /
                props.portfolioIntervention.totalCost
              ).toFixed(2)
            )
          : "n/a"}
      </TableCell>
      <TableCell align="right">
        <React.Fragment>
          {numberWithCommas(
            props.portfolioIntervention.totalKwhImpact.toFixed(0)
          )}
        </React.Fragment>
      </TableCell>
      <TableCell align="right">
        {numberWithCommas(
          props.portfolioIntervention.numberProperties.toFixed(0)
        )}
      </TableCell>
      <TableCell align="right">
        <>
          <Input
            type="number"
            value={carryOutProperties}
            onChange={(event) =>
              setCarryOutProperties(parseInt(event.target.value))
            }
            disabled={!props.editing}
            style={{ maxWidth: "80px" }}
          />
          <IconButton
            style={{
              marginLeft: "5px",
              fontSize: "10px",
            }}
            // variant="contained"
            size="small"
            onClick={() => {
              setCarryOutProperties(
                props.portfolioIntervention.numberProperties
              );
              props.amendSandboxIntervention({
                interventionId: props.portfolioIntervention.interventionId,
                year: year,
                numberOfProperties: parseInt(
                  props.portfolioIntervention.numberProperties.toFixed(0)
                ),
              });
              props.amendSandboxYearIntervention({
                plannedYear: year,
                propertyNum: parseInt(
                  props.portfolioIntervention.numberProperties.toFixed(0)
                ),
                interventionName: props.portfolioIntervention.name,
                doneYear: -1,
                installCost:
                  props.portfolioIntervention.totalCost /
                  props.portfolioIntervention.numberProperties,
                kwhImpact:
                  props.portfolioIntervention.totalKwhImpact /
                  props.portfolioIntervention.numberProperties,
              });
            }}
          >
            <PublishOutlinedIcon />
          </IconButton>
        </>
      </TableCell>
      <TableCell>
        <>
          <Input
            type="number"
            value={year}
            onChange={(event) => setYear(parseInt(event.target.value))}
            disabled={!props.editing}
            style={{ maxWidth: "80px" }}
          />
          <IconButton
            style={{ marginLeft: "5px" }}
            // variant="contained"
            size="small"
            onClick={() => {
              setYear(-1);
              props.amendSandboxIntervention({
                interventionId: props.portfolioIntervention.interventionId,
                year: -1,
                numberOfProperties: carryOutProperties,
              });
              props.amendSandboxYearIntervention({
                plannedYear: -1,
                propertyNum: carryOutProperties,
                interventionName: props.portfolioIntervention.name,
                doneYear: -1,
                installCost:
                  props.portfolioIntervention.totalCost /
                  props.portfolioIntervention.numberProperties,
                kwhImpact:
                  props.portfolioIntervention.totalKwhImpact /
                  props.portfolioIntervention.numberProperties,
              });
            }}
          >
            <RotateLeftIcon />
          </IconButton>
          <Button
            style={{
              marginLeft: "5px",
              fontSize: "10px",
            }}
            size="small"
            variant="contained"
            onClick={() => {
              setYear(2030);
              props.amendSandboxIntervention({
                interventionId: props.portfolioIntervention.interventionId,
                year: 2030,
                numberOfProperties: carryOutProperties,
              });
              props.amendSandboxYearIntervention({
                plannedYear: 2030,
                propertyNum: carryOutProperties,
                interventionName: props.portfolioIntervention.name,
                doneYear: -1,
                installCost:
                  props.portfolioIntervention.totalCost /
                  props.portfolioIntervention.numberProperties,
                kwhImpact:
                  props.portfolioIntervention.totalKwhImpact /
                  props.portfolioIntervention.numberProperties,
              });
            }}
          >
            <div style={{ fontSize: "12px" }}>2030</div>
          </Button>
          <Button
            style={{
              marginLeft: "10px",
              fontSize: "10px",
            }}
            size="small"
            variant="contained"
            onClick={() => {
              setYear(2040);
              props.amendSandboxIntervention({
                interventionId: props.portfolioIntervention.interventionId,
                year: 2040,
                numberOfProperties: carryOutProperties,
              });
              props.amendSandboxYearIntervention({
                plannedYear: 2040,
                propertyNum: carryOutProperties,
                interventionName: props.portfolioIntervention.name,
                doneYear: -1,
                installCost:
                  props.portfolioIntervention.totalCost /
                  props.portfolioIntervention.numberProperties,
                kwhImpact:
                  props.portfolioIntervention.totalKwhImpact /
                  props.portfolioIntervention.numberProperties,
              });
            }}
          >
            <div style={{ fontSize: "12px" }}>2040</div>
          </Button>
        </>
      </TableCell>
      <TableCell>
        {props.editing ? (
          <IconButton
            size="small"
            onClick={() => {
              props.amendSandboxIntervention({
                interventionId: props.portfolioIntervention.interventionId,
                year: year,
                numberOfProperties: carryOutProperties,
              });
              props.amendSandboxYearIntervention({
                plannedYear: year,
                propertyNum: carryOutProperties,
                interventionName: props.portfolioIntervention.name,
                doneYear: -1,
                installCost:
                  props.portfolioIntervention.totalCost /
                  props.portfolioIntervention.numberProperties,
                kwhImpact:
                  props.portfolioIntervention.totalKwhImpact /
                  props.portfolioIntervention.numberProperties,
              });
            }}
          >
            <SaveOutlinedIcon />
          </IconButton>
        ) : null}
      </TableCell>
    </TableRow>
  );
}
