import React from 'react';
import { ApexOptions } from 'apexcharts';
import Chart from 'react-apexcharts';

interface IProps {
    options: ApexOptions;
    series: any;
}

export default function RadarChart(props: IProps) {
    return <Chart options={props.options} series={props.series} type="radar" height="100%" width="99%" />;
}
