import React, { Dispatch, useContext, useEffect, useState } from "react";
import LoadingOverlayWrapper from "react-loading-overlay-ts";
import { UserContext } from "../../contexts/UserContext";
import { getData } from "../get-data";
import { Filter } from "./filter";
import { PropertyTable } from "./PropertyTable";

interface IProps {
  setSelectedProperty: Dispatch<React.SetStateAction<IProperty>>;
  fetchPropertyDetails: (property: number) => Promise<void>;
  fetchInterventions: (property: IProperty) => void;
}

export function PropertyFilter(props: IProps) {
  const [frameState, setFrameState] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const userContext = useContext(UserContext);

  const [data, setData] = useState<PropertyDataColumns>({
    order: [],
    propertyDatas: [],
    allEncryptedUPRN: [],
    purchasedUprns: [],
  });
  const [filterDto, setFilterDto] = useState<FilterDto>({
    Filters: {},
    Location: {
      Address: "",
      Postcode: "",
      OutputArea: "",
      LSOA: "",
      MSOA: "",
      Ward: "",
    },
    LocalAuthorities: [],
    assignedFilters: [],
  });
  const [filters, setFilters] = useState<FilterCategory>({});

  useEffect(() => {
    getData("https://captapi.absolarlab.com/api/filtering/filters", userContext).then(
      (filters: FilterCategory) => {
        setFilters(filters);
      }
    );
  }, []);
  return (
    <div className="App" style={{ width: "100%" }}>
      <LoadingOverlayWrapper
        active={loading}
        spinner
        text="Loading filtered search results... this can take up to 30 seconds"
      >
        <header className="Router Sample">
          {Object.keys(filters).length > 0 ? (
            <Filter
              frameState={frameState}
              setFrameState={setFrameState}
              generalFilters={filters}
              setData={setData}
              setFilterDto={setFilterDto}
              setLoading={setLoading}
            ></Filter>
          ) : (
            "Loading filters..."
          )}
        </header>
        <body>
          <PropertyTable
            data={data}
            filterDto={filterDto}
            setSelectedProperty={props.setSelectedProperty}
            fetchPropertyDetails={props.fetchPropertyDetails}
            fetchInterventions={props.fetchInterventions}
          />
        </body>
      </LoadingOverlayWrapper>
    </div>
  );
}
