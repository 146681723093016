import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardContent,
  Grid,
  makeStyles,
  TextField,
  ButtonProps,
  Switch,
} from "@material-ui/core";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination, {
  TablePaginationProps,
} from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import CircleIcon from "@mui/icons-material/Circle";

import EPCMap from "./components/EPCMap";
import HeatMap from "./components/HeatMap";
import BarChartsEPC from "./components/BarChartsEPC";
import BarChartsCategories from "./components/BarChartsCategories";

import "./VisualisationPage.css";
import { styled } from "@mui/material/styles";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { getData, postData } from "../get-data";
import { UserContext } from "../../contexts/UserContext";
import InfillChartLoader from "./components/InfillChartLoader";
import { useParams } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay-ts";

const theme = createTheme({
  components: {
    MuiTablePagination: {
      styleOverrides: {
        root: {
          borderTop: "1px solid black",
          height: 30,
          minHeight: 30,
        },
        toolbar: {
          height: 30,
          minHeight: 30,
        },
        selectLabel: {
          fontSize: 10,
          lineHeight: "normal",
          marginTop: 0,
          marginBottom: 0,
        },
        input: {
          fontSize: 10,
          lineHeight: "normal",
          marginTop: 0,
          marginBottom: 0,
        },
        displayedRows: {
          fontSize: 10,
          lineHeight: "normal",
          marginTop: 0,
          marginBottom: 0,
        },
        actions: {
          fontSize: 10,
          lineHeight: "normal",
          marginLeft: 0,
          marginTop: 0,
          marginBottom: 0,
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          height: 20,
          minHeight: 20,
          marginTop: -10,
        },
      },
    },
  },
});

interface IParams {
  id: string | undefined;
}

interface IProps {
  lat?: number;
  lon?: number;
  portfolioCategoryStats: CategoryData;
  portoflioMapProperties: IPropertyEPC[];
  selectedPortfolio: PortfolioDetail;
  setSelectedPortfolio: React.Dispatch<React.SetStateAction<PortfolioDetail>>;
  getPortfolio: (id?: number) => void;
}

const ratingColor = [
  { band: "A", primary: "#008054", secondary: "#CCE5DC" },
  { band: "B", primary: "#19b459", secondary: "#D1F0DD" },
  { band: "C", primary: "#8dce46", secondary: "#E8F5DA" },
  { band: "D", primary: "#ffd500", secondary: "#FFF6CC" },
  { band: "E", primary: "#fcaa65", secondary: "#FEEEE0" },
  { band: "F", primary: "#ef8023", secondary: "#FBE5D3" },
  { band: "G", primary: "#e9153b", secondary: "#FAD0D7" },
  { band: "ALL", primary: "#FFFFFF", secondary: "#FFFFFF" },
];
const epcTypesColor = ["#38A3A5", "#113CFC", "#57CC99", "#80ED99"];
//these interfaces are for the table below the maps - defining the type of the columns and setting styles
interface Column {
  id:
    | "property_id"
    | "address"
    | "current_energy_rating"
    | "potential_energy_rating"
    | "emissions_current"
    | "emissions_potential"
    | "epc_type";
  label: string;
  minWidth?: number;
  padding?: number;
  align?: "right";
  format?: (value: number) => string;
}

const columns: readonly Column[] = [
  {
    id: "property_id",
    label: "ID",
    minWidth: 20,
    padding: 0,
    format: (value: number) => value.toLocaleString("en-US"),
  },
  { id: "address", label: "Address", minWidth: 100, padding: 0 },
  {
    id: "epc_type",
    label: "EPC Type",
    minWidth: 7,
    padding: 0,
  },
  {
    id: "current_energy_rating",
    label: "Current SAP Rating",
    minWidth: 10,
    padding: 0,
  },

  {
    id: "potential_energy_rating",
    label: "Potential SAP Rating per EPC",
    minWidth: 10,
    padding: 0,
  },
  {
    id: "emissions_current",
    label: "Current SAP Emissions",
    minWidth: 10,
    padding: 0,
    format: (value: number) => value.toLocaleString("en-US"),
  },
  {
    id: "emissions_potential",
    label: "Potential SAP Emissions per EPC",
    minWidth: 10,
    padding: 0,
    format: (value: number) => value.toLocaleString("en-US"),
  },
];

const RatingButton = styled(Button)<ButtonProps>(({ theme }) => ({
  minWidth: 25,
}));

export const VisualisationPage: React.FC<IProps> = (props: IProps) => {
  let { id } = useParams<IParams>();

  const [epcTypeSelected, setEpcTypeSelected] = React.useState<
    string | undefined
  >();
  const userContext = useContext(UserContext);
  const [toggleSat, setToggleSat] = useState<boolean>(false);

  const [propertyStats, setPropertyStats] = useState<CategoryData>(
    props.portfolioCategoryStats
  );
  const [portfolioEpcTypes, setPortfolioEpcTypes] = useState<
    Array<PortfolioEpcType>
  >([]);

  // props.portoflioMapProperties to display - initial fetch from json - to be taken from backend
  const [prop, setProp] = React.useState<IPropertyEPC[]>(
    props.portoflioMapProperties
  );
  //table with list of props.portoflioMapProperties
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState(props.portoflioMapProperties);
  const [portfolioDataLoading, setPortfolioDataLoading] =
    useState<boolean>(true);

  useEffect(() => {
    setProp(props.portoflioMapProperties);
    setNoProperties(props.portoflioMapProperties.length);
    setAccumulatedCO2(getAccumulatedCO2(props.portoflioMapProperties));
    setRows(props.portoflioMapProperties);
    setPage(0);
  }, [props.portoflioMapProperties]);
  //CO2 from all displayed props.portoflioMapProperties
  const [accumulatedCO2, setAccumulatedCO2] = React.useState(
    getAccumulatedCO2(props.portoflioMapProperties)
  );

  //number of props.portoflioMapProperties from all displayed props.portoflioMapProperties
  const [noProperties, setNoProperties] = React.useState(
    props.portoflioMapProperties.length
  );
  function areEqual(array1: number[], array2: number[]) {
    if (array1.length === array2.length) {
      return array1.every((element) => {
        if (array2.includes(element)) {
          return true;
        }

        return false;
      });
    }

    return false;
  }

  const [ratingSelected, setRatingSelected] = React.useState("");
  function filterRating(rating: string, typeOfRating: string) {
    if (ratingSelected != rating) {
      setRatingSelected(rating);
      //first branch - resets all props.portoflioMapProperties to default
      if (rating == "ALL") {
        setProp(props.portoflioMapProperties);
        setAccumulatedCO2(getAccumulatedCO2(props.portoflioMapProperties));
        setNoProperties(props.portoflioMapProperties.length);
        setRowSelected(-1);
        setPage(0);
        setRows(props.portoflioMapProperties);
      } else {
        //filter by the rating button pressed
        setRowSelected(-1);
        setPage(0);
        const propertiesWithRating = getPropertiesByRating(
          rating,
          typeOfRating
        );
        setProp(propertiesWithRating);
        setAccumulatedCO2(
          getAccumulatedCO2(propertiesWithRating as [IPropertyEPC])
        );
        setNoProperties(propertiesWithRating.length);
        setRows(propertiesWithRating);
      }
    }
  }

  function filterByProperty(properties: IPropertyEPC[]) {
    if (
      !areEqual(
        properties.map((x) => x.property_id),
        prop.map((x) => x.property_id)
      )
    ) {
      setProp(properties);
      setRowSelected(-1);
      setRatingSelected("");
      //computing the page the property should be on
      //property IDs start from 1.. and therefore we compute the page by divifing to the number of rows per page
      setRows(properties);
      // let computation = property.property_id / 10;
      // let pageOfProperty =
      //   computation > Math.floor(computation)
      //     ? Math.floor(computation)
      //     : Math.floor(computation) - 1; //because pages start from 0
      setPage(0);
      setAccumulatedCO2(getAccumulatedCO2(properties));
      setNoProperties(properties.length);
    }
  }

  /*
        functions for props.portoflioMapProperties filtering
        -iterations through the list
    */
  function getPropertiesByRating(rating: string, typeOfRating: string) {
    let propertiesToUpdate = [];

    for (const eachProperty of props.portoflioMapProperties) {
      if (
        typeOfRating == "potential" &&
        eachProperty.potential_energy_rating == rating
      ) {
        propertiesToUpdate.push(eachProperty);
      } else if (
        typeOfRating == "current" &&
        eachProperty.current_energy_rating == rating
      ) {
        propertiesToUpdate.push(eachProperty);
      }
    }
    return propertiesToUpdate;
  }
  function getAccumulatedCO2(properties: IPropertyEPC[]) {
    let accumulatedCO2 = 0;
    for (const eachProperty of properties) {
      accumulatedCO2 += eachProperty.emissions_current;
    }
    return accumulatedCO2;
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [rowSelected, setRowSelected] = React.useState(-1);
  const [rowHovered, setRowHovered] = React.useState(-1);

  const getFilteredPropertyStats = () => {
    setPortfolioDataLoading(true);

    const url = "/api/portfolio/propertycategorystats";
    postData(
      "https://captapi.absolarlab.com" + url,
      prop.map((x) => x.property_id),
      userContext
    )
      .then((propertyStats: CategoryData) => {
        setPropertyStats(propertyStats);
      })
      .then(() => {
        setPortfolioDataLoading(false);
      });
  };
  const getPortfolioEpcTypes = () => {
    setPortfolioDataLoading(true);

    const url = "/api/portfolio/portfolioepctypes?portfolioId=" + id;

    getData("https://captapi.absolarlab.com" + url, userContext)
      .then((portfolioEpcTypes: Array<PortfolioEpcType>) => {
        setPortfolioEpcTypes(portfolioEpcTypes);
      })
      .then(() => {
        setPortfolioDataLoading(false);
      });
  };

  useEffect(() => {
    if (epcTypeSelected) {
      setRowSelected(-1);
      setPage(0);
      const propertiesWithRating = getPropertiesByEpc(epcTypeSelected);
      setProp(propertiesWithRating);
      setAccumulatedCO2(
        getAccumulatedCO2(propertiesWithRating as [IPropertyEPC])
      );
      setNoProperties(propertiesWithRating.length);
      setRows(propertiesWithRating);
    } else {
      setProp(props.portoflioMapProperties);
      setAccumulatedCO2(getAccumulatedCO2(props.portoflioMapProperties));
      setNoProperties(props.portoflioMapProperties.length);
      setRowSelected(-1);
      setPage(0);
      setRows(props.portoflioMapProperties);
    }
  }, [epcTypeSelected]);

  function getPropertiesByEpc(epcType: string) {
    let propertiesToUpdate = [];

    for (const eachProperty of props.portoflioMapProperties) {
      if (eachProperty.epc_type.toLowerCase().includes(epcType.toLowerCase())) {
        propertiesToUpdate.push(eachProperty);
      }
    }
    return propertiesToUpdate;
  }

  useEffect(() => {
    getPortfolioEpcTypes();
    setProp(props.portoflioMapProperties);
    filterRating("ALL", "current");
    if (props.selectedPortfolio.id == -1 && id) {
      props.getPortfolio(parseInt(id));
    }
  }, []);
  useEffect(() => {
    if (prop !== props.portoflioMapProperties) {
      getFilteredPropertyStats();
    } else {
      setPropertyStats(props.portfolioCategoryStats);
    }
  }, [prop]);
  useEffect(() => {
    setPropertyStats(props.portfolioCategoryStats);
  }, [props.portfolioCategoryStats]);
  useEffect(() => {
    if (rowSelected === -1) {
      setRows(props.portoflioMapProperties);
      setPage(0);
    } else {
      const property = rows.filter((x) => x.property_id === rowSelected);
      setRows(property);
      setPage(0);
    }
  }, [rowSelected]);
  function setEpcType(epcType: string) {
    if (epcTypeSelected) {
      setEpcTypeSelected(undefined);
    } else {
      setEpcTypeSelected(epcType);
    }
  }
  let colorNum = -1;
  const [showBigMap, setShowBigMap] = useState<boolean>(false);
  return (
    //page has been split into 2, main grid (whole page) has 2 more grids (left for maps, right for charts)

    <div className="card">
      <LoadingOverlay active={portfolioDataLoading} spinner>
        <CardContent className="header">
          <h3 className="headerText">{props.selectedPortfolio.name}</h3>
        </CardContent>
        <Grid
          container
          spacing={1}
          justify="space-between"
          style={{ width: "100%", marginTop: "5px", marginBottom: "5px" }}
        >
          <Grid item xs={12}>
            <InfillChartLoader portfolioEpcTypes={portfolioEpcTypes} />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {portfolioEpcTypes.map((p) => {
                colorNum = colorNum + 1;
                while (colorNum > 4) {
                  colorNum = colorNum - 4;
                }
                return (
                  <Button
                    key={p.epc_type}
                    variant="text"
                    startIcon={
                      <CircleIcon style={{ color: epcTypesColor[colorNum] }} />
                    }
                    style={{ color: "white" }}
                    onClick={() => {
                      setEpcType(p.epc_type);
                    }}
                  >
                    {p.epc_type}
                  </Button>
                );
              })}
            </div>
          </Grid>
          <Grid item xs={12}>
            <div style={{ color: "white" }}>
              Toggle Satellite view
              <Switch
                checked={toggleSat}
                onChange={() => {
                  setToggleSat(!toggleSat);
                }}
              />
            </div>
          </Grid>

          <Grid item xs={6}>
            <Grid container spacing={2} justify="space-between">
              <Grid item xs={1}>
                <Grid container spacing={0} justify="space-between">
                  {ratingColor.map((eachRating, i) => (
                    <Grid item xs={12} key={i}>
                      <RatingButton
                        id="rating_button"
                        style={{
                          backgroundColor:
                            ratingSelected == "ALL"
                              ? eachRating.primary
                              : ratingSelected == eachRating.band
                              ? eachRating.primary
                              : eachRating.secondary,
                        }}
                        onClick={() => {
                          if (ratingSelected != eachRating.band) {
                            filterRating(eachRating.band, "current");
                            setRatingSelected(eachRating.band);
                          }
                        }}
                      >
                        {eachRating.band}
                      </RatingButton>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid item xs={11}>
                <EPCMap
                  properties={prop}
                  noProperties={noProperties}
                  filterProperty={filterByProperty}
                  toggleSat={toggleSat}
                  height="300px"
                />
              </Grid>

              <Grid item xs={1}></Grid>
              <Grid item xs={11}>
                <HeatMap
                  properties={prop}
                  accumulatedCO2={accumulatedCO2}
                  height="300px"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={1} justify="space-between">
              <BarChartsEPC
                properties={prop}
                allProperties={props.portoflioMapProperties}
                filterEPC={async (rating: string, typeOfRating: string) => {
                  filterRating(rating, typeOfRating);
                }}
              ></BarChartsEPC>

              <BarChartsCategories
                propertiesCategories={propertyStats}
              ></BarChartsCategories>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{
                            minWidth: column.minWidth,
                            // padding: column.padding,
                            fontSize: "14px",
                            padding: 5,
                            fontWeight: 700,
                            borderColor: "black",
                            lineHeight: "normal",
                          }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                      <TableCell
                        style={{
                          minWidth: 5,
                          // padding: column.padding,
                          fontSize: "14px",
                          padding: 5,
                          fontWeight: 700,
                          borderColor: "black",
                          lineHeight: "normal",
                        }}
                      ></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.property_id}
                          >
                            {columns.map((column) => {
                              const value = row[column.id];
                              return (
                                <TableCell
                                  key={column.id}
                                  align={column.align}
                                  style={{
                                    padding: 5,
                                    fontSize: "14px",
                                    cursor: "pointer",
                                    backgroundColor:
                                      row.property_id == rowSelected
                                        ? "rgb(158, 158, 158)"
                                        : row.property_id == rowHovered
                                        ? "rgb(212, 212, 212)"
                                        : "white",
                                  }}
                                  onClick={() => {
                                    filterByProperty([row]);
                                    setRowSelected(row.property_id);
                                    setRatingSelected(
                                      row.current_energy_rating
                                    );
                                  }}
                                  //manual rewriting of the style of the table, in order to adjust display accordingly
                                  onMouseEnter={() => {
                                    setRowHovered(row.property_id);
                                  }}
                                  onMouseLeave={() => {
                                    setRowHovered(-1);
                                  }}
                                >
                                  {column.format && typeof value === "number"
                                    ? column.format(value)
                                    : value}
                                </TableCell>
                              );
                            })}
                            <TableCell
                              style={{
                                padding: 5,
                                fontSize: "14px",
                                cursor: "pointer",
                                backgroundColor:
                                  row.property_id == rowSelected
                                    ? "rgb(158, 158, 158)"
                                    : row.property_id == rowHovered
                                    ? "rgb(212, 212, 212)"
                                    : "white",
                              }}
                            >
                              <Button
                                size="small"
                                variant="contained"
                                style={{
                                  display: "inline-block",
                                  padding: 2,
                                  minHeight: 0,
                                  minWidth: 0,
                                  paddingLeft: 10,
                                  paddingRight: 10,
                                }}
                                onClick={() => {
                                  window.open(
                                    window.location.origin +
                                      "/propertyoverview/" +
                                      row.property_id.toString()
                                  );
                                }}
                              >
                                Open
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <ThemeProvider theme={theme}>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  // classes={classes}
                />
              </ThemeProvider>
            </Paper>
          </Grid>
        </Grid>
        <Button
          variant="contained"
          onClick={() => {
            setShowBigMap(!showBigMap);
          }}
        >
          show big maps
        </Button>
        {showBigMap ? (
          <div>
            <EPCMap
              properties={prop}
              noProperties={noProperties}
              filterProperty={filterByProperty}
              toggleSat={toggleSat}
              height="100vh"
            />
            <HeatMap
              properties={prop}
              accumulatedCO2={accumulatedCO2}
              height="100vh"
            />
          </div>
        ) : null}
      </LoadingOverlay>
    </div>
  );
};

export default VisualisationPage;
