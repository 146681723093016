import React, { useState, useContext } from "react";
import {
  Card,
  CardContent,
  TextField,
  Button,
  Typography,
  makeStyles,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
} from "@material-ui/core";
import Fuse from "fuse.js";
import { getData, postData } from "../get-data";
import { UserContext } from "../../contexts/UserContext";
import FilterInputList from "../LandingPage/FilterInputList";
import LoadingOverlay from "react-loading-overlay-ts";

interface IProps {
  properties: IPropertyEPC[];
  filters: Array<IFilterObject>;
  addProperty: (property: IProperty) => void;
}

const useStyles = makeStyles({
  card: {
    padding: "0px",
    backgroundColor: "#113d64",
  },
  searchButton: {
    backgroundColor: "#5E81AC",
    "&:hover": {
      backgroundColor: "#81A1C1",
    },
    width: "200px",
  },
  addNewButton: {
    marginRight: "15px",
    backgroundColor: "#5E81AC",
    "&:hover": {
      backgroundColor: "#81A1C1",
    },
    width: "200px",
  },
  searchText: {
    color: "white",
  },
  listbox: {
    width: 200,
    margin: 0,
    padding: 0,
    zIndex: 1,
    position: "absolute",
    listStyle: "none",
    backgroundColor: "white",
    overflow: "auto",
    maxHeight: 200,
    border: "1px solid rgba(0,0,0,.25)",
    '& li[data-focus="true"]': {
      backgroundColor: "#4a8df6",
      color: "white",
      cursor: "pointer",
    },
    "& li:active": {
      backgroundColor: "#2977f5",
      color: "white",
    },
  },
});

export default function SearchComponent(props: IProps) {
  const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
  const [addressInput, setAddressInput] = useState("");
  const [foundProperties, setFoundProperties] = useState<IPropertyEPC[]>([]);
  const [noResults, setNoResults] = useState<boolean>(false);
  const [postcode, setPostcode] = useState<string>("");
  const [epc, setEPC] = useState<string>("");
  const [assignedFilters, setAssignedFilters] = useState<Array<IFilter>>([]);
  const userContext = useContext(UserContext);
  const classes = useStyles();
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const [currentProp, setCurrentProp] = React.useState<number>(0);

  const fetchFilteredProperties = (assignedFilters: Array<IFilter>) => {
    let url = "/api/sap/filterproperties";
    if (postcode !== "") {
      if (url.includes("?")) {
        url = url + "&postcode=" + postcode;
      } else {
        url = url + "?postcode=" + postcode;
      }
    }
    if (epc !== "") {
      if (url.includes("?")) {
        url = url + "&epcRating=" + epc;
      } else {
        url = url + "?epcRating=" + epc;
      }
    }

    if (assignedFilters.length > 0 || epc !== "" || postcode !== "") {
      return postData(
        "https://captapi.absolarlab.com" + url,
        assignedFilters,
        userContext
      );
    } else {
      return new Promise((resolve, reject) => resolve(props.properties));
    }
  };

  const searchProperties = (event: any) => {
    const options = {
      threshold: 0.15,
      distance: 1000,
      keys: ["address"],
    };
    setLoadingSearch(true);
    event.preventDefault();
    if (addressInput === "") {
      fetchFilteredProperties(assignedFilters).then((properties) => {
        setFoundProperties(properties);
        setLoadingSearch(false);
        setNoResults(false);
      });
    } else {
      let results: Fuse.FuseResult<IPropertyEPC>[] = [];
      fetchFilteredProperties(assignedFilters).then((list) => {
        const fuse = new Fuse(list, options);
        results = fuse.search(addressInput);
        setFoundProperties(results.map((result) => result.item));

        if (results.length === 0) {
          setNoResults(true);
        } else {
          setNoResults(false);
        }
        setLoadingSearch(false);
      });
    }
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
    setCurrentProp(newPage * rowsPerPage);
  };

  return (
    <>
      <Card style={{ background: "#D8DEE9", marginBottom: "12px" }}>
        <CardContent className="content">
          <form noValidate onSubmit={searchProperties}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
              }}
            >
              <TextField
                label="Search address"
                variant="filled"
                fullWidth
                margin="dense"
                style={{ background: "white", marginBottom: "20px" }}
                onChange={(e) => setAddressInput(e.target.value)}
              />
              <TextField
                fullWidth
                label="Postcode"
                margin="dense"
                variant="filled"
                style={{
                  background: "white",
                  flex: "1 0 auto",
                  marginBottom: "20px",
                  marginTop: "0px",
                }}
                onChange={(e) => setPostcode(e.target.value)}
              />
              <TextField
                fullWidth
                label="EPC"
                margin="dense"
                variant="filled"
                style={{
                  background: "white",
                  flex: "1 0 auto",
                  marginBottom: "20px",
                  marginTop: "0px",
                }}
                onChange={(e) => setEPC(e.target.value)}
              />

              <FilterInputList
                assignedFilters={assignedFilters}
                setAssignedFilters={setAssignedFilters}
                filters={props.filters}
              />
              <div>
                <Button
                  variant="contained"
                  classes={{ root: classes.addNewButton }}
                  onClick={() => {
                    setAssignedFilters([...assignedFilters, {} as IFilter]);
                  }}
                >
                  <Typography classes={{ root: classes.searchText }}>
                    Add New Filter
                  </Typography>
                </Button>
                <Button
                  variant="contained"
                  classes={{ root: classes.searchButton }}
                  type="submit"
                >
                  <Typography classes={{ root: classes.searchText }}>
                    Search
                  </Typography>
                </Button>
              </div>
            </div>
          </form>
        </CardContent>
      </Card>
      <LoadingOverlay
        active={loadingSearch}
        spinner
        text="Loading search results... this can take up to 30 seconds"
      >
        {foundProperties.length > 0 && !loadingSearch ? (
          <Card style={{ background: "#D8DEE9" }}>
            <CardContent style={{ paddingTop: "2px" }}>
              <h3 style={{ color: "#4C566A" }}>Results </h3>

              <hr className="solid" />
              <Table size="small">
                <TableHead>
                  <TablePagination
                    count={foundProperties.length}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                  />
                  <TableRow>
                    <TableCell>Address</TableCell>
                    <TableCell>Postcode</TableCell>
                    {/* <TableCell>Property type</TableCell>
          <TableCell>Owner</TableCell>
          <TableCell>Asset Group</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {foundProperties.length > 0
                    ? foundProperties
                        .slice(currentProp, currentProp + rowsPerPage)
                        .map((element) => (
                          <PropertyRow
                            element={element}
                            handleClick={props.addProperty}
                            key={element.property_id}
                          />
                        ))
                    : null}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        ) : noResults ? (
          <Card style={{ background: "#D8DEE9" }}>
            <CardContent>
              <h3 style={{ color: "#4C566A" }}>No Properties Found</h3>
            </CardContent>
          </Card>
        ) : (
          <></>
        )}
      </LoadingOverlay>
    </>
  );
}

function PropertyRow(props: {
  element: IProperty;
  handleClick: (property: IProperty) => void;
  key: number;
}): JSX.Element {
  return (
    <TableRow key={props.key}>
      <TableCell>
        <Button onClick={() => props.handleClick(props.element)}>
          {props.element.address}
        </Button>
      </TableCell>
      <TableCell>{props.element.postcode}</TableCell>
    </TableRow>
  );
}
