import {
  Button,
  Card,
  CardContent,
  Grid,
  makeStyles,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  TextField,
  Input,
} from "@material-ui/core";
import LoadingOverlay from "react-loading-overlay-ts";
import { useHistory, useParams } from "react-router-dom";
import ActionPlanChartLoader from "../ActionPlanChart/ActionPlanChartLoader";
import Intervention from "./Intervention";
import "./ActionPlanPage.css";
import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../contexts/UserContext";
import { deleteData, getData } from "../get-data";
import TariffChangeRow from "./TariffChangeRow";
import { numberWithCommas } from "../utilities";

const useStyles = makeStyles({
  button: {
    width: "20%",
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
    backgroundColor: "#205a81",
    border: "2px solid #091e49",
  },
  gridItem: {
    backgroundColor: "#091e49",
  },
  card: {
    padding: "0px",
    backgroundColor: "#113d64",
  },
});

interface ITimeData {
  sap: {
    [year: string]: number;
  };
  carbon: {
    [year: string]: number;
  };
  bill: {
    [year: string]: number;
  };
}

interface IParams {
  id: string | undefined;
}

interface IProps {
  selectedProperty: IProperty;
  interventionList: Intervention[];
  tariffChanges: TariffChange[];
  setSingleProperty: React.Dispatch<React.SetStateAction<boolean>>;
  setInterventions: React.Dispatch<React.SetStateAction<Intervention[]>>;
  setTariffChanges: React.Dispatch<React.SetStateAction<TariffChange[]>>;
  fetchTariffChanges: (propertyId: number) => void;
  tariffs: Array<tariff>;

  reload: (id?: number) => Promise<void>|null;
}

export default function ActionPlanPage(props: IProps) {
  const userContext = useContext(UserContext);
  const history = useHistory();
  const classes = useStyles();
  const [yearRange, setYearRange] = useState<number[]>([]);
  const [co2Data, setCo2Data] = useState<number[]>([]);
  const [fuelBillData, setFuelBillData] = useState<number[]>([]);
  const [sapScores, setSapScores] = useState<number[]>([]);
  const { id } = useParams<IParams>();
  const [currentCo2, setCurrentCo2] = useState<number>(0);
  const [co2By2035, setCo2By2035] = useState<number>(0);
  const [co2By2050, setCo2By2050] = useState<number>(0);
  const [percentChange, setPercentChange] = useState<number>(0);
  const [timelinePrices, setTimelinePrices] = useState<
    Array<TimelinePrice> | undefined
  >([]);
  const [editing, setEditing] = useState<boolean>(false);
  const [energyCategories, setEnergyCategories] = useState<
    Array<EnergyCategory>
  >([]);
  const [editingTariffs, setEditingTariffs] = useState<boolean>(false);
  const [isChartLoading, setChartLoading] = useState<boolean>(true);

  const range = (start: number, stop: number, step: number) =>
    Array.from(
      { length: (stop - start) / step + 1 },
      (_, i) => start + i * step
    );

  function fetchTimeData(): Promise<ITimeData> {
    const url = "/api/sap/timeline?propertyId=" + id + "&start=2012&end=2050";

    return getData("https://captapi.absolarlab.com" + url, {
      userState: userContext.userState,
      setUserState: userContext.setUserState,
    });
  }

  const fetchEnergyCategories = () => {
    const url = "/api/sap/energycategories";
    getData("https://captapi.absolarlab.com" + url, {
      userState: userContext.userState,
      setUserState: userContext.setUserState,
    }).then((energyCategories: Array<EnergyCategory>) => {
      setEnergyCategories(energyCategories);
    });
  };

  const handleEdit = (id: number, year: number) => {
    const url =
      "/api/sap/intervention_year?propertyInterventionId=" +
      id +
      "&year=" +
      year;

    setChartLoading(true);
    getData("https://captapi.absolarlab.com" + url, {
      userState: userContext.userState,
      setUserState: userContext.setUserState,
    }).then((res) => {
      getTimeData();
      getTimelinePrices(id, 2012, 2050);
      updateLocalInterventions(id, year);
    });
  };

  const getTimelinePrices = (id: number, start: number, end: number) => {
    const url =
      "/api/sap/timelinespend?propertyId=" +
      id +
      "&start=" +
      start.toString() +
      "&end=" +
      end.toString();

    getData("https://captapi.absolarlab.com" + url, {
      userState: userContext.userState,
      setUserState: userContext.setUserState,
    }).then((timelineSpend: Array<TimelinePrice>) => {
      setTimelinePrices(timelineSpend);
    });
  };
  const initTopMetrics = (data: ITimeData): void => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const cur = data.carbon[currentYear];
    setCurrentCo2(Math.round(cur));
    setCo2By2035(Math.round(data.carbon[2035]));

    setCo2By2050(Math.round(data.carbon[2050]));
    setPercentChange(Math.round(((cur - data.carbon[2050]) / cur) * 100));
  };

  /**
   *  fetches time data from server.
   *  Then formats it ready for it to
   *  be passed into ActionPlanChartLoader
   */
  const getTimeData = (): void => {
    fetchTimeData()
      .then((data) => {
        initTopMetrics(data);

        const years = Object.keys(data.sap);
        const startYear = parseInt(years[0]);
        const endYear = parseInt(years[years.length - 1]);
        setYearRange(range(startYear, endYear, 1));

        const sapArray = Object.values(data.sap);
        const roundedSapArray = sapArray.map((val) => Math.round(val));
        setSapScores(roundedSapArray);

        const co2Array = Object.values(data.carbon);
        const roundedCo2Array = co2Array.map(
          (val) => Math.round((val + Number.EPSILON) * 100) / 100
        );
        setCo2Data(roundedCo2Array);

        const fuelBillArray = Object.values(data.bill);
        const roundedFuelBillArray = fuelBillArray.map(
          (val) => Math.round((val + Number.EPSILON) * 100) / 100
        );
        setFuelBillData(roundedFuelBillArray);

        setChartLoading(false);
      })
      .catch((e) => console.log(e));
  };

  const updateLocalInterventions = (id: number, year: number) => {
    const editedIndex = props.interventionList.findIndex(
      (element) => element.id === id
    );
    if (editedIndex !== -1) {
      const editedIntervention = props.interventionList[editedIndex];
      let newInterventions = props.interventionList.filter((x) => x.id !== id);
      editedIntervention.plannedYear = year;
      newInterventions.push(editedIntervention);

      props.setInterventions(newInterventions);
    }
  };
  const updateTimeline = () => {
    getTimeData();
    getTimelinePrices(parseInt(id!), 2012, 2050);
    props.fetchTariffChanges(parseInt(id!));
  };
  const removeTariffChange = (tariffChangeId: number) => {
    const url = "/api/sap/tariff_plan?tariffPlanId=" + tariffChangeId;
    deleteData("https://captapi.absolarlab.com" + url, userContext).then(
      (res) => {
        let newTariffChanges = props.tariffChanges.filter(
          (tariffChange) => tariffChange.id !== tariffChangeId
        );
        props.setTariffChanges(newTariffChanges);
        updateTimeline();
      }
    );
  };
  useEffect(() => {
    if (Object.keys(props.selectedProperty).length === 0) {
      props.setSingleProperty(true);
      props.reload(parseInt(id!))?.catch((e)=>{history.push("/404")});
    } else {
      getTimeData();
      props.setSingleProperty(true);
      getTimelinePrices(parseInt(id!), 2012, 2050);
    }
    fetchEnergyCategories();
  }, []);

  useEffect(() => {
    getTimeData();
    getTimelinePrices(parseInt(id!), 2012, 2050);
  }, [props.selectedProperty]);

  return (
    <Card className="card" style={{ backgroundColor: "#113d64" }}>
      <CardContent className="header">
        <h3 className="headerText">{props.selectedProperty.address}</h3>
      </CardContent>
      <hr className="solid" />
      <CardContent style={{ paddingTop: "0px", paddingBottom: "0px" }}>
        <h3 className="headerText">Action Plan</h3>

        <Grid container spacing={1} justify="space-between">
          <Grid item xs={3}>
            <Button fullWidth id="plan_button_active">
              Main Plan
            </Button>
          </Grid>
          {/* <Grid item xs={3}>
            <Button fullWidth id="plan_button_disabled">
              Scenario 2
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button fullWidth id="plan_button_disabled">
              Scenario 3
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button fullWidth id="plan_button_disabled">
              Scenario 4
            </Button>
          </Grid> */}
        </Grid>
        <div className="action-plan">
          <div
            className="action-plan_inner"
            style={{
              background: "rgb(0, 26, 61)",
              color: "white",
              padding: "1px",
            }}
          >
            <Grid
              container
              spacing={3}
              style={{
                textAlign: "center",
                paddingTop: "20px",
                paddingBottom: "20px",
              }}
            >
              <Grid item xs={3}>
                Current emissions <br />
                <h1>{currentCo2} kg</h1>
              </Grid>
              <Grid item xs={3}>
                By 2035 <br />
                <h1>{co2By2035} kg</h1>
              </Grid>
              <Grid item xs={3}>
                By 2050 <br />
                <h1>{co2By2050} kg</h1>
              </Grid>
              <Grid item xs={3}>
                Change in emissions (by 2050)
                <br />
                <h1>{percentChange}%</h1>
              </Grid>
            </Grid>
          </div>
          <div className="action-plan_inner">
            <div
              style={{
                backgroundColor: "white",
                padding: 10,
              }}
            >
              <Table size="small">
                <TableHead style={{ background: "lightgrey"}}>
                  <TableRow>
                    <TableCell>
                      <strong>Intervention </strong>
                    </TableCell>
                    <TableCell style={{ width: "7%" , textAlign:"right"}}>Install Cost</TableCell>
                    <TableCell style={{ width: "7%" , textAlign:"right" }}>
                      CO2 Impact (Year 1)
                    </TableCell>
                    <TableCell style={{ width: "7%" , textAlign:"right"}}>
                      Fuel Cost Impact (Year 1)
                    </TableCell>
                    <TableCell style={{ width: "7%" , textAlign:"right"}}>
                      Lifetime (Per Salix)
                    </TableCell>
                    <TableCell style={{ width: "7%" , textAlign:"right"}}>
                      CO2 Saving over Lifetime
                    </TableCell>
                    <TableCell style={{ width: "7%" , textAlign:"right"}}>
                      Cost per Tonne of lifetime CO2 saving
                    </TableCell>
                    <TableCell style={{ width: "7%" , textAlign:"right"}}>
                      Bill saving over lifetime
                    </TableCell>
                    <TableCell style={{ width: "7%" , textAlign:"right"}}>
                      Lifetime bill saving per £1 of Install Cost
                    </TableCell>
                    <TableCell style={{ width: "7%" , textAlign:"right"}}>
                      kWh Impact p.a.
                    </TableCell>
                    <TableCell>
                      <strong>Year Planned </strong>
                      <Button
                        variant="contained"
                        size="small"
                        color="secondary"
                        style={{ marginLeft: 10 }}
                        onClick={() => {
                          setEditing(!editing);
                        }}
                      >
                        Edit
                      </Button>
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.interventionList
                    .filter((x) => x.doneYear === -1)
                    .map((element) => (
                      <Intervention
                        key={element.id.toString()}
                        editing={editing}
                        intervention={element}
                        handleYearEdit={handleEdit}
                        type={"year"}
                      />
                    ))}
                  {props.interventionList
                    .filter((x) => x.doneYear > 1900)
                    .map((element) => (
                      <TableRow key={element.id}>
                        <TableCell>
                          <strong>{element.interventionName} (Done) </strong>
                        </TableCell>
                        <TableCell align="right">
                          {"£" + Math.round(element.installCost).toString()}
                        </TableCell>

                        <TableCell align="right">
                          {Math.round(element.currentCo2Impact).toString() +
                            "kg"}
                        </TableCell>
                        <TableCell align="right">
                          {"£" +
                            Math.round(element.currentCostSavings).toString()}
                        </TableCell>
                        <TableCell align="right">
                          {element.lifetime != null
                            ? Math.round(element.lifetime)
                            : 0}{" "}
                          years
                        </TableCell>
                        <TableCell align="right">
                          {element.lifetime
                            ? (
                                element.currentCo2Impact * element.lifetime
                              ).toFixed(0) + " kg"
                            : "n/a"}
                        </TableCell>
                        <TableCell align="right">
                          {element.lifetime
                            ? "£" +
                              numberWithCommas(
                                (
                                  element.installCost /
                                  ((element.currentCo2Impact / 1000) *
                                    element.lifetime)
                                ).toFixed(0)
                              )
                            : "n/a"}
                        </TableCell>
                        <TableCell align="right">
                          {element.lifetime
                            ? "£" +
                              numberWithCommas(
                                (
                                  element.currentCostSavings * element.lifetime
                                ).toFixed(0)
                              )
                            : "n/a"}
                        </TableCell>
                        <TableCell align="right">
                          {element.lifetime && element.installCost
                            ? "£" +
                              numberWithCommas(
                                (
                                  (element.currentCostSavings *
                                    element.lifetime) /
                                  element.installCost
                                ).toFixed(2)
                              )
                            : "n/a"}
                        </TableCell>
                        <TableCell align="right">
                          <strong>{element.doneYear}</strong>
                        </TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </div>
          </div>
          {props.tariffChanges.length === 0 ? (
            <></>
          ) : (
            <div className="action-plan_inner" style={{ background: "white" }}>
              <div
                style={{
                  backgroundColor: "white",
                  padding: 10,
                }}
              >
                <Table size="small">
                  <TableHead style={{ background: "lightgrey" }}>
                    <TableRow>
                      <TableCell>
                        <strong>Tariff Name </strong>
                      </TableCell>
                      <TableCell>
                        <strong>Energy Category</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Year Planned </strong>
                        {editingTariffs ? (
                          <Button
                            variant="contained"
                            size="small"
                            color="primary"
                            style={{ marginLeft: 10 }}
                            onClick={() => {
                              setEditingTariffs(!editingTariffs);
                            }}
                          >
                            Save
                          </Button>
                        ) : (
                          <Button
                            variant="contained"
                            size="small"
                            color="secondary"
                            style={{ marginLeft: 10 }}
                            onClick={() => {
                              setEditingTariffs(!editingTariffs);
                            }}
                          >
                            Edit
                          </Button>
                        )}
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props.tariffChanges.map((tariffChange) => {
                      return (
                        <TariffChangeRow
                          key={tariffChange.id}
                          tariffChange={tariffChange}
                          tariffs={props.tariffs}
                          editing={editingTariffs}
                          energyCategories={energyCategories}
                          updateTimeline={updateTimeline}
                          removeTariffChange={removeTariffChange}
                        />
                      );
                    })}
                  </TableBody>
                </Table>
              </div>
            </div>
          )}
          <div
            className="action-plan_inner"
            style={{ background: "white", padding: "1px" }}
          >
            <LoadingOverlay active={isChartLoading} spinner text="Loading...">
              <ActionPlanChartLoader
                co2Data={co2Data}
                fuelBillData={fuelBillData}
                sapScores={sapScores}
                yearRange={yearRange}
                isChartLoading={false}
              />
            </LoadingOverlay>
          </div>
          {timelinePrices !== undefined && timelinePrices.length > 0 ? (
            <div className="action-plan_inner">
              <div
                style={{
                  backgroundColor: "white",
                }}
              >
                <Table size="small">
                  <TableHead style={{ background: "lightgrey" }}>
                    <TableRow>
                      <TableCell>
                        <strong>Year </strong>
                      </TableCell>
                      <TableCell>
                        <strong>Total Year Spend</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Per Intervention Spend</strong>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {timelinePrices.map((timelinePrice: TimelinePrice) => {
                      return (
                        <TableRow key={timelinePrice.year}>
                          <TableCell>{timelinePrice.year}</TableCell>
                          <TableCell>£{timelinePrice.totalPrice}</TableCell>
                          <TableCell>
                            {Object.keys(timelinePrice.interventionPrices).map(
                              (interventionName) => {
                                return (
                                  <div key={interventionName}>
                                    {interventionName} : £
                                    {
                                      timelinePrice.interventionPrices[
                                        interventionName
                                      ]
                                    }
                                  </div>
                                );
                              }
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </div>
            </div>
          ) : null}
        </div>
      </CardContent>
    </Card>
  );
}
