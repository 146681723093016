import { ApexOptions } from "apexcharts";
import React, { useState } from "react";
import RadarChart from "./RadarChart";

interface IProps {
  wall: string | null;
  roof: string | null;
  window: string | null;
  heating: string | null;
  control: string | null;
  water: string | null;
  lighting: string | null;
  floor: string | null;
  secondary: string | null;
}

export default function RadarLoader(props: IProps): JSX.Element {
  const convertRating = (rating: string) => {
    //rinse and repeat for any rating
    if (rating === "Very Good") {
      return 5;
    }
    if (rating === "Good") {
      return 4;
    }
    if (rating === "Average") {
      return 3;
    }
    if (rating === "Poor") {
      return 2;
    }
    if (rating === "Very Poor") {
      return 1;
    } else {
      return 1;
    }
  };
  const [options, setOptions] = useState<ApexOptions>({
    chart: {
      type: "radar",
      background: "#fff",
      toolbar: {
        show: false,
      },
    },
    title: {},
    xaxis: {
      categories: [
        "Wall",
        "Roof",
        "Window",
        "Heating",
        "Heating control",
        "Hot water",
        "Lighting",
        "Floor",
        "Secondary heating",
      ],
    },

    yaxis: {
      show: false,
    },
    markers: {
      size: 3,
    },
    fill: {
      opacity: 0.3,
    },
    legend: {
      show: true,
    },
  });

  const [series, setSeries] = useState([
    {
      name: "Authority-wide",
      data: [3.402, 3.2, 3.368, 3.628, 3.423, 3.494, 3.623, 1.1, 3],
    },
    {
      name: "England",
      data: [2.999, 3, 3.316, 3.73, 3.39, 3.583, 3.588, 1.8, 3],
    },
    {
      name: "This property",
      data: [
        convertRating(props.wall ? props.wall : "Average"),
        convertRating(props.roof ? props.roof : "Average"),
        convertRating(props.window ? props.window : "Average"),
        convertRating(props.heating ? props.heating : "Average"),
        convertRating(props.control ? props.control : "Average"),
        convertRating(props.water ? props.water : "Average"),
        convertRating(props.lighting ? props.lighting : "Average"),
        convertRating(props.floor ? props.floor : "Average"),
        convertRating(props.secondary ? props.secondary : "Average"),
      ],
    },
  ]);

  return <RadarChart options={options} series={series} />;
}
