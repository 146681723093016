import React, { useEffect, useState } from "react";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import FileInput from "./FileInput";

interface IFilter {
  name: string;
  options: "more" | "less" | "equal" | "category";
  value: string;
}

interface IProps {
  assignedFilters: Array<IFilter>;
  setAssignedFilters: React.Dispatch<React.SetStateAction<IFilter[]>>;
  filters: Array<IFilterObject>;
}

export default function FilterInputList(props: IProps): JSX.Element {
  const [filter, setFilter] = useState<IFilter>({} as IFilter);

  const filterOptions = createFilterOptions({
    limit: 100,
    stringify: (option: string) => option,
  });

  const trySetSelected = (filterName: string) => {
    const matched = props.filters.find(
      (x: IFilterObject) => x.name === filterName
    );
    return matched;
  };

  if (props.assignedFilters.length > 0) {
    return (
      <>
        {props.assignedFilters.map((filter, index) => {
          return (
            <FileInput
              key={index}
              filter={filter}
              index={index}
              filterOptions={filterOptions}
              trySetSelected={trySetSelected}
              assignedFilters={props.assignedFilters}
              setAssignedFilters={props.setAssignedFilters}
              filters={props.filters}
            />
          );
        })}
      </>
    );
  } else {
    return <></>;
  }
}
