import React, { useContext, useState } from "react";
import {
  Autocomplete,
  Button,
  Input,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import InterventionSelect from "./InterventionSelect";
import { postData } from "../get-data";
import { UserContext } from "../../contexts/UserContext";

interface IProps {
  interventions: PortfolioIntervention[];
  selectedPortfolio: PortfolioDetail;
  setUpdated: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function BulkSetYear(props: IProps) {
  const userContext = useContext(UserContext);

  const [tickedInterventions, setTickedInterventions] = useState<number[]>([]);
  const [addingIntervention, setAddingIntervention] = useState<boolean>(false);
  const [year, setYear] = useState<number>(0);

  const addTickedIntervention = (interventionId: number) => {
    let newTickedInterventions = tickedInterventions;
    if (newTickedInterventions.find((x) => x === interventionId)) {
      newTickedInterventions = newTickedInterventions.filter(
        (x) => x !== interventionId
      );
    } else {
      newTickedInterventions = newTickedInterventions.concat(interventionId);
    }
    setTickedInterventions(newTickedInterventions);
  };

  const addAllInterventions = (ticked: boolean) => {
    if (ticked) {
      const newTickedInterventions: number[] = [];
      props.interventions.forEach((intervention) =>
        newTickedInterventions.push(intervention.interventionId)
      );
      setTickedInterventions(newTickedInterventions);
    } else {
      setTickedInterventions([]);
    }
  };

  const postBulkSetYear = (data: {
    portfolioId: number;
    year: number;
    interventionIds: number[];
  }) => {
    postData("https://captapi.absolarlab.com/api/portfolio/bulk_set_year", data, {
      userState: userContext.userState,
      setUserState: userContext.setUserState,
    }).then(() => {
      setAddingIntervention(false);
      setTickedInterventions([]);
      setYear(0);
      props.setUpdated(true);
    });
  };

  return (
    <>
      {addingIntervention ? (
        <></>
      ) : (
        <Button
          color="inherit"
          style={{ margin: "0px 15px" }}
          disabled={tickedInterventions.length === 0 && addingIntervention}
          size="small"
          variant="contained"
          onClick={() => {
            if (addingIntervention) {
              if (year === 0) {
                alert("Please enter year");
              } else {
                let data = {
                  portfolioId: props.selectedPortfolio.id,
                  year: year,
                  interventionIds: tickedInterventions,
                };

                postBulkSetYear(data);
              }
            } else {
              setAddingIntervention(true);
            }
          }}
        >
          {addingIntervention ? "Confirm" : "Set"} planned year
        </Button>
      )}

      {addingIntervention ? (
        <div
          style={{
            backgroundColor: "white",
            marginTop: "25px",
            marginBottom: "20px",
          }}
        >
          <hr className="solid" />
          <InterventionSelect
            interventionList={props.interventions}
            addAllInterventions={addAllInterventions}
            addTickedIntervention={addTickedIntervention}
            tickedInterventions={tickedInterventions}
            defaultRows={20}
          />

          <Table size="small">
            <TableBody>
              <TableRow>
                <TableCell>
                  <></>
                </TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell>
                  <></>
                </TableCell>
                <TableCell>
                  <></>
                </TableCell>
                <TableCell>
                  <></>
                </TableCell>
                <TableCell>
                  <></>
                </TableCell>
                <TableCell align="right">
                  Set intervention year for ticked interventions:{"  "}
                  <Input
                    // margin="normal"
                    // fullWidth
                    type="number"
                    value={year}
                    onChange={(e) => {
                      setYear(parseFloat(e.target.value));
                    }}
                  />
                  <Button
                    style={{ margin: "0px 15px" }}
                    size="small"
                    variant="contained"
                    onClick={() => {
                      setYear(2030);
                    }}
                  >
                    2030
                  </Button>
                  <Button
                    size="small"
                    variant="contained"
                    onClick={() => {
                      setYear(2040);
                    }}
                  >
                    2040
                  </Button>
                  {addingIntervention ? (
                    <Button
                      style={{ margin: "0px 15px" }}
                      color="inherit"
                      size="small"
                      variant="contained"
                      onClick={() => {
                        setAddingIntervention(false);
                        setTickedInterventions([]);
                        setYear(0);
                      }}
                    >
                      Cancel
                    </Button>
                  ) : (
                    <></>
                  )}
                  <Button
                    style={{ margin: "0px 15px" }}
                    disabled={
                      tickedInterventions.length === 0 && addingIntervention
                    }
                    size="small"
                    variant="contained"
                    onClick={() => {
                      if (year === 0) {
                        alert("Please enter year");
                      } else {
                        let data = {
                          portfolioId: props.selectedPortfolio.id,
                          year: year,
                          interventionIds: tickedInterventions,
                        };

                        postBulkSetYear(data);
                      }
                    }}
                  >
                    Save
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      ) : null}
    </>
  );
}
