import React from "react";
import { TableCell, TableRow } from "@material-ui/core";
interface IProps {
  intervention: PlannedInterventionDto;
  isDone: boolean;
}

export default function PlannedIntervention(props: IProps) {
  function numberWithCommas(x: number | string) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <>
      <TableCell style={{ width: "3%" }}>
        {props.intervention.address}
      </TableCell>
      <TableCell style={{ width: "5%" }}>
        {props.intervention.interventionName}
      </TableCell>
      {props.isDone ? (
        <TableCell style={{ width: "7%" }} align="right">
          {props.intervention.doneYear}
        </TableCell>
      ) : (
        <TableCell style={{ width: "7%" }} align="right">
          {props.intervention.plannedYear}
        </TableCell>
      )}
      <TableCell style={{ width: "7%" }} align="right">
        £{numberWithCommas(props.intervention.installCost.toFixed(0))}
      </TableCell>
      <TableCell style={{ width: "7%" }} align="right">
        <span>
          {" "}
          {numberWithCommas(props.intervention.currentCo2Impact.toFixed(0)) +
            " "}
          kg
        </span>
      </TableCell>
      <TableCell style={{ width: "7%" }} align="right">
        £
        {numberWithCommas(props.intervention.currentFuelCostImpact.toFixed(0)) +
          " "}
      </TableCell>
      <TableCell style={{ width: "7%" }} align="right">
        {props.intervention.lifetime
          ? Math.round(props.intervention.lifetime) + " years"
          : "n/a"}
      </TableCell>
      <TableCell style={{ width: "7%" }} align="right">
        {props.intervention.lifetime
          ? numberWithCommas(
              (props.intervention.lifetime * props.intervention.carbon).toFixed(
                0
              )
            ) + " kg"
          : "n/a"}
      </TableCell>
      <TableCell style={{ width: "7%" }} align="right">
        £
        {props.intervention.lifetime
          ? numberWithCommas(
              (
                props.intervention.installCost /
                ((props.intervention.lifetime * props.intervention.carbon) /
                  1000)
              ).toFixed(0)
            )
          : "n/a"}
      </TableCell>
      <TableCell style={{ width: "7%" }} align="right">
        £
        {props.intervention.lifetime
          ? numberWithCommas(
              (
                props.intervention.currentFuelCostImpact *
                props.intervention.lifetime
              ).toFixed(0)
            )
          : "n/a"}
      </TableCell>
      <TableCell style={{ width: "7%" }} align="right">
        £
        {props.intervention.lifetime && props.intervention.installCost
          ? numberWithCommas(
              (
                (props.intervention.lifetime *
                  props.intervention.currentFuelCostImpact) /
                props.intervention.installCost
              ).toFixed(2)
            )
          : "n/a"}
      </TableCell>
      <TableCell align="right" style={{ width: "7%" }}>
        {numberWithCommas(props.intervention.kwhImpact.toFixed(0))}
      </TableCell>
      <TableCell align="right" style={{ width: "5%" }}>
        {props.intervention.isSap ? "Yes" : "No"}
      </TableCell>
    </>
  );
}
