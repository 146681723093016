import React, { useState, useEffect } from "react";
import { ApexOptions } from "apexcharts";
import InfillChart from "./InfillChart";

interface IProps {
  portfolioEpcTypes: Array<PortfolioEpcType>;
}

export default function InfillChartLoader(props: IProps): JSX.Element {
  const allData: { name: string; data: number[] }[] = [];

  props.portfolioEpcTypes.map((epc) => {
    allData.push({ name: epc.epc_type, data: [epc.numProperties] });
  });

  const [series, setSeries] = useState(allData);

  const [options, setOptions] = useState<ApexOptions>({
    chart: {
      type: "bar",
      stacked: true,
      stackType: "100%",
      background: "white",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    colors: ["#38A3A5", "#113CFC", "#57CC99", "#80ED99"],

    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },

    xaxis: {
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    tooltip: {
      x: {
        formatter: () => "EPC Type",
      },
      y: {
        title: {
          formatter: function (val) {
            return val + " Properties";
          },
        },
      },
    },

    fill: {
      opacity: 1,
    },
    legend: {
      show: false,
      showForSingleSeries: true,
      position: "bottom",
      horizontalAlign: "center",
      itemMargin: {
        horizontal: 5,
        vertical: 0,
      },
      labels: {
        colors: "#fff",
        useSeriesColors: false,
      },
    },
    grid: {
      borderColor: "black",
      padding: {
        left: -50,
        right: 0,
        bottom: -40,
        top: -50,
      },
      show: true,
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
  });

  useEffect(() => {
    setSeries(allData);
    setOptions({
      ...options,
    });
  }, [props]);

  return (
    <>
      <InfillChart options={options} series={series} />
    </>
  );
}
