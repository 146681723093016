import {
  Button,
  TableCell,
  TableRow,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import "./ActionPlanPage.css";
import React, { useContext, useEffect, useState } from "react";
import { getData } from "../get-data";
import { UserContext } from "../../contexts/UserContext";
import { TextField, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import CheckIcon from "@mui/icons-material/Check";
import { numberWithCommas } from "../utilities";

interface SandboxImpact {
  id: number;
  kwhImpact: number;
  year: number;
  energyCategoryId: number;
}
interface IProps {
  amendSandboxImpact: (sandboxImpact: SandboxImpact) => void;
  deleteRow?: (id: number, edit: boolean) => void;
  setMap: (id: number, edit: boolean) => void;
  energyCategories: Array<EnergyCategory>;
  id: number;
  key: number;
  delete: boolean;
  co2Impact?: { id: number; impact: number };
}

export default function NewImpactRow(props: IProps) {
  const userContext = useContext(UserContext);

  const [edit, setEdit] = useState<boolean>(true);

  const [kwh, setKwh] = useState<number>(0);
  const [kwhError, setKwhError] = useState<boolean>(false);

  const [year, setYear] = useState<number>(0);
  const [yearError, setYearError] = useState<boolean>(false);

  const [energyError, setEnergyError] = useState<boolean>(false);
  const [energyCategory, setEnergyCategory] = useState<EnergyCategory>({
    id: -1,
    name: "Please Select One",
  });

  function deleteRow() {
    if (props.deleteRow) {
      props.deleteRow(props.id, !edit);
    }
  }

  const applyImpact = () => {
    if (kwh == 0) {
      setKwhError(true);
    }
    if (year < 2011) {
      setYearError(true);
    }
    if (energyCategory.id == -1) {
      setEnergyError(true);
    }
    if (energyCategory.id > -1 && kwh !== 0 && year > 2011) {
      props.amendSandboxImpact({
        id: props.id,
        kwhImpact: kwh,
        year: year,
        energyCategoryId: energyCategory.id,
      });

      setEdit(!edit);
      setEnergyError(false);
      setKwhError(false);
      setYearError(false);
      // setKwh(0);
      // setYear(0);
      // setEnergyCategory({ id: -1, name: "Please Select One" });
      props.setMap(props.id, false);
    }
  };

  useEffect(() => {
    props.setMap(props.id, true);
  }, []);

  return (
    <TableRow >
      <TableCell>Kwh Impact:</TableCell>
      <TableCell style={{minWidth:40}}>
        <TextField
          // margin="normal"
          error={kwhError}
          fullWidth
          type="number"
          value={kwh}
          helperText={kwhError ? "Enter correct kwh." : ""}
          variant="standard"
          onChange={(e) => {
            setKwh(parseFloat(e.target.value));
            setKwhError(false);
            setEdit(true);
            props.setMap(props.id, true);
          }}
        />
      </TableCell>
      <TableCell>Energy Category:</TableCell>
      <TableCell>
        <FormControl style={{ width: "200px" }}>
          <InputLabel id="energy-category-select-label">
            Energy Category
          </InputLabel>
          <Select
            error={energyError}
            // helperText={kwhError ? "Enter correct kwh." : ""}
            labelId="energy-category-select-label"
            id="energy-category-select"
            value={energyCategory.id}
            onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
              let newCategory = props.energyCategories.find(
                (x) => x.id == (e.target.value as number)
              );
              if (newCategory) {
                setEnergyCategory(newCategory);
                setEnergyError(false);
                setEdit(true);
                props.setMap(props.id, true);
              }
            }}
          >
            {props.energyCategories.map((energyCategoryChoice) => {
              return (
                <MenuItem
                  key={energyCategoryChoice.id}
                  value={energyCategoryChoice.id}
                >
                  {energyCategoryChoice.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </TableCell>
      <TableCell>Year:</TableCell>
      <TableCell style={{minWidth:40}}>
        <TextField
          // margin="normal"
          error={yearError}
          fullWidth
          type="number"
          value={year}
          helperText={yearError ? "Enter correct year." : ""}
          variant="standard"
          onChange={(e) => {
            setYear(parseFloat(e.target.value));
            setYearError(false);
            setEdit(true);
            props.setMap(props.id, true);
          }}
        />
      </TableCell>
      <TableCell>CO2 Reduction:</TableCell>
      <TableCell>
        {props.co2Impact && !edit ? (
          <>
            <span style={{ color: "green", fontWeight: "bold" }}>
              {numberWithCommas(props.co2Impact.impact.toFixed(0))}
            </span>
            {" "}kg
          </>
        ) : (
          <span
          //  style={{ color: "red" }}
          >
            calculated after graph updated{" "}
          </span>
        )}
      </TableCell>
      <TableCell>
        {props.delete ? (
          <IconButton onClick={deleteRow}>
            <DeleteIcon />
          </IconButton>
        ) : (
          <></>
        )}
      </TableCell>
      <TableCell>
        {edit ? (
          <IconButton onClick={applyImpact}>
            <SaveIcon />
          </IconButton>
        ) : (
          <IconButton>
            <CheckIcon />
          </IconButton>
        )}
      </TableCell>
    </TableRow>
  );
}
