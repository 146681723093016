import React, {
  Component,
  ReactElement,
  useState,
  FC,
  useContext,
  useEffect,
} from "react";
import {
  Card,
  Grid,
  Hidden,
  CardActionArea,
  Button,
  CardContent,
} from "@material-ui/core";
import EcoOutlinedIcon from "@material-ui/icons/EcoOutlined";
import PowerOutlinedIcon from "@material-ui/icons/PowerOutlined";
import TimelineOutlinedIcon from "@material-ui/icons/TimelineOutlined";
import co2_img from "../../../img/co_2.png";
import panel_img from "../../../img/solar-panel.png";
import saving_img from "../../../img/piggy-bank.png";
import { PoundCircleOutlined } from "@ant-design/icons";

interface IProps {
  icon: string;
  preunit: string;
  value: string;
  title: string | JSX.Element;
  unit: string;
}

interface IState {
  icon: JSX.Element;
  open: boolean;
  backgroundColor: string;
}

const SummaryExecutive: FC<IProps> = (props: IProps): ReactElement => {
  const [state, setState] = useState({
    icon: <EcoOutlinedIcon />,
    backgroundColor: "#2980B9",
    open: false,
  });

  useEffect(() => {
    if (props.icon === "Eco") {
      setState({
        ...state,
        icon: (
          <img
            alt="co2_cloud"
            src={co2_img}
            style={{ width: "50%", maxWidth: "80px" }}
          />
        ),
        backgroundColor: "#0f3057",
      });
    } else if (props.icon === "Eco_original") {
      setState({
        ...state,
        icon: <EcoOutlinedIcon style={{ color: "black", fontSize: 30 }} />,
      });
      setState({ ...state, backgroundColor: "#0f3057" });
    } else if (props.icon === "Power") {
      setState({
        ...state,
        icon: (
          <img
            alt="no_of_panels"
            src={panel_img}
            style={{ width: "50%", maxWidth: "80px" }}
          />
        ),
        backgroundColor: "#fca652",
      });
    } else if (props.icon === "Power_original") {
      setState({
        ...state,
        icon: <PowerOutlinedIcon style={{ color: "black", fontSize: 30 }} />,
      });
      setState({ ...state, backgroundColor: "#fca652" });
    } else if (props.icon === "Moneti") {
      setState({
        ...state,
        icon: (
          <img
            alt="money_piggy_bank"
            src={saving_img}
            style={{ width: "50%", maxWidth: "80px" }}
          />
        ),
        backgroundColor: "#008891",
      });
    } else if (props.icon === "Moneti_original") {
      setState({
        ...state,
        icon: <PoundCircleOutlined style={{ color: "black", fontSize: 30 }} />,
      });
      setState({ ...state, backgroundColor: "#008891" });
    } else if (props.icon === "Timeline") {
      setState({
        ...state,
        icon: <TimelineOutlinedIcon style={{ color: "black", fontSize: 30 }} />,
      });
      setState({ ...state, backgroundColor: "#5c969e" });
    }
  }, []);

  return (
    <div style={{ height: "100%" }}>
      <Card
        style={{
          borderRadius: "10px",
          backgroundColor: "#f8f8f8",
          boxShadow: "1px 1px 5px 1px #9999",
          width: "100%",
          height: "100%",
        }}
      >
        <CardContent
          style={{
            textAlign: "center",
            padding: "10px 10px",
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <h3 style={{ textAlign: "center" }}>
            {props.title}
          </h3>
          <Grid container>
            <Grid
              item
              xs={3}
              sm={12}
              style={{
                justifyContent: "center",
                margin: "auto"
              }}
            >
              {state.icon}
            </Grid>
            <Grid
              item
              xs={4}
              sm={12}
              style={{
                justifyContent: "center",
                margin: "auto",
                padding: "10px 0",
              }}
            >
              <div>
                <span className="heading_box_preunit">{props.preunit}</span>
                <span className="heading_box_number">{props.value}</span>{" "}
                <br />
                <span className="heading_box_unit">{props.unit}</span>
                <br></br>
              </div>
            </Grid>
            <Grid
              item
              xs={5}
              sm={12}
              style={{
                justifyContent: "center",
                margin: "auto",
                padding: "10px 0",
              }}
            >
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

export default SummaryExecutive;
