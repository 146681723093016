import React, { Dispatch, useContext, useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Card,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  TablePagination,
  IconButton,
  Tooltip,
  Modal,
  Badge,
} from "@mui/material";
import { postDataNoLogin } from "./fetch-data";
import { useHistory } from "react-router-dom";

import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { postData, getData } from "../get-data";
import { UserContext } from "../../contexts/UserContext";
interface IProps {
  data: PropertyDataColumns;
  filterDto: FilterDto;
  setSelectedProperty: Dispatch<React.SetStateAction<IProperty>>;
  fetchPropertyDetails: (property: number) => Promise<void>;
  fetchInterventions: (property: IProperty) => void;
}
interface TickedIdData {
  [uprn: string]: boolean;
}
const tooltipHeaders: { [header: string]: string } = {
  EWI: "External Wall Insulation",
  CWI: "Cavity Wall Insulation",
  LI: "Loft Insulation",
  RIR: "Room in Roof Insulation",
  HC: "Upgrade Heating Controls",
  SH: "Storage Heaters",
  Boiler: "Upgrade Condensing Boiler",
  Solar: "Solar PV 2.5kW",
  ASHP: "Air Source Heat Pump",
};
export const PropertyTable: React.FC<IProps> = (props: IProps) => {
  const [currentProp, setCurrentProp] = React.useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [tickedIds, setTickedIds] = useState<TickedIdData>({});
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const [newPortfolioName, setNewPortfolioName] = useState<string>("");

  const userContext = useContext(UserContext);

  const history = useHistory();
  const [portfolioUprns, setPorfolioUprns] = useState<string>("");
  const firstFetch = (uprn: string) => {
    getData(
      "https://captapi.absolarlab.com/api/sap/uprnpropertydetails?uprn=" + uprn,
      userContext
    ).then((property: IProperty) => {
      props.setSelectedProperty(property);
      props.fetchPropertyDetails(property.property_id)
      .catch((e) => history.push("/404"));
      props.fetchInterventions(property);
      history.push("/propertyoverview/" + property.property_id);
    });
  };
  const [enablePortfolioButton, setEnablePortfolioButton] =
    useState<boolean>(true);
  const postNewPortfolio = () => {
    setEnablePortfolioButton(false);
    const url =
      "/api/portfolio?name=" +
      newPortfolioName +
      "&user=" +
      userContext.userState.username;
    return postData(
      "https://captapi.absolarlab.com" + url,
      uprnStringToArray(),
      userContext
    ).then((portfolio: PortfolioDetail) => {
      alert(
        "Adding new portfolio successful, " +
          portfolio.numberProperties.toString() +
          " properties with EPCs added."
      );
      setNewPortfolioName("");
      setOpenPaste(false);
      setEnablePortfolioButton(true);
    });
  };
  function uprnStringToArray() {
    try {
      let uprnString = portfolioUprns;
      if (uprnString.length >= 1) {
        if (uprnString.slice(-1) == ",") {
          uprnString = uprnString.slice(0, -1);
        }
        const arrayUprns = uprnString.split(",");

        return arrayUprns;
      } else {
        return [];
      }
    } catch (error) {
      return [];
    }
  }
  function getTickedString() {
    let csvUprn = "";
    Object.keys(tickedIds).forEach((id) => {
      if (tickedIds[id]) {
        csvUprn += id + ",";
      }
    });
    setPorfolioUprns(csvUprn);
  }
  function tickId(id: string, ticked: boolean) {
    let newTicked = { ...tickedIds };
    if (ticked) {
      newTicked[id] = true;
    } else {
      delete newTicked[id];
    }
    setTickedIds(newTicked);
  }
  function tickAll() {
    let newTicked: TickedIdData = {};
    props.data.purchasedUprns.forEach((uprn: number) => {
      newTicked[uprn.toString()] = true;
    });
    setTickedIds(newTicked);
  }
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
    setCurrentProp(newPage * rowsPerPage);
  };

  function downloadCSV() {
    let name = new Date().toJSON().slice(0, 10).replace(/-/g, "_").toString();
    name += "_capt_filtered";

    let filtersSelected: { [filter: string]: string } = {};
    Object.keys(props.filterDto.Filters).forEach((key) => {
      Object.keys(props.filterDto.Filters[key]).forEach((categoryName) => {
        let filterChoicesString = "";
        props.filterDto.Filters[key][categoryName].forEach((value) => {
          filterChoicesString += value + "|";
        });
        filtersSelected[categoryName] = filterChoicesString;
      });
    });

    name = name.slice(0, 27);
    name = name + ".csv";

    postData(
      "https://captapi.absolarlab.com/api/filtering/csv",
      { uprns: props.data.purchasedUprns, filters: filtersSelected },
      userContext,
      true
    )
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = name;
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove(); //afterwards we remove the element again
      });
  }

  const [openPaste, setOpenPaste] = useState(false);

  return (
    <Card
      style={{
        background: "#D8DEE9",
        padding: 10,
        // maxWidth: 2000,
        margin: "20px 10px",
        overflow: "auto",
      }}
    >
      {/* <Grid container style={{ paddingTop: 10 }}>
        <Grid item xs={6}>
          <Badge
            badgeContent={
              props.data.allEncryptedUPRN.length +
              props.data.purchasedUprns.length
            }
            color="secondary"
          >
            <HomeRoundedIcon color="action" fontSize="large" />
          </Badge>
          <div style={{ fontSize: 30, fontWeight: "bolder" }}>
            {props.data.allEncryptedUPRN.length +
              props.data.purchasedUprns.length}
          </div>
          <div>Properties found</div>
        </Grid>
       
      </Grid>
      <hr style={{ marginTop: 30, marginBottom: 0, padding: 0 }} /> */}
      <div style={{ textAlign: "right", padding: "0px 30px 10px 30px" }}></div>

      <Grid
        container
        spacing={2}
        direction="row"
        style={{ float: "left", marginLeft: 10, marginTop: 20 }}
      >
        <Button
          variant="outlined"
          style={{ float: "left", marginRight: 10 }}
          onClick={() => {
            tickAll();
          }}
        >
          Select all
        </Button>
        <Button
          variant="outlined"
          style={{ float: "left", marginRight: 10 }}
          onClick={() => {
            setTickedIds({});
          }}
        >
          Clear selected
        </Button>{" "}
        <Button
          variant="contained"
          style={{ float: "left", marginRight: 10 }}
          onClick={() => {
            getTickedString();
            setOpenPaste(true);
          }}
        >
          Create Portfolio
        </Button>
        <div style={{ float: "left", marginLeft: 10 }}>
          <CheckBoxIcon style={{ marginTop: 5 }} />
        </div>
        <div style={{ float: "left", marginTop: 5 }}>
          {Object.keys(tickedIds).length.toString()} Properties ticked
        </div>
      </Grid>
      <Button
        variant="contained"
        style={{ float: "right", marginLeft: 10, marginTop: 20 }}
        disabled={props.data.purchasedUprns.length === 0}
        onClick={() => {
          downloadCSV();
        }}
      >
        Download all filtered to CSV
      </Button>
      <TablePagination
        count={props.data.propertyDatas.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Table
        size="small"
        style={{ background: "white", borderRadius: 5, minHeight: 100 }}
      >
        <TableHead>
          <TableCell></TableCell>
          {props.data.order.map((column) => {
            return (
              //do stuff
              tooltipHeaders[column] ? (
                <TableCell>
                  <Tooltip
                    title={
                      <div style={{ fontSize: 15 }}>
                        {tooltipHeaders[column]}
                      </div>
                    }
                    placement="top"
                    arrow
                  >
                    <b>{column}</b>
                  </Tooltip>
                </TableCell>
              ) : (
                <TableCell>
                  <b>{column}</b>
                </TableCell>
              )
            );
          })}
        </TableHead>
        <TableBody>
          {props.data.propertyDatas
            .slice(currentProp, currentProp + rowsPerPage)
            .map((propertyData) => {
              return (
                <PropertyDataRow
                  key={propertyData.id}
                  order={props.data.order}
                  propertyData={propertyData}
                  ticked={tickedIds[propertyData.id] ? true : false}
                  tickId={tickId}
                  firstFetch={firstFetch}
                />
              );
            })}
        </TableBody>
      </Table>
      <TablePagination
        count={props.data.propertyDatas.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <Modal
        open={openPaste}
        onClose={() => {
          setOpenPaste(false);
        }}
      >
        <div
          style={{
            top: "50%",
            left: "50%",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            position: "absolute",
            width: "65%",
            backgroundColor: "white",
            padding: 20,
            // display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            height: "80%",
            overflow: "auto",
          }}
        >
          <h3>Add new portfolio</h3>
          <Grid container>
            <Grid item xs={12} sm={5} md={3}>
              <p style={{ padding: 5 }}>Name: </p>
            </Grid>
            <Grid item xs={12} sm={5} md={9}>
              <TextField
                style={{ width: "100%" }}
                onChange={(e) => {
                  setNewPortfolioName(e.target.value);
                }}
              />
            </Grid>

            <br />

            <Grid item xs={12} sm={5} md={3}>
              <p style={{ padding: 5 }}>
                UPRN’s to add:
                <br />
                Note: These are added automatically from those selected on the
                previous screen.
                <br />
                <br /> If you wish to manually add UPRN’s, please paste them in,
                separated by commas.
                <br />
                <br />
                For example: 100062040270, 100062034635,
                <br />
                100062034636, 100062034424,...
              </p>
            </Grid>
            <Grid item xs={12} sm={5} md={9}>
              <TextField
                id="outlined-multiline-flexible"
                label="UPRNs for portfolio"
                multiline
                // maxRows={25}
                value={portfolioUprns}
                onChange={(e) => {
                  setPorfolioUprns(e.target.value);
                }}
                style={{ width: "100%", height: "500px", overflow: "auto" }}
              />
            </Grid>

            <Grid item>
              <p>
                Number of properties to be added to portfolio:{" "}
                {uprnStringToArray().length}
              </p>
              <Button
                disabled={!enablePortfolioButton}
                size="small"
                variant="contained"
                onClick={() => {
                  if (newPortfolioName.length > 0) {
                    postNewPortfolio();
                  }
                }}
              >
                Add new portfolio
              </Button>
            </Grid>
          </Grid>
        </div>
      </Modal>
    </Card>
  );
};
interface PropertyDataRowProps {
  order: string[];
  propertyData: PropertyData;
  ticked: boolean;
  tickId: (id: string, ticked: boolean) => void;
  firstFetch: (uprn: string) => void;
}
const PropertyDataRow: React.FC<PropertyDataRowProps> = (
  props: PropertyDataRowProps
) => {
  return (
    <TableRow>
      <TableCell>
        <Checkbox
          checked={props.ticked}
          onChange={(e) => {
            props.tickId(props.propertyData.id, e.target.checked);
          }}
        />
      </TableCell>
      {props.order.map((column) => {
        return (
          <TableCell key={column}>
            {props.propertyData.data[column] === "TRUE" ? (
              <span>&#10003;</span>
            ) : column === "Address" ? (
              <Button
                style={{ textAlign: "left" }}
                onClick={() => {
                  props.firstFetch(props.propertyData.data["uprn"] ?? "");
                }}
              >
                {" "}
                {props.propertyData.data[column]}
              </Button>
            ) : (
              props.propertyData.data[column]
            )}
          </TableCell>
        );
      })}
      <TableCell></TableCell>
    </TableRow>
  );
};
