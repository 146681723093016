import {
  Button,
  Card,
  CardContent,
  Grid,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  TextField,
  Link,
  TableContainer,
  Modal,
} from "@material-ui/core";

import "./PortfolioPage.css";
import street from "../../img/street.png";
import React, { useContext, useEffect, useState } from "react";
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core/styles";
import { useHistory } from "react-router";
import { deleteData, getData } from "../get-data";
import { UserContext } from "../../contexts/UserContext";
import { numberWithCommas } from "../utilities";
import PortfolioListItem from "./PortfolioListItem";

interface IProps {
  setSelectedPortfolio: React.Dispatch<React.SetStateAction<PortfolioDetail>>;
  properties: IPropertyEPC[];
  filters: Array<IFilterObject>;
}

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      // backgroundColor: theme.palette.common.black,
      color: "black",
      fontWeight: "bold",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

export default function PortfolioPage(props: IProps) {
  const userContext = useContext(UserContext);
  const [portfolios, setPortfolios] = useState<PortfolioDetail[]>([]);
  const [deleteCheck, setDeleteCheck] = useState<boolean>(false);
  const [portfolioToDelete, setPortfolioToDelete] = useState<PortfolioDetail>(
    {} as PortfolioDetail
  );
  const history = useHistory();
  const [showEditUI, setShowEditUI] = useState<boolean>(false);
  const fetchPortfolios = () => {
    getData("https://captapi.absolarlab.com/api/portfolio", userContext).then(
      (portfolioDetails: PortfolioDetail[]) => {
        setPortfolios(portfolioDetails);
      }
    );
  };
  const handleOpenPortfolio = (portfolio: PortfolioDetail) => () => {
    event?.preventDefault();
    history.push("/portfolio_report/" + portfolio.id.toString());
    props.setSelectedPortfolio(portfolio);
  };

  useEffect(() => {
    props.setSelectedPortfolio({
      id: -1,
      name: "",
      user: "",
      numberProperties: 0,
    });
  }, []);

  const deletePortfolio = (portfolioId: number) => {
    deleteData(
      "https://captapi.absolarlab.com/api/portfolio/" + portfolioId.toString(),
      userContext
    )
      .then((res) => {
        return res.json();
      })
      .then((status) => {
        if (status === 1) {
          alert(
            "Deletion unsuccessful, the portfolio may be protected from deletion"
          );
        } else {
          fetchPortfolios();
        }
      });
  };

  function handleEdit() {
    setShowEditUI(true);
  }

  function renderModal(portfolio: PortfolioDetail) {
    setShowEditUI(true);
  }

  useEffect(() => {
    fetchPortfolios();
  }, []);

  return (
    <div className="card">
      <div className="header">
        <h3 className="headerText">Portfolio Tools</h3>
      </div>
      <hr className="solid" />
      <div id="portfolio_container">
        <Grid container justify="space-between">
          <Grid
            item
            xs={3}
            container
            justify="center"
            alignItems="center"
            style={{ textAlign: "center", background:"rgb(230, 230, 230, 0.5)" }}
          >
            <Grid item xs={10}>
              <Button
                onClick={() => {
                  history.push("/home");
                }}
                variant="contained"
                fullWidth
              >
                Create new
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={9}>
            <img src={street} style={{ width: "100%" }} />
          </Grid>
        </Grid>
      </div>
      <div id="portfolio_list_container">
        <h4>Existing portfolios</h4>
        <TableContainer
          style={{
            borderTop: "1px solid darkgrey",
            borderBottom: "1px solid darkgrey",
          }}
        >
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Portfolio Name</StyledTableCell>
                <StyledTableCell align="left">
                  Number of properties
                </StyledTableCell>
                <StyledTableCell align="left">User</StyledTableCell>
                <StyledTableCell align="left"></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {portfolios
                .filter((x) => x.user !== "Admin")
                .map((portfolio) => {
                  return (
                    <PortfolioListItem
                      portfolio={portfolio}
                      setSelectedPortfolio={props.setSelectedPortfolio}
                      setDeleteCheck={setDeleteCheck}
                      setPortfolioToDelete={setPortfolioToDelete}
                      key={portfolio.id}
                      properties={props.properties}
                      filters={props.filters}
                    />
                  );
                })}
              {portfolios
                .filter((x) => x.user === "Admin")
                .map((portfolio) => {
                  return (
                    <StyledTableRow key={portfolio.id}>
                      <StyledTableCell component="th" scope="row">
                        {portfolio.name}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {numberWithCommas(portfolio.numberProperties)}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {portfolio.user}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <Button
                          size="small"
                          onClick={handleOpenPortfolio(portfolio)}
                        >
                          OPEN
                        </Button>
                        <Button
                          style={{ backgroundColor: "#f58484", color: "#fff" }}
                          size="small"
                          onClick={() => {
                            setPortfolioToDelete(portfolio);
                            setDeleteCheck(true);
                          }}
                        >
                          Delete
                        </Button>
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}

              <StyledTableRow>
                <StyledTableCell></StyledTableCell>
                <StyledTableCell></StyledTableCell>
                <StyledTableCell></StyledTableCell>
                <StyledTableCell></StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell></StyledTableCell>
                <StyledTableCell></StyledTableCell>
                <StyledTableCell></StyledTableCell>
                <StyledTableCell></StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell></StyledTableCell>
                <StyledTableCell></StyledTableCell>
                <StyledTableCell></StyledTableCell>
                <StyledTableCell></StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell></StyledTableCell>
                <StyledTableCell></StyledTableCell>
                <StyledTableCell></StyledTableCell>
                <StyledTableCell></StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell></StyledTableCell>
                <StyledTableCell></StyledTableCell>
                <StyledTableCell></StyledTableCell>
                <StyledTableCell></StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <Modal
        open={deleteCheck}
        onClose={() => {
          setDeleteCheck(false);
        }}
      >
        <div
          style={{
            top: "50%",
            left: "50%",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            position: "absolute",
            width: "25%",
            backgroundColor: "white",
            padding: 10,
          }}
        >
          <h1>Delete Porfolio</h1>
          <p>
            Are you sure you want to delete the <b>{portfolioToDelete.name}</b>{" "}
            portfolio
          </p>
          <Button
            size="small"
            onClick={() => {
              deletePortfolio(portfolioToDelete.id);
              setDeleteCheck(false);
            }}
          >
            Delete
          </Button>
        </div>
      </Modal>
    </div>
  );
}
