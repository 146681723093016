import React from "react";
import {
  Eco,
  HomeSharp,
  ListAlt,
  Equalizer,
  WbSunny,
  Info,
  LocalGasStation
} from "@material-ui/icons";
import dashboard_icon from "../../img/dashboard-red.svg";
import "./propertyitems.css";

let PropertyMenuItems: IMenuItem[] = [

  {
    title: "< Back to Search",
    button: (
      <img
        className="dashboard_icon"
        src={dashboard_icon}
        alt="dashboard_icon"
      />
    ),
    url: "/#",
    cName: "nav-links",
  },
  {
    title: "Property details",
    button: <HomeSharp />,
    url: "/propertyoverview",
    cName: "nav-links",
  },
  {
    title: "EPC Analysis",
    button: <Eco />,
    url: "/energyperformance",
    cName: "nav-links",
  },
  {
    title: "Fuel Poverty",
    button: <LocalGasStation />,
    url: "/fueldetails",
    cName:"nav-links"
  },
  {
    title: "Solar Assessment",
    button: <WbSunny />,
    url: "/solarassessment",
    cName: "nav-links",
  },
  {
    title: "Interventions",
    button: <ListAlt />,
    url: "/interventions",
    cName: "nav-links",
  },
  {
    title: "Carbon Action plan",
    button: <Equalizer />,
    url: "/actionplan",
    cName: "nav-links",
  },
  {
    title: "Additional Info",
    button: <Info />,
    url: "/additionalinfo",
    cName: "nav-links",
  }
];

export default PropertyMenuItems;
