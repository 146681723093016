import React, { Component, useEffect, useState } from "react";
import { ApexOptions } from "apexcharts";
import Chart from "react-apexcharts";
interface IProps {
  labels: string[];
  series: number[];
}
export function DonutChart(props: IProps) {
  const [options, setOptions] = useState<ApexOptions>({ 
    labels: props.labels,
    legend: {
      show: false,
    }
  });
  const [series, setSeries] = useState<number[]>(props.series);
  useEffect(() => {
    setOptions({ labels: props.labels });
    setSeries(props.series);
  }, []);
  useEffect(() => {
    setOptions({ labels: props.labels });
    setSeries(props.series);
  }, [props.series]);
  useEffect(() => {
    setOptions({ labels: props.labels });
    setSeries(props.series);
  }, [props.labels]);
  return (
    <div className="donut">
      <Chart options={options} series={series} type="donut" width="90%"  />
    </div>
  );
}
