import React, { useEffect, useState } from "react";
import { ApexOptions } from "apexcharts";
import BuildingDensityChart from "./BuildingDensityChart";
interface IProps {
  value: number | null;
}
export default function BuildingDensityLoader(props: IProps) {
  const [series, setSeries] = useState([
    {
      name: "No of buildings",
      data: [
        142, 1865, 4162, 17352, 34706, 8070, 2250, 1201, 662, 409, 345, 171, 74,
        52,
      ],
    },
  ]);
  useEffect(() => {
    setOptions({
      chart: {
        type: "area",
        toolbar: {
          show: false,
        },
      },
      colors: [
        "#54bf58",
        "#9ee09e",
        "#fdfd97",
        "#feb144",
        "#FF6653",
        "#CC99C9",
      ],
      title: { text: "Building height" },
      plotOptions: {
        bar: {
          horizontal: true,
          distributed: true,
          barHeight: "100%",
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      stroke: {
        width: 1,
        colors: ["#fff"],
      },
      yaxis: {
        // min: 0,
        // max: 30,
        // show: false,
      },
      xaxis: {
        categories: [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
        labels: {
          style: {
            colors: [
              "#54bf58",
              "#9ee09e",
              "#fdfd97",
              "#feb144",
              "#FF6653",
              "#CC99C9",
            ],
            fontSize: "12px",
          },
          show: false,
        },
      },
      annotations: {
        yaxis: [
          {
            y: props.value ? Math.round(props.value) : 10,
            borderColor: "#f29305",
            label: {
              borderColor: "#f29305",
              style: {
                color: "#fff",
                background: "#f29305",
              },
              text: props.value
                ? "This building: " + props.value.toString() + " m"
                : "unknown",
              position: "right",
            },
          },
        ],
      },
    });
  }, [props.value]);
  const [options, setOptions] = useState<ApexOptions>({
    chart: {
      type: "bar",
      // height: 500,
    },
    colors: ["#54bf58", "#9ee09e", "#fdfd97", "#feb144", "#FF6653", "#CC99C9"],
    plotOptions: {
      bar: {
        horizontal: true,
        distributed: true,

        // barHeight: "100%",
        dataLabels: {
          position: "bottom",
        },
      },
    },
  });

  return <BuildingDensityChart options={options} series={series} />;
}
