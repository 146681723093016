import React, { useEffect, useState } from "react";
import "./App.css";
import {
  Button,
  Card,
  CardContent,
  Grid,
  makeStyles,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
} from "@material-ui/core";
import InterventionsPage from "./components/InterventionsPage/InterventionsPage";
import ActionPlanPage from "./components/ActionPlanPage/ActionPlanPage";
import PropertyOverviewPage from "./components/PropertyOverviewPage/PropertyOverviewPage";
import EnergyEfficiencyPage from "./components/EnergyEfficiencyPage/EnergyEfficiencyPage";
import KeyAssumptionsPage from "./components/KeyAssumptionsPage/KeyAssumptionsPage";
import LandingPage from "./components/LandingPage/SearchPage";
import PortfolioPage from "./components/PortfolioPage/PortfolioPage";
import HomePage from "./components/LandingPage/Home";
import StatPage from "./components/LandingPage/StatisticsPage";
import AdditionalInfoPage from "./components/AdditionalInfoPage/AdditionalInfoPage";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useParams,
  useHistory,
} from "react-router-dom";
import { SmallNavbar } from "./components/NavBar/navbar-small";
import PowerBIPage from "./components/PowerBI/PowerBIPage";
import PageNotFound from "./components/PageNotFound/PageNotFoundPage";
import VisualisationPage from "./components/Visualisations/VisualisationPage";
import SolarAssessmentPage from "./components/PropertyOverviewPage/SolarAssessment/SolarAssessment";
import FuelDetailsPage from "./components/FuelDetailsPage/FuelDetailsPage";
import PortfolioActionPlanPage from "./components/ActionPlanPage/PortfolioActionPlanPage";
import { UserContext } from "./contexts/UserContext";
import { LogInPage } from "./components/Profile/login-page";
import { getData } from "./components/get-data";
import PortfolioInterventionsPage from "./components/InterventionsPage/PortfolioInterventionsPage";
import PortfolioSandboxing from "./components/PortfolioSandboxing/PortfolioSandboxing";
import LoadingOverlay from "react-loading-overlay-ts";
import useGaTracker from "./useGaTracker";
import { PropertyFilter } from "./components/Filtering/PropertyFilter";
import CostAssumptionsPage from "./components/KeyAssumptionsPage/CostAssumptionsPage";

interface IParams {
  id: string | undefined;
}

function App() {
  const [x, setX] = useState(-100);
  const [y, setY] = useState(-100);
  const [selectedProperty, setSelectedProperty] = useState<IProperty>(
    {} as IProperty
  );
  const [basicData, setBasicData] = useState<BasicData>({
    organisation: "Loading...",
    propertiesLoaded: 0,
    averageEpcRating: 0,
    averageEpcRatingLetter: "",
    totalCo2Emissions: 0,
  });
  const [dashboardData, setDashboardData] = useState<DashboardData | undefined>(
    undefined
  );
  const [epcStats, setEpcStats] = useState<EpcStats | undefined>(undefined);
  const [propertyDetails, setPropertyDetails] = useState<IPropertyDetails>(
    {} as IPropertyDetails
  );
  const [tariffs, setTariffs] = useState<Array<tariff>>([]);
  const [interventions, setInterventions] = useState<Array<Intervention>>([]);
  const [interventionsLoaded, setInterventionsLoaded] =
    useState<boolean>(false);
  const [tariffChanges, setTariffChanges] = useState<Array<TariffChange>>([]);
  const [isSingleProperty, setSingleProperty] = useState(false);
  const [isPortfolio, setIsPortfolio] = useState(false);
  const [isPortfolioReport, setIsPortfolioReport] = useState(false);
  const [properties, setProperties] = useState<IPropertyEPC[]>([]);
  const [propertyId, setPropertyId] = useState<number>();
  const [reloaded, setReloaded] = useState<boolean>(false);
  const [filters, setFilters] = useState<Array<IFilterObject>>([]);
  const [userState, setUserState] = useState<UserState>({
    idToken: "",
    refreshToken: "",
    accessToken: "",
    username: "",
    loggedIn: false,
  });
  const [userGroup, setUserGroup] = useState<string>("");
  const [portfolioDataLoading, setPortfolioDataLoading] =
    useState<boolean>(false);
  const [propertyDataLoading, setPropertyDataLoading] = useState<boolean>(true);
  const [homeDataLoading, setHomeDataLoading] = useState<boolean>(true);
  const history = useHistory();

  const [readySearch, setReadySearch] = useState<boolean>(true);

  const range = (start: number, stop: number, step: number) =>
    Array.from(
      { length: (stop - start) / step + 1 },
      (_, i) => start + i * step
    );

  const reload = (id?: number) => {
    if (id) {
      setPropertyId(id);
      fetchTariffs();
      fetchFilters();
      let found = properties.find(
        (property) => property.property_id === propertyId
      );
      if (found) {
        setSelectedProperty(found);
      }
      return (fetchPropertyDetails(id));

    }else{ 
      return null;
    }
  };

  useEffect(() => {
    if (Object.keys(selectedProperty).length === 0) {
      let found = properties.find(
        (property) => property.property_id === propertyId
      );
      if (found) {
        setSelectedProperty(found);
      }
    }
  }, [properties]);

  useEffect(() => {
    if (userState.loggedIn) {
      fetchProperties();
    }
  }, [propertyId]);

  useEffect(() => {
    if (userState.loggedIn) {
      if (
        interventions.length === 0 &&
        Object.keys(selectedProperty).length !== 0
      ) {
        fetchInterventions(selectedProperty);
      }
      if (
        tariffChanges.length === 0 &&
        Object.keys(selectedProperty).length !== 0
      ) {
        fetchTariffChanges(selectedProperty.property_id);
      }
    }
  }, [selectedProperty]);

  useEffect(() => {
    if (userState.loggedIn) {
      if (
        !(properties.length === 0) &&
        !(Object.keys(propertyDetails).length === 0)
      ) {
        fetchPropertyDetails(selectedProperty.property_id);
        if (!(tariffs.length === 0) && !(interventions.length === 0)) {
          setReloaded(true);
        }
      }
    }
  }, [selectedProperty]);

  const fetchProperties = () => {
    setPropertyDataLoading(true);
    setReadySearch(false);
    const url = "/api/sap/propertieswithepc";
    getData("https://captapi.absolarlab.com" + url, {
      userState: userState,
      setUserState: setUserState,
    })
      .then((list) => {
        setProperties(list);
        setReadySearch(true);
      })
      .then(() => {
        setPropertyDataLoading(false);
      });
  };

  const fetchPropertyDetails = (property_id: number) => {
    const url = "/api/sap/propertysap/?propertyID=" + property_id;
    return getData(
      "https://captapi.absolarlab.com/api/sap/propertysap?propertyID=" +
        property_id,
      { userState: userState, setUserState: setUserState }
    )
      .then((data) => {
        data["property_id"] = property_id;
        setPropertyDetails(data);
      });
  };
  const fetchBasicData = () => {
    const url = "/api/sap/basicdata";
    getData("https://captapi.absolarlab.com" + url, {
      userState: userState,
      setUserState: setUserState,
    }).then((basicData: BasicData) => {
      setBasicData(basicData);
    });
  };
  const fetchDashboardData = () => {
    setHomeDataLoading(true);

    const url = "/api/portfolio/homepagedata";
    getData("https://captapi.absolarlab.com" + url, {
      userState: userState,
      setUserState: setUserState,
    })
      .then((dashboardData: DashboardData) => {
        setDashboardData(dashboardData);
      })
      .then(() => {
        setHomeDataLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });

    const statsUrl = "/api/portfolio/epcstats";
    getData("https://captapi.absolarlab.com" + statsUrl, {
      userState: userState,
      setUserState: setUserState,
    }).then((epcStats: EpcStats) => {
      setEpcStats(epcStats);
    });
  };
  const fetchTariffs = () => {
    const url = "/api/sap/tariffs";
    getData("https://captapi.absolarlab.com" + url, {
      userState: userState,
      setUserState: setUserState,
    }).then((tariffs) => {
      1;
      setTariffs(tariffs);
    });
  };
  const fetchTariffChanges = (propertyId: number) => {
    const url = "/api/sap/tariff_changes?propertyId=" + propertyId;
    getData("https://captapi.absolarlab.com" + url, {
      userState: userState,
      setUserState: setUserState,
    }).then((list: Array<TariffChange>) => {
      setTariffChanges(list);
    });
  };
  const fetchInterventions = (property: IProperty, customTariff?: number) => {
    setInterventionsLoaded(false);

    let url = "/api/sap/interventions?propertyId=" + property.property_id;
    if (customTariff) {
      url += "&customTariff=" + customTariff.toString();
    }
    getData("https://captapi.absolarlab.com" + url, {
      userState: userState,
      setUserState: setUserState,
    }).then((list) => {
      setInterventions(list);
      setInterventionsLoaded(true);
    }).catch((e)=>{console.log("interventions not found")});
  };

  const fetchFilters = () => {
    const url = "/api/sap/filters";

    getData("https://captapi.absolarlab.com" + url, {
      userState: userState,
      setUserState: setUserState,
    }).then((filters: Array<IFilterObject>) => {
      setFilters(filters);
    });
  };
  const [co2DataPlanned, setCo2DataPlanned] = useState<number[]>([]);
  const [co2DataDone, setCo2DataDone] = useState<number[]>([]);
  const [co2DataDefault, setCo2DataDefault] = useState<number[]>([]);
  const [yearRange, setYearRange] = useState<number[]>([]);

  const [loadingCO2, setLoadingCO2] = useState<boolean>(false);

  const fetchTimeDataCO2 = () => {
    setLoadingCO2(true);

    const defaultUrl = "/api/sap/carbonkpichartdata?start=2012&end=2050";
    getData("https://captapi.absolarlab.com/api/sap/carbonpropertycount", {
      userState: userState,
      setUserState: setUserState,
    }).then((propertyCount: number) => {

    
    getData("https://captapi.absolarlab.com" + defaultUrl, {
      userState: userState,
      setUserState: setUserState,
    })
      .then((carbon: ITimeDataCO2) => {
        //years
        const years = Object.keys(carbon.planned);
        const startYear = parseInt(years[0]);
        const endYear = parseInt(years[years.length - 1]);
        setYearRange(range(startYear, endYear, 1));
        //default
        let co2Array = Object.values(carbon.default);
        let roundedCo2Array = co2Array.map(
          (val) => Math.round(propertyCount * ((val + Number.EPSILON) * 100) / 100)
        );
        setCo2DataDefault(roundedCo2Array);
        //done
        co2Array = Object.values(carbon.done);
        roundedCo2Array = co2Array.map(
          (val) =>  Math.round(propertyCount * ((val + Number.EPSILON) * 100) / 100)
        );
        setCo2DataDone(roundedCo2Array);
        //planned
        co2Array = Object.values(carbon.planned);
        roundedCo2Array = co2Array.map(
          (val) => Math.round( propertyCount * ((val + Number.EPSILON) * 100) / 100)
        );
        setCo2DataPlanned(roundedCo2Array);
        setLoadingCO2(false);
      })
      .catch((e) => {
        console.log("missing emission projections");
      });
    })
  };

  useEffect(() => {
    if (userState.loggedIn) {
      fetchProperties();
      fetchTariffs();
      fetchBasicData();
      fetchDashboardData();
      fetchTimeDataCO2();

      useGaTracker();
    }
  }, []);

  useEffect(() => {
    if (userState.loggedIn) {
      fetchProperties();
      fetchTariffs();
      fetchBasicData();
      fetchDashboardData();
      fetchTimeDataCO2();
    }
  }, [userState.loggedIn]);

  const [selectedPortfolio, setSelectedPortfolio] = useState<PortfolioDetail>({
    id: -1,
    name: "",
    user: "",
    numberProperties: 0,
  });

  const [portfolioInterventionsDto, setPortfolioInterventionsDto] =
    useState<PortfolioInterventionsDto>({
      emissions: 0,
      currentEmissions: 0,
      numberProperties: 0,
      portfolioInterventionsDtos: [],
      doneInterventionsDtos: [],
    });
  const [portfolioCategoryStats, setPorfolioCategoryStats] =
    useState<CategoryData>({});
  const [portfolioMapProperties, setPortfolioMapProperties] = useState<
    IPropertyEPC[]
  >([]);
  const getPortfolioInterventions = (customTariff?: number) => {
    setPortfolioDataLoading(true);
    let url = "";
    if (customTariff) {
      url += "&customTariff=" + customTariff.toString();
    }
    getData(
      "https://captapi.absolarlab.com/api/portfolio/interventions?portfolioId=" +
        selectedPortfolio.id +
        url,
      { userState, setUserState }
    )
      .then((portfolioInterventionsDto: PortfolioInterventionsDto) => {
        setPortfolioInterventionsDto(portfolioInterventionsDto);
      })
      .then(() => {
        setPortfolioDataLoading(false);
      });
  };
  const getPortfolioProperties = () => {
    getData(
      "https://captapi.absolarlab.com/api/portfolio/" +
        selectedPortfolio.id +
        "/properties",
      { userState, setUserState }
    ).then((portfolioMapData: IPropertyEPC[]) => {
      setPortfolioMapProperties(portfolioMapData);
      setPortfolioDataLoading(false);
    });
  };
  const getPortfolioStats = () => {
    getData(
      "https://captapi.absolarlab.com/api/portfolio/" +
        selectedPortfolio.id +
        "/propertycategorystats",
      { userState, setUserState }
    ).then((portfolioStats: CategoryData) => {
      setPorfolioCategoryStats(portfolioStats);
    });
  };
  const getPortfolioData = (id?: number) => {
    if (id) {
      const url = "/api/portfolio/portfolioInfo?portfolioId=" + id.toString();

      getData("https://captapi.absolarlab.com" + url, {
        userState,
        setUserState,
      }).then((portfolio: PortfolioDetail) => {
        setSelectedPortfolio(portfolio);
      });
    }
  };

  useEffect(() => {
    if (selectedPortfolio.id != -1) {
      setPortfolioDataLoading(true);
      setPortfolioInterventionsDto({
        emissions: 0,
        currentEmissions: 0,
        numberProperties: 0,
        portfolioInterventionsDtos: [],
        doneInterventionsDtos: [],
      });
      getPortfolioInterventions();
      getPortfolioStats();
      getPortfolioProperties();
    }
  }, [selectedPortfolio.id]);

  let sampleData = {
    co2: 676,
    panel_count: 11,
    radiationUprn: "",
  };

  return (
    <UserContext.Provider
      value={{ userState: userState, setUserState: setUserState, userGroup: userGroup}}
    >
      <div className="baseDiv_new">
        {userState.loggedIn && userGroup != "" ? (
          <Router>
            <SmallNavbar
              propertyDetails={propertyDetails}
              selectedProperty={selectedProperty}
              selectedPortfolio={selectedPortfolio}
              isSingleProperty={isSingleProperty}
              setSingleProperty={setSingleProperty}
              isPortfolio={isPortfolio}
              isPortfolioReport={isPortfolioReport}
              setIsPortfolio={setIsPortfolio}
              setIsPortfolioReport={setIsPortfolioReport}
              userGroup={userGroup}
            />
            <Switch>
              <Route path="/home">
                <PropertyFilter
                    setSelectedProperty={setSelectedProperty}
                    fetchInterventions={fetchInterventions}
                    fetchPropertyDetails={fetchPropertyDetails}
                  />
            
              </Route>
              <Route path="/statistics">
                <StatPage
                  homeDataLoading={homeDataLoading}
                  basicData={basicData}
                  dashboardData={dashboardData}
                  epcStats={epcStats}
                  co2DataDefault={co2DataDefault}
                  co2DataDone={co2DataDone}
                  co2DataPlanned={co2DataPlanned}
                  yearRange={yearRange}
                  loading={loadingCO2}
                  group={userGroup}
                />
              </Route>
              <Route path="/search">
                <LandingPage
                  readySearch={readySearch}
                  organisation={basicData.organisation}
                  setSelectedProperty={setSelectedProperty}
                  selectedProperty={selectedProperty}
                  fetchPropertyDetails={fetchPropertyDetails}
                  fetchInterventions={fetchInterventions}
                  setSingleProperty={setSingleProperty}
                  fetchProperties={fetchProperties}
                  properties={properties}
                  filters={filters}
                />
              </Route>
              <Route path="/portfolio">
                <PortfolioPage
                  setSelectedPortfolio={setSelectedPortfolio}
                  properties={properties}
                  filters={filters}
                />
              </Route>
              <Route path="/portfolio_report/:id">
                <LoadingOverlay active={portfolioDataLoading} spinner>
                  <VisualisationPage
                    setSelectedPortfolio={setSelectedPortfolio}
                    portoflioMapProperties={portfolioMapProperties}
                    portfolioCategoryStats={portfolioCategoryStats}
                    selectedPortfolio={selectedPortfolio}
                    getPortfolio={getPortfolioData}
                  />
                </LoadingOverlay>
              </Route>
              <Route path="/portfolio_interventions/:id">
                <PortfolioInterventionsPage
                  portfolioDataLoading={portfolioDataLoading}
                  selectedPortfolio={selectedPortfolio}
                  portfolioInterventionsDto={portfolioInterventionsDto}
                  setPortfolioInterventionsDto={setPortfolioInterventionsDto}
                  tariffs={tariffs}
                  getPortfolioInterventions={getPortfolioInterventions}
                  getPortfolio={getPortfolioData}
                />
              </Route>
              <Route path="/portfolio_mictomac/:id">
                <PortfolioActionPlanPage
                  selectedPortfolio={selectedPortfolio}
                  portfolioInterventionsDto={portfolioInterventionsDto}
                  getPortfolio={getPortfolioData}
                />
              </Route>
              <Route path="/portfolio_sandbox/:id">
                <PortfolioSandboxing
                  selectedPortfolio={selectedPortfolio}
                  portfolioInterventionsDto={portfolioInterventionsDto}
                  getPortfolio={getPortfolioData}
                />
              </Route>
              <Route path="/assumptions">
                  <Card className="card" style={{ backgroundColor: "#113d64" }}>
                    <CardContent className="header">
                      <h3 className="headerText">Assumptions</h3>
                    </CardContent>
                    <hr className="solid" />
                    <CardContent className="assumptionsContent">
                      <CostAssumptionsPage />

                      <KeyAssumptionsPage
                        fetchTariffs={fetchTariffs}
                        tariffs={tariffs}
                        group={userGroup}
                      />
                    </CardContent>
                  </Card>
              
              </Route>
              <Route path="/propertyoverview/:id">
                <PropertyOverviewPage
                  propertyDataLoading={propertyDataLoading}
                  selectedProperty={selectedProperty}
                  propertyDetails={propertyDetails}
                  fetchPropertyDetails={fetchPropertyDetails}
                  fetchProperties={fetchProperties}
                  properties={properties}
                  setSelectedProperty={setSelectedProperty}
                  setSingleProperty={setSingleProperty}
                  reload={reload}
                />
              </Route>
              <Route path="/energyperformance/:id">
                <EnergyEfficiencyPage
                  propertyDetails={propertyDetails}
                  tariffs={tariffs}
                  setSingleProperty={setSingleProperty}
                  reload={reload}
                  reloaded={reloaded}
                  epcStats={epcStats}
                />
              </Route>
              <Route path="/solarassessment/:id">
                <SolarAssessmentPage
                  propertyDataLoading={propertyDataLoading}
                  selectedProperty={selectedProperty}
                  setSingleProperty={setSingleProperty}
                  reload={reload}
                  saving_1yr={300}
                  data={sampleData}
                  image={""}
                  pvPriceLow={2000}
                  pvPriceHigh={3000}
                  years={12}
                  kw={1.3}
                  noPanels={4}
                  utilisedRoofSpace={6}
                />
              </Route>
              <Route path="/fueldetails/:id">
                <FuelDetailsPage
                  propertyDetails={propertyDetails}
                  setSingleProperty={setSingleProperty}
                  reload={reload}
                />
              </Route>
              <Route path="/reporting/:id">
                <PowerBIPage organisation={basicData.organisation} />
              </Route>
              <Route path="/actionplan/:id">
                <ActionPlanPage
                  selectedProperty={selectedProperty}
                  interventionList={interventions}
                  tariffChanges={tariffChanges}
                  setSingleProperty={setSingleProperty}
                  setInterventions={setInterventions}
                  setTariffChanges={setTariffChanges}
                  fetchTariffChanges={fetchTariffChanges}
                  reload={reload}
                  tariffs={tariffs}
                />
              </Route>
              <Route path="/interventions/:id">
                <InterventionsPage
                  interventionsLoaded={interventionsLoaded}
                  interventionList={interventions}
                  setInterventions={setInterventions}
                  propertyDetails={propertyDetails}
                  setSingleProperty={setSingleProperty}
                  reload={reload}
                  reloaded={reloaded}
                  tariffs={tariffs}
                  fetchTariffChanges={fetchTariffChanges}
                  fetchInterventions={fetchInterventions}
                  selectedProperty={selectedProperty}
                />
              </Route>
              <Route path="/additionalinfo/:id">
                <AdditionalInfoPage
                  selectedProperty={selectedProperty}
                  setSingleProperty={setSingleProperty}
                  reload={reload}
                />
              </Route>
              <Route path="/404">
                {/* phil to finish */}
                <PageNotFound />
              </Route>
              <Route path="/">
                <Redirect
                  to= "/home" 
                />
              </Route>
            </Switch>
          </Router>
        ) : (
          <LogInPage setUserGroup={setUserGroup} redirected={false} />
        )}
      </div>
    </UserContext.Provider>
  );
}

export default App;
