import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import { Grid } from "@material-ui/core";
import { useStyles } from "./navbar-small-styles";
import { useHistory } from "react-router-dom";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem, { ListItemProps } from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import "./navbar-small.css";
import "./propertyitems.css";
import MenuItems from "./MenuItems";
import PropertyMenuItems from "./PropertyMenuItems";
import PortfolioMenuItems from "./PortfolioMenuItems";
//import PortfolioReportMenu from "./PortfolioReportMenu";

import { useEffect } from "react";
import { useLocation } from "react-router";
export interface IProps {
  selectedProperty: IProperty;
  propertyDetails: IPropertyDetails;
  selectedPortfolio: PortfolioDetail;
  isSingleProperty: boolean;
  setSingleProperty: React.Dispatch<React.SetStateAction<boolean>>;
  isPortfolio: boolean;
  isPortfolioReport: boolean;
  setIsPortfolio: React.Dispatch<React.SetStateAction<boolean>>;
  setIsPortfolioReport: React.Dispatch<React.SetStateAction<boolean>>;
  userGroup: string;
}

export const SmallNavbar: React.FC<IProps> = (props: IProps) => {
  const classes = useStyles();
  const location = useLocation();
  const [clicked, setClicked] = React.useState(false);

  const handleClicking = (event: React.MouseEvent<HTMLElement>) => {
    setClicked(!clicked);
  };

  const history = useHistory();
  const [menuItems, setMenuItems] = useState(MenuItems);

  useEffect(() => {
    if (location.pathname === "/portfolio_report") {
      props.setIsPortfolioReport(true);
    }
  }, [location.pathname]);

  const handleClick = (item: IMenuItem) => () => {
    if (props.isSingleProperty) {
      if (item.title === "< Back to Search") {
        history.push(item.url);
        props.setSingleProperty(false);
      } else {
        history.push(item.url + "/" + props.propertyDetails.property_id);
      }
    } else if (props.isPortfolio) {
      if (item.title === "< Back to Search") {
        history.push(item.url);
        props.setIsPortfolio(false);
      } else {
        history.push(item.url + "/" + props.selectedPortfolio.id);
      }
    } else {
      if (item.title === "Portfolio HQ") {
        history.push(item.url);
      } else {
        history.push(item.url);
      }
    }
  };

  useEffect(() => {
    if (props.isSingleProperty) {
      setMenuItems(PropertyMenuItems);
    } else {
      setMenuItems(MenuItems);
    }
  }, [props.isSingleProperty]);

  useEffect(() => {
    if (props.isPortfolio) {
      let menuItems = PortfolioMenuItems;
      if (props.userGroup === "gosport") {
        menuItems.pop();
      }
      setMenuItems(menuItems);
    } else {
      setMenuItems(MenuItems);
    }
  }, [props.isPortfolio]);

  useEffect(() => {
    if (
      location.pathname.includes("portfolio") &&
      !(location.pathname === "/portfolio")
    ) {
      props.setIsPortfolio(true);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (location.pathname === "/portfolio") {
      setMenuItems(MenuItems);
    }
  }, [location.pathname]);

  return (
    <div>
      <Drawer variant="permanent" anchor="left" style={{ width: "240px" }}>
        <div style={{ width: "240px", height: "100%", background: "#001a3d" }}>
          <div className="menu-icon" onClick={handleClicking}>
            <i className={clicked ? "fas fa-times" : "fas fa-bars"}></i>
          </div>
          <Button
            style={{ padding: 16, textAlign: "left" }}
            onClick={() => {
              history.push("/");
              props.setSingleProperty(false);
              props.setIsPortfolio(false);
              props.setIsPortfolioReport(false);
            }}
          >
            <Grid container>
              <Grid item xs={8}>
                <h4 style={{ color: "white" }}>Carbon Action Planning Tool</h4>
              </Grid>
              <Grid
                item
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  width: "24px",
                }}
              >
                <div id="beta-container">
                  <div id="beta">BETA</div>
                </div>
              </Grid>
            </Grid>
          </Button>
          <List>
            {menuItems.map((item, index) => {
              let isPath = location.pathname.includes(item.url);
              return (
                <ListItem
                  style={{
                    color: "white",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    backgroundColor: isPath ? "#205a81" : "#001a3d",
                  }}
                  button
                  key={index}
                  onClick={handleClick(item)}
                >
                  <ListItemIcon style={{ color: "white" }}>
                    {item.button}
                  </ListItemIcon>
                  <ListItemText primary={item.title} />
                </ListItem>
              );
            })}
          </List>
        </div>
      </Drawer>
    </div>
  );
};
