import "./interventionspage.css";
import {
  Button,
  Card,
  CardContent,
  Grid,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Checkbox,
  Switch,
} from "@material-ui/core";
import WaterfallLoader from "../Waterfall/WaterfallLoader";
import LoadingOverlay from "react-loading-overlay-ts";
import pound from "../../img/pound-sterling.svg";
import i_symbol from "../../img/info-white.svg";
import checked from "../../img/checked-white.svg";
import Intervention from "../ActionPlanPage/Intervention";
import { SmallNavbar } from "../NavBar/navbar-small";
import React, { useState, useEffect, useContext } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Input from "@material-ui/core/Input";
import Modal from "@material-ui/core/Modal";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import { useHistory, useParams } from "react-router-dom";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { UserContext } from "../../contexts/UserContext";
import { deleteData, getData, postData, putData } from "../get-data";
import DoneIntervention from "./DoneInterventionRow";

interface IProps {
  interventionsLoaded: boolean;
  interventionList: Array<Intervention>;
  propertyDetails: IPropertyDetails;
  setSingleProperty: React.Dispatch<React.SetStateAction<boolean>>;
  reload: (id?: number) => Promise<void> | null;
  reloaded: boolean;
  setInterventions: (value: React.SetStateAction<Intervention[]>) => void;
  tariffs: Array<tariff>;
  fetchTariffChanges: (propertyId: number) => void;
  fetchInterventions: (property: IProperty, customTariff?: number) => void;
  selectedProperty: IProperty;
}

interface IParams {
  id: string | undefined;
}

export default function InterventionsPage(props: IProps) {
  const userContext = useContext(UserContext);
  function numberWithCommas(x: number | string) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  function getEnergyCategory(id: number, energyCategory: number) {
    const heatingIds = [7, 10, 11, 16, 22, 32, 31];
    if (heatingIds.includes(id)) {
      if (energyCategory === 1) {
        return " Heating";
      } else if (energyCategory === 3) {
        return " Hot water";
      } else {
        return "";
      }
    } else {
      return "";
    }
  }
  const [planned, setPlanned] = useState<Map<string, number>>(
    new Map<string, number>()
  );
  const [energyCategories, setEnergyCategories] = useState<
    Array<EnergyCategory>
  >([]);
  const [addingIntervention, setAddingIntervention] = useState<boolean>(false);
  const [addInterventionModal, setAddInterventionModal] =
    useState<boolean>(false);
  const [newIntervention, setNewIntervention] = useState<string>("");
  const [newKwhImpact, setNewKwhImpact] = useState<number>(0);
  const [newInstallCost, setNewInstallCost] = useState<number>(0);
  const [energyCategory, setEnergyCategory] = useState<EnergyCategory>({
    id: -1,
    name: "Please Select One",
  });
  const [energyTariff, setEnergyTariff] = useState<tariff>({
    ...({} as tariff),
    fuel: "Select a Tariff",
    id: -1,
  });

  const [interventionTypes, setInterventionTypes] = useState<
    Array<InterventionType>
  >([]);
  const [fuelChange, setFuelChange] = useState<boolean>(false);
  const [interventionReduces, setInterventionReduces] = useState<boolean>(true);
  const { id } = useParams<IParams>();
  const [toggleSap, setToggleSap] = useState<boolean>(false);
  const [toggleCustomTariff, setToggleCustomTariff] = useState<boolean>(false);
  const [editing, setEditing] = useState<boolean>(false);
  const [toReload, setToReload] = useState<boolean>(false);
  const history = useHistory();
  const editIntervention = (
    id: number,
    newInstallCost: number,
    newKwh: number
  ) => {
    const url =
      "/api/sap/intervention?interventionId=" +
      id +
      "&installCost=" +
      newInstallCost +
      "&kwhImpact=" +
      newKwh;
    putData(
      "https://captapi.absolarlab.com" + url,
      undefined,
      userContext
    ).then(() => {
      if (toggleCustomTariff) {
        props.fetchInterventions(props.selectedProperty, 35);
      } else {
        props.fetchInterventions(props.selectedProperty);
      }
    });
  };

  const deleteIntervention = (interventionId: number) => {
    const url = "/api/sap/intervention?interventionId=" + interventionId;
    deleteData("https://captapi.absolarlab.com" + url, {
      userState: userContext.userState,
      setUserState: userContext.setUserState,
    }).then(() => {
      let newInterventionList = props.interventionList.filter(
        (x) => x.id !== interventionId
      );
      props.setInterventions(newInterventionList);
    });
  };

  const fetchInterventionTypes = () => {
    const url = "/api/sap/interventionlist";
    getData("https://captapi.absolarlab.com" + url, {
      userState: userContext.userState,
      setUserState: userContext.setUserState,
    }).then((interventionTypes: Array<InterventionType>) => {
      setInterventionTypes(interventionTypes);
    });
  };
  const fetchEnergyCategories = () => {
    const url = "/api/sap/energycategories";

    getData("https://captapi.absolarlab.com" + url, {
      userState: userContext.userState,
      setUserState: userContext.setUserState,
    }).then((energyCategories: Array<EnergyCategory>) => {
      setEnergyCategories(energyCategories);
    });
  };
  const totalCo2 = () => {
    if (toggleSap) {
      return props.interventionList
        .filter((x) => x.doneYear === -1)
        .reduce((sum, x) => sum + x.co2Impact, 0);
    } else {
      return props.interventionList
        .filter((x) => x.doneYear === -1)
        .reduce((sum, x) => sum + x.currentCo2Impact, 0);
    }
  };
  const totalFuelCost = () => {
    if (toggleSap) {
      return props.interventionList
        .filter((x) => x.doneYear === -1)
        .reduce((sum, x) => sum + x.costSavings, 0)
        .toFixed(2);
    } else {
      return props.interventionList
        .filter((x) => x.doneYear === -1)
        .reduce((sum, x) => sum + x.currentCostSavings, 0)
        .toFixed(2);
    }
  };
  const postNewIntervention = (data: {
    scenarioId: number;
    propertyId: number;
    interventionName: string;
    plannedYear: number;
    installCost: number;
    kwhImpact: number;
    tariffChange: boolean;
    energyCategoryId: number;
  }) => {
    const url = "/api/sap";
    postData("https://captapi.absolarlab.com" + url, data, {
      userState: userContext.userState,
      setUserState: userContext.setUserState,
    }).then((intervention: Intervention) => {
      props.interventionList.push(intervention);
      setAddInterventionModal(false);
      setAddingIntervention(false);
      setNewInstallCost(0);
      setNewKwhImpact(0);
    });
  };

  const postTariffChange = (data: {
    propertyId: number;
    year: number;
    fuelTariffId: number;
    energyCategoryId: number;
  }) => {
    postData(
      "https://captapi.absolarlab.com/api/sap/tariff_plan?propertyId=" +
        id +
        "&fuelTariffId=" +
        data.fuelTariffId +
        "&year=" +
        data.year +
        "&energyCategoryId=" +
        data.energyCategoryId,
      undefined,
      userContext
    ).then(() => {
      props.fetchTariffChanges(parseInt(id!));
    });
  };
  useEffect(() => {
    if (toggleSap) {
      if (props.interventionList.length !== 0) {
        setPlanned(
          new Map<string, number>(
            props.interventionList
              .filter((x) => x.doneYear === -1)
              .map((i) => [
                i.interventionName +
                  getEnergyCategory(i.captRecId, i.energyCategoryId),
                -i.co2Impact,
              ])
          )
        );
      }
    } else {
      if (props.interventionList.length !== 0) {
        setPlanned(
          new Map<string, number>(
            props.interventionList
              .filter((x) => x.doneYear === -1)
              .map((i) => [
                i.interventionName +
                  getEnergyCategory(i.captRecId, i.energyCategoryId),
                -i.currentCo2Impact,
              ])
          )
        );
      }
    }
  }, [props.interventionList]);
  useEffect(() => {
    if (toggleSap) {
      if (props.interventionList.length !== 0) {
        setPlanned(
          new Map<string, number>(
            props.interventionList
              .filter((x) => x.doneYear === -1)
              .map((i) => [
                i.interventionName +
                  " " +
                  getEnergyCategory(i.captRecId, i.energyCategoryId),
                -i.co2Impact,
              ])
          )
        );
      }
    } else {
      if (props.interventionList.length !== 0) {
        setPlanned(
          new Map<string, number>(
            props.interventionList
              .filter((x) => x.doneYear === -1)
              .map((i) => [
                i.interventionName +
                  " " +
                  getEnergyCategory(i.captRecId, i.energyCategoryId),
                -i.currentCo2Impact,
              ])
          )
        );
      }
    }
  }, [toggleSap]);

  useEffect(() => {
    if (!props.interventionsLoaded) {
      setToReload(true);
      props.reload(parseInt(id!))?.catch((e) => {
        history.push("/404");
      });
    }
    props.setSingleProperty(true);
    fetchInterventionTypes();
    fetchEnergyCategories();
  }, []);

  useEffect(() => {
    if (props.interventionsLoaded) {
      setToReload(false);
    }
  }, [props.interventionsLoaded]);

  function setAsDone(
    propertyId: number,
    doneYear: string,
    doneKwhImpact: string,
    doneInstallCost: string
  ) {
    const url =
      "/api/sap/intervention_done_year" +
      "?propertyInterventionId=" +
      id +
      "&doneYear=" +
      doneYear +
      "&kwhImpact=" +
      doneKwhImpact +
      "&cost=" +
      doneInstallCost;
    putData("https://captapi.absolarlab.com" + url, {}, userContext).then(
      () => {
        if (toggleCustomTariff) {
          props.fetchInterventions(props.selectedProperty, 35);
        } else {
          props.fetchInterventions(props.selectedProperty);
        }
      }
    );
  }
  useEffect(() => {
    if (props.selectedProperty.property_id) {
      if (toggleCustomTariff) {
        props.fetchInterventions(props.selectedProperty, 35);
      } else {
        props.fetchInterventions(props.selectedProperty);
      }
    }
  }, [toggleCustomTariff]);
  return (
    <>
      <Card className="card" style={{ backgroundColor: "#113d64" }}>
        <LoadingOverlay active={toReload} spinner text="Loading...">
          <CardContent className="header">
            <h3 className="headerText">
              {props.propertyDetails.single_line_address}
            </h3>
          </CardContent>
          <hr className="solid" />
          <CardContent
            className="interventionsContent"
            style={{ paddingTop: "0px", paddingBottom: "0px" }}
          >
            <h3 className="headerText">Interventions</h3>
            <Grid
              container
              spacing={2}
              style={{ height: "80px", marginBottom: "16px" }}
            >
              <Grid md={4} item>
                <div
                  style={{
                    backgroundColor: "#fff",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "3px",
                    height: "100%",
                  }}
                >
                  Total Cost <br />
                  <b>
                    £
                    {numberWithCommas(
                      (
                        props.interventionList
                          .filter((x) => x.doneYear === -1)
                          .filter(
                            (x) =>
                              x.isSap &&
                              props.interventionList.find(
                                (u) => u.captRecId === x.captRecId
                              )?.id === x.id
                          )
                          .reduce((sum, x) => sum + x.installCost, 0) +
                        props.interventionList
                          .filter((x) => !x.isSap)
                          .reduce((sum, x) => sum + x.installCost, 0)
                      ).toFixed(0)
                    )}
                  </b>
                </div>
              </Grid>
              <Grid md={4} item>
                <div
                  style={{
                    backgroundColor: "#fff",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "3px",
                    height: "100%",
                  }}
                >
                  {toggleSap
                    ? "Total CO2 Impact (SAP2012)"
                    : "Total CO2 Impact (Current Rates)"}
                  <br />
                  <b>{numberWithCommas(totalCo2().toFixed(0))} kg/year</b>
                </div>
              </Grid>
              <Grid md={4} item>
                <div
                  style={{
                    backgroundColor: "#fff",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "3px",
                    height: "100%",
                  }}
                >
                  {toggleSap
                    ? "Total Fuel cost impact (SAP2012)"
                    : "Total Fuel cost impact (Current Rates)"}
                  <br />
                  <b>£{numberWithCommas(totalFuelCost())}</b>
                </div>
              </Grid>
              {/* <Grid
                  md={3}
                  item
                  style={{
                    backgroundColor: "#fff",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "3px",
                  }}
                >
                  Potential SAP rating <br />
                  <b>B (82)</b>
                </Grid> */}
            </Grid>
            <div
              style={{
                backgroundColor: "white",
                marginTop: "25px",
                overflow: "auto",
              }}
            >
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell style={{position:"sticky", left:0, background:"white",minWidth:200 }}><b>Intervention</b></TableCell>
                    <TableCell style={{ minWidth:100 , textAlign:"right"}}>SAP score</TableCell>
                    <TableCell style={{ minWidth:150 , textAlign:"right"}}>Install Cost</TableCell>
                    <TableCell style={{ minWidth:150 , textAlign:"right"}}>
                      CO2 Impact (Year 1)
                    </TableCell>
                    <TableCell style={{ minWidth: 150, textAlign: "right" }}>
                      Cost saving (Year 1)
                    </TableCell>
                    <TableCell style={{ minWidth: 120, textAlign: "right" }}>
                      Lifetime (Salix)
                    </TableCell>
                    <TableCell style={{ minWidth: 150, textAlign: "right" }}>
                      Lifetime CO<sub>2</sub> Saving
                    </TableCell>
                    <TableCell style={{ minWidth: 150, textAlign: "right" }}>
                      Cost per Tonne of lifetime CO<sub>2</sub> saving
                    </TableCell>
                    <TableCell style={{ minWidth: 150, textAlign: "right" }}>
                      Bill saving over lifetime
                    </TableCell>
                    <TableCell style={{ minWidth: 150, textAlign: "right" }}>
                      Lifetime bill saving per £1 of Install Cost
                    </TableCell>
                    <TableCell style={{ minWidth: 150, textAlign: "right" }}>
                      kWh Impact p.a.
                    </TableCell>
                    <TableCell style={{ minWidth: 150, textAlign: "center" }}>
                      Energy Category
                    </TableCell>

                    <TableCell style={{ minWidth: 150, textAlign: "center" }}>
                      Show/Hide on chart
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        size="small"
                        color="secondary"
                        style={{ marginLeft: 10 }}
                        onClick={() => {
                          setEditing(!editing);
                        }}
                      >
                        {editing ? "Edit" : "Edit"}
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.interventionList
                    .filter((x) => x.doneYear === -1)
                    .map((element) => (
                      <>
                        <Intervention
                          key={element.id.toString()}
                          intervention={element}
                          handleEdit={editIntervention}
                          type={"check"}
                          planned={planned}
                          setPlanned={setPlanned}
                          editing={editing}
                          deleteIntervention={deleteIntervention}
                          toggleSap={toggleSap}
                          setAsDone={setAsDone}
                        />
                      </>
                    ))}
                  {addingIntervention ? (
                    <TableRow>
                      <TableCell>
                        <Autocomplete
                          style={{ width: "100%" }}
                          freeSolo
                          id="free-solo-2-demo"
                          disableClearable
                          onChange={(e: any, newValue: string) => {
                            setNewIntervention(newValue);
                          }}
                          defaultValue={""}
                          options={interventionTypes.map(
                            (option) => option.name
                          )}
                          renderInput={(params: any) => (
                            <TextField
                              {...params}
                              label="Search input"
                              // margin="normal"
                              fullWidth
                              variant="outlined"
                              onChange={(e) => {
                                setNewIntervention(e.target.value);
                              }}
                              defaultValue={
                                ""
                                // selectedIntervention !== undefined
                                //     ? selectedIntervention.interventionType.intervention
                                //     : null
                              }
                              InputProps={{
                                ...params.InputProps,
                                type: "search",
                              }}
                            />
                          )}
                        />
                      </TableCell>
                      <TableCell>
                        <Input
                          // margin="normal"
                          fullWidth
                          type="number"
                          defaultValue={0}
                          onChange={(e) => {
                            setNewInstallCost(parseFloat(e.target.value));
                          }}
                        />
                      </TableCell>
                      <TableCell>Calculated based on the kWh impact</TableCell>
                      <TableCell>Calculated based on the kWh impact</TableCell>
                      <TableCell>
                        <Input
                          // margin="normal"
                          fullWidth
                          type="number"
                          defaultValue={0}
                          onChange={(e) => {
                            setNewKwhImpact(parseFloat(e.target.value));
                          }}
                        />
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  ) : null}
                </TableBody>
              </Table>
            </div>
            <br />
            <Button
              size="small"
              variant="contained"
              onClick={() => {
                if (addingIntervention) {
                  if (newIntervention === "") {
                    alert("Please enter the intervention name");
                  } else {
                    setAddInterventionModal(true);
                  }
                } else {
                  setAddingIntervention(true);
                }
              }}
            >
              {addingIntervention ? "Confirm" : "Add"} new intervention
            </Button>
            <Modal
              open={addInterventionModal}
              onClose={() => {
                setAddInterventionModal(false);
                setFuelChange(false);
                setEnergyCategory({
                  id: -1,
                  name: "Please Select One",
                });
                setEnergyTariff({
                  ...({} as tariff),
                  fuel: "Select a Tariff",
                  id: -1,
                });
                setInterventionReduces(true);
              }}
            >
              <div
                style={{
                  top: "50%",
                  left: "50%",
                  marginRight: "-50%",
                  transform: "translate(-50%, -50%)",
                  position: "absolute",
                  width: "40%",
                  backgroundColor: "white",
                  padding: 10,
                }}
              >
                <h1>
                  Add <b style={{ color: "red" }}>{newIntervention}</b>{" "}
                  intervention
                </h1>
                <p>Please select if this reduces or consumes energy:</p>
                <ToggleButtonGroup
                  value={interventionReduces}
                  exclusive
                  onChange={(_, newValue: boolean) => {
                    setInterventionReduces(newValue);
                  }}
                  aria-label="text alignment"
                >
                  <ToggleButton value={true} aria-label="left aligned">
                    Reduces KWh
                  </ToggleButton>
                  <ToggleButton value={false} aria-label="left aligned">
                    Consumes KWh
                  </ToggleButton>
                </ToggleButtonGroup>
                <p>Please select the category:</p>
                <div
                // style={{
                //   display: "flex",
                //   justifyContent: "space-around",
                //   alignItems: "flex-end",
                // }}
                >
                  <FormControl style={{ width: "200px" }}>
                    <InputLabel id="energy-category-select-label">
                      Energy Category
                    </InputLabel>
                    <Select
                      labelId="energy-category-select-label"
                      id="energy-category-select"
                      value={energyCategory.id}
                      onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
                        let newCategory = energyCategories.find(
                          (x) => x.id == (e.target.value as number)
                        );
                        if (newCategory) {
                          setEnergyCategory(newCategory);
                        }
                      }}
                    >
                      {energyCategories.map((energyCategoryChoice) => {
                        return (
                          <MenuItem
                            key={energyCategoryChoice.id}
                            value={energyCategoryChoice.id}
                          >
                            {energyCategoryChoice.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
                <br />
                <p>
                  The <b>deviation</b> option applies to electrical
                  interventions that don&#39;t fit into the <b>lighting</b>{" "}
                  category.
                </p>
                Does this intervention include a Fuel Tarriff change?
                <Checkbox
                  checked={fuelChange}
                  onClick={() => {
                    setFuelChange(!fuelChange);
                    setEnergyTariff({
                      ...({} as tariff),
                      fuel: "Select a Tariff",
                      id: -1,
                    });
                  }}
                />
                <br />
                {fuelChange ? (
                  <div>
                    <FormControl style={{ width: "200px" }}>
                      <InputLabel id="energy-category-select-label">
                        Select New Fuel Tariff
                      </InputLabel>
                      <Select
                        labelId="energy-category-select-label"
                        id="energy-category-select"
                        value={energyTariff.id}
                        onChange={(
                          e: React.ChangeEvent<{ value: unknown }>
                        ) => {
                          let newTariff = props.tariffs.find(
                            (x) => x.id == (e.target.value as number)
                          );
                          if (newTariff) {
                            setEnergyTariff(newTariff);
                          }
                        }}
                      >
                        {props.tariffs.length > 0 ? (
                          props.tariffs.map((tariff) => {
                            return (
                              <MenuItem key={tariff.id} value={tariff.id}>
                                {tariff.fuel}
                              </MenuItem>
                            );
                          })
                        ) : (
                          <></>
                        )}
                      </Select>
                    </FormControl>
                    {energyTariff.id !== -1 && energyCategory.id !== -1 ? (
                      <p>
                        This will create a fuel tariff update for{" "}
                        <b>{energyCategory.name}</b> to{" "}
                        <b>{energyTariff.fuel}</b>
                        .
                        <br />
                        If this is not what you intended:{" "}
                        <button
                          onClick={() => {
                            setEnergyTariff({
                              ...({} as tariff),
                              fuel: "Select a Tariff",
                              id: -1,
                            });
                          }}
                        >
                          clear fuel tariff change selection
                        </button>
                      </p>
                    ) : null}
                  </div>
                ) : null}
                <Button
                  size="small"
                  variant="contained"
                  disabled={energyCategory.id === -1}
                  onClick={() => {
                    if (energyTariff.id !== -1) {
                      let tariffChangeData = {
                        propertyId: parseInt(id!),
                        year: -1,
                        energyCategoryId: energyCategory.id,
                        fuelTariffId: energyTariff.id,
                      };
                      postTariffChange(tariffChangeData);
                    }
                    let kwhImpact = newKwhImpact;
                    if (!interventionReduces) {
                      kwhImpact = 0 - newKwhImpact;
                    }
                    let data = {
                      scenarioId: 0,
                      propertyId: parseInt(id!),
                      interventionName: newIntervention,
                      plannedYear: -1,
                      installCost: newInstallCost,
                      kwhImpact: kwhImpact,
                      tariffChange: false,
                      energyCategoryId: energyCategory.id,
                    };
                    postNewIntervention(data);
                  }}
                >
                  Add intervention
                </Button>
              </div>
            </Modal>
            <div style={{ display: "flex", alignItems: "center" }}>
              <h2 className="headerText">
                CO2 impact ({toggleSap ? "SAP 2012" : "Current Rates"})
              </h2>
              {/* <h2 className="headerText" style={{ paddingLeft: "10%" }}>
            Market Rate Fuel cost impact
          </h2> */}
            </div>
            <div style={{ color: "white" }}>
              Current Rates
              <Switch
                checked={toggleSap}
                onChange={() => {
                  setToggleSap(!toggleSap);
                }}
              />
              SAP 2012
            </div>
            <div style={{ color: "white", alignItems: "right" }}>
              Existing Tariff
              <Switch
                checked={toggleCustomTariff}
                onChange={() => {
                  setToggleCustomTariff(!toggleCustomTariff);
                }}
              />
              If electricity tariff applied
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flex: "1 0 1",
                paddingBottom: "0px",
              }}
            >
              <div style={{ width: "85vw", paddingBottom: "0px" }}>
                <WaterfallLoader
                  co2Reductions={planned}
                  co2Emissions={
                    toggleSap
                      ? parseInt(
                          (
                            props.propertyDetails.co2_emissions_current * 1000 -
                            props.interventionList
                              .filter(
                                (x) =>
                                  x.doneYear > 1900 &&
                                  x.doneYear >= props.propertyDetails.year
                              )
                              .reduce((sum, x) => sum + x.co2Impact, 0)
                          ).toString()
                        )
                      : props.propertyDetails.marketRateCarbon
                      ? parseInt(
                          (
                            props.propertyDetails.marketRateCarbon -
                            props.interventionList
                              .filter(
                                (x) =>
                                  x.doneYear > 1900 &&
                                  x.doneYear >= props.propertyDetails.year
                              )
                              .reduce((sum, x) => sum + x.currentCo2Impact, 0)
                          ).toString()
                        )
                      : 0
                  }
                />
              </div>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <h2 className="headerText">Done Interventions</h2>
            </div>
            <div
              style={{
                backgroundColor: "white",
                marginTop: "25px",
                overflow: "auto",
              }}
            >
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Intervention</TableCell>
                    <TableCell style={{ width: "7%" }}>Install Cost</TableCell>

                    <TableCell style={{ width: "7%" }}>
                      CO2 Impact
                      <br />
                      (Done year rates)
                    </TableCell>
                    <TableCell style={{ width: "7%" }}>
                      Fuel Cost Impact
                      <br />
                      (Done year rates)
                    </TableCell>

                    <TableCell style={{ width: "7%" }}>
                      Lifetime (Per Salix)
                    </TableCell>
                    <TableCell style={{ width: "7%" }}>
                      CO2 Saving over Lifetime
                    </TableCell>
                    <TableCell style={{ width: "7%" }}>
                      Cost per Tonne of lifetime CO2 saving
                    </TableCell>
                    <TableCell style={{ width: "7%" }}>
                      Bill saving over lifetime
                    </TableCell>
                    <TableCell style={{ width: "7%" }}>
                      Lifetime bill saving per £1 of Install Cost
                    </TableCell>

                    <TableCell style={{ width: "7%" }}>kWh Impact</TableCell>
                    <TableCell style={{ width: "7%" }}>
                      Energy Category
                    </TableCell>
                    <TableCell>Done Year</TableCell>
                    <TableCell>Delete?</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.interventionList
                    .filter((x) => x.doneYear > 1900)
                    .map((element) => (
                      <DoneIntervention
                        key={element.id.toString()}
                        intervention={element}
                        toggleSap={toggleSap}
                        deleteIntervention={deleteIntervention}
                      />
                    ))}
                </TableBody>
              </Table>
            </div>
          </CardContent>
        </LoadingOverlay>
      </Card>
    </>
  );
}
