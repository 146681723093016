import {
  Card,
  CardContent,
  TextField,
  Button,
  makeStyles,
  Typography,
  Modal,
} from "@material-ui/core";
import "./SearchPage.css";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import React, { Dispatch, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import FoundProperties from "./FoundProperties";
import Fuse from "fuse.js";
import { SettingsInputAntennaTwoTone } from "@material-ui/icons";
import FilterInputList from "./FilterInputList";
import { UserContext } from "../../contexts/UserContext";
import { getData, postData } from "../get-data";
import { setServers } from "dns";
import MapComponent from "./MapComponent";
import LoadingOverlay from "react-loading-overlay-ts";

interface IProps {
  setSelectedProperty: Dispatch<React.SetStateAction<IProperty>>;
  selectedProperty: IProperty;
  fetchPropertyDetails: (property: number) => Promise<void>;
  fetchInterventions: (property: IProperty) => void;
  setSingleProperty: Dispatch<React.SetStateAction<boolean>>;
  fetchProperties: () => void;
  properties: IPropertyEPC[];
  filters: Array<IFilterObject>;
  organisation: string;
  readySearch: boolean;
}

const useStyles = makeStyles({
  card: {
    padding: "0px",
    backgroundColor: "#113d64",
  },
  searchButton: {
    backgroundColor: "#5E81AC",
    "&:hover": {
      backgroundColor: "#81A1C1",
    },
    width: "200px",
  },
  addNewButton: {
    marginRight: "15px",
    backgroundColor: "#5E81AC",
    "&:hover": {
      backgroundColor: "#81A1C1",
    },
    width: "200px",
  },
  searchText: {
    color: "white",
  },
  listbox: {
    width: 200,
    margin: 0,
    padding: 0,
    zIndex: 1,
    position: "absolute",
    listStyle: "none",
    backgroundColor: "white",
    overflow: "auto",
    maxHeight: 200,
    border: "1px solid rgba(0,0,0,.25)",
    '& li[data-focus="true"]': {
      backgroundColor: "#4a8df6",
      color: "white",
      cursor: "pointer",
    },
    "& li:active": {
      backgroundColor: "#2977f5",
      color: "white",
    },
  },
});

type FuseResult<T> = Fuse.FuseResult<T>;

export default function LandingPage(props: IProps) {
  const history = useHistory();
  const userContext = useContext(UserContext);

  const classes = useStyles();
  const [addressInput, setAddressInput] = useState("");
  const [postcode, setPostcode] = useState<string>("");
  const [epc, setEPC] = useState<string>("");
  const [foundProperties, setFoundProperties] = useState<IPropertyEPC[]>([]);
  const [noResults, setNoResults] = useState<boolean>(false);
  const [assignedFilters, setAssignedFilters] = useState<Array<IFilter>>([]);
  const [tickedProperties, setTickedProperties] = useState<number[]>([]);
  const [addPortfolioModelOpen, setAddPortfolioModalOpen] =
    useState<boolean>(false);
  const [enablePortfolioButton, setEnablePortfolioButton] =
    useState<boolean>(true);
  const [newPortfolioName, setNewPortfolioName] = useState<string>("");
  const [showMap, setShowMap] = useState(false);
  const addTickedProperty = (propertyId: number) => {
    let newTickedProperties = tickedProperties;
    if (newTickedProperties.find((x) => x === propertyId)) {
      newTickedProperties = newTickedProperties.filter((x) => x !== propertyId);
    } else {
      newTickedProperties = newTickedProperties.concat(propertyId);
    }
    setTickedProperties(newTickedProperties);
  };
  const addAllProperties = (ticked: boolean) => {
    if (ticked) {
      const newTickedProperties: number[] = [];
      foundProperties.forEach((property) =>
        newTickedProperties.push(property.property_id)
      );
      setTickedProperties(newTickedProperties);
    } else {
      setTickedProperties([]);
    }
  };

  const filterOptions = createFilterOptions({
    stringify: (option: string) => option,
  });
  const [loadingOn, setLoadingOn] = React.useState<boolean>(false);
  const [loadingSearch, setLoadingSearch] = React.useState<boolean>(false);
  useEffect(() => {
    if (props.properties.length === 0) {
      props.fetchProperties();
    }
  }, []);

  const firstFetch = (property: IProperty) => {
    props.setSelectedProperty(property);
    props.fetchPropertyDetails(property.property_id)
    .catch((e) => history.push("/404"));
    props.fetchInterventions(property);
  };

  const fetchFilteredProperties = (assignedFilters: Array<IFilter>) => {
    let url = "/api/sap/filterproperties";
    if (postcode !== "") {
      if (url.includes("?")) {
        url = url + "&postcode=" + postcode;
      } else {
        url = url + "?postcode=" + postcode;
      }
    }
    if (epc !== "") {
      if (url.includes("?")) {
        url = url + "&epcRating=" + epc;
      } else {
        url = url + "?epcRating=" + epc;
      }
    }

    if (assignedFilters.length > 0 || epc !== "" || postcode !== "") {
      return postData(
        "https://captapi.absolarlab.com" + url,
        assignedFilters,
        userContext
      );
    } else {
      return new Promise((resolve, reject) => resolve(props.properties));
    }
  };

  const postNewPortfolio = () => {
    setEnablePortfolioButton(false);
    const url =
      "/api/portfolio?name=" +
      newPortfolioName +
      "&user=" +
      userContext.userState.username;
    return postData(
      "https://captapi.absolarlab.com" + url,
      tickedProperties,
      userContext
    ).then(() => {
      alert("Adding new portfolio successful!");
      setNewPortfolioName("");
      setAddPortfolioModalOpen(false);
      setEnablePortfolioButton(true);
    });
  };

  const searchProperties = (event: any) => {
    const options = {
      threshold: 0.15,
      distance: 1000,
      keys: ["address"],
    };
    setLoadingSearch(true);
    event.preventDefault();
    if (addressInput === "") {
      fetchFilteredProperties(assignedFilters).then((properties) => {
        setFoundProperties(properties);
        setLoadingSearch(false);
        setNoResults(false);
      });
    } else {
      let results: Fuse.FuseResult<IPropertyEPC>[] = [];
      fetchFilteredProperties(assignedFilters).then((list) => {
        const fuse = new Fuse(list, options);
        results = fuse.search(addressInput);
        setFoundProperties(results.map((result) => result.item));

        if (results.length === 0) {
          setNoResults(true);
        } else {
          setNoResults(false);
        }
        setLoadingSearch(false);
      });
    }
  };

  const fetchCSV = () => {
    const url = "/api/export/csv";
    postData(
      "https://captapi.absolarlab.com" + url,
      foundProperties.map<number>((address) => address.property_id),
      userContext,
      true
    )
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "PropertiesDetailsCAPT.csv";
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove(); //afterwards we remove the element again
      });
  };
  return (
    <Card className="card">
      <LoadingOverlay active={!props.readySearch} spinner>
        <CardContent className="header">
          <h3 className="headerText">Property Search</h3>
          <Button
            variant="contained"
            style={{
              marginLeft: "15px",
              backgroundColor: showMap ? "#e3a1a9" : "#a1c6e3",
            }}
            onClick={() => setShowMap(!showMap)}
          >
            {showMap ? "Hide Map" : "Show Map"}
          </Button>
        </CardContent>
        <hr className="solid" />
        {showMap ? (
          <MapComponent
            organisation={props.organisation}
            filters={props.filters}
            setSelectedProperty={props.setSelectedProperty}
            selectedProperty={props.selectedProperty}
            fetchPropertyDetails={props.fetchPropertyDetails}
            fetchInterventions={props.fetchInterventions}
            foundProperties={foundProperties}
            loadingOn={loadingOn}
            setLoadingOn={setLoadingOn}
          />
        ) : null}

        <Card style={{ background: "#D8DEE9", marginBottom: "12px" }}>
          <CardContent className="content">
            <form noValidate onSubmit={searchProperties}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                }}
              >
                <TextField
                  label="Search address"
                  variant="filled"
                  fullWidth
                  margin="dense"
                  style={{ background: "white", marginBottom: "20px" }}
                  onChange={(e) => setAddressInput(e.target.value)}
                />
                <TextField
                  fullWidth
                  label="Postcode"
                  margin="dense"
                  variant="filled"
                  style={{
                    background: "white",
                    flex: "1 0 auto",
                    marginBottom: "20px",
                    marginTop: "0px",
                  }}
                  onChange={(e) => setPostcode(e.target.value)}
                />
                <TextField
                  fullWidth
                  label="EPC"
                  margin="dense"
                  variant="filled"
                  style={{
                    background: "white",
                    flex: "1 0 auto",
                    marginBottom: "20px",
                    marginTop: "0px",
                  }}
                  onChange={(e) => setEPC(e.target.value)}
                />

                <FilterInputList
                  assignedFilters={assignedFilters}
                  setAssignedFilters={setAssignedFilters}
                  filters={props.filters}
                />
                <div>
                  <Button
                    variant="contained"
                    classes={{ root: classes.addNewButton }}
                    onClick={() => {
                      setAssignedFilters([...assignedFilters, {} as IFilter]);
                    }}
                  >
                    <Typography classes={{ root: classes.searchText }}>
                      Add New Filter
                    </Typography>
                  </Button>
                  <Button
                    variant="contained"
                    classes={{ root: classes.searchButton }}
                    type="submit"
                  >
                    <Typography classes={{ root: classes.searchText }}>
                      Search
                    </Typography>
                  </Button>
                </div>
              </div>
            </form>
          </CardContent>
        </Card>
        <LoadingOverlay
          active={loadingSearch}
          spinner
          text="Loading search results... this can take up to 30 seconds"
        >
          {foundProperties.length > 0 && !loadingSearch ? (
            <Card style={{ background: "#D8DEE9" }}>
              <CardContent style={{ paddingTop: "2px" }}>
                <h3 style={{ color: "#4C566A" }}>
                  Results{" "}
                  <Button
                    disabled={tickedProperties.length === 0}
                    variant="contained"
                    classes={{ root: classes.searchButton }}
                    onClick={() => {
                      setAddPortfolioModalOpen(true);
                    }}
                  >
                    <Typography classes={{ root: classes.searchText }}>
                      Create portfolio
                    </Typography>
                  </Button>
                  <Button
                    style={{ float: "right" }}
                    variant="contained"
                    classes={{ root: classes.searchButton }}
                    onClick={() => {
                      fetchCSV();
                    }}
                  >
                    <Typography classes={{ root: classes.searchText }}>
                      Download To CSV
                    </Typography>
                  </Button>
                </h3>

                <hr className="solid" />
                <FoundProperties
                  propertyList={foundProperties}
                  firstFetch={firstFetch}
                  addAllProperties={addAllProperties}
                  addTickedProperty={addTickedProperty}
                  tickedProperties={tickedProperties}
                  defaultRows={100}
                />
              </CardContent>
            </Card>
          ) : noResults ? (
            <Card style={{ background: "#D8DEE9" }}>
              <CardContent>
                <h3 style={{ color: "#4C566A" }}>No Properties Found</h3>
              </CardContent>
            </Card>
          ) : (
            <></>
          )}
        </LoadingOverlay>
        <Modal
          open={addPortfolioModelOpen}
          onClose={() => {
            setAddPortfolioModalOpen(false);
          }}
        >
          <div
            style={{
              top: "50%",
              left: "50%",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
              position: "absolute",
              width: "25%",
              backgroundColor: "white",
              padding: 10,
            }}
          >
            <h1>Add new portfolio</h1>
            <p>
              Name:{" "}
              <TextField
                onChange={(e) => {
                  setNewPortfolioName(e.target.value);
                }}
              />
            </p>
            <p>
              Number of properties to be added to portfolio:{" "}
              {tickedProperties.length}
            </p>
            <Button
              disabled={!enablePortfolioButton}
              size="small"
              variant="contained"
              onClick={() => {
                if (
                  tickedProperties.length > 0 &&
                  newPortfolioName.length > 0
                ) {
                  postNewPortfolio();
                }
              }}
            >
              Add new portfolio
            </Button>
          </div>
        </Modal>
      </LoadingOverlay>
    </Card>
  );
}
