import { ApexOptions } from "apexcharts";
import React, { useEffect, useState } from "react";
import EPCFreqChart_home from "./EPCFreqChart_home";

interface IProps {
  band: string | null;
  rating: number | null;
  data?: number[];
}

export default function EPCFreqLoader(props: IProps): JSX.Element {
  const [options, setOptions] = useState<ApexOptions>({
    chart: {
      type: "area",
      background: "#fff",
      height:"auto",
      width:"auto",
      toolbar: {
        show: false,
      },
    },
    colors: [
      "#008054",
      "#19b459",
      "#8dce46",
      "#ffd500",
      "#fcaa65",
      "#ef8023",
      "#e9153b",
    ],
    title: {
      text: props.rating
        ? "Rating: " + props.band + " (" + props.rating.toString() + ")"
        : "unknown",
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: true,
        distributed: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: ["A", "B", "C", "D", "E", "F", "G"],

      title: {
        text: props.data ? "Number of properties" : "Probability",
      },
    },

    yaxis: {
      show: true,
      // reversed: true,
      title: {
        text: "EPC band",
      },
    },
    markers: {
      size: 0,
    },
    fill: {
      opacity: 1,
    },
    legend: {
      show: true,
    },
    annotations: {
      yaxis: [
        {
          y: props.band ? props.band : "D",
          borderColor: "#f29305",
          label: {
            borderColor: "#f29305",
            style: {
              color: "#fff",
              background: "#f29305",
            },
            text: props.band ? props.band.toString() : "unknown",
            position: "right",
          },
        },
      ],
    },
  });

  const [series, setSeries] = useState([
    {
      name: "No. of properties",
      data: props.data
        ? props.data
        : [
            0.000991, 0.114112, 0.309633106, 0.365994236, 0.15039127,
            0.046644872, 0.0122336,
          ],
    },
  ]);
  useEffect(() => {
    setSeries([
      {
        name: "No. of properties",
        data: props.data
          ? props.data
          : [
              0.000991, 0.114112, 0.309633106, 0.365994236, 0.15039127,
              0.046644872, 0.0122336,
            ],
      },
    ]);
  }, [props.data]);

  return <EPCFreqChart_home options={options} series={series} />;
}
