import React, { Dispatch, useEffect, useState, useContext } from "react";
import {
  Button,
  Card,
  CardContent,
  Grid,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  TextField,
  Link,
  TableContainer,
  Modal,
  TablePagination,
} from "@material-ui/core";
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import { putData } from "../get-data";
import { UserContext } from "../../contexts/UserContext";

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      // backgroundColor: theme.palette.common.black,
      color: "black",
      fontWeight: "bold",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

interface IProps {
  loaded: boolean;
  properties: IPropertyEPC[];
  portfolio: PortfolioDetail;
  fetchPortfolioProperties: (newPortfolio?: PortfolioDetail) => void;
}

export default function PropertyList(props: IProps) {
  const userContext = useContext(UserContext);

  const removeProperty = (property: IPropertyEPC) => {
    let url =
      "https://captapi.absolarlab.com/api/portfolio/" +
      props.portfolio.id +
      "/delete_property?property_id=" +
      property.property_id;
    putData(url, undefined, userContext).then((res: PortfolioDetail) => {
      props.fetchPortfolioProperties(res);
    });
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
    setCurrentProp(newPage * rowsPerPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [page, setPage] = useState<number>(0);
  const [currentProp, setCurrentProp] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  return (
    <>
      <Table>
        <TableHead>
          <TablePagination
            count={props.properties.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </TableHead>
        <TableHead>
          <TableCell>UPRN</TableCell>
          <TableCell>Address</TableCell>
          <TableCell>Property Type</TableCell>
          {/* <TableCell>EPC Type</TableCell> */}
          <TableCell>EPC Rating</TableCell>
        </TableHead>
        <TableBody>
          {props.loaded ? (
            props.properties
              .slice(currentProp, currentProp + rowsPerPage)
              .map((property: IPropertyEPC) => {
                console.log("here");
                return (
                  <StyledTableRow key={property.property_id}>
                    <StyledTableCell>{property.uprn}</StyledTableCell>
                    <StyledTableCell>{property.address}</StyledTableCell>
                    <StyledTableCell>
                      {property.classification_code}
                    </StyledTableCell>
                    {/* <StyledTableCell>{property.epc_type}</StyledTableCell> */}
                    <StyledTableCell>
                      {property.current_energy_efficiency}
                    </StyledTableCell>
                    <StyledTableCell>
                      <Button onClick={() => removeProperty(property)}>
                        <DeleteIcon />
                      </Button>
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })
          ) : (
            <></>
          )}
        </TableBody>
      </Table>
    </>
  );
}
