import {
  Button,
  Card,
  CardContent,
  Grid,
  makeStyles,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  TextField,
  Switch,
} from "@material-ui/core";
import { SmallNavbar } from "../NavBar/navbar-small";
import { useParams } from "react-router-dom";
import ActionPlanChartLoader from "../ActionPlanChart/ActionPlanChartLoader";
import "./ActionPlanPage.css";
import React, { useContext, useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay-ts";
import { UserContext } from "../../contexts/UserContext";
import { getData, postData } from "../get-data";
import PlannedIntervention from "./PlannedInterventionPage";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import BulkSetYear from "../InterventionsPage/BulkSetYear";

const useStyles = makeStyles({
  button: {
    width: "20%",
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
    backgroundColor: "#205a81",
    border: "2px solid #091e49",
  },
  gridItem: {
    backgroundColor: "#091e49",
  },
  card: {
    padding: "0px",
    backgroundColor: "#113d64",
  },
});

interface IParams {
  id: string | undefined;
}

interface IProps {
  selectedPortfolio: PortfolioDetail;
  portfolioInterventionsDto: PortfolioInterventionsDto;
  getPortfolio: (id?: number) => void;
}

export default function PortfolioActionPlanPage(props: IProps) {
  const userContext = useContext(UserContext);

  const classes = useStyles();
  const [yearRange, setYearRange] = useState<number[]>([]);
  const [co2Data, setCo2Data] = useState<number[]>([]);
  const [fuelBillData, setFuelBillData] = useState<number[]>([]);
  const [sapScores, setSapScores] = useState<number[]>([]);
  const { id } = useParams<IParams>();
  const [currentCo2, setCurrentCo2] = useState<number>(0);
  const [totalCurrentCo2, setTotalCurrentCo2] = useState<number>(0);
  const [co2By2035, setCo2By2035] = useState<number>(0);
  const [totalCo2By2035, setTotalCo2By2035] = useState<number>(0);
  const [co2By2050, setCo2By2050] = useState<number>(0);
  const [totalCo2By2050, setTotalCo2By2050] = useState<number>(0);
  const [percentChange, setPercentChange] = useState<number>(0);
  const [timelinePrices, setTimelinePrices] = useState<
    Array<TimelinePrice> | undefined
  >([]);
  const [editing, setEditing] = useState<boolean>(false);
  const [isAverage, setIsAverage] = useState<boolean>(false);
  const [totalCo2, setTotalCo2] = useState<number[]>([]);
  const [totalFuel, setTotalFuel] = useState<number[]>([]);
  const [loadingOn, setLoadingOn] = useState<boolean>(true);
  const [loadingOnInterventions, setLoadingOnInterventions] =
    useState<boolean>(true);
  const [updated, setUpdated] = useState<boolean>(false);
  const [portfolioDataLoading, setPortfolioDataLoading] =
    useState<boolean>(false);

  const range = (start: number, stop: number, step: number) =>
    Array.from(
      { length: (stop - start) / step + 1 },
      (_, i) => start + i * step
    );

  function fetchTimeData(): Promise<ITimeData> {
    const url =
      "/api/sap/averagetimeline?portfolioId=" +
      id?.toString() +
      "&start=2012&end=2050&plannedCO2=true&doneCO2=true";

    return getData("https://captapi.absolarlab.com" + url, {
      userState: userContext.userState,
      setUserState: userContext.setUserState,
    });
  }

  const initTopMetrics = (data: ITimeData): void => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const cur = data.carbon[currentYear];
    setCurrentCo2(Math.round(cur));
    setTotalCurrentCo2(
      Math.round(cur * props.selectedPortfolio.numberProperties)
    );
    setCo2By2035(Math.round(data.carbon[2035]));
    setTotalCo2By2035(
      Math.round(data.carbon[2035] * props.selectedPortfolio.numberProperties)
    );
    setCo2By2050(Math.round(data.carbon[2050]));
    setTotalCo2By2050(
      Math.round(data.carbon[2050] * props.selectedPortfolio.numberProperties)
    );
    setPercentChange(Math.round(((cur - data.carbon[2050]) / cur) * 100));
  };
  const [portfolioYearInterventionsDto, setPortfolioYearInterventionsDto] =
    useState<Array<YearPortfolioIntervention>>();

  const getPlannedYearPortfolioInterventions = () => {
    getData(
      "https://captapi.absolarlab.com/api/portfolio/plannedinterventions?portfolioId=" +
        id,
      {
        userState: userContext.userState,
        setUserState: userContext.setUserState,
      }
    ).then((portfolioInterventionsDto: PlannedInterventionsDto) => {
      let portfolioInterventions =
        portfolioInterventionsDto.plannedInterventions;
      let yearPortfolioInterventions: YearPortfolioIntervention[] = [];
      portfolioInterventions.map((intervention) => {
        let allForYear = portfolioInterventions.filter(
          (x) => x.interventionName == intervention.interventionName
        );
        let allKwh = allForYear
          .map((intervention) => intervention.kwhImpact)
          .reduce((a, b) => a + b, 0);
        let allCost = allForYear
          .map((intervention) => intervention.installCost)
          .reduce((a, b) => a + b, 0);

        let matching = yearPortfolioInterventions.filter(
          (x) => x.interventionName == intervention.interventionName
        );

        if (matching.length == 0) {
          yearPortfolioInterventions.push({
            interventionName: intervention.interventionName,
            plannedYear: intervention.plannedYear,
            doneYear: intervention.doneYear,
            installCost: allCost,
            kwhImpact: allKwh,
            propertyNum: allForYear.length,
          });
        }
      });
      setPortfolioYearInterventionsDto(yearPortfolioInterventions);
    });
  };

  const [portfolioInterventionsDto, setPortfolioInterventionsDto] =
    useState<PlannedInterventionsDto>();

  const getPlannedPortfolioInterventions = () => {
    setLoadingOnInterventions(true);
    getData(
      "https://captapi.absolarlab.com/api/portfolio/plannedinterventions?portfolioId=" +
        id,
      {
        userState: userContext.userState,
        setUserState: userContext.setUserState,
      }
    )
      .then((portfolioInterventionsDto: PlannedInterventionsDto) => {
        setPortfolioInterventionsDto(portfolioInterventionsDto);
      })
      .then(() => {
        setLoadingOnInterventions(false);
      });
  };
  function numberWithCommas(x: number | string) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  const fetchDoneCSV = () => {
    const url = "/api/export/done_interventions_csv";
    const parameters = "?portfolioId=" + id;
    let name = props.selectedPortfolio.name + "_DoneCAPT.csv";
    name = name.replace(/ /g, "_");

    postData(
      "https://captapi.absolarlab.com" + url + parameters,
      {},
      {
        userState: userContext.userState,
        setUserState: userContext.setUserState,
      },
      true
    )
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = name;
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove(); //afterwards we remove the element again
      });
  };

  const fetchNotDoneCSV = () => {
    const url = "/api/export/planned_interventions_csv";
    const parameters = "?portfolioId=" + id;
    let name = props.selectedPortfolio.name + "_PlannedCAPT.csv";
    name = name.replace(/ /g, "_");

    postData(
      "https://captapi.absolarlab.com" + url + parameters,
      {},
      {
        userState: userContext.userState,
        setUserState: userContext.setUserState,
      },
      true
    )
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = name;
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove(); //afterwards we remove the element again
      });
  };

  /**
   *  fetches time data from server.
   *  Then formats it ready for it to
   *  be passed into ActionPlanChartLoader
   */
  const getTimeData = (): void => {
    fetchTimeData()
      .then((data) => {
        initTopMetrics(data);

        const years = Object.keys(data.sap);
        const startYear = parseInt(years[0]);
        const endYear = parseInt(years[years.length - 1]);
        setYearRange(range(startYear, endYear, 1));

        const sapArray = Object.values(data.sap);
        const roundedSapArray = sapArray.map((val) => Math.round(val));
        setSapScores(roundedSapArray);

        const co2Array = Object.values(data.carbon);
        const roundedCo2Array = co2Array.map(
          (val) => Math.round((val + Number.EPSILON) * 100) / 100
        );
        setCo2Data(roundedCo2Array);

        const fuelBillArray = Object.values(data.bill);
        const roundedFuelBillArray = fuelBillArray.map(
          (val) => Math.round((val + Number.EPSILON) * 100) / 100
        );
        setFuelBillData(roundedFuelBillArray);

        const totalFuel = roundedFuelBillArray.map(
          (val) =>
            Math.round(
              (val * props.selectedPortfolio.numberProperties +
                Number.EPSILON) *
                100
            ) / 100
        );

        setTotalFuel(totalFuel);

        const totalCo2 = roundedCo2Array.map(
          (val) =>
            Math.round(
              (val * props.selectedPortfolio.numberProperties +
                Number.EPSILON) *
                100
            ) / 100
        );
        setTotalCo2(totalCo2);

        setUpdated(false);
      })
      .then(() => {
        setLoadingOn(false);
      })
      .catch((e) => console.log(e));
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsAverage(!isAverage);
  };

  useEffect(() => {
    if (props.selectedPortfolio.id == -1 && id) {
      props.getPortfolio(parseInt(id));
    }
    getTimeData();
    getPlannedPortfolioInterventions();
    getPlannedYearPortfolioInterventions();
  }, []);
  useEffect(() => {
    getTimeData();
    getPlannedPortfolioInterventions();
    getPlannedYearPortfolioInterventions();
  }, [updated]);
  useEffect(() => {
    if (props.selectedPortfolio.id == -1 && id) {
      props.getPortfolio(parseInt(id));
    }
    getTimeData();
    getPlannedPortfolioInterventions();
    getPlannedYearPortfolioInterventions();
  }, [props.selectedPortfolio]);

  return (
    <Card className="card" classes={{ root: classes.card }}>
      <CardContent className="header">
        <h3 className="headerText">{props.selectedPortfolio.name}</h3>
      </CardContent>
      <hr className="solid" />
      <CardContent style={{ paddingTop: "0px", paddingBottom: "0px" }}>
        <h3 className="headerText">Action Plan</h3>

        {/* <div className="action-plan"> */}
        <LoadingOverlay active={loadingOn} spinner text="Loading...">
          <div
            className="action-plan_inner"
            style={{
              background: "rgb(0, 26, 61)",
              color: "white",
              padding: "1px",
            }}
          >
            <Grid
              container
              spacing={3}
              style={{
                textAlign: "center",
                paddingTop: "20px",
                paddingBottom: "20px",
              }}
            >
              <Grid item xs={3}>
                Current emissions <br />
                <h1>
                  {isAverage
                    ? numberWithCommas(currentCo2)
                    : numberWithCommas(totalCurrentCo2)}{" "}
                  kg
                </h1>
              </Grid>
              <Grid item xs={3}>
                By 2035 <br />
                <h1>
                  {isAverage
                    ? numberWithCommas(co2By2035)
                    : numberWithCommas(totalCo2By2035)}{" "}
                  kg
                </h1>
              </Grid>
              <Grid item xs={3}>
                By 2050 <br />
                <h1>
                  {isAverage
                    ? numberWithCommas(co2By2050)
                    : numberWithCommas(totalCo2By2050)}{" "}
                  kg
                </h1>
              </Grid>
              <Grid item xs={3}>
                Change in emissions (by 2050)
                <br />
                <h1>{percentChange}%</h1>
              </Grid>
            </Grid>
          </div>
          <div
            className="action-plan_inner"
            style={{ background: "white", padding: "1px" }}
          >
            {isAverage ? (
              <ActionPlanChartLoader
                portfolioYearInterventions={portfolioYearInterventionsDto}
                co2Data={co2Data}
                fuelBillData={fuelBillData}
                sapScores={sapScores}
                yearRange={yearRange}
                isChartLoading={false}
              />
            ) : (
              <ActionPlanChartLoader
                portfolioYearInterventions={portfolioYearInterventionsDto}
                co2Data={totalCo2}
                fuelBillData={totalFuel}
                sapScores={sapScores}
                yearRange={yearRange}
                isChartLoading={false}
              />
            )}
          </div>
        </LoadingOverlay>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            paddingLeft: "30px",
          }}
        >
          <h3 style={{ color: "white" }}>Total</h3>
          <Switch checked={isAverage} onChange={handleChange} name="switch" />
          <h3 style={{ color: "white" }}>Average</h3>
        </div>
        {/* <LoadingOverlay
          active={loadingOnInterventions}
          spinner
          text="Loading..."
        > */}
        {portfolioInterventionsDto?.plannedInterventions.filter(
          (x) => x.plannedYear !== -1
        ).length != 0 ? (
          <>
            <h3 className="headerText">Interventions</h3>
            <h3>
              {" "}
              <BulkSetYear
                interventions={
                  props.portfolioInterventionsDto.portfolioInterventionsDtos
                }
                selectedPortfolio={props.selectedPortfolio}
                setUpdated={setUpdated}
              />
            </h3>
            <div className="action-plan_inner">
              <div style={{ backgroundColor: "white" }}>
                <Table size="small">
                  <TableHead style={{ background: "lightgrey" }}>
                    <TableRow>
                      <TableCell>
                        <strong>Property</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Intervention</strong>
                      </TableCell>
                      <TableCell style={{ width: "7%" }}>
                        <strong>Planned Year</strong>
                      </TableCell>
                      <TableCell style={{ width: "7%" }}>
                        Install Cost
                      </TableCell>
                      <TableCell style={{ width: "7%" }}>
                        CO2 Impact (Year 1)
                      </TableCell>
                      <TableCell style={{ width: "7%" }}>
                        Fuel Cost Impact (Year 1)
                      </TableCell>
                      <TableCell style={{ width: "7%" }}>
                        Lifetime (Per Salix)
                      </TableCell>
                      <TableCell style={{ width: "7%" }}>
                        CO2 Saving over Lifetime
                      </TableCell>
                      <TableCell style={{ width: "7%" }}>
                        Cost per Tonne of lifetime CO2 saving
                      </TableCell>
                      <TableCell style={{ width: "7%" }}>
                        Bill saving over lifetime
                      </TableCell>
                      <TableCell style={{ width: "7%" }}>
                        Lifetime bill saving per £1 of Install Cost
                      </TableCell>
                      <TableCell style={{ width: "7%" }}>
                        kWh Impact p.a.
                      </TableCell>
                      <TableCell style={{ width: "7%" }}>
                        <strong>From SAP Assessment</strong>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                </Table>
                <Table size="small">
                  <TableBody>
                    {portfolioInterventionsDto?.plannedInterventions
                      .filter((x) => x.doneYear === -1)
                      .filter(
                        (v, i, a) =>
                          a.map((x) => x.plannedYear).indexOf(v.plannedYear) ===
                          i
                      )
                      .map((x) => x.plannedYear)
                      .map((year: number) => {
                        return (
                          <Accordion key={year}>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Typography>
                                View interventions planned for year:{" "}
                                <strong>{year}</strong>
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              {portfolioInterventionsDto.plannedInterventions
                                .filter((x) => x.plannedYear == year)
                                .map((intervention) => {
                                  return (
                                    <TableRow
                                      key={JSON.stringify(
                                        intervention.id + intervention.address
                                      )}
                                    >
                                      <PlannedIntervention
                                        isDone={false}
                                        intervention={intervention}
                                      />
                                    </TableRow>
                                  );
                                })}
                            </AccordionDetails>
                          </Accordion>
                        );
                      })}
                  </TableBody>
                </Table>
              </div>
              {portfolioInterventionsDto ? (
                <div
                  style={{
                    background: "lightgrey",
                    padding: "10px",
                  }}
                >
                  <strong
                    style={{
                      paddingRight: "15px",
                    }}
                  >
                    Total kWh:{" "}
                    {numberWithCommas(
                      portfolioInterventionsDto.totalNotDoneKwh
                        ? portfolioInterventionsDto.totalNotDoneKwh.toFixed(0)
                        : 0
                    )}
                  </strong>
                  <strong>
                    Total Cost:{" "}
                    {numberWithCommas(
                      portfolioInterventionsDto.totalNotDoneCost
                        ? portfolioInterventionsDto.totalNotDoneCost.toFixed(0)
                        : 0
                    )}
                  </strong>
                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    style={{
                      marginLeft: "20px",
                    }}
                    onClick={() => fetchNotDoneCSV()}
                  >
                    Download CSV
                  </Button>
                </div>
              ) : (
                <React.Fragment></React.Fragment>
              )}
            </div>
          </>
        ) : (
          <></>
        )}
        {portfolioInterventionsDto?.plannedInterventions.filter(
          (x) => x.doneYear !== -1
        ).length != 0 ? (
          <>
            <h3 className="headerText">Done Interventions</h3>
            <div className="action-plan_inner">
              <div style={{ backgroundColor: "white" }}>
                <Table size="small">
                  <TableHead style={{ background: "lightgrey" }}>
                    <TableRow>
                      <TableCell>
                        <strong>Property</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Intervention</strong>
                      </TableCell>
                      <TableCell style={{ width: "7%" }}>
                        <strong>Done Year</strong>
                      </TableCell>
                      <TableCell style={{ width: "7%" }}>
                        Install Cost
                      </TableCell>
                      <TableCell style={{ width: "7%" }}>
                        CO2 Impact (Year 1)
                      </TableCell>
                      <TableCell style={{ width: "7%" }}>
                        Fuel Cost Impact (Year 1)
                      </TableCell>
                      <TableCell style={{ width: "7%" }}>
                        Lifetime (Per Salix)
                      </TableCell>
                      <TableCell style={{ width: "7%" }}>
                        CO2 Saving over Lifetime
                      </TableCell>
                      <TableCell style={{ width: "7%" }}>
                        Cost per Tonne of lifetime CO2 saving
                      </TableCell>
                      <TableCell style={{ width: "7%" }}>
                        Bill saving over lifetime
                      </TableCell>
                      <TableCell style={{ width: "7%" }}>
                        Lifetime bill saving per £1 of Install Cost
                      </TableCell>
                      <TableCell style={{ width: "7%" }}>
                        kWh Impact p.a.
                      </TableCell>
                      <TableCell style={{ width: "7%" }}>
                        <strong>From SAP Assessment</strong>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                </Table>
                <Table size="small">
                  <TableBody>
                    {portfolioInterventionsDto?.plannedInterventions
                      .filter((x) => x.doneYear !== -1)
                      .filter(
                        (v, i, a) =>
                          a.map((x) => x.doneYear).indexOf(v.doneYear) === i
                      )
                      .map((x) => x.doneYear)
                      .map((year: number) => {
                        return (
                          <Accordion key={year}>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Typography>
                                View interventions done in year:{" "}
                                <strong>{year}</strong>
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              {portfolioInterventionsDto.plannedInterventions
                                .filter((x) => x.doneYear == year)
                                .map((intervention) => {
                                  return (
                                    <TableRow
                                      key={JSON.stringify(
                                        intervention.id + intervention.address
                                      )}
                                    >
                                      <PlannedIntervention
                                        isDone={true}
                                        intervention={intervention}
                                      />
                                    </TableRow>
                                  );
                                })}
                            </AccordionDetails>
                          </Accordion>
                        );
                      })}
                  </TableBody>
                </Table>
              </div>
              {portfolioInterventionsDto ? (
                <div
                  style={{
                    background: "lightgrey",
                    padding: "10px",
                  }}
                >
                  <strong
                    style={{
                      paddingRight: "15px",
                    }}
                  >
                    Total kWh:{" "}
                    {numberWithCommas(
                      portfolioInterventionsDto.totalDoneKwh
                        ? portfolioInterventionsDto.totalDoneKwh.toFixed(0)
                        : 0
                    )}
                  </strong>
                  <strong>
                    Total Cost:{" "}
                    {numberWithCommas(
                      portfolioInterventionsDto.totalDoneCost
                        ? portfolioInterventionsDto.totalDoneCost.toFixed(0)
                        : 0
                    )}
                  </strong>
                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    style={{
                      marginLeft: "20px",
                    }}
                    onClick={() => fetchDoneCSV()}
                  >
                    Download CSV
                  </Button>
                </div>
              ) : (
                <React.Fragment></React.Fragment>
              )}
            </div>
          </>
        ) : (
          <></>
        )}
        {/* </LoadingOverlay> */}
        {timelinePrices !== undefined && timelinePrices.length > 0 ? (
          <div className="action-plan_inner">
            <div
              style={{
                backgroundColor: "white",
              }}
            >
              <Table size="small">
                <TableHead style={{ background: "lightgrey" }}>
                  <TableRow>
                    <TableCell>
                      <strong>Year </strong>
                    </TableCell>
                    <TableCell>
                      <strong>Total Year Spend</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Per Intervention Spend</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {timelinePrices.map((timelinePrice: TimelinePrice) => {
                    return (
                      <TableRow key={timelinePrice.year}>
                        <TableCell>{timelinePrice.year}</TableCell>
                        <TableCell>£{timelinePrice.totalPrice}</TableCell>
                        <TableCell>
                          {Object.keys(timelinePrice.interventionPrices).map(
                            (interventionName) => {
                              return (
                                <div key={interventionName}>
                                  {interventionName} : £
                                  {
                                    timelinePrice.interventionPrices[
                                      interventionName
                                    ]
                                  }
                                </div>
                              );
                            }
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </div>
          </div>
        ) : null}
        {/* </div> */}
      </CardContent>
    </Card>
  );
}
