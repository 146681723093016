import React, { useState, useEffect } from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  Button,
  TablePagination,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { Checkbox } from "@material-ui/core";

interface IProps {
  propertyList: PortfolioProperty[];
  addAllProperties: (ticked: boolean) => void;
  addTickedProperty: (propertyId: number) => void;
  tickedProperties: number[];
  defaultRows: number;
}

export default function PropertySelect(props: IProps) {
  const history = useHistory();
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [currentProp, setCurrentProp] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(props.defaultRows);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
    setCurrentProp(newPage * rowsPerPage);
  };
  return (
    <Table size="small">
      <TableHead>
        <TablePagination
          count={props.propertyList.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
        <TableRow>
          <TableCell>
            <Checkbox
              checked={
                props.tickedProperties.length === props.propertyList.length
              }
              onChange={() => {
                if (!selectAll) {
                  props.addAllProperties(true);
                } else {
                  if (
                    props.tickedProperties.length !== props.propertyList.length
                  ) {
                    props.addAllProperties(true);
                  } else {
                    props.addAllProperties(false);
                  }
                }
                setSelectAll(!selectAll);
              }}
              color="primary"
            />{" "}
            Add to list
          </TableCell>
          <TableCell>Address</TableCell>
          <TableCell>Postcode</TableCell>
          {/* <TableCell>Property type</TableCell>
          <TableCell>Owner</TableCell>
          <TableCell>Asset Group</TableCell> */}
        </TableRow>
      </TableHead>
      <TableBody>
        {props.propertyList.length > 0
          ? props.propertyList
              .slice(currentProp, currentProp + rowsPerPage)
              .map((element) => (
                <PropertyRow
                  element={element}
                  selectAll={selectAll}
                  key={element.propertyId}
                  addTickedProperty={props.addTickedProperty}
                  tickedProperties={props.tickedProperties}
                />
              ))
          : null}
      </TableBody>
    </Table>
  );
}
function PropertyRow(props: {
  element: PortfolioProperty;
  selectAll: boolean;
  key: number;
  addTickedProperty: (propertyId: number) => void;
  tickedProperties: number[];
}): JSX.Element {
  const [checked, setChecked] = useState<boolean>(
    props.tickedProperties.includes(props.element.propertyId)
  );
  useEffect(() => {
    setChecked(props.tickedProperties.includes(props.element.propertyId));
  }, [props.selectAll]);
  return (
    <TableRow key={props.key}>
      <TableCell>
        <Checkbox
          checked={checked}
          onChange={() => {
            props.addTickedProperty(props.element.propertyId);

            setChecked(!checked);
          }}
          color="primary"
        />
      </TableCell>
      <TableCell>{props.element.address}</TableCell>
      <TableCell>{props.element.postcode}</TableCell>
    </TableRow>
  );
}
