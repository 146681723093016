import { ApexOptions } from "apexcharts";
import React, { useEffect, useState } from "react";
import ImdIncomeChart from "./ImdIncomeChart";

interface IProps {
  value: number | null;
}

export default function ImdIncomeChartLoader(props: IProps): JSX.Element {
  useEffect(() => {
    setOptions({
      chart: {
        type: "area",
        background: "#fff",
        toolbar: {
          show: false,
        },
      },
      colors: ["#feb144", "#fe7c44"],
      title: {
        text: "Income",
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        title: {
          text: "No of LSOA in each decile",
        },
      },

      yaxis: {
        // show: true,
        reversed: true,
        title: {
          text: "IMD income decile",
        },
      },
      markers: {
        size: 0,
      },
      fill: {
        opacity: 1,
      },
      legend: {
        show: true,
      },
      annotations: {
        yaxis: [
          {
            y: props.value ? props.value : 0,
            borderColor: "#f29305",
            label: {
              borderColor: "#f29305",
              style: {
                color: "#fff",
                background: "#f29305",
              },
              text: props.value
                ? "This property: " + props.value.toString() + " / 10"
                : "unknown",
              position: "left",
              offsetX: 60,
            },
          },
        ],
      },
    });
  }, [props.value]);
  const [options, setOptions] = useState<ApexOptions>({
    chart: {
      type: "area",
      background: "#fff",
      toolbar: {
        show: false,
      },
    },
    colors: ["#feb144", "#fe7c44"],
    title: {
      text: "Income",
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      title: {
        text: "No of LSOA in each decile",
      },
    },

    yaxis: {
      // show: true,
      reversed: true,
      title: {
        text: "IMD income decile",
      },
    },
    markers: {
      size: 0,
    },
    fill: {
      opacity: 1,
    },
    legend: {
      show: true,
    },
    annotations: {
      yaxis: [
        {
          y: props.value ? props.value : 0,
          borderColor: "#f29305",
          label: {
            borderColor: "#f29305",
            style: {
              color: "#fff",
              background: "#f29305",
            },
            text: props.value
              ? "This property Income IMD: " + props.value.toString() + " / 10"
              : "unknown",
            position: "right",
          },
        },
      ],
    },
  });

  const [series, setSeries] = useState([
    {
      name: "This property",
      data: [13, 19, 19, 18, 25, 11, 14, 10, 13, 6],
    },
  ]);

  return <ImdIncomeChart options={options} series={series} />;
}
