import { Grid, Card, CardActionArea , Table, TableBody, TableCell, TableHead, TableRow ,IconButton } from "@material-ui/core";
import React, { Dispatch, useContext } from "react";
import { useHistory } from "react-router-dom";
import Map_soton from "../../img/map_soton.png";
import map_pmth from "../../img/map_pmth.png";
import building3D from "../../img/3d_building.png";
import portfolio3D from "../../img/3d_portfolio.png";
import "./SearchPage.css";
import EPCFreq from "../PropertyOverviewPage/EPCFreqChart/EPCFreqLoader_home";
import CompletedLineGraph from "./CompletedLineGraph";
import { numberWithCommas } from "../utilities";
import LoadingOverlay from "react-loading-overlay-ts";
import { DonutChart } from "./DonutChart";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from '@mui/material/Box';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CancelIcon from '@material-ui/icons/Cancel';
import CloseIcon from '@material-ui/icons/Close';
import ReleaseNotePage from "./ReleaseNotes"
import { UserContext } from "../../contexts/UserContext";
import fig_scc_epc from "../../img/scc_epc_stats.png";

//number[]
function createData(
  col: string,
  values:number []
  ) {
  return { col, values
  };
}

const columnArr = [
  'Mar_2008', 'Jun_2008', 'Sep_2008', 'Dec_2008',
  'Mar_2009', 'Jun_2009', 'Sep_2009', 'Dec_2009',
  'Mar_2010', 'Jun_2010', 'Sep_2010', 'Dec_2010',
  'Mar_2011', 'Jun_2011', 'Sep_2011', 'Dec_2011',
  'Mar_2012', 'Jun_2012', 'Sep_2012', 'Dec_2012',
  'Mar_2013', 'Jun_2013', 'Sep_2013', 'Dec_2013',
  'Mar_2014', 'Jun_2014', 'Sep_2014', 'Dec_2014',
  'Mar_2015', 'Jun_2015', 'Sep_2015', 'Dec_2015',
  'Mar_2016', 'Jun_2016', 'Sep_2016', 'Dec_2016',
  'Mar_2017', 'Jun_2017', 'Sep_2017', 'Dec_2017',
  'Mar_2018', 'Jun_2018', 'Sep_2018', 'Dec_2018',
  'Mar_2019', 'Jun_2019', 'Sep_2019', 'Dec_2019',
  'Mar_2020', 'Jun_2020', 'Sep_2020', 'Dec_2020',
  'Mar_2021', 'Jun_2021', 'Sep_2021', 'Dec_2021',
  'Mar_2022', 'Jun_2022', 'Sep_2022', 'Dec_2022',
  'Mar_2023', 'Jun_2023', 'Sep_2023', 'Dec_2023',
];

const rows = [
  createData("A",[0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0]),
  createData("B",[0.0,0.0,0.0,4.0,11.0,34.0,69.0,82.0,93.0,108.0,117.0,134.0,145.0,183.0,185.0,188.0,208.0,208.0,208.0,210.0,210.0,211.0,210.0,232.0,233.0,234.0,238.0,242.0,240.0,235.0,234.0,236.0,237.0,237.0,237.0,242.0,243.0,244.0,248.0,249.0,250.0,250.0,253.0,254.0,254.0,254.0,251.0,249.0,246.0,244.0,241.0,239.0,239.0,237.0,234.0,234.0,236.0,234.0,236.0,231.0,230.0,229.0,228.0,230.0]),
  createData("C",[0.0,0.0,0.0,65.0,174.0,351.0,501.0,598.0,692.0,796.0,909.0,1003.0,1080.0,1164.0,1243.0,1328.0,1421.0,1534.0,1614.0,1697.0,1780.0,1898.0,2069.0,2244.0,2333.0,2443.0,2539.0,2628.0,2707.0,2815.0,2929.0,2997.0,3055.0,3129.0,3195.0,3273.0,3326.0,3381.0,3697.0,3819.0,3803.0,3751.0,3744.0,3708.0,3685.0,3695.0,3760.0,3807.0,3830.0,3846.0,3887.0,3931.0,3981.0,4050.0,4121.0,4302.0,4552.0,4767.0,4878.0,4935.0,4999.0,5060.0,5110.0,5130.0]),
  createData("D",[0.0,0.0,0.0,85.0,320.0,487.0,630.0,753.0,857.0,963.0,1079.0,1196.0,1293.0,1410.0,1531.0,1663.0,1766.0,1871.0,1961.0,2061.0,2149.0,2282.0,2438.0,2566.0,2728.0,2832.0,2894.0,2963.0,3079.0,3193.0,3283.0,3368.0,3436.0,3491.0,3557.0,3608.0,3651.0,3691.0,3777.0,3767.0,3665.0,3497.0,3457.0,3367.0,3316.0,3321.0,3366.0,3427.0,3478.0,3452.0,3499.0,3576.0,3627.0,3687.0,3754.0,3944.0,4251.0,4570.0,4807.0,4830.0,4864.0,4914.0,4957.0,4971.0]),
  createData("E",[0.0,0.0,0.0,49.0,123.0,166.0,209.0,258.0,287.0,329.0,382.0,440.0,473.0,514.0,539.0,571.0,594.0,620.0,640.0,664.0,682.0,728.0,750.0,777.0,805.0,832.0,842.0,869.0,926.0,953.0,970.0,1001.0,1012.0,1046.0,1062.0,1072.0,1084.0,1096.0,1102.0,1190.0,1361.0,1546.0,1674.0,1816.0,1951.0,2015.0,2020.0,2012.0,2064.0,2160.0,2185.0,2176.0,2164.0,2167.0,2160.0,2059.0,1930.0,1787.0,1696.0,1698.0,1683.0,1686.0,1681.0,1682.0]),
  createData("F",[0.0,0.0,0.0,32.0,45.0,52.0,62.0,68.0,81.0,85.0,114.0,133.0,144.0,152.0,165.0,170.0,174.0,180.0,180.0,181.0,185.0,187.0,193.0,216.0,223.0,228.0,238.0,275.0,256.0,295.0,301.0,324.0,329.0,337.0,345.0,348.0,351.0,353.0,372.0,380.0,566.0,927.0,1116.0,1307.0,1427.0,1506.0,1490.0,1474.0,1477.0,1507.0,1491.0,1475.0,1460.0,1431.0,1406.0,1298.0,1056.0,824.0,700.0,696.0,683.0,673.0,665.0,661.0]),
  createData("G",[0.0,0.0,0.0,9.0,13.0,14.0,15.0,17.0,18.0,19.0,28.0,29.0,31.0,32.0,34.0,33.0,36.0,36.0,37.0,36.0,37.0,38.0,37.0,40.0,41.0,40.0,39.0,42.0,43.0,74.0,84.0,90.0,99.0,104.0,104.0,104.0,104.0,104.0,105.0,109.0,137.0,178.0,208.0,315.0,398.0,433.0,426.0,421.0,417.0,417.0,408.0,400.0,399.0,387.0,383.0,308.0,209.0,159.0,121.0,119.0,116.0,95.0,85.0,85.0]),
  createData("Average EPC",[0.0,0.0,0.0,56.49180327868852,59.77259475218659,62.380434782608695,63.450201884253026,63.509009009009006,63.53402366863905,63.71260869565217,63.26892354507417,63.167972742759794,63.162349968414404,63.394500723589005,63.3324317013795,63.39488995699469,63.56775422719695,63.67992807372443,63.75581896551724,63.81295112394308,63.86575451120365,63.862836826347305,64.0270317711076,64.13761316872429,64.10843941537011,64.14011196852776,64.21575846833579,64.08092320843426,64.10219280099297,63.747124917382685,63.75028842456096,63.624251497005986,63.58251714005877,63.53127996164909,63.55482352941176,63.61651439805713,63.64859002169197,63.6727928740557,64.02193312547038,63.98896363254152,62.88765078716009,61.15715834072322,60.28367776502105,59.03152430391694,58.06504449914789,57.38867243181602,57.33824804856895,57.37519409937888,57.30665240225637,57.006437768240346,57.07115479115479,57.15404389954519,57.23242439266237,57.30251346499102,57.48194333599361,58.53087529976019,60.16736778846154,61.57820949329061,62.34447198810114,62.38973289643531,62.48388380421807,62.66266506602641,62.66279069767442,62.59431090584654]),
  createData("Properties",[18713.0,18713.0,18713.0,18713.0,18713.0,18713.0,18713.0,18713.0,18713.0,18713.0,18713.0,18713.0,18713.0,18713.0,18713.0,18713.0,18713.0,18713.0,18713.0,18713.0,18713.0,18713.0,18713.0,18713.0,18713.0,18713.0,18713.0,18713.0,18713.0,18713.0,18713.0,18713.0,18713.0,18713.0,18713.0,18713.0,18713.0,18713.0,18713.0,18713.0,18713.0,18713.0,18713.0,18713.0,18713.0,18713.0,18713.0,18713.0,18713.0,18713.0,18713.0,18713.0,18713.0,18713.0,18713.0,18713.0,18713.0,18713.0,18713.0,18713.0,18713.0,18713.0,18713.0,18713.0]),
  createData("EPC",[0.0,0.0,0.0,244.0,686.0,1104.0,1486.0,1776.0,2028.0,2300.0,2629.0,2935.0,3166.0,3455.0,3697.0,3953.0,4199.0,4449.0,4640.0,4849.0,5043.0,5344.0,5697.0,6075.0,6363.0,6609.0,6790.0,7019.0,7251.0,7565.0,7801.0,8016.0,8168.0,8344.0,8500.0,8647.0,8759.0,8869.0,9301.0,9514.0,9782.0,10149.0,10452.0,10767.0,11031.0,11224.0,11313.0,11390.0,11512.0,11626.0,11711.0,11797.0,11870.0,11959.0,12058.0,12145.0,12234.0,12341.0,12438.0,12509.0,12575.0,12657.0,12726.0,12759.0]),
  createData("Properties without EPC",[18713.0,18713.0,18713.0,18469.0,18027.0,17609.0,17227.0,16937.0,16685.0,16413.0,16084.0,15778.0,15547.0,15258.0,15016.0,14760.0,14514.0,14264.0,14073.0,13864.0,13670.0,13369.0,13016.0,12638.0,12350.0,12104.0,11923.0,11694.0,11462.0,11148.0,10912.0,10697.0,10545.0,10369.0,10213.0,10066.0,9954.0,9844.0,9412.0,9199.0,8931.0,8564.0,8261.0,7946.0,7682.0,7489.0,7400.0,7323.0,7201.0,7087.0,7002.0,6916.0,6843.0,6754.0,6655.0,6568.0,6479.0,6372.0,6275.0,6204.0,6138.0,6056.0,5987.0,5954.0]),
  createData("Valide EPC",[0.0,0.0,0.0,244.0,686.0,1104.0,1486.0,1776.0,2028.0,2300.0,2629.0,2935.0,3166.0,3455.0,3697.0,3953.0,4199.0,4449.0,4640.0,4849.0,5043.0,5344.0,5697.0,6075.0,6363.0,6609.0,6790.0,7019.0,7251.0,7565.0,7801.0,8016.0,8168.0,8344.0,8500.0,8647.0,8759.0,8869.0,9301.0,9514.0,9782.0,10149.0,10452.0,10595.0,10562.0,10523.0,10377.0,10304.0,10282.0,10252.0,10175.0,10114.0,10085.0,10026.0,10024.0,10008.0,9984.0,9986.0,10085.0,10071.0,10052.0,9996.0,9804.0,9527.0]),
  createData("Out of date EPC",[0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,172.0,469.0,701.0,936.0,1086.0,1230.0,1374.0,1536.0,1683.0,1785.0,1933.0,2034.0,2137.0,2250.0,2355.0,2353.0,2438.0,2523.0,2661.0,2922.0,3232.0]),
];



interface IProps {
  // propertyDetails: any;
  // tariffs: any[];
  homeDataLoading: boolean;
  basicData: BasicData;
  dashboardData: DashboardData | undefined;
  epcStats: EpcStats | undefined;
  co2DataPlanned: number[];
  co2DataDone: number[];
  co2DataDefault: number[];
  yearRange: number[];
  loading: boolean;
  group:string;
}

export default function StatPage(props: IProps) {
  
  const [powerBI, setPowerBI] = React.useState<boolean>(false);

  const history = useHistory();
  const userContext = useContext(UserContext);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div className="home_background">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <Card className="panel_card">
            <div className="top_headline">
              <div>Organisation</div>
              <div className="headline_result">
                {props.basicData.organisation}
              </div>
            </div>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card className="panel_card">
            <div className="top_headline">
              <div>Properties loaded</div>
              <div className="headline_result">
                {props.basicData.propertiesLoaded}
              </div>
            </div>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card className="panel_card">
            <div className="top_headline">
              <div>Average EPC rating</div>
              <div className="headline_result">
                {props.basicData.averageEpcRatingLetter} (
                {props.basicData.averageEpcRating})
              </div>
            </div>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card className="panel_card">
            <div className="top_headline">
              <div>Total CO2 Emissions (SAP 2012) /yr</div>
              <div className="headline_result">
                {numberWithCommas(props.basicData.totalCo2Emissions)} Tonnes
              </div>
            </div>
          </Card>
        </Grid>
        {props.basicData.organisation === "Southampton City Council" ? <>
        <Modal
          open={powerBI}
          onClose={() => {
            setPowerBI(false);
          }}
        >
          <div
            style={{
              top: "50%",
              left: "50%",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
              position: "absolute",
              width: "80%",
              height: "95vh", 
              backgroundColor: "white",
              padding: 0,
            }}
          >
            <div style={{position:"absolute", right:10, top:10}}>
              <IconButton onClick={() => setPowerBI(false)} style={{background:"rgba(255,255,255,0.5)"}}>
                <CloseIcon style={{fontSize:50}} />
              </IconButton>
              </div>
            <iframe title="Report Section" width="100%" height="100%" src="https://app.powerbi.com/view?r=eyJrIjoiYzNmYWMxYzEtODQ4YS00MjEwLWJmMmUtMDIwNWM1OGE3ZDk0IiwidCI6ImUyYmUwY2QyLTc0ODMtNDcwYi05M2QzLThmZjNiZDEwOTQ3NiJ9"></iframe>
          </div>
        </Modal>
        <Grid item xs={12} sm={12} md={12}>
          <Card className="panel_card">
            <div className="top_headline">
              <div>Portfolio EPC Status</div>  
              <div><img src={fig_scc_epc} style={{width:"100%"}} /></div>
              <div style={{textAlign:"left"}}><Button variant="contained" onClick={() => setPowerBI(true)}>Open Power BI analysis</Button></div>
            </div>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Card className="panel_card">
            <div className="top_headline">
              {/* <div>Unnamed table</div>   */}
              <div style={{overflow:"auto" }}>
              <Table aria-label="simple table" size="small" >
                <TableHead>
                  <TableRow >
                    <TableCell style={{ whiteSpace:"nowrap" }}></TableCell>
                    {columnArr.map((columnName) => <TableCell style={{fontWeight:"bold"}} align="right" key={columnName}>{columnName}</TableCell>)}
                  </TableRow>
                </TableHead>
                <TableBody>
                {rows.map((row) => (
                  <TableRow style={{ whiteSpace:"nowrap" }} key={row.col}>
                    <TableCell component="th" scope="row" style={{fontWeight:"bold"}}>
                      {row.col}
                    </TableCell>
                    {row.values.map((value, index) => (
                      <TableCell key={index} align="right">
                        {value.toLocaleString(undefined, {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                      </TableCell>
                    ))} 

                  </TableRow>
                ))}
                </TableBody>
              </Table>
              </div>
            </div>
          </Card>
        </Grid>
        </>:<>

        <Grid item xs={12} sm={6} md={2}>
          <Card className="panel_card">
            <div className="top_headline">
              <div>
                Current CO<sub>2</sub>
              </div>
              <div className="headline_result_sub">
                {props.dashboardData
                  ? numberWithCommas(
                      parseInt(
                        (props.dashboardData.currentCarbon / 1000000).toFixed(0)
                      )
                    ) + " kT/year"
                  : "Loading..."}
              </div>
            </div>
          </Card>
        </Grid>
        
        <Grid item xs={12} sm={6} md={2}>
          <Card className="panel_card">
            <div className="top_headline">
              <div>Current Fuel Cost</div>
              <div className="headline_result_sub">
                {props.dashboardData
                  ? "£" +
                    numberWithCommas(
                      parseInt(
                        (props.dashboardData.currentFuelCost / 1000000).toFixed(
                          0
                        )
                      ) + " M/year"
                    )
                  : "Loading..."}
              </div>
            </div>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <Card className="panel_card">
            <div className="top_headline">
              <div>
                Planned CO<sub>2</sub> in 2050
              </div>
              <div className="headline_result_sub">
                {props.dashboardData
                  ? numberWithCommas(
                      parseInt(
                        (props.dashboardData.carbon2050 / 1000000).toFixed(0)
                      )
                    ) + " kT/year"
                  : "Loading..."}
              </div>
            </div>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <Card className="panel_card">
            <div className="top_headline">
              <div>Planned Fuel Cost in 2050</div>
              <div className="headline_result_sub">
                {props.dashboardData
                  ? "£" +
                    numberWithCommas(
                      parseInt(
                        (props.dashboardData.fuelCost2050 / 1000000).toFixed(0)
                      ) + " M/year"
                    )
                  : "Loading..."}
              </div>
            </div>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <Card className="panel_card">
            <div className="top_headline">
              <div>Interventions Completed</div>
              <div className="headline_result_sub">
                {props.dashboardData
                  ? props.dashboardData.numberCompletedInterventions.toFixed(
                      0
                    ) +
                    " | " +
                    numberWithCommas(
                      parseInt(
                        (props.dashboardData.completedCarbon / 1000).toFixed(0)
                      )
                    ) +
                    " Tonnes CO2 by 2050"
                  : "Loading..."}
              </div>
            </div>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <Card className="panel_card">
            <div className="top_headline">
              <div>Interventions planned</div>
              <div className="headline_result_sub">
                {props.dashboardData
                  ? props.dashboardData.numberPlannedInterventions.toFixed(0) +
                    " | " +
                    numberWithCommas(
                      parseInt(
                        (
                          props.dashboardData.plannedInterventionCarbon / 1000
                        ).toFixed(0)
                      )
                    ) +
                    " T CO2 by 2050"
                  : "Loading..."}
              </div>
            </div>
          </Card>
        </Grid>
        <Grid item xs={12} sm={5} md={5}>
          <Card className="panel_card">
            <div className="panel_title">
              Emission projections (updated daily on the first load)
            </div>
            <div style={{ height: "100%" }}>
              <LoadingOverlay
                active={props.loading}
                spinner={props.loading}
                // text={"under construction"}
              >
                <CompletedLineGraph
                  co2DataDefault={props.co2DataDefault}
                  co2DataDone={props.co2DataDone}
                  co2DataPlanned={props.co2DataPlanned}
                  yearRange={props.yearRange}
                />
              </LoadingOverlay>
            </div>
          </Card>
        </Grid>
        <Grid item xs={12} sm={5} md={5}>
          <Card className="panel_card">
            <div className="panel_title">Portfolio EPC Status</div>
            <div style={{ height: "100% !important" }}>
              <LoadingOverlay active={props.homeDataLoading} spinner>
                {props.basicData.averageEpcRating ? (
                  <EPCFreq
                    rating={props.basicData.averageEpcRating}
                    band={props.basicData.averageEpcRatingLetter}
                    data={
                      props.epcStats
                        ? Object.keys(props.epcStats.energyRatingsDistribution)
                            .sort()
                            .map((rating) =>
                              props.epcStats
                                ? props.epcStats.energyRatingsDistribution[
                                    rating
                                  ]
                                : 0
                            )
                        : undefined
                    }
                  />
                ) : null}
              </LoadingOverlay>
            </div>
          </Card>
        </Grid>
        <Grid item xs={12} sm={2} md={2}>
          <Card className="panel_card">
            {props.epcStats ? (
              <div style={{ height: "100%" }}>
                <div className="panel_title">
                  EPC Status{" "}
                  <div style={{ fontSize: 10 }}>(hover to view categories)</div>
                </div>
                <DonutChart
                  series={Object.values(props.epcStats.epcTypeDistribution)}
                  labels={Object.keys(props.epcStats.epcTypeDistribution)}
                />
                <div className="panel_title">
                  Property Type
                  <div style={{ fontSize: 10 }}>(hover to view categories)</div>
                </div>
                <DonutChart
                  series={Object.values(
                    props.epcStats.propertyTypeDistribution
                  )}
                  labels={Object.keys(props.epcStats.propertyTypeDistribution)}
                />{" "}
              </div>
            ) : null}
          </Card>
        </Grid>
        </>}
      </Grid>
      
      {/* <hr style={{ width: "95%", color: "#e6e6e6", marginTop: "25px" }} />
      <Grid container spacing={2} style={{ marginTop: "10px" }}>
        <Grid item xs={12} sm={6} md={3}>
          <Card className="panel_card">
            <div className="top_headline">
              <div>Current CAPT version</div>
              <div className="headline_result_sub">{userContext.userGroup == 'reading' ||userContext.userGroup == 'soton' ? '1.7' : '1.4.20'}</div>
            </div>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card className="panel_card">
            <div className="top_headline">
              <div>Released date</div>
              <div className="headline_result_sub">{userContext.userGroup == 'soton' ? '25 September 2023' :userContext.userGroup == 'reading' ? '09 March 2023' : '31 October 2022'}</div>
            </div>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card className="panel_card">
            <div className="top_headline">
              <div>Latest EPC update</div>
              <div className="headline_result_sub">{userContext.userGroup == 'soton' ? 'July 2023' :userContext.userGroup == 'reading' ? 'December 2022' : 'June 2022'}</div>
            </div>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
        <Card className="panel_card" onClick={handleOpen} style={{ display: "flex", alignItems: "center"}}>
            <CardActionArea style={{width: "100%", height:"100%" }}>
              <div className="top_headline">
                <div style={{ fontSize: "16px", paddingTop: 10 }}>
                  Release notes
                </div>
              </div>
            </CardActionArea>
          </Card>
        </Grid>
      </Grid> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={{position:"absolute", top:"50%", left:"50%", transform:"translate(-50%, -50%)", width:"80%", height:"80%", backgroundColor:"white", padding: 20, overflow:"auto"}}>
          <ReleaseNotePage />
          {/* <Typography id="modal-modal-title" variant="h6" component="h2">
            Release note
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <ul>
              <li>Latest EPC database dated March 2022. Next update is estimated to be June 2022</li>
              
            </ul>
          </Typography> */}
        </div>
      </Modal>
    </div>
  );
}
