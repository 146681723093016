import React, { useContext, useEffect, useState } from "react";
import "./filter.css";
import { Collapse, Icon } from "uiw";
import { Grid, TextField, Button, Card, CardContent } from "@mui/material";
// import Card from "react-bootstrap/Card";
// import Button from "react-bootstrap/Button";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import { getData, postData } from "../get-data";
import { postDataNoLogin } from "./fetch-data";
import FilterInputList from "../LandingPage/FilterInputList";
import { UserContext } from "../../contexts/UserContext";

interface IProps {
  generalFilters: PropertyFilters;
  setFrameState: React.Dispatch<React.SetStateAction<boolean>>;
  frameState: boolean;
  setData: React.Dispatch<React.SetStateAction<PropertyDataColumns>>;
  setFilterDto: React.Dispatch<React.SetStateAction<FilterDto>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const Panel = Collapse.Panel;
export const Filter: React.FC<IProps> = (props: IProps) => {
  const [localAuthorities, setLocalAuthorities] = React.useState<any>([]);
  const handleLocalAuthorities = (value: any) => {
    setLocalAuthorities(value);
  };
  const [tokens, setTokens] = useState<number>(1);

  const [assignedFilters, setAssignedFilters] = useState<Array<IFilter>>([]);

  const [filtersAny, setFiltersAny] = useState<Array<IFilterObject>>([]);

  const userContext = useContext(UserContext);

  const fetchFilters = () => {
    const url = "/api/sap/filters";

    getData("https://captapi.absolarlab.com" + url, userContext)
      .then((filters: Array<IFilterObject>) => {
        setFiltersAny(filters);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  //location
  const [location, setLocation] = React.useState({
    address: "",
    postcode: "",
    outputArea: "",
    LSOA: "",
    MSOA: "",
    Ward: "",
  });

  //CHECKBOXES
  const [checkboxes, setCheckboxes] = React.useState(populateCheckboxState());
  const [subcategoriesCheckboxes, setSubcategoriesCheckboxes] = React.useState(
    populateSubcategoriesCheckboxState()
  );
  const [areAllChecked, setAreAllChecked] = React.useState(false);
  const [modalForCheck, setModalForCheck] = React.useState(false);
  function openModal() {
    setModalForCheck(true);
  }
  function closeModal() {
    setModalForCheck(false);
  }

  function populateCheckboxState() {
    //iterator through all categories to create checboxes state
    let cb = new Map();
    for (let j = 0; j < Object.keys(props.generalFilters).length; j++) {
      let nameOfCat = Object.keys(props.generalFilters)[j];
      let objectWithSubcat = props.generalFilters[nameOfCat];
      let numberOfSubCat = Object.keys(objectWithSubcat).length;
      //process each subcategory into a panel
      for (let k = 0; k < numberOfSubCat; k++) {
        let nameOfSubCat = Object.keys(objectWithSubcat)[k];
        //the attributes are the filters of a subcategory
        let arrayWithAttr = objectWithSubcat[nameOfSubCat];
        for (let m = 0; m < arrayWithAttr.length; m++) {
          let attr = arrayWithAttr[m];
          //adding checkbox to state letiable
          cb.set(nameOfCat + ":" + nameOfSubCat + ":" + attr, false);
        }
      }
    }
    return cb;
  }
  function populateSubcategoriesCheckboxState() {
    //iterator through all categories to create checboxes state
    let cb = new Map();
    for (let j = 0; j < Object.keys(props.generalFilters).length; j++) {
      let nameOfCat = Object.keys(props.generalFilters)[j];
      let objectWithSubcat = props.generalFilters[nameOfCat];
      let numberOfSubCat = Object.keys(objectWithSubcat).length;
      //process each subcategory into a panel
      for (let k = 0; k < numberOfSubCat; k++) {
        let nameOfSubCat = Object.keys(objectWithSubcat)[k];
        //the attributes are the filters of a subcategory
        cb.set(nameOfCat + ":" + nameOfSubCat + ":all:", false);
      }
    }
    return cb;
  }

  function updateMultipleCheckboxes(type: string, bool: boolean) {
    if (type.includes(":all:")) {
      //it means we need to update a subcategory
      let subcategoryName = type.substring(0, type.length - 4);
      let clonedCheckboxes = new Map(checkboxes);

      for (let [key, value] of clonedCheckboxes) {
        if (key.includes(subcategoryName)) {
          clonedCheckboxes.set(key, bool);
        }
      }
      setCheckboxes(clonedCheckboxes);
      //further checks - if ALL filters are checked - we check the "check all" button too
      let assumption = true; //that all checkboxes are pressed
      for (let [key, value] of clonedCheckboxes) {
        if (value == false) {
          assumption = false;
          break;
        }
      }
      if (assumption == true) {
        setAreAllChecked(true);
      } else {
        setAreAllChecked(false);
      }
    } else if (type == "all") {
      setAreAllChecked(bool); //update the "all" button
      let clonedCheckboxes = new Map(checkboxes);
      for (let [key, value] of clonedCheckboxes) {
        clonedCheckboxes.set(key, bool);
      }
      setCheckboxes(clonedCheckboxes);

      let clonedSubcategoriesCheckboxes = new Map(subcategoriesCheckboxes);
      for (let [key, value] of clonedSubcategoriesCheckboxes) {
        clonedSubcategoriesCheckboxes.set(key, bool);
      }
      setSubcategoriesCheckboxes(clonedSubcategoriesCheckboxes);
    } else {
      //2 function to this: (1) updating teh state of checkboxes after one is checked and (2) updating the subcategory checkbox - for example - in a sub category: 3 out of 5 are checked: do nothing; 5 out of 5 checked -> check subcategory checkbox
      const clonedcheckboxes = new Map(checkboxes);
      clonedcheckboxes.set(type, bool);
      setCheckboxes(clonedcheckboxes);

      let assumption = true; //our assumption is that all within the group are checked
      //the type would be of form category:subcategory:filter
      //therefore we will extract it into category:subcategory
      let groupOfCheckboxes = type.split(":")[0] + ":" + type.split(":")[1];

      for (let [key, value] of clonedcheckboxes) {
        if (key.includes(groupOfCheckboxes)) {
          if (value == false) {
            assumption = false;
            break;
          }
        }
      }
      if (assumption) {
        //all checkboxes within subcategory are pressed
        let clonedSubcategoriesCheckboxes = new Map(subcategoriesCheckboxes);
        for (let [key, value] of clonedSubcategoriesCheckboxes) {
          if (key.includes(groupOfCheckboxes)) {
            clonedSubcategoriesCheckboxes.set(key, true);
            break;
          }
        }
        setSubcategoriesCheckboxes(clonedSubcategoriesCheckboxes);
        //further checks: if ALL subcategories are checked - we check the "check all" button too
        let assumption2 = true; //assuming ALL are checked
        for (let [key, value] of clonedSubcategoriesCheckboxes) {
          if (value == false) {
            assumption2 = false;
          }
        }
        if (assumption2 == true) {
          setAreAllChecked(true);
        } else {
          setAreAllChecked(false);
        }
      } else {
        // subcategory checkbox should be unchecked
        let clonedSubcategoriesCheckboxes = new Map(subcategoriesCheckboxes);
        for (let [key, value] of clonedSubcategoriesCheckboxes) {
          if (key.includes(groupOfCheckboxes)) {
            clonedSubcategoriesCheckboxes.set(key, false);
            break;
          }
        }
        setSubcategoriesCheckboxes(clonedSubcategoriesCheckboxes);

        //the "all check" button should stop
        setAreAllChecked(false);
      }
    }
  }
  //text for number of results
  function displayResults() {
    props.setLoading(true);
    //first step - returning a json object with the options that we need
    let choices = computeReturnObject();
    props.setFilterDto(choices);
    //TODO: display properties with current requirment
    console.log("foilters", assignedFilters);
    postData("https://captapi.absolarlab.com/api/filtering/show_filtered", choices, {
      userState: userContext.userState,
      setUserState: userContext.setUserState,
    })
      .then((propertyData: PropertyDataColumns) => {
        props.setData(propertyData);
        props.setFrameState(!props.frameState);
        props.setLoading(false);
      })
      .catch((e) => {
        alert(
          "Could not load properties, please try again, or refining the filters"
        );
        props.setFrameState(!props.frameState);
        props.setLoading(false);
      });

    console.log(choices);
  }

  function resetResults() {
    //uncheck everything
    updateMultipleCheckboxes("all", false);
    //reset location text
    setLocation({
      address: "",
      postcode: "",
      outputArea: "",
      LSOA: "",
      MSOA: "",
      Ward: "",
    });
    //reset local authorities
    setLocalAuthorities([]);
    props.setData({
      order: [],
      propertyDatas: [],
      allEncryptedUPRN: [],
      purchasedUprns: [],
    });
    //TODO: reset properties on the displayed page (remove everything?)
  }

  function computeReturnObject() {
    //initialising the return object and computing location json
    let returnObject: FilterDto = {
      Filters: {},
      Location: {
        Address: location.address,
        Postcode: location.postcode,
        OutputArea: location.outputArea,
        LSOA: location.LSOA,
        MSOA: location.MSOA,
        Ward: location.Ward,
      },
      LocalAuthorities: localAuthorities.map((x: any) => x.value),
      assignedFilters: assignedFilters,
    };

    //computing the filters
    for (let [key, value] of checkboxes) {
      //iterating through all checkboxes
      if (value) {
        //if the filter is pressed
        let CatSubcatAttr = key;
        let category = key.split(":")[0];
        let subcategory = key.split(":")[1];
        let attribute = key.split(":")[2];
        if (!(category in returnObject["Filters"])) {
          //if the result doesn't have that category
          let attributes = [];
          attributes.push(attribute);
          let subcategoryObject = { [subcategory]: [] };
          returnObject["Filters"][category] = subcategoryObject;
          returnObject["Filters"][category][subcategory].push(attribute);
        } else {
          //if the result has that category
          if (!(subcategory in returnObject["Filters"][category])) {
            //if the result doesn't have that subcategory
            let attributes = [];
            attributes.push(attribute);
            returnObject["Filters"][category][subcategory] = attributes;
          } else {
            //if the result has that subcategory
            returnObject["Filters"][category][subcategory].push(attribute);
          }
        }
      }
    }

    //computing the local authorities
    let arrayOfLocalAuthorities = [];
    for (let j = 0; j < localAuthorities.length; j++) {
      let objectWithAuthorities: any = localAuthorities[j];
      arrayOfLocalAuthorities.push(objectWithAuthorities.value);
    }
    returnObject["LocalAuthorities"] = arrayOfLocalAuthorities;

    return returnObject;
  }
  useEffect(() => {
    fetchFilters();
  }, []);

  return (
    <Card className="card">
      <div className="filterPanel">
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            key="0"
            style={{ marginTop: 10 }}
          >
            <Typography>Location</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <TextField
                    className="locationBox"
                    type="text"
                    label="Address (don't include postcode)"
                    value={location.address}
                    onChange={(e) =>
                      setLocation({ ...location, address: e.target.value })
                    }
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    className="locationBox"
                    type="text"
                    label="Postcode"
                    value={location.postcode}
                    onChange={(e) =>
                      setLocation({ ...location, postcode: e.target.value })
                    }
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    className="locationBox"
                    type="text"
                    label="Output Area Code"
                    value={location.outputArea}
                    onChange={(e) =>
                      setLocation({ ...location, outputArea: e.target.value })
                    }
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    className="locationBox"
                    type="text"
                    label="LSOA Code"
                    value={location.LSOA}
                    onChange={(e) =>
                      setLocation({ ...location, LSOA: e.target.value })
                    }
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    className="locationBox"
                    type="text"
                    label="MSOA Code"
                    value={location.MSOA}
                    onChange={(e) =>
                      setLocation({ ...location, MSOA: e.target.value })
                    }
                  />
                </Grid>
                {/* <Grid item xs={2}>
                  <TextField
                    className="locationBox"
                    type="text"
                    label="Ward"
                    value={location.Ward}
                    onChange={(e) =>
                      setLocation({ ...location, Ward: e.target.value })
                    }
                  />
                </Grid> */}
              </Grid>
            </div>
          </AccordionDetails>
        </Accordion>

        {Object.entries(props.generalFilters).map(
          ([categoryName, categoryObject]) => {
            return (
              <Accordion key={categoryName}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  key={categoryName}
                  style={{ marginTop: 10 }}
                >
                  <Typography>{categoryName}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={0}>
                    {Object.entries(categoryObject).map(
                      ([subcategoryName, subcategoryFiltersArr]) => {
                        return (
                          <Grid
                            item
                            style={{ display: "grid" }}
                            xs={
                              Math.max(
                                ...subcategoryFiltersArr.map(
                                  (filter) => filter.length
                                )
                              ) > 10
                                ? 4
                                : 4
                            }
                            key={categoryName + ":" + subcategoryName}
                          >
                            <Card  className="cards">
                              <CardContent style={{ padding: 2, maxHeight: 300, overflow: "auto" }}>
                                <h5
                                  style={{
                                    fontWeight: "bold",
                                    margin: "5px 0px",
                                  }}
                                >
                                  <input
                                    type="checkbox"
                                    id={
                                      categoryName +
                                      ":" +
                                      subcategoryName +
                                      ":all:"
                                    }
                                    value={
                                      categoryName +
                                      ":" +
                                      subcategoryName +
                                      ":all:"
                                    }
                                    checked={subcategoriesCheckboxes.get(
                                      categoryName +
                                        ":" +
                                        subcategoryName +
                                        ":all:"
                                    )}
                                    onChange={(e) => {
                                      let oldCheckboxValue =
                                        subcategoriesCheckboxes.get(
                                          e.target.value
                                        );

                                      let clonedSubcategoriesCheckboxes =
                                        new Map(subcategoriesCheckboxes);
                                      clonedSubcategoriesCheckboxes.set(
                                        e.target.value,
                                        !oldCheckboxValue
                                      );
                                      setSubcategoriesCheckboxes(
                                        clonedSubcategoriesCheckboxes
                                      );

                                      //check or uncheck all filters in that subcategory
                                      updateMultipleCheckboxes(
                                        e.target.value,
                                        !oldCheckboxValue
                                      );
                                    }}
                                  />
                                  {subcategoryName}
                                </h5>
                                <hr></hr>
                                {subcategoryFiltersArr.map(function (
                                  filter,
                                  index
                                ) {
                                  return (
                                    <div
                                      key={
                                        categoryName +
                                        ":" +
                                        subcategoryName +
                                        ":" +
                                        filter
                                      }
                                    >
                                      <label>
                                        <input
                                          type="checkbox"
                                          key={checkboxes.get(
                                            categoryName +
                                              ":" +
                                              subcategoryName +
                                              ":" +
                                              filter
                                          )}
                                          value={
                                            categoryName +
                                            ":" +
                                            subcategoryName +
                                            ":" +
                                            filter
                                          }
                                          checked={checkboxes.get(
                                            categoryName +
                                              ":" +
                                              subcategoryName +
                                              ":" +
                                              filter
                                          )}
                                          onChange={(e) => {
                                            let oldCheckboxValue =
                                              checkboxes.get(e.target.value);
                                            //now check or uncheck the main checkbox in that subcategory (or leave it as it is)
                                            updateMultipleCheckboxes(
                                              e.target.value,
                                              !oldCheckboxValue
                                            );
                                          }}
                                        />
                                        {filter}
                                      </label>

                                      <br></br>
                                    </div>
                                  );
                                })}
                              </CardContent>
                            </Card>
                          </Grid>
                        );
                      }
                    )}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            );
          }
        )}

        <div>
          <Button
            variant="outlined"
            style={{ float: "left", marginTop: 10 }}
            onClick={() => {
              setAssignedFilters([...assignedFilters, {} as IFilter]);
            }}
          >
            Add New Filter
          </Button>
          <div style={{ minHeight: 50 }}>
            <FilterInputList
              assignedFilters={assignedFilters}
              setAssignedFilters={setAssignedFilters}
              filters={filtersAny}
            />
          </div>
        </div>
        <Modal open={modalForCheck} onClose={closeModal}>
          <div style={{ padding: 20 }}>
            <h2>Are you sure?</h2>
            <p>
              You are about to {areAllChecked ? "uncheck" : "check"} all
              filters. Please confirm to proceed.
            </p>
            <Button
              variant="contained"
              onClick={() => {
                updateMultipleCheckboxes("all", !areAllChecked);
                closeModal();
              }}
              style={{ marginRight: 10 }}
            >
              Yes, proceed
            </Button>
            <Button variant="outlined" color="error" onClick={closeModal}>
              No, go back
            </Button>
          </div>
        </Modal>
        {/* <input
          type="checkbox"
          id={"all"}
          value={"all"}
          checked={areAllChecked}
          style={{
            color: "black",
            float: "left",
            margin: "5px",
            marginTop: 15,
          }}
          onChange={(e) => {
            openModal();
          }}
        /> */}

        <br></br>
        <br></br>
        <div>
          <Button
            variant="outlined"
            onClick={resetResults}
            style={{ marginRight: 10 }}
          >
            Reset search
          </Button>

          <Button
            variant="contained"
            style={{ float: "right" }}
            onClick={displayResults}
          >
            Display Results
          </Button>
        </div>
      </div>
    </Card>
  );
};
