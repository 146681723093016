import React, { useState } from "react";
import { Button, Modal, TableCell, TableRow } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { numberWithCommas } from "../utilities";

interface IProps {
  intervention: Intervention;
  key?: string;
  toggleSap?: boolean;
  deleteIntervention: (interventionId: number) => void;
}

export default function DoneIntervention(props: IProps) {
  const [toDelete, setToDelete] = useState<boolean>(false);
  function getEnergyCategory(energyCategory: number) {
    if (energyCategory === 1) {
      return "Heating";
    } else if (energyCategory === 2) {
      return "Lighting";
    } else if (energyCategory === 3) {
      return "Hot water";
    } else {
      return "Deviation";
    }
  }
  return (
    <TableRow>
      <TableCell>{props.intervention.interventionName}</TableCell>

      <TableCell align="right">
        {"£" + Math.round(props.intervention.installCost).toString()}
      </TableCell>

      {props.toggleSap ? (
        <>
          <TableCell align="right">
            {numberWithCommas(
              Math.round(props.intervention.co2Impact).toFixed(0)
            ).toString() + "kg"}
          </TableCell>
          <TableCell align="right">
            {"£" +
              numberWithCommas(
                Math.round(props.intervention.costSavings).toFixed(0)
              ).toString()}
          </TableCell>

          <TableCell align="right">
            {props.intervention.lifetime != null
              ? Math.round(props.intervention.lifetime)
              : 0}{" "}
            years
          </TableCell>
          <TableCell align="right">
            {props.intervention.lifetime
              ? numberWithCommas(
                  (
                    props.intervention.co2Impact * props.intervention.lifetime
                  ).toFixed(0)
                ) + " kg"
              : "n/a"}
          </TableCell>
          <TableCell align="right">
            {props.intervention.lifetime
              ? "£" +
                numberWithCommas(
                  (
                    props.intervention.installCost /
                    ((props.intervention.co2Impact / 1000) *
                      props.intervention.lifetime)
                  ).toFixed(0)
                )
              : "n/a"}
          </TableCell>
          <TableCell align="right">
            {props.intervention.lifetime
              ? "£" +
                numberWithCommas(
                  (
                    props.intervention.costSavings * props.intervention.lifetime
                  ).toFixed(0)
                )
              : "n/a"}
          </TableCell>
          <TableCell align="right">
            {props.intervention.lifetime && props.intervention.installCost
              ? "£" +
                numberWithCommas(
                  (
                    (props.intervention.costSavings *
                      props.intervention.lifetime) /
                    props.intervention.installCost
                  ).toFixed(2)
                )
              : "n/a"}
          </TableCell>
        </>
      ) : (
        <>
          <TableCell align="right">
            {numberWithCommas(
              Math.round(props.intervention.currentCo2Impact).toFixed(0)
            ).toString() + "kg"}
          </TableCell>
          <TableCell align="right">
            {"£" +
              numberWithCommas(
                Math.round(props.intervention.currentCostSavings).toFixed(0)
              ).toString()}
          </TableCell>
          <TableCell align="right">
            {props.intervention.lifetime != null
              ? Math.round(props.intervention.lifetime)
              : 0}{" "}
            years
          </TableCell>
          <TableCell align="right">
            {props.intervention.lifetime
              ? numberWithCommas(
                  (
                    props.intervention.currentCo2Impact *
                    props.intervention.lifetime
                  ).toFixed(0)
                ) + " kg"
              : "n/a"}
          </TableCell>
          <TableCell align="right">
            {props.intervention.lifetime
              ? "£" +
                numberWithCommas(
                  (
                    props.intervention.installCost /
                    ((props.intervention.currentCo2Impact / 1000) *
                      props.intervention.lifetime)
                  ).toFixed(0)
                )
              : "n/a"}
          </TableCell>
          <TableCell align="right">
            {props.intervention.lifetime
              ? "£" +
                numberWithCommas(
                  (
                    props.intervention.currentCostSavings *
                    props.intervention.lifetime
                  ).toFixed(0)
                )
              : "n/a"}
          </TableCell>
          <TableCell align="right">
            {props.intervention.lifetime && props.intervention.installCost
              ? "£" +
                numberWithCommas(
                  (
                    (props.intervention.currentCostSavings *
                      props.intervention.lifetime) /
                    props.intervention.installCost
                  ).toFixed(2)
                )
              : "n/a"}
          </TableCell>
        </>
      )}

      <TableCell align="right">
        {numberWithCommas(
          Math.round(props.intervention.kwhImpact).toFixed(0)
        ).toString()}
      </TableCell>
      <TableCell>
        {getEnergyCategory(props.intervention.energyCategoryId)}
      </TableCell>

      <TableCell align="right">{props.intervention.doneYear}</TableCell>
      <TableCell>
        <Button variant="contained" onClick={() => setToDelete(true)}>
          <DeleteIcon />
        </Button>
      </TableCell>
      <Modal
        open={toDelete}
        onClose={() => {
          setToDelete(false);
        }}
      >
        <div
          style={{
            top: "50%",
            left: "50%",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            position: "absolute",
            width: "35%",
            backgroundColor: "white",
            padding: 10,
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          Please confirm that you would like to delete this intervention:{" "}
          <b>{props.intervention.interventionName}</b>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              props.deleteIntervention(props.intervention.id);
            }}
          >
            Delete
          </Button>
        </div>
      </Modal>
    </TableRow>
  );
}
