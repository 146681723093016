import React, { useEffect, useState } from "react";
import MapGL, { Marker, MapRef, FlyToInterpolator } from "react-map-gl";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

import { getCenter, getBounds } from "geolib";

import { WebMercatorViewport } from "react-map-gl";

import "./EPCMap.css";
import useSupercluster from "use-supercluster";
import { BBox } from "geojson";
import { Badge } from "@material-ui/core";
import RoomIcon from "@material-ui/icons/Room";

interface IProps {
  filterProperty: (properties: IPropertyEPC[]) => void;
  properties: IPropertyEPC[];
  noProperties: number;
  toggleSat: boolean;
  height: string;
}

export const EPCMap: React.FC<IProps> = (props: IProps) => {
  const [propNum, setPropNum] = useState<number>(0);
  const mapRef = React.useRef<MapRef>(null);

  const [viewportEPC, setViewportEPC] = useState<any>({
    latitude: 50.908187, //
    longitude: -1.4176743,
    zoom: 11,
    height: window.innerHeight,
    width: window.innerWidth,
  });
  const [epcColour, setepcColour] = useState<boolean>(true);
  const [noPropertiesText, setNoPropertiesText] = useState<string>("0");
  useEffect(() => {
    setTimeout(() => {
      setPropNum(props.properties.length);
    }, 1);
  });

  useEffect(() => {
    try {
      //this mainly renders the viewport - when the properties change, the viewport changes automatically
      const markersToCalculateCentre: any = [];
      props.properties.map((property: IPropertyEPC) => {
        markersToCalculateCentre.push({
          latitude: property.lat,
          longitude: property.lon,
        });
      });

      if (markersToCalculateCentre.length != 0) {
        //only update viewport if there is more than 1 property
        const centreOfMarkers = getCenter(markersToCalculateCentre);
        const boundsOfMarkers = getBounds(markersToCalculateCentre);

        if (centreOfMarkers && boundsOfMarkers) {
          let { latitude, longitude, zoom } = new WebMercatorViewport(
            viewportEPC
          ).fitBounds(
            [
              [boundsOfMarkers.minLng, boundsOfMarkers.minLat],
              [boundsOfMarkers.maxLng, boundsOfMarkers.maxLat],
            ],
            {
              padding: 40,
            }
          );
          //if there's only one property
          if (markersToCalculateCentre.length == 1) zoom = 13;
          setViewportEPC({
            latitude: latitude,
            longitude: longitude,
            zoom: zoom,
            transitionInterpolator: new FlyToInterpolator({ speed: 1.2 }),
            transitionDuration: "auto",
          });
        }
      }
    } catch {
      console.log("error");
    }
  }, [propNum]);

  let boundsToUSe: BBox | undefined = undefined;

  const points: point[] = props.properties.map((property: IPropertyEPC) => ({
    type: "Feature",
    properties: {
      isCluster: false,
      propertyID: property.property_id,
      allInformation: property,
      propertyType: "house",
      current: property.current_energy_efficiency,
    },
    geometry: {
      type: "Point",
      coordinates: [property.lon, property.lat],
    },
  }));

  const zoomToUSe: number =
    viewportEPC.zoom == undefined ? 0 : viewportEPC.zoom;

  const bounds = mapRef.current
    ? mapRef.current.getMap().getBounds().toArray().flat()
    : undefined;

  if (bounds != undefined) {
    boundsToUSe = [bounds[0], bounds[1], bounds[2], bounds[3]];
  } else {
    boundsToUSe = undefined;
  }

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds: boundsToUSe,
    zoom: zoomToUSe,
    options: {
      radius: 75,
      maxZoom: 20,
      map: (item: { propertyType: string; current: number }) => ({
        [item.propertyType]: 1,
        current: item.current,
      }),
      reduce: (
        acc: { [x: string]: number; current: number },
        cur: { [x: string]: number; current: number }
      ) => {
        for (const eachField in cur) {
          acc[eachField] = (acc[eachField] || 0) + cur[eachField];
        }
      },
    },
  });

  function instanceOfClusterPoint(object: any): object is clusterPoint {
    return "id" in object;
  }

  function instanceOfPoint(object: any): object is point {
    return "propertyID" in object.properties;
  }

  const getColor = (epcRatingToEval: number, clusterCount: number) => {
    let epcRating = 0;
    if (clusterCount > -1) {
      epcRating = epcRatingToEval / clusterCount;
    } else {
      epcRating = epcRatingToEval;
    }
    if (epcRating >= 92) {
      return "#008054";
    }
    if (epcRating >= 81) {
      return "#19b459";
    }
    if (epcRating >= 69) {
      return "#8dce46";
    }
    if (epcRating >= 55) {
      return "#ffd500";
    }
    if (epcRating >= 39) {
      return "#fcaa65";
    }
    if (epcRating >= 21) {
      return "#ef8023";
    }
    return "#e9153b";
  };

  //process text of number of properties
  React.useEffect(() => {
    let text = "";
    if (props.noProperties >= 1000) {
      text = (props.noProperties / 1000).toString() + "K";
    } else {
      text = props.noProperties.toString();
    }
    setNoPropertiesText(text);
  }, [props.noProperties]);
  const handleClickCluster = (points: point[]) => {
    props.filterProperty(
      points.flatMap((point) => point.properties.allInformation)
    );
  };

  return (
    <div id="container">
      <MapGL
        {...viewportEPC}
        mapboxApiAccessToken="pk.eyJ1IjoianNhbmNoOTgiLCJhIjoiY2tzNHVpajEwMjg1cjJwbXNoeGY0NmhkcyJ9.OGFBN2CHTg4vUIIVOk0CIw"
        mapStyle={
          props.toggleSat
            ? "mapbox://styles/mapbox/satellite-v9"
            : "mapbox://styles/mapbox/light-v9"
        }
        onViewportChange={(
          e: React.SetStateAction<{
            latitude: number;
            longitude: number;
            zoom: number;
            height: string;
            width: string;
          }>
        ) => {
          setViewportEPC(e);
        }}
        ref={mapRef}
        height={props.height}
        width="100%"
      >
        {clusters.map((cluster: clusterPoint | point) => {
          const [longitude, latitude] = cluster.geometry.coordinates;

          if (instanceOfClusterPoint(cluster)) {
            const { point_count: pointCount } = cluster.properties;
            return (
              <Marker
                key={`cluster-${cluster.id}`}
                latitude={latitude}
                longitude={longitude}
              >
                <Badge badgeContent={pointCount} color="secondary">
                  <RoomIcon
                    className="marker"
                    style={{
                      color: epcColour
                        ? getColor(
                            cluster.properties.current,
                            cluster.properties.point_count
                          )
                        : "grey",
                      stroke: "grey",
                    }}
                    fontSize="large"
                    onClick={(e) => {
                      //console.log(cluster, supercluster.getLeaves(cluster.id));
                      handleClickCluster(
                        supercluster.getLeaves(cluster.id, Infinity)
                      );
                    }}
                  />
                </Badge>
                {/* // </Box> */}
                {/* )} */}
              </Marker>
            );
          } else if (instanceOfPoint(cluster)) {
            const { allInformation: propInfo } = cluster.properties;
            return (
              <Marker
                key={`property-${cluster.properties.propertyID}`}
                latitude={latitude}
                longitude={longitude}
              >
                <RoomIcon
                  style={{
                    color: epcColour
                      ? getColor(propInfo.current_energy_efficiency, -1)
                      : "grey",
                    stroke: "grey",
                  }}
                  className="marker"
                  fontSize="large"
                  onClick={(e) => {
                    props.filterProperty([propInfo]);
                  }}
                />
              </Marker>
            );
          }
        })}
        <p id="noPropText">{noPropertiesText}</p>
        <p id="noPropExpText">No. of properties</p>
      </MapGL>
    </div>
  );
};

export default EPCMap;
