import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import {
  Button,
  Card,
  CardContent,
  Grid,
  makeStyles,
  TextField,
  ButtonProps,
} from "@material-ui/core";

interface IProps {
  propertiesCategories: CategoryData;
}

export const BarChartsCategories: React.FC<IProps> = (props: IProps) => {
  const initialState: any = [];
  /**
   * Instead of creating a state variable for each category, we do it dynamically.
   * each entry in the dataCategories array contains the data for a certain category
   */
  Object.entries(props.propertiesCategories).map(([categoryName, counts]) => {
    const eachCategory = [
      [categoryName, "Number of properties", { role: "style" }],
    ];
    initialState.push(eachCategory);
  });
  const [dataCategories, setDataCAtegories] =
    React.useState<[[[string, string | number, { role: string } | string]]]>(
      initialState
    );

  useEffect(() => {
    //whenever the categories change (are passed as props/adjusted), updating the charts
    const previousDataCategories = initialState;

    Object.entries(props.propertiesCategories).map(
      ([categoryName, counts], key) => {
        previousDataCategories[key].push([
          "very good",
          counts.verygood,
          "blue",
        ]);
        previousDataCategories[key].push(["good", counts.good, "blue"]);
        previousDataCategories[key].push(["average", counts.average, "blue"]);
        previousDataCategories[key].push(["poor", counts.poor, "blue"]);
        previousDataCategories[key].push([
          "very poor",
          counts.verypoor,
          "blue",
        ]);
      }
    );
    setDataCAtegories(previousDataCategories);
  }, [props.propertiesCategories]);

  return (
    <>
      {/**adding a chart for each category of data - i.e. hot water etc. xs=4 so there are 3 per row */}
      {dataCategories.map((eachArrayOfData, key) => (
        <Grid item xs={4} key={key}>
          <Chart
            width={"100%"}
            height={"200px"}
            chartType="ColumnChart"
            loader={<div>Loading Chart</div>}
            data={eachArrayOfData}
            options={{
              title: eachArrayOfData[0][0],
              legend: { position: "none" },
            }}
            // For tests
            rootProps={{ "data-testid": "5" }}
          />
        </Grid>
      ))}
    </>
  );
};

export default BarChartsCategories;
