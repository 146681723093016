import {
  Grid,
  Card,
  CardContent,
  TextField,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Divider,
  CardActionArea,
  Switch,
} from "@material-ui/core";
import "./SolarAssessment.css";
import "../../../App.css";
import React, { Dispatch, useContext } from "react";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import saving from "../../../img/piggy-bank.png";
import co2 from "../../../img/co_2.png";
import solar from "../../../img/solar-panel.png";
import map_dummy from "../../../img/satellite_image_dummy.png";
import solar_rad from "../../../img/solar_rad.png";
import SunDiagram from "../../../img/sun_diagram_background_diagram.png";
import RestoreIcon from "@material-ui/icons/Restore";
import Brightness7Icon from "@material-ui/icons/Brightness7";
import AspectRatioIcon from "@material-ui/icons/AspectRatio";
import BatteryChargingFullIcon from "@material-ui/icons/BatteryChargingFull";
import SystemSummaryExecutive from "./SummaryExecutive";
import { getData } from "../../get-data";
import { UserContext } from "../../../contexts/UserContext";
import LoadingOverlay from "react-loading-overlay-ts";
// import { PoundCircleOutlined } from "@ant-design/icons";
// import { ListItem } from "material-ui";
interface ToidUrls {
  radiation: string;
  outline: string;
  satellite: string;
}
interface IProps {
  // propertyDetails: any;
  // tariffs: any[];
  propertyDataLoading: boolean;
  selectedProperty: IProperty;
  setSingleProperty: React.Dispatch<React.SetStateAction<boolean>>;
  reload: (id?: number) =>  Promise<void>|null;
  saving_1yr: number;
  data: IDataDto;
  image: string;
  pvPriceLow: number;
  pvPriceHigh: number;
  years: number;
  kw: number;
  noPanels: number;
  utilisedRoofSpace: number;
}

interface IParams {
  id: string | undefined;
}

export default function SolarAssessmentPage(props: IProps) {
  const { id } = useParams<IParams>();
  const history = useHistory();
  const userContext = useContext(UserContext);

  const [toidInfo, setToidInfo] = useState<IToid | undefined>();
  const [toidUrls, setToidUrls] = useState<ToidUrls>();

  const [toggleSat, setToggleSat] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingImages, setLoadingImages] = useState<boolean>(true);
  const [noInfo, setNoInfo] = useState<boolean>(false);

  const [radiationImageWorking, setRadiationImageWorking] =
    useState<boolean>(true);
  const [radiationImage2Working, setRadiationImage2Working] =
    useState<boolean>(true);

  const [buildingImageWorking, setBuildingImageWorking] =
    useState<boolean>(true);

  useEffect(() => {
    if (props.selectedProperty) {
      props.setSingleProperty(true);
      if (Object.keys(props.selectedProperty).length === 0) {
        props.reload(parseInt(id!))?.catch((e)=>{history.push("/404")});
      }
      getStats();
      getToid();
    }
  }, []);
  const getStats = () => {
    setLoading(true);
    getData(
      "https://captapi.absolarlab.com/api/sap/toidSolar?propertyId=" + id,
      {
        userState: userContext.userState,
        setUserState: userContext.setUserState,
      }
    )
      .then((toidInfo: IToid | null) => {
        if (toidInfo == null) {
          setNoInfo(true);
          setLoading(false);
        } else {
          setToidInfo(toidInfo);
        }
      })
      .then(() => {
        setLoading(false);
      })
      .catch((e) => {
        setNoInfo(true);
        setLoading(false);
      });
  };
  const getToid = () => {
    setLoading(true);
    getData(
      "https://captapi.absolarlab.com/api/sap/radiationImage?propertyId=" + id,
      {
        userState: userContext.userState,
        setUserState: userContext.setUserState,
      }
    )
      .then((toidUrls: ToidUrls) => {
        setToidUrls(toidUrls);
      })
      .then(() => {
        setLoadingImages(false);
      });
  };

  const numberWithCommas = (x: string) => {
    return parseInt(x).toLocaleString("en");
  };

  const formatter = new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: "GBP",
    // These options are needed to round to whole numbers if that's what you want.
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  return (
    <LoadingOverlay
      className="myLoading"
      active={loading || props.propertyDataLoading}
      spinner
    >
      {!noInfo ? (
        <Card className="card" style={{ backgroundColor: "#113d64" }}>
          <CardContent className="header">
            <h3 className="headerText">{props.selectedProperty.address}</h3>
          </CardContent>
          <hr className="solid" />
          <CardContent style={{ height: "100%" }}>
            {toidInfo?.existing_solar ? (
              <>
                <Grid container spacing={2}>
                  {" "}
                  <Grid
                    item
                    xs={12}
                    className="results_container"
                    style={{ textAlign: "center" }}
                  >
                    <Card className="panel_card">
                      <h3>
                        We recognize that this property already has solar.
                      </h3>
                    </Card>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                <Grid item xs={12}>
                <Card className="panel_card">
                  <Grid container style={{ padding: 10 }}>
                    {!loadingImages ? (
                      <>
                        <Grid
                          item
                          xs={4}
                          className="results_container"
                          style={{ textAlign: "center" }}
                        >
                          <div className="diagram_title">Satellite image</div>
                          <div className="sun_diagram_container">
                            <img
                              onError={() => {
                                setBuildingImageWorking(false);
                              }}
                              className="sun_diagram"
                              src={toidUrls?.satellite}
                              alt=""
                              style={{
                                boxShadow: "0 10px 10px -5px",
                                width: 600,
                                height: 600,
                              }}
                            />
                          </div>
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          className="results_container"
                          style={{ textAlign: "center" }}
                        >
                         
                          {toidUrls ? (
                            <div className="sun_diagram_container">
                              {
                                radiationImageWorking ? (
                                  <>
                                    <img
                                      onError={() => {
                                        setRadiationImageWorking(false);
                                      }}
                                      src={toidUrls?.radiation}
                                      className="sun_radiation"
                                    />
                                    <img
                                      src={SunDiagram}
                                      className="sun_diagram"
                                    />
                                  </>
                                ) : (
                                  <div>
                                    <span>
                                      Unable to fetch radiation image, contact
                                      info@absolar.co.uk
                                    </span>
                                  </div>
                                )
                             }
                            </div>
                          ) : (
                            <></>
                          )}
                        </Grid>
                      </>
                    ) : (
                      <span>Loading images...</span>
                    )}
                    
                  </Grid>
                  </Card>
                </Grid>
                </Grid>
              </>
            ) : (
              
              
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <SystemSummaryExecutive
                  icon="Moneti"
                  preunit="£"
                  unit="/year "
                  title="Energy bill saving"
                  value={
                    toidInfo?.saving
                      ? numberWithCommas(toidInfo.saving.toFixed(0))
                      : "0"
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <SystemSummaryExecutive
                  icon="Eco"
                  preunit=""
                  unit="kg/year "
                  title={
                    <span>
                      CO<sub>2</sub> saving
                    </span>
                  }
                  value={
                    toidInfo?.co2_saving
                      ? numberWithCommas(toidInfo.co2_saving.toFixed(0))
                      : "0"
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <SystemSummaryExecutive
                  icon="Power"
                  preunit=""
                  unit="Panels"
                  title={<span>Number of solar panels</span>}
                  value={
                    toidInfo?.number_of_panels_suitable
                      ? numberWithCommas(
                          toidInfo.number_of_panels_suitable.toFixed(0)
                        )
                      : "0"
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Card className="panel_card">
                  <Grid container style={{ padding: 10 }}>
                    {!loadingImages ? (
                      <>
                        <Grid
                          item
                          xs={4}
                          className="results_container"
                          style={{ textAlign: "center" }}
                        >
                          <div className="diagram_title">Satellite image</div>
                          <div className="sun_diagram_container">
                            <img
                              onError={() => {
                                setBuildingImageWorking(false);
                              }}
                              className="sun_diagram"
                              src={toidUrls?.satellite}
                              alt=""
                              style={{
                                boxShadow: "0 10px 10px -5px",
                                width: 600,
                                height: 600,
                              }}
                            />
                          </div>
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          className="results_container"
                          style={{ textAlign: "center" }}
                        >
                          <div className="switch-title">
                            Toggle Radiation view
                            <Switch
                              checked={toggleSat}
                              onChange={() => {
                                setToggleSat(!toggleSat);
                              }}
                            />
                          </div>
                          {toidUrls ? (
                            <div className="sun_diagram_container">
                              {toggleSat ? (
                                radiationImageWorking ? (
                                  <>
                                    <img
                                      onError={() => {
                                        setRadiationImageWorking(false);
                                      }}
                                      src={toidUrls?.radiation}
                                      className="sun_radiation"
                                    />
                                    <img
                                      src={SunDiagram}
                                      className="sun_diagram"
                                    />
                                  </>
                                ) : (
                                  <div>
                                    <span>
                                      Unable to fetch radiation image, contact
                                      info@absolar.co.uk
                                    </span>
                                  </div>
                                )
                              ) : radiationImage2Working ? (
                                <>
                                  <img
                                    onError={() => {
                                      setRadiationImage2Working(false);
                                    }}
                                    src={toidUrls?.outline}
                                    className="sun_radiation"
                                  />
                                  <img
                                    src={SunDiagram}
                                    className="sun_diagram"
                                  />
                                </>
                              ) : (
                                <div>
                                  <span>
                                    Unable to fetch solar outline, contact
                                    info@absolar.co.uk
                                  </span>
                                </div>
                              )}
                            </div>
                          ) : (
                            <></>
                          )}
                        </Grid>
                      </>
                    ) : (
                      <span>Loading images...</span>
                    )}

                    <Grid item xs={4}>
                      <List>
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar>
                              <Brightness7Icon />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary={
                              <span className="summary_test_number_bold">
                                {formatter.format(
                                  toidInfo?.installation_cost
                                    ? toidInfo.installation_cost
                                    : 0
                                )}
                                <span className="summary_test_number">
                                  {" "}
                                  incl. VAT
                                </span>
                              </span>
                            }
                            secondary={
                              <span className="summary_sub">
                                Estimated cost to purchase and install
                              </span>
                            }
                          />
                        </ListItem>
                        <Divider
                          variant="inset"
                          component="li"
                          style={{ marginRight: "5%" }}
                        />
                        {/* =================== payback ======================= */}
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar>
                              <RestoreIcon />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary={
                              <span className="summary_test_number_bold">
                                {toidInfo?.payback ? toidInfo.payback : 0}
                                <span className="summary_test_number">
                                  {" "}
                                  years
                                </span>
                              </span>
                            }
                            secondary={
                              <span className="summary_sub">
                                Payback period{" "}
                              </span>
                            }
                          />
                        </ListItem>
                        <Divider
                          variant="inset"
                          component="li"
                          style={{ marginRight: "5%" }}
                        />
                        {/* =================== payback ======================= */}
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar>
                              <Brightness7Icon />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary={
                              toidInfo?.system_size ? (
                                <span className="summary_test_number_bold">
                                  {toidInfo.system_size} kW /{" "}
                                  {toidInfo.number_of_panels_suitable} solar
                                  panels{" "}
                                  <span className="summary_test_number"> </span>
                                </span>
                              ) : (
                                <></>
                              )
                            }
                            secondary={
                              <span className="summary_sub">
                                Maximum system size
                              </span>
                            }
                          />
                        </ListItem>
                        <Divider
                          variant="inset"
                          component="li"
                          style={{ marginRight: "5%" }}
                        />
                        {/* =================== payback ======================= */}
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar>
                              <AspectRatioIcon />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary={
                              <span className="summary_test_number_bold">
                                {toidInfo?.roof_surface_used_m2
                                  ? toidInfo.roof_surface_used_m2.toFixed(0)
                                  : 0}{" "}
                                m<sup>2</sup> /{" "}
                                {toidInfo?.roof_surface_used_m2
                                  ? (
                                      toidInfo.roof_surface_used_m2 * 10.7639
                                    ).toFixed(0)
                                  : 0}{" "}
                                sq ft{" "}
                                <span className="summary_test_number">sqm</span>{" "}
                              </span>
                            }
                            secondary={
                              <span className="summary_sub">
                                Roof space suitable for solar panels{" "}
                              </span>
                            }
                          />
                        </ListItem>
                        <Divider
                          variant="inset"
                          component="li"
                          style={{ marginRight: "5%" }}
                        />
                        {/* =================== payback ======================= */}
                        {/* <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <BatteryChargingFullIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <span className="summary_test_number_bold">
                          <span>Battery storage potential </span>
                          <span className="summary_test_number">
                            
                          </span>{" "}
                        </span>
                      }
                      secondary={
                        <span className="summary_sub">
                          This size installation may suit battery storage to
                          help utilise up to 100% of electricity generation
                        </span>
                      }
                    />
                  </ListItem> */}

                        {/* =================== payback ======================= */}
                      </List>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            </Grid>)}
          </CardContent>
        </Card>
      ) : (
        <h1>No Information for this property/Property not suitable for solar</h1>
      )}
    </LoadingOverlay>
  );
}
