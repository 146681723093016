import React from "react";
import {
  Eco,
  HomeSharp,
  ListAlt,
  Equalizer,
  WbSunny,
} from "@material-ui/icons";
import dashboard_icon from "../../img/dashboard-red.svg"
import { Search, LocationCity, Settings, Map } from "@material-ui/icons";

import "./propertyitems.css";

let PortfolioMenuItems: IMenuItem[] = [
  {
    title: "< Back to Search",
    button: (
      <img
        className="dashboard_icon"
        src={dashboard_icon}
        alt="dashboard_icon"
      />
    ),
    url: "/#",
    cName: "nav-links",
  },
  {
    title: "Portfolio EPC Analysis",
    button: <Eco />,
    url: "/portfolio_report",
    cName: "nav-links",
  },
  {
    title: "Portfolio Interventions",
    button: <ListAlt />,
    url: "/portfolio_interventions",
    cName: "nav-links",
  },
  {
    title: "Micro to Macro",
    button: <Equalizer />,
    url: "/portfolio_mictomac",
    cName: "nav-links",
  },
  {
    title: "Portfolio Sandbox",
    button: <Equalizer />,
    url: "/portfolio_sandbox",
    cName: "nav-links",
  },
  // {
  //   title: "< Back to Menu",
  //   button: (
  //     <img
  //       className="dashboard_icon"
  //       src={dashboard_icon}
  //       alt="dashboard_icon"
  //     />
  //   ),
  //   url: "/#",
  //   cName: "nav-links",
  // },
  // {
  //   title: "Property details",
  //   button: <HomeSharp />,
  //   url: "/propertyoverview",
  //   cName: "nav-links",
  // },
  // {
  //   title: "Portfolio EPC Analysis",
  //   button: <Eco />,
  //   url: "/energyperformance",
  //   cName: "nav-links",
  // },
  // {
  //   title: "Portfolio Interventions",
  //   button: <WbSunny />,
  //   url: "/solarassessment",
  //   cName: "nav-links",
  // },
  // {
  //   title: "Portfolio Action plan",
  //   button: <Equalizer />,
  //   url: "/actionplan",
  //   cName: "nav-links",
  // },
  // {
  //   title: "Portfolio Waterfall",
  //   button: <Equalizer />,
  //   url: "/actionplan",
  //   cName: "nav-links",
  // },
];

export default PortfolioMenuItems;
