import React, { useContext, useState } from "react";
import "../../App.css";
import { Grid, makeStyles, Card, CardContent, Switch } from "@material-ui/core";
import "./PropertyOverviewPage.css";
import MapGL, { Marker } from "react-map-gl";
import RoomIcon from "@material-ui/icons/Room";
import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import BuildingVolumeLoader from "./BuildingVolumeChart/BuildingVolumeLoader";
import BuildingDensityLoader from "./BuildingDensityChart/BuildingDensityLoader";
import ImdChartLoader from "../FuelDetailsPage/ImdChart/ImdChartLoader";
import ImdIncomeChartLoader from "../FuelDetailsPage/ImdIncomeChart/ImdIncomeChartLoader";
import EnergyConsumptionLoader from "./EnergyConsumption/EnergyConsumptionLoader";
import BuildingAgeLoader from "./BuildingAgeChart/BuildingAgeLoader";
import BuildingHeightChartLoader from "./BuildingHeightChart/BuildingHeightChartLoader";
import { UserContext } from "../../contexts/UserContext";
import { getData } from "../get-data";
import LoadingOverlay from "react-loading-overlay-ts";

const useStyles = makeStyles({
  tableCell: {
    height: 10,
  },
});

interface IProps {
  propertyDataLoading: boolean;
  selectedProperty: IProperty;
  propertyDetails: any;
  fetchPropertyDetails: (property: number) => Promise<void>;
  fetchProperties: () => void;
  properties: IProperty[];
  setSelectedProperty: React.Dispatch<React.SetStateAction<IProperty>>;
  setSingleProperty: React.Dispatch<React.SetStateAction<boolean>>;
  reload: (id?: number) => Promise<void> | null;
}

interface IParams {
  id: string | undefined;
}

export default function PropertyOverviewPage(props: IProps) {
  const userContext = useContext(UserContext);
  const [toggleSat, setToggleSat] = useState<boolean>(false);
  const [toid, setToid] = useState<string>("");
  const classes = useStyles();
  const [buildingAge, setBuildingAge] = useState<string>("");
  const history = useHistory();
  const [viewport, setViewport] = useState({
    latitude: props.selectedProperty.lat,
    longitude: props.selectedProperty.lon,
    width: "100%",
    height: "100%",
    zoom: 18,
  });
  let { id } = useParams<IParams>();
  const [buildingAttributes, setBuildingAttributes] = useState<
    BuildingAttributes | undefined
  >(undefined);
  const [lsoaDetails, setLsoaDetails] = useState<LSOA | undefined>(undefined);
  const [propertyDataLoading, setPropertyDataLoading] = useState<boolean>(true);

  useEffect(() => {
    if (Object.keys(props.propertyDetails).length === 0 && id) {
      props.fetchProperties();
      props.fetchPropertyDetails(parseInt(id)).catch((e) => {
        history.push("/404");
      });
    }
  }, [props.propertyDetails]);

  useEffect(() => {
    if (id && Object.keys(props.selectedProperty).length === 0) {
      let found = props.properties.find(
        (property) => property.property_id === parseInt(id!)
      );
      if (found) {
        props.setSelectedProperty(found);
      }
    }
  }, [props.properties]);

  useEffect(() => {
    setViewport({
      ...viewport,
      latitude: props.selectedProperty.lat,
      longitude: props.selectedProperty.lon,
    });
    getToid();
  }, [props.selectedProperty]);

  useEffect(() => {
    props.setSingleProperty(true);
    getBuildingAttributes();
    getToid();
  }, [props.selectedProperty]);
  const getToid = () => {
    getData(
      "https://captapi.absolarlab.com/api/sap/toid?propertyId=" + id,
      {
        userState: userContext.userState,
        setUserState: userContext.setUserState,
      },
      true
    )
      .then((toid: string) => {
        setToid(toid);
      })
      .catch((e) => {
        console.log("couldn't get toid");
      });
  };
  const getBuildingAttributes = () => {
    if (props.selectedProperty.uprn) {
      const url =
        "/api/sap/buildingattribute?uprn=" + props.selectedProperty.uprn;

      getData("https://captapi.absolarlab.com" + url, userContext)
        .then((buildingAttributesDto: BuildingAttributes) => {
          setBuildingAttributes(buildingAttributesDto);
        })
        .then(() => {
          setPropertyDataLoading(false);
        });
    }
  };
  const getBuildingAge = () => {
    let years = [
      "Pre 1900",
      "1900 - 1918",
      "1919 - 1929",
      "1930 - 1939",
      "1945 - 1954",
      "1955 - 1964",
      "1965 - 1972",
      "1973 - 1982",
      "1983 - 1992",
      "1993 - 1999",
      "2000 - 2008",
      "2009 - 2011",
      "2012 - 2014",
      "2015 - 2017",
      "2018 - 2020",
      "Post 2021",
    ];
    getData(
      "https://captapi.absolarlab.com/api/sap/buildingage?propertyId=" + id,
      userContext,
      true
    )
      .then((response) => response.text())
      .then((buildingAge) => {
        if (buildingAge !== "NO DATA!") {
          let age = buildingAge.substring(buildingAge.length - 4);

          let output = years.reduce((prev, curr) =>
            Math.abs(
              parseInt(curr.substring(curr.length - 4)) - parseInt(age)
            ) <
            Math.abs(parseInt(prev.substring(prev.length - 4)) - parseInt(age))
              ? curr
              : prev
          );
          setBuildingAge(output);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  useEffect(() => {
    if (Object.keys(props.propertyDetails).length === 0 && id) {
      props.reload(parseInt(id))?.catch((e) => {
        history.push("/404");
      });
    }
    getBuildingAge();
  }, []);

  useEffect(() => {
    getLSOADetails();
  }, [buildingAttributes?.lsoa11cd]);
  const getLSOADetails = () => {
    if (buildingAttributes) {
      const url = "/api/sap/lsoa?lsoa=" + buildingAttributes.lsoa11cd;
      getData("https://captapi.absolarlab.com" + url).then((lsoa: LSOA) => {
        setLsoaDetails(lsoa);
      });
    }
  };

  return (
    <LoadingOverlay
      className="myLoading"
      active={propertyDataLoading || props.propertyDataLoading}
      spinner
    >
      {Object.keys(props.selectedProperty).length === 0 ? (
        <></>
      ) : (
        <Card className="card" style={{ backgroundColor: "#113d64" }}>
          <CardContent className="header">
            <h3 className="headerText">{props.selectedProperty.address}</h3>
          </CardContent>
          <hr className="solid" />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <div className="top_headline">
                  <div>UPRN</div>
                  <div className="headline_result">
                    {props.propertyDetails.uprn}
                  </div>
                </div>
              </Grid>
              <Grid item xs={3}>
                <div className="top_headline">
                  <div>Property type</div>
                  <div className="headline_result">
                    {props.propertyDetails.property_type}
                  </div>
                </div>
              </Grid>
              <Grid item xs={3}>
                <div className="top_headline">
                  <div>Property class</div>
                  <div className="headline_result">
                    {/* {props.propertyDetails.built_form} */}
                    Residential
                  </div>
                </div>
              </Grid>
              <Grid item xs={3}>
                <div className="top_headline">
                  <div>Property Tenure</div>
                  <div className="headline_result">
                    {/* {props.propertyDetails.tenure} */}
                    Social Housing
                  </div>
                </div>
              </Grid>
            </Grid>
            <h3 className="headerText">Property Overview</h3>
            <h3 style={{ color: "white" }}>
              Toggle Satellite view
              <Switch
                checked={toggleSat}
                onChange={() => {
                  setToggleSat(!toggleSat);
                }}
              />
            </h3>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <div
                  className="panel_card"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <MapGL
                    {...viewport}
                    mapboxApiAccessToken="pk.eyJ1IjoianNhbmNoOTgiLCJhIjoiY2tzNHVpajEwMjg1cjJwbXNoeGY0NmhkcyJ9.OGFBN2CHTg4vUIIVOk0CIw"
                    mapStyle={
                      toggleSat
                        ? "mapbox://styles/mapbox/satellite-v9"
                        : "mapbox://styles/mapbox/light-v9"
                    }
                  >
                    <Marker
                      key={"key"}
                      latitude={props.selectedProperty.lat}
                      longitude={props.selectedProperty.lon}
                      offsetLeft={-1}
                      offsetTop={-1}
                    >
                      <RoomIcon
                        style={{ color: toggleSat ? "red" : "black" }}
                      />
                    </Marker>
                  </MapGL>
                </div>
              </Grid>

              <Grid item xs={6}>
                <div
                  style={{ height: "50vh", overflow: "auto" }}
                  className="panel_card"
                >
                  <div className="panel_title">Building age in LSOA</div>
                  {lsoaDetails ? (
                    <BuildingAgeLoader value={lsoaDetails} age={buildingAge} />
                  ) : null}
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="panel_card">
                  <div className="panel_title">
                    Building structural characteristics compared with
                    authority-wide statistics
                  </div>
                  <div className="panel_card_graphs" style={{ minHeight: 200 }}>
                    <div style={{ width: "50%" }}>
                      <BuildingVolumeLoader
                        value={
                          buildingAttributes
                            ? buildingAttributes.volumn >= 1400
                              ? 1400
                              : buildingAttributes.volumn
                            : null
                        }
                        realValue={
                          buildingAttributes ? buildingAttributes.volumn : null
                        }
                      />
                    </div>
                    <div style={{ width: "50%" }}>
                      <BuildingDensityLoader
                        value={
                          buildingAttributes
                            ? buildingAttributes.bld_height
                            : null
                        }
                      />
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="panel_card">
                  <div className="panel_title">Building Height Data</div>
                  <div className="panel_card_graphs" style={{ minHeight: 200 }}>
                    <BuildingHeightChartLoader
                      maxHeight={
                        buildingAttributes ? buildingAttributes.relHMax : 0
                      }
                      heightToBase={
                        buildingAttributes ? buildingAttributes.relH2 : 0
                      }
                    />
                  </div>
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}
    </LoadingOverlay>
  );
}
